export const GET_LIST_USERS_REQUEST = 'GET_LIST_USERS_REQUEST';
export const GET_LIST_USERS_SUCCESS = 'GET_LIST_USERS_SUCCESS';
export const GET_LIST_USERS_FAILURE = 'GET_LIST_USERS_FAILURE';

export const SET_LOADING = 'SET_LOADING';

export const IMPORT_REQUEST = 'IMPORT_REQUEST';
export const IMPORT_SUCCESS = 'IMPORT_SUCCESS';
export const IMPORT_FAILURE = 'IMPORT_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const GET_LIST_CHECKIN_CHECKOUT_REQUEST = 'GET_LIST_CHECKIN_CHECKOUT_REQUEST';
export const GET_LIST_CHECKIN_CHECKOUT_SUCCESS = 'GET_LIST_CHECKIN_CHECKOUT_SUCCESS';
export const GET_LIST_CHECKIN_CHECKOUT_FAILURE = 'GET_LIST_CHECKIN_CHECKOUT_FAILURE';

export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'DOWNLOAD_FAILURE';

export const GET_CHECK_LIST_REQUEST = 'GET_CHECK_LIST_REQUEST';
export const GET_CHECK_LIST_SUCCESS = 'GET_CHECK_LIST_SUCCESS';
export const GET_CHECK_LIST_FAILURE = 'GET_CHECK_LIST_FAILURE';

export const SUBMIT_CHECKLIST_ITEM_REQUEST = 'SUBMIT_CHECKLIST_ITEM_REQUEST';
export const SUBMIT_CHECKLIST_ITEM_SUCCESS = 'SUBMIT_CHECKLIST_ITEM_SUCCESS';
export const SUBMIT_CHECKLIST_ITEM_FAILURE = 'SUBMIT_CHECKLIST_ITEM_FAILURE';

export const GET_LIST_PHOTOS_REQUEST = 'GET_LIST_PHOTOS_REQUEST';
export const GET_LIST_PHOTOS_SUCCESS = 'GET_LIST_PHOTOS_SUCCESS';
export const GET_LIST_PHOTOS_FAILURE = 'GET_LIST_PHOTOS_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const RESCHEDULE_CHECKLIST_REQUEST = 'RESCHEDULE_CHECKLIST_REQUEST';
export const RESCHEDULE_CHECKLIST_SUCCESS = 'RESCHEDULE_CHECKLIST_SUCCESS';
export const RESCHEDULE_CHECKLIST_FAILURE = 'RESCHEDULE_CHECKLIST_FAILURE';

export const DOWNLOAD_PHOTO_REQUEST = 'DOWNLOAD_PHOTO_REQUEST';
export const DOWNLOAD_PHOTO_SUCCESS = 'DOWNLOAD_PHOTO_SUCCESS';
export const DOWNLOAD_PHOTO_FAILURE = 'DOWNLOAD_PHOTO_FAILURE';
export const DOWNLOAD_PHOTO_CLEAR = 'DOWNLOAD_PHOTO_CLEAR';

export const CLEAR_LOADING = "CLEAR_LOADING"

export const GET_AUDIT_PROGRESS_REQUEST = 'GET_AUDIT_PROGRESS_REQUEST';
export const GET_AUDIT_PROGRESS_SUCCESS = 'GET_AUDIT_PROGRESS_SUCCESS';
export const GET_AUDIT_PROGRESS_FAILURE = 'GET_AUDIT_PROGRESS_FAILURE';

export const GET_AUDIT_PROGRESS_MIX_REQUEST = 'GET_AUDIT_PROGRESS_MIX_REQUEST';
export const GET_AUDIT_PROGRESS_MIX_SUCCESS = 'GET_AUDIT_PROGRESS_MIX_SUCCESS';
export const GET_AUDIT_PROGRESS_MIX_FAILURE = 'GET_AUDIT_PROGRESS_MIX_FAILURE';

export const GET_AUDIT_PROGRESS_INDEX_SHOPS_REQUEST = 'GET_AUDIT_PROGRESS_INDEX_SHOPS_REQUEST';
export const GET_AUDIT_PROGRESS_INDEX_SHOPS_SUCCESS = 'GET_AUDIT_PROGRESS_INDEX_SHOPS_SUCCESS';
export const GET_AUDIT_PROGRESS_INDEX_SHOPS_FAILURE = 'GET_AUDIT_PROGRESS_INDEX_SHOPS_FAILURE';

export const GET_KPI_REPORT_REQUEST = 'GET_KPI_REPORT_REQUEST';
export const GET_KPI_REPORT_SUCCESS = 'GET_KPI_REPORT_SUCCESS';
export const GET_KPI_REPORT_FAILURE = 'GET_KPI_REPORT_FAILURE';

export const GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST = 'GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST';
export const GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS = 'GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS';
export const GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE = 'GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE'

export const GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST_IN_MONTH = 'GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST_IN_MONTH';
export const GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS_IN_MONTH = 'GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS_IN_MONTH';
export const GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE_IN_MONTH = 'GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE_IN_MONTH';

export const SAVE_FILTER = 'SAVE_FILTER';

export const GET_AUDITOR_PERFORMANCE_REQUEST = 'GET_AUDITOR_PERFORMANCE_REQUEST';
export const GET_AUDITOR_PERFORMANCE_SUCCESS = 'GET_AUDITOR_PERFORMANCE_SUCCESS';
export const GET_AUDITOR_PERFORMANCE_FAILURE = 'GET_AUDITOR_PERFORMANCE_FAILURE';