import axios from 'axios';
import store from './store';

const apiUrl = process.env.REACT_APP_API_URL;

const api = (contentType, api_url, withoutCredentials, responseType) => {
  const root = JSON.parse(localStorage.getItem('persist:root'));
  const { auth } = store.getState();
  let headers;
  if (auth.headers.Authorization !== '') {
    headers = auth.headers;
  } else {
    if (root) {
      headers = JSON.parse(root.auth).headers;
    }
  }

  headers.App = 'osa2-webportal';
  headers.Platform = 'web';
  headers.AppVersion = '4';
  // headers.version = '2.2.81';
  if (contentType) {
    headers['Content-Type'] = contentType;
  }
  if (!api_url) {
    api_url = apiUrl;
  }
  let option = {
    baseURL: api_url,
    headers,
    withCredentials: !withoutCredentials,
  };
  if (responseType) {
    option.responseType = responseType;
  }
  const api = axios.create(option);
  return api;
};

export default api;
