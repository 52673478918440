import React from 'react';
import {
    Row,
    Typography,
    Button
} from 'antd';
import NumberFormat from 'react-number-format';
function Prool(props) {
    const item = props.item || {};
    let data = { ...item.data };
    delete data.pass;
    delete data.qc_note;
    delete data.predefined_qc_note;
    const { Text } = Typography;
    return <div key={'id' + item.id} >

        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Chương trình:</b></Text>
            <Text>
                {item.mechanic || ''}
            </Text>
        </Row>

        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Vị trí chấm điểm:</b></Text>
            <Text>
                {item.custom_attributes.location || ''}
            </Text>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Giá KA</b></Text>
            <NumberFormat value={item.custom_attributes.ka_input_price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={0} renderText={(value) => <Text>{value}</Text>} />
        </Row>
        {Object.entries(data).length ? <table style={{ marginBottom: 5 }}>
            <thead className="ant-table-thead">
                <tr>
                    <th style={{ padding: 5 }}></th>
                    {Object.entries(data).map(([key, value]) => {
                        return (value && value.Checked ? <th key={'key' + key} style={{ padding: 5 }}>{`${key}`}</th> : null)
                    })}
                </tr>
            </thead>
            <tbody className="ant-table-tbody">
                <tr>
                    <td style={{ padding: 5 }}>Hiện diện sản phẩm</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Presence';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{<Button size='small' type={data[key][k] == 'Y' ? 'primary' : 'danger'}>{data[key][k]}</Button>}</td> : null
                    })}
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Có thông báo khuyến mãi</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Notice';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{<Button size='small' type={data[key][k] == 'Y' ? 'primary' : 'danger'}>{data[key][k]}</Button>}</td> : null
                    })}
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Sản phẩm đúng nội dung khuyến mãi</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Content';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{<Button size='small' type={data[key][k] == 'Y' ? 'primary' : 'danger'}>{data[key][k]}</Button>}</td> : null
                    })}
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Cửa hàng đồng ý audit điều chỉnh bảng giá</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Allow Price Tag Adjustment';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{<Button size='small' type={data[key][k] == 'Y' ? 'primary' : 'danger'}>{data[key][k]}</Button>}</td> : null
                    })}
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Bảng giá đạt vị trí sau khi audit điều chỉnh</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Price Tag Passes After Adjustment';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{<Button size='small' type={data[key][k] == 'Y' ? 'primary' : 'danger'}>{data[key][k]}</Button>}</td> : null
                    })}
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Ghi chú</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Note';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{data[key][k]}</td> : null
                    })}
                </tr>
            </tbody>
        </table> : null}
        {/* {Object.entries(data).map(([key, value]) => {
            return (value.Checked ? <div key={key} style={{ border: '1px solid #eaeaea', padding: 5, marginBottom: 3 }}>
                <Row key={key} style={{ justifyContent: 'space-between' }}>
                    <Text><b>{`${key}`}</b></Text>
                    <Text>{value.Checked ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</Text>
                </Row>
                {Object.entries(value).map(([k, v]) => {
                    return (k != 'Checked' ? <Row key={k} style={{ justifyContent: 'space-between', paddingLeft: 10, borderTop: '1px solid #f5f5f5', paddingTop: 3, paddingBottom: 3 }}>
                        <Text><i>{(k == 'Presence') ? 'Hiện diện sản phẩm' : ((k == 'Notice') ? 'Có thông báo khuyến mãi' : ((k == 'Content') ? 'Sản phẩm đúng nội dung khuyến mãi' : k))}:</i></Text>
                        {k == 'Note' ? <Text>{v}</Text> : <Button type={v == 'Y' ? 'primary' : 'danger'}>{v}</Button>}
                    </Row> : null)
                })}
            </div> : null)
        })} */}
    </div>
}
export default React.memo(Prool);