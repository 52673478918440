import initialState from './initialState';
import {
  GET_LIST_USERS_REQUEST,
  GET_LIST_USERS_SUCCESS,
  GET_LIST_USERS_FAILURE,
  SET_LOADING,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  GET_LIST_CHECKIN_CHECKOUT_REQUEST,
  GET_LIST_CHECKIN_CHECKOUT_SUCCESS,
  GET_LIST_CHECKIN_CHECKOUT_FAILURE,
  IMPORT_SUCCESS,
  IMPORT_REQUEST,
  IMPORT_FAILURE,
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_FAILURE,
  GET_CHECK_LIST_REQUEST,
  GET_CHECK_LIST_SUCCESS,
  GET_CHECK_LIST_FAILURE,
  GET_LIST_PHOTOS_REQUEST,
  GET_LIST_PHOTOS_SUCCESS,
  GET_LIST_PHOTOS_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  RESCHEDULE_CHECKLIST_REQUEST,
  RESCHEDULE_CHECKLIST_SUCCESS,
  RESCHEDULE_CHECKLIST_FAILURE,
  DOWNLOAD_PHOTO_REQUEST,
  DOWNLOAD_PHOTO_SUCCESS,
  DOWNLOAD_PHOTO_FAILURE,
  DOWNLOAD_PHOTO_CLEAR,
  SUBMIT_CHECKLIST_ITEM_REQUEST,
  SUBMIT_CHECKLIST_ITEM_SUCCESS,
  SUBMIT_CHECKLIST_ITEM_FAILURE,
  GET_AUDIT_PROGRESS_REQUEST,
  GET_AUDIT_PROGRESS_SUCCESS,
  GET_AUDIT_PROGRESS_FAILURE,
  GET_AUDIT_PROGRESS_MIX_REQUEST,
  GET_AUDIT_PROGRESS_MIX_SUCCESS,
  GET_AUDIT_PROGRESS_MIX_FAILURE,
  GET_AUDIT_PROGRESS_INDEX_SHOPS_REQUEST,
  GET_AUDIT_PROGRESS_INDEX_SHOPS_SUCCESS,
  GET_AUDIT_PROGRESS_INDEX_SHOPS_FAILURE,
  GET_KPI_REPORT_REQUEST,
  GET_KPI_REPORT_SUCCESS,
  GET_KPI_REPORT_FAILURE,
  GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST,
  GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS,
  GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE,
  GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST_IN_MONTH,
  GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS_IN_MONTH,
  GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE_IN_MONTH,
  CLEAR_LOADING,
  SAVE_FILTER
} from './constants';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LIST_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    case GET_LIST_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errMessage: action.payload.message,
      };
    case EDIT_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        errMessage: action.payload.message,
      };
    case GET_LIST_CHECKIN_CHECKOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LIST_CHECKIN_CHECKOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listCheckInCheckOut: action.payload,
      };
    case GET_LIST_CHECKIN_CHECKOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        errMessage: action.payload.message,
      };
    case GET_LIST_PHOTOS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LIST_PHOTOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listPhotos: action.payload,
      };
    case GET_LIST_PHOTOS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errMessage: action.payload.message,
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordErrorMessage: '',
        changePasswordSuccessMessage: '',
        isLoading: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        changePasswordSuccessMessage: 'Success',
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        changePasswordErrorMessage: action.payload,
      };
    case RESCHEDULE_CHECKLIST_REQUEST:
      return {
        ...state,
        rescheduleCheckListErrorMessage: '',
        rescheduleCheckListSuccessMessage: '',
        isLoading: true,
      };
    case RESCHEDULE_CHECKLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rescheduleCheckListSuccessMessage: 'Success',
      };
    case RESCHEDULE_CHECKLIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        rescheduleCheckListErrorMessage: action.payload,
      };
    case GET_CHECK_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CHECK_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        checkList: action.payload,
      };
    case GET_CHECK_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        errMessage: action.payload.message,
      };
    case DOWNLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DOWNLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };
    case DOWNLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload.statusText,
      };
    case DOWNLOAD_PHOTO_REQUEST:
      return {
        ...state,
        fileData: null,
        isLoading: true,
        isDownLoading: true,
      };
    case DOWNLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        fileData: action.payload,
        isLoading: false,
        isDownLoading: false,
      };
    case DOWNLOAD_PHOTO_FAILURE:
      return {
        ...state,
        fileData: null,
        isLoading: false,
        isDownLoading: false,
      };
    case DOWNLOAD_PHOTO_CLEAR:
      return {
        ...state,
        fileData: null,
        isLoading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CLEAR_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case IMPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case IMPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case IMPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        errMessage: action.payload.message,
      };
    case SUBMIT_CHECKLIST_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdateItemSuccess: false,
      };
    case SUBMIT_CHECKLIST_ITEM_SUCCESS:
      let _checkList = { ...state.checkList }
      let key = action.payload.key;
      for (let i = 0; i < _checkList.checklist_items[key].length; i++) {
        if (_checkList.checklist_items[key][i].id == action.payload.id) {
          _checkList.checklist_items[key][i].data = { ..._checkList.checklist_items[key][i].data, ...action.payload.data };
          // if (!_checkList.checklist_items[key][i].data.qc_user_name) {
          //   _checkList.checklist_items[key][i].data.qc_user_name = 'test';
          // }
          break;
        }
      }
      return {
        ...state,
        isLoading: false,
        checkList: _checkList,
        isUpdateItemSuccess: true,
      };
    case SUBMIT_CHECKLIST_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdateItemSuccess: false,
      };
    case GET_AUDIT_PROGRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
        auditProgress: null
      };
    case GET_AUDIT_PROGRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        auditProgress: action.payload.data
      };
    case GET_AUDIT_PROGRESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        auditProgress: null
      };
    case GET_AUDIT_PROGRESS_MIX_REQUEST:
      return {
        ...state,
        isLoading: true,
        auditProgressMixDim: null
      };
    case GET_AUDIT_PROGRESS_MIX_SUCCESS:
      return {
        ...state,
        isLoading: false,
        auditProgressMixDim: action.payload.data
      };
    case GET_AUDIT_PROGRESS_MIX_FAILURE:
      return {
        ...state,
        isLoading: false,
        auditProgressMixDim: null
      };
    case GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        kpiCheckList: null
      };
    case GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        kpiCheckList: action.payload.data
      };
    case GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        kpiCheckList: null
      };
    case GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST_IN_MONTH:
      return {
        ...state,
        isLoading: true,
        kpiCheckListInMonth: null
      };
    case GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS_IN_MONTH:
      return {
        ...state,
        isLoading: false,
        kpiCheckListInMonth: action.payload.data
      };
    case GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE_IN_MONTH:
      return {
        ...state,
        isLoading: false,
        kpiCheckListInMonth: null
      };
    case GET_AUDIT_PROGRESS_INDEX_SHOPS_REQUEST:
      return {
        ...state,
        isLoading: true,
        auditProgressIndexShops: null
      };
    case GET_AUDIT_PROGRESS_INDEX_SHOPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        auditProgressIndexShops: action.payload.data
      };
    case GET_AUDIT_PROGRESS_INDEX_SHOPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        auditProgressIndexShops: null
      };
    case GET_KPI_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        allKpiDatas: null
      };
    case GET_KPI_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allKpiDatas: action.payload.data
      };
    case GET_KPI_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        allKpiDatas: null
      };
    case SAVE_FILTER:
      return {
        ...state,
        filterDatas: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
