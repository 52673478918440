import api from '../../../../common/axiosConfig';
import { request, success, failure } from '../../../../common/reduxActions';
import {
  GET_LIST_USERS_REQUEST,
  GET_LIST_USERS_SUCCESS,
  GET_LIST_USERS_FAILURE,
  SET_LOADING,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  EDIT_USER_REQUEST,
  GET_LIST_CHECKIN_CHECKOUT_REQUEST,
  GET_LIST_CHECKIN_CHECKOUT_SUCCESS,
  GET_LIST_CHECKIN_CHECKOUT_FAILURE,
  GET_CHECK_LIST_REQUEST,
  GET_CHECK_LIST_SUCCESS,
  GET_CHECK_LIST_FAILURE,
  SUBMIT_CHECKLIST_ITEM_REQUEST,
  SUBMIT_CHECKLIST_ITEM_SUCCESS,
  SUBMIT_CHECKLIST_ITEM_FAILURE,
  GET_LIST_PHOTOS_REQUEST,
  GET_LIST_PHOTOS_SUCCESS,
  GET_LIST_PHOTOS_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  RESCHEDULE_CHECKLIST_REQUEST,
  RESCHEDULE_CHECKLIST_SUCCESS,
  RESCHEDULE_CHECKLIST_FAILURE,
  DOWNLOAD_PHOTO_REQUEST,
  DOWNLOAD_PHOTO_SUCCESS,
  DOWNLOAD_PHOTO_FAILURE,
  DOWNLOAD_PHOTO_CLEAR,
  GET_AUDIT_PROGRESS_REQUEST,
  GET_AUDIT_PROGRESS_SUCCESS,
  GET_AUDIT_PROGRESS_FAILURE,
  GET_AUDIT_PROGRESS_MIX_REQUEST,
  GET_AUDIT_PROGRESS_MIX_SUCCESS,
  GET_AUDIT_PROGRESS_MIX_FAILURE,
  GET_AUDIT_PROGRESS_INDEX_SHOPS_REQUEST,
  GET_AUDIT_PROGRESS_INDEX_SHOPS_SUCCESS,
  GET_AUDIT_PROGRESS_INDEX_SHOPS_FAILURE,
  GET_KPI_REPORT_REQUEST,
  GET_KPI_REPORT_SUCCESS,
  GET_KPI_REPORT_FAILURE,
  GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST,
  GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS,
  GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE,
  GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST_IN_MONTH,
  GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS_IN_MONTH,
  GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE_IN_MONTH,
  GET_AUDITOR_PERFORMANCE_REQUEST,
  GET_AUDITOR_PERFORMANCE_SUCCESS,
  GET_AUDITOR_PERFORMANCE_FAILURE,
  CLEAR_LOADING,
  SAVE_FILTER
} from '../constants';
import authActions from '../../../auth/redux/actions';
import { removeVietnameseTones } from '../../../../../src/utils/string'
import { downloadZipBinary } from '../../../../../src/common/download';
import qs from 'qs';
const apiUrl = process.env.REACT_APP_API_URL;

const getListUsers = () => {
  return (dispatch) => {
    dispatch(request(GET_LIST_USERS_REQUEST));
    return api()
      .get('users')
      .then((res) => {
        console.log(res);
        dispatch(success(GET_LIST_USERS_SUCCESS, res.data));
        dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        const { status } = (error && error.response) ? error.response : 401;
        dispatch(failure(GET_LIST_USERS_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};
const getListPhotos = (checklist_id, category, sub_category) => {
  // console.log('checklist_idchecklist_id', checklist_id);
  // checklist_id = 185931;
  // category = 'ICE CREAM';
  let checklist_ids = [];
  if ((typeof checklist_id) !== 'object') {
    checklist_ids.push(checklist_id);
  } else {
    checklist_ids = checklist_id;
  }
  if ((sub_category === 'Other') || (sub_category === 'Khác')) {
    sub_category = 'Chọn Tiếp';
  }
  return async (dispatch) => {
    dispatch(request(GET_LIST_PHOTOS_REQUEST));
    let listPhotos = [];
    for (let i = 0; i < checklist_ids.length; i++) {
      let obj = {
        checklist_id: checklist_ids[i]
      }
      if (category) {
        obj.category = category;
      }
      let str = qs.stringify(obj);
      await api()
        // .get(`checkin_checkouts/3267/download_photos`)
        .get(`photos/?${str}`)
        .then((res) => {
          // console.log('params', `photos/?checklist_id=${checklist_id}&category=${category}&sub_category=${sub_category}`);

          if (res.data && res.data.length) {
            res.data.map((item) => {
              listPhotos.push({ ...item, image: `${apiUrl}${item.image}` });
            })

          }
          dispatch(authActions.updateAuthorization(res.headers));
        })
        .catch((error) => {
          const status = (error && error.response) ? error.response.status : 401;
          dispatch(failure(GET_LIST_PHOTOS_FAILURE, (error && error.response) ? error.response : { message: '' }));
          if (status === 401) {
            dispatch(authActions.logout());
          } else if (status >= 500) {
            console.log('error-getListPhotos', error);
          }
        });
    }
    dispatch(success(GET_LIST_PHOTOS_SUCCESS, listPhotos));
  };
};
const downloadPhotos = (checklist_id, type) => {
  return (dispatch) => {
    dispatch(request(DOWNLOAD_PHOTO_REQUEST));
    let urlDownload = '';
    let urlDelete = '';
    if (type == 'photo') {
      // checkin_checkouts/:id/send_confirmation_document
      urlDownload = `checkin_checkouts/${checklist_id}/download_photos`;
      urlDelete = `checkin_checkouts/${checklist_id}/delete_downloaded_photos`;
    } else if (type == 'pxn') {
      // checkin_checkouts/:id/send_confirmation_document
      urlDownload = `checkin_checkouts/${checklist_id}/send_confirmation_document`;
      urlDelete = '';
    } else {
      urlDownload = `checkin_checkouts/${checklist_id}/download_data`;
      urlDelete = `checkin_checkouts/${checklist_id}/delete_downloaded_data`;
    }
    if (urlDelete) {


      return api('', '', false, 'blob')
        // .get(`api/v1/g/test-download`)
        // .get(`checkin_checkouts/${checklist_id}/download_photos`)//cũ
        // .get(`checkin_checkouts/5650/download_data`)//hardcode
        .get(urlDownload)//mới
        // .get(`photos/?${str}`)
        .then((res) => {
          let filedata = {
            file: res.data,
            ext: 'zip',
          }
          downloadZipBinary(res.data);
          setTimeout(() => {
            // return api().post(`checkin_checkouts/${checklist_id}/delete_downloaded_photos`)//cũ  
            // return api().post(`checkin_checkouts/5650/delete_downloaded_data`)//hardcode
            return api().post(urlDelete)//mới
              .then((res) => {
                dispatch(authActions.updateAuthorization(res.headers));
              }).catch((error) => {

                const status = error && error.response ? error.response.status : 401;
                if (status === 401) {
                  dispatch(authActions.logout());
                } else if (status >= 500) {
                  console.log('error-downloadPhotos', error);
                }
              });
          }, 3000);
          // console.log('res.headers', res.headers);
          // console.log('params', `photos/?checklist_id=${checklist_id}&category=${category}&sub_category=${sub_category}`);
          dispatch(success(DOWNLOAD_PHOTO_SUCCESS, filedata));
          dispatch(authActions.updateAuthorization(res.headers));

        })
        .catch((error) => {
          dispatch(failure(DOWNLOAD_PHOTO_FAILURE));

          const status = error && error.response ? error.response.status : 401;
          if (status === 401) {
            dispatch(authActions.logout());
          } else if (status >= 500) {
            console.log('error-downloadPhotos', error);
          }
        });
    } else {
      return api()
        .post(urlDownload)
        .then((res) => {
          dispatch(authActions.updateAuthorization(res.headers));
          console.log('pxn', res);
        })
        .catch((error) => {
          // console.log('CHANGE_PASSWORD_FAILURE', error);
          const { status } = error.response || { status: 500, message: 'Server Error' };
          dispatch(authActions.logout());
          if (status === 401) {
            dispatch(authActions.logout());
          } else if (status === 406) {
            dispatch(authActions.logout());
          } else if (status >= 500) {
            dispatch(authActions.logout());
            console.log('error-pxn', error);
          }
        });
    }
  };
};
const deleteCall = (checkin) => {
  return (dispatch) => {
    return api()
      .delete(`checkin_checkouts/${checkin.id}/clear_checkin_checkout`)
      .then((res) => {
        // console.log('res', res);
        return true;
        // if (res.data && res.data.type === 'error') {
        //   dispatch(failure(CHANGE_PASSWORD_FAILURE, res.data.message));
        // } else {
        //   dispatch(success(CHANGE_PASSWORD_SUCCESS, res.data));
        // }

        // dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        // console.log('CHANGE_PASSWORD_FAILURE', error);
        // const { status } = error.response || { status: 500, message: 'Server Error' };
        // dispatch(failure(CHANGE_PASSWORD_FAILURE, error.response));
        // if (status === 401) {
        //   dispatch(authActions.logout());
        // } else if (status >= 500) {
        //   console.log('error-changePassword', error);
        // }
        // return false;
      });
  };
};
const clearDownloadPhotos = () => {
  return (dispatch) => {
    dispatch(request(DOWNLOAD_PHOTO_CLEAR));
  }
};
const clearLoading = () => {
  return (dispatch) => {
    dispatch(success(CLEAR_LOADING));
  }
};
const changePassword = (data) => {
  return (dispatch) => {
    dispatch(request(CHANGE_PASSWORD_REQUEST));
    const { user_id, old_password, new_password } = data;
    return api()
      .post(`users/${user_id}/change_password`, { old_password: old_password, new_password: new_password })
      .then((res) => {
        dispatch(authActions.updateAuthorization(res.headers));
        if (res.data && res.data.type === 'error') {
          dispatch(failure(CHANGE_PASSWORD_FAILURE, res.data.message));
        } else {
          dispatch(success(CHANGE_PASSWORD_SUCCESS, res.data));
        }

        // dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        // console.log('CHANGE_PASSWORD_FAILURE', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(CHANGE_PASSWORD_FAILURE, error.response));
        dispatch(authActions.logout());
        if (status === 401) {
          dispatch(authActions.logout());
        } else if (status === 406) {
          dispatch(authActions.logout());
        } else if (status >= 500) {
          dispatch(authActions.logout());
          console.log('error-changePassword', error);
        }
      });
  };
};
const rescheduleChecklists = (data) => {
  return (dispatch) => {
    dispatch(request(RESCHEDULE_CHECKLIST_REQUEST));
    return api()
      .post(`shops/reschedule_checklists`, data)
      .then((res) => {
        // console.log('res', res);
        // console.log('res.data', res.data && res.data.error);
        if (res.data && res.data.error) {
          dispatch(failure(RESCHEDULE_CHECKLIST_FAILURE, res.data.error));
        } else {
          // console.log('222');
          dispatch(success(RESCHEDULE_CHECKLIST_SUCCESS, res.data));
        }

        dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        const { status } = error.response || { status: 500, message: 'Server Error' };
        console.log(error.response);
        dispatch(failure(RESCHEDULE_CHECKLIST_FAILURE, 'lỗi'));
        if (status === 401) {
          dispatch(authActions.logout());
        } else if (status >= 500) {
          console.log('error-rescheduleChecklists', error);
        }
      });
  };
};
const editUser = (userId, data) => {
  return (dispatch) => {
    dispatch(request(EDIT_USER_REQUEST));
    return api()
      .put('users/' + userId, data)
      .then((res) => {
        dispatch(success(EDIT_USER_SUCCESS, res.status));
        dispatch(authActions.updateAuthorization(res.headers));
        console.log(res);
      })
      .catch((error) => {
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(EDIT_USER_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};

const lockUser = (userId) => {
  return (dispatch) => {
    dispatch(request(EDIT_USER_REQUEST));
    return api()
      .post(`users/${userId}/lock`, {})
      .then((res) => {
        dispatch(success(EDIT_USER_SUCCESS, res.status));
        dispatch(authActions.updateAuthorization(res.headers));
        console.log(res);
      })
      .catch((error) => {
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(EDIT_USER_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};

const unlockUser = (userId) => {
  return (dispatch) => {
    dispatch(request(EDIT_USER_REQUEST));
    return api()
      .post(`users/${userId}/unlock`, {})
      .then((res) => {
        dispatch(success(EDIT_USER_SUCCESS, res.status));
        dispatch(authActions.updateAuthorization(res.headers));
        console.log(res);
      })
      .catch((error) => {
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(EDIT_USER_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};

const getCheckInCheckOut = (date, delayed) => {

  const dateFrom = date.length > 0 ? date[0] : '';
  const dateTo = date.length > 1 ? date[1] : '';
  let _endpoint = `checkin_checkouts?date_from=${dateFrom}&date_to=${dateTo}`;
  if (delayed) {
    _endpoint += '&delayed=true'
  }
  // console.log('_endpoint', _endpoint);
  return (dispatch) => {
    dispatch(request(GET_LIST_CHECKIN_CHECKOUT_REQUEST));
    return api()
      .get(_endpoint)
      .then((res) => {
        // let _data = (res.data && res.data.length) ? res.data.filter((item) => {
        //   return item.data && item.data.pass === false
        // }) : []
        //bainguyen bo set key
        // res.data = res.data.map((data) => {
        //   data.key = data.id;
        //   return data;
        // });
        res.data.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        // console.log('res.headers', res.headers);
        dispatch(authActions.updateAuthorization(res.headers));
        // console.log('authActions');
        dispatch(success(GET_LIST_CHECKIN_CHECKOUT_SUCCESS, res.data));
        // console.log('authActions', res);
      })
      .catch((error) => {
        console.log(error);
        if (!error.response) {
          dispatch(authActions.logout());
        }
        let response = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(GET_LIST_CHECKIN_CHECKOUT_FAILURE, response));
        if (response.status === 401 || response.status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};

const getCheckListById = (checkListId, for_review) => {
  return (dispatch) => {
    dispatch(request(GET_CHECK_LIST_REQUEST));
    let endpoint = 'checklists/';
    for (let i = 0; i < checkListId.length; i++) {
      endpoint += (!i) ? '?checklist_ids[]=' + checkListId[i] : '&checklist_ids[]=' + checkListId[i];
    }
    return api()
      .get(endpoint)
      .then((res) => {
        // console.log('res', res);
        let data = {
          categories: {},
          checklist_items: {},
          checklistTypes: {}
        };
        let categories = {};
        let checklistTypes = {};
        let checklist_items = {};
        for (let j = 0; j < res.data.length; j++) {
          for (let kk = 0; kk < res.data[j].checklist_items.length; kk++) {
            let item = res.data[j].checklist_items[kk];
            if (for_review) {
              if (item.data.pass !== false) {
                continue;
              }
            }
            let categoryCode = item.custom_attributes.category || 'Other';
            let subcategory = (item.custom_attributes.sub_category) ? {
              sub_category: item.custom_attributes.sub_category,
              sub_category_id: item.custom_attributes.sub_category_id,
              sub_category_vn: item.custom_attributes.sub_category_vn,
            } : {
              sub_category: 'Other',
              sub_category_id: 'Other',
              sub_category_vn: 'Khác',
            }

            let combineKey = removeVietnameseTones(categoryCode + '-' + subcategory.sub_category, '-');
            if (typeof (checklistTypes[combineKey]) == 'undefined') {
              checklistTypes[combineKey] = [res.data[j].id]
            } else {

              if (!checklistTypes[combineKey].includes(res.data[j].id)) {
                checklistTypes[combineKey].push(
                  res.data[j].id
                )
              }

            }

            if (typeof (categories[categoryCode]) === 'undefined') {
              categories[categoryCode] = {
                category: item.custom_attributes.category || 'Other',
                category_code: categoryCode || 'Other',
                category_id: item.custom_attributes.category_id || 0,
                category_vn: item.custom_attributes.category_vn || 'Khác',
                subcategory: {},
              }
              categories[categoryCode]['subcategory'][subcategory.sub_category] = subcategory;
            } else {
              if (typeof (categories[categoryCode]['subcategory'][subcategory.sub_category] === 'undefined')) {
                categories[categoryCode]['subcategory'][subcategory.sub_category] = subcategory;
              }
            }
            item = {
              ...item,
              data: {
                ...item.data,
                pass: ((item.data.pass !== null) && (typeof (item.data.pass) !== 'undefined')) ? item.data.pass : true,
                review_pass: ((item.data.review_pass !== null) && (typeof (item.data.review_pass) !== 'undefined')) ? !!item.data.review_pass : true,
                qc_note: item.data.qc_note || '',
                predefined_qc_note: item.data.predefined_qc_note || '',
              },
              photos: item.photos
                ? item.photos.map((photo) => {
                  return {
                    ...photo,
                    uri: `${apiUrl}${photo.image}`,
                  };
                })
                : [],
            };
            let combineKey2 = combineKey + '-' + item.checklist_type.toUpperCase();

            if (typeof (checklist_items[combineKey2]) == 'undefined') {
              // if (categoryCode == 'DEODORANTS' && res.data[j].checklist_type == 'osa' && subcategory.sub_category == 'Other') {
              //   console.log('combineKey2', combineKey2);
              // }
              checklist_items[combineKey2] = [item]
            } else {
              checklist_items[combineKey2].push(item);
              // if (categoryCode == 'DEODORANTS' && res.data[j].checklist_type == 'osa' && subcategory.sub_category == 'Other') {
              //   console.log('combineKey2', combineKey2);
              // }
            }
          };
          data.checklist_items = { ...data.checklist_items, ...checklist_items };
        }
        data.categories = { ...categories };
        data.checklistTypes = { ...checklistTypes };
        // const data = {
        //   ...res.data,
        //   checklist_items: res.data.checklist_items.map((item) => {
        //     return {
        //       ...item,
        //       data: {
        //         ...item.data,
        //         pass: item.data.pass || true,
        //         qc_note: item.data.qc_note || '',
        //       },
        //       photos: item.photos
        //         ? item.photos.map((photo) => {
        //           return {
        //             ...photo,
        //             uri: `${apiUrl}${photo.image}`,
        //           };
        //         })
        //         : [],
        //     };
        //   }),
        // };
        dispatch(success(GET_CHECK_LIST_SUCCESS, data));
        dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        console.log(error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(GET_CHECK_LIST_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};

const submitChecklistItem = (itemId, payload) => {
  let key = payload.key;
  delete payload.key;
  return (dispatch) => {
    dispatch(request(SUBMIT_CHECKLIST_ITEM_REQUEST));
    return api()
      .post(`checklist_items/${itemId}`, payload)
      .then((res) => {
        dispatch(success(SUBMIT_CHECKLIST_ITEM_SUCCESS, { id: itemId, data: res.data.data, key }));
        dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(SUBMIT_CHECKLIST_ITEM_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};
const requestSupport = (payload) => {
  // checkin_id
  // checkout_id
  // checklist_item_id

  return (dispatch) => {
    return api()
      .post(`support/checklist_item`, payload)
      .then((res) => {
        console.log('support/checklist_item', res);

        // dispatch(success(SUBMIT_CHECKLIST_ITEM_SUCCESS, { id: itemId, data: res.data.data, key }));
        dispatch(authActions.updateAuthorization(res.headers));
        return res;
      })
      .catch((error) => {
        const { status } = error.response || { status: 500, message: 'Server Error' };
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
        return null;
      });
  };
};
const getAuditProgress = (data) => {
  const date_from = data?.date_from || '';
  const date_to = data?.date_to || '';


  return (dispatch) => {
    dispatch(request(GET_AUDIT_PROGRESS_REQUEST));
    return api(null, process.env.REACT_APP_DASHBOARD_API_URL)
      .get(`audit_progress/?date_from=${date_from}&date_to=${date_to}`)
      .then((res) => {
        dispatch(success(GET_AUDIT_PROGRESS_SUCCESS, { data: res.data }));
        // dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        console.log('getAuditProgress-homeaction', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(GET_AUDIT_PROGRESS_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};
const getAuditProgressMixDims = (data) => {
  const date_from = data?.date_from || '';
  const date_to = data?.date_to || '';
  // console.log('process.env.REACT_APP_DASHBOARD_API_URL', process.env.REACT_APP_DASHBOARD_API_URL);
  return (dispatch) => {
    dispatch(request(GET_AUDIT_PROGRESS_MIX_REQUEST));
    return api(null, process.env.REACT_APP_DASHBOARD_API_URL)
      .get(`audit_progress/index_cicos/?date_from=${date_from}&date_to=${date_to}`)
      .then((res) => {
        // console.log('success-getAuditProgressMixDims');
        dispatch(success(GET_AUDIT_PROGRESS_MIX_SUCCESS, { data: res.data }));
        return res.data;
        // dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        // console.log('getAuditProgressMixDims-homeaction', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(GET_AUDIT_PROGRESS_MIX_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};
const getAuditProgressMixDimsYtd = (data) => {
  const date_from = data?.date_from || '';
  const date_to = data?.date_to || '';
  return (dispatch) => {
    dispatch(request(GET_AUDIT_PROGRESS_MIX_REQUEST));
    return api(null, process.env.REACT_APP_DASHBOARD_API_URL)
      .get(`auditor_performance/index_cicos_by_ytd/?date_from=${date_from}&date_to=${date_to}`)
      .then((res) => {
        // console.log('success-getAuditProgressMixDims');
        dispatch(success(GET_AUDIT_PROGRESS_MIX_SUCCESS, { data: res.data }));
        return res.data;
        // dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        // console.log('getAuditProgressMixDims-homeaction', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(GET_AUDIT_PROGRESS_MIX_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};
const getAuditorPerformance3Month = (data) => {
  const date_from = data?.date_from || '';
  const date_to = data?.date_to || '';
  return (dispatch) => {
    dispatch(request(GET_AUDITOR_PERFORMANCE_REQUEST));
    return api(null, process.env.REACT_APP_DASHBOARD_API_URL)
      .get(`auditor_performance/index_cicos_by_3_months/?date_from=${date_from}&date_to=${date_to}`)
      .then((res) => {
        // console.log('success-getAuditProgressMixDims');
        dispatch(success(GET_AUDITOR_PERFORMANCE_SUCCESS, { data: res.data }));
        return res.data;
        // dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        // console.log('getAuditProgressMixDims-homeaction', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(GET_AUDITOR_PERFORMANCE_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};
const getAuditProgressIndexShops = (data) => {
  const date_from = data?.date_from || '';
  const date_to = data?.date_to || '';
  return (dispatch) => {
    dispatch(request(GET_AUDIT_PROGRESS_INDEX_SHOPS_REQUEST));
    return api(null, process.env.REACT_APP_DASHBOARD_API_URL)
      .get(`audit_progress/index_shops/?date_from=${date_from}&date_to=${date_to}`)
      .then((res) => {
        // console.log('success-getAuditProgressIndexShops');
        dispatch(success(GET_AUDIT_PROGRESS_INDEX_SHOPS_SUCCESS, { data: res.data }));
        // dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        // console.log('getAuditProgressIndexShops-homeaction', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(GET_AUDIT_PROGRESS_INDEX_SHOPS_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};
const getKpiReport = (data) => {
  const date_from = data?.date_from || '';
  const date_to = data?.date_to || '';
  return (dispatch) => {
    dispatch(request(GET_KPI_REPORT_REQUEST));
    return api(null, process.env.REACT_APP_DASHBOARD_API_URL)
      .get(`kpi_report/index_cicos/?date_from=${date_from}&date_to=${date_to}`)
      .then((res) => {
        console.log('success-getKpiReport');
        dispatch(success(GET_KPI_REPORT_SUCCESS, { data: res.data }));
        // dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        console.log('getKpiReport-homeaction', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(GET_KPI_REPORT_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};
const saveFilter = (data) => {
  return (dispatch) => {
    dispatch(success(SAVE_FILTER, data));
  };
};
const getKpiReportIndexCheckList = (data) => {
  const date_from = data?.date_from || '';
  const date_to = data?.date_to || '';
  return (dispatch) => {
    dispatch(request(GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST));
    return api(null, process.env.REACT_APP_DASHBOARD_API_URL)
      .get(`kpi_report/index_checklists/?date_from=${date_from}&date_to=${date_to}`)
      .then((res) => {
        console.log('success-getKpiReportIndexCheckList');
        dispatch(success(GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS, { data: res.data }));
        // dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        console.log('getKpiReportIndexCheckList-homeaction', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};
const getKpiReportIndexCheckListInMonth = (data) => {
  const date_in_month = data?.date_in_month || '';
  return (dispatch) => {
    dispatch(request(GET_KPI_REPORT_INDEX_CHECKLIST_REQUEST_IN_MONTH));
    return api(null, process.env.REACT_APP_DASHBOARD_API_URL)
      .get(`kpi_report/index_checklists_by_month/?date_in_month=${date_in_month}`)
      .then((res) => {
        console.log('success-getKpiReportIndexCheckListInMonth');
        dispatch(success(GET_KPI_REPORT_INDEX_CHECKLIST_SUCCESS_IN_MONTH, { data: res.data }));
        // dispatch(authActions.updateAuthorization(res.headers));
      })
      .catch((error) => {
        console.log('getKpiReportIndexCheckListInMonth-homeaction', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        dispatch(failure(GET_KPI_REPORT_INDEX_CHECKLIST_FAILURE_IN_MONTH, error.response));
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
      });
  };
};
const getKpiReportIndexCheckListInMonthByCustomer = (data) => {
  // customer:string
  // date_in_month:string
  let str = qs.stringify(data);
  return (dispatch) => {
    dispatch(request(SET_LOADING));
    return api(null, process.env.REACT_APP_DASHBOARD_API_URL)
      .get(`kpi_report/index_checklist_facts_by_customer/?${str}`)
      .then((res) => {
        dispatch(request(CLEAR_LOADING));
        console.log('success-getKpiReportIndexCheckListInMonthByCustomer');
        return res.data;
      })
      .catch((error) => {
        dispatch(request(CLEAR_LOADING));
        console.log('getKpiReportIndexCheckListInMonthByCustomer-homeaction', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
        return null;
      });
  };
};
const setLoading = (type) => {
  return (dispatch) => {
    if (type) {
      dispatch(request(SET_LOADING));
    } else {
      dispatch(request(CLEAR_LOADING));
    }
  }
}
const getKpiReportCheckListItemByCategory = (data) => {
  // customer:string
  // date_in_month:string

  let str = qs.stringify(data);
  return (dispatch) => {
    // dispatch(request(SET_LOADING));
    return api(null, process.env.REACT_APP_DASHBOARD_API_URL)
      .get(`kpi_report/index_checklist_item_facts_by_category/?${str}`)
      .then((res) => {
        // dispatch(request(CLEAR_LOADING));
        console.log('success-getKpiReportCheckListItemByCategory');
        return res.data;
      })
      .catch((error) => {
        // dispatch(request(CLEAR_LOADING));
        console.log('getKpiReportCheckListItemByCategory-homeaction', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
        return null;
      });
  };
};
const getKpiReportCheckListProolByCategory = (data) => {
  // customer:string
  // date_in_month:string

  let str = qs.stringify(data);
  return (dispatch) => {
    dispatch(request(SET_LOADING));
    return api(null, process.env.REACT_APP_DASHBOARD_API_URL)
      .get(`kpi_report/index_checklist_item_facts_by_category/?${str}`)
      .then((res) => {
        dispatch(request(CLEAR_LOADING));
        console.log('success-getKpiReportCheckListProolByCategory');
        return res.data;
      })
      .catch((error) => {
        dispatch(request(CLEAR_LOADING));
        console.log('getKpiReportCheckListProolByCategory-homeaction', error);
        const { status } = error.response || { status: 500, message: 'Server Error' };
        if (status === 401 || status === 500) {
          dispatch(authActions.logout());
        }
        return null;
      });
  };
};
const homeActions = {
  getListUsers,
  editUser,
  lockUser,
  unlockUser,
  getCheckInCheckOut,
  getCheckListById,
  getListPhotos,
  changePassword,
  rescheduleChecklists,
  submitChecklistItem,
  downloadPhotos,
  deleteCall,
  clearDownloadPhotos,
  clearLoading,
  setLoading,
  getAuditProgress,
  getAuditProgressMixDims,
  getAuditProgressMixDimsYtd,
  getAuditorPerformance3Month,
  getAuditProgressIndexShops,
  getKpiReport,
  getKpiReportIndexCheckList,
  getKpiReportIndexCheckListInMonth,
  getKpiReportIndexCheckListInMonthByCustomer,
  getKpiReportCheckListItemByCategory,
  getKpiReportCheckListProolByCategory,
  saveFilter, requestSupport
};

export default homeActions;
