import React from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Image,
  Space,
  Form,
  Button,
  DatePicker,
  Tabs,
  Switch,
  List as AntList,
  Divider,
  Input,
  Collapse,
  Dropdown,
  Menu,
  Modal,
  Select

} from 'antd';

import { connect } from 'react-redux';
// import { homeActions } from '../redux/actions';
// import authActions from '../../auth/redux/actions';
// import history from '../../../common/history';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const PassAndFail = ({ dispatch, home, user }) => {
  const { Text, Title } = Typography;
  const rootData = {
    customers: [
      {
        name: 'BSMART',
        data: [1095, 8077, 11.94, 88.06]
      },
      {
        name: 'IND SOUTH M',
        data: [1441, 3074, 31.92, 68.08]
      },
      {
        name: 'IND SOUTH',
        data: [1661, 2957, 36.01, 63.99]
      },
      {
        name: 'GS25',
        data: [4055, 6539, 38.24, 61.76]
      },
      {
        name: 'COOPFOOD',
        data: [22196, 34819, 38.93, 61.07]
      },
      {
        name: 'IND NORTH',
        data: [6336, 6523, 39.93, 60.07]
      },
      {
        name: 'IND NORTH M',
        data: [5474, 6616, 45.28, 54.72]
      },
      {
        name: 'CIRCLE K',
        data: [27692, 26731, 50.88, 49.12]
      },
    ],
    category: [
      {
        name: 'SURFACE CLEANING',
        data: [1005, 6518, 13.36, 86.64]
      },
      {
        name: 'CILINARY',
        data: [3529, 6574, 34.93, 65.07]
      },
      {
        name: 'FLOOR CARE',
        data: [2806, 4521, 38.30, 61.70]
      },
      {
        name: 'LIQUID',
        data: [5509, 8035, 40.67, 59.33]
      },
      {
        name: 'FABRIC SENSATIONS',
        data: [4390, 5932, 42.53, 57.47]
      },
      {
        name: 'DISH WASH',
        data: [4522, 6049, 42.78, 57.22]
      },
      {
        name: 'POWDER',
        data: [4230, 4941, 46.12, 53.88]
      },
      {
        name: 'TOILET',
        data: [2631, 3021, 46.55, 53.45]
      },
    ]
  };
  const fulldatas = {
    data1: {
      labels: ['BSMART', 'IND SOUTH M', 'IND SOUTH', 'GS25', 'COOPFOOD', 'IND NORTH', 'IND NORTH M', 'CIRCLE K'],
      datasets: [
        {
          label: ['Pass'],
          data: [1095, 1441, 1661, 4055, 22196, 6336, 5474, 27692],
          backgroundColor: 'rgba(10, 98, 240,0.5)',
          format: 'BIG FORMAT'
        },
        {
          label: ['Fail'],
          data: [8077, 3074, 2957, 6539, 34819, 6523, 6616, 26731],
          backgroundColor: 'rgba(212, 103, 2,0.8)',
          format: 'BIG FORMAT'
        },

      ],
    },
    data2: {
      labels: ['SURFACE CLEANING', 'CILINARY', 'FLOOR CARE', 'LIQUID', 'FABRIC SENSATIONS', 'DISH WASH', 'POWDER', 'TOILET'],
      datasets: [
        {
          label: ['Pass'],
          data: [1005, 3529, 2806, 5509, 4390, 4522, 4230, 2631],
          backgroundColor: 'rgba(3, 145, 84,0.5)',
          format: 'BIG FORMAT'
        },
        {
          label: ['Fail'],
          data: [6518, 6574, 4521, 8035, 5932, 6049, 4941, 3021],
          backgroundColor: 'rgba(237, 2, 34,0.8)',
          format: 'BIG FORMAT'
        },
      ],
    },
    data3: {
      labels: ['BSMART', 'IND SOUTH M', 'IND SOUTH', 'GS25', 'COOPFOOD', 'IND NORTH', 'IND NORTH M', 'CIRCLE K'],
      datasets: [
        {
          label: ['Pass'],
          data: [11.94, 31.92, 36.01, 38.24, 38.93, 39.93, 45.28, 50.88],
          backgroundColor: 'rgba(10, 98, 240,0.5)',
          format: 'BIG FORMAT'
        },
        {
          label: ['Fail'],
          data: [88.06, 68.08, 63.99, 61.76, 61.07, 60.07, 54.72, 49.12],
          backgroundColor: 'rgba(212, 103, 2,0.8)',
          format: 'BIG FORMAT'
        },

      ],
    },
    data4: {
      labels: ['SURFACE CLEANING', 'CILINARY', 'FLOOR CARE', 'LIQUID', 'FABRIC SENSATIONS', 'DISH WASH', 'POWDER', 'TOILET'],
      datasets: [
        {
          label: ['Pass'],
          data: [13.36, 34.93, 38.30, 40.67, 42.53, 42.78, 46.12, 46.55],
          backgroundColor: 'rgba(3, 145, 84,0.5)',
          format: 'BIG FORMAT'
        },
        {
          label: ['Fail'],
          data: [86.64, 65.07, 61.70, 59.33, 57.47, 57.22, 53.88, 53.45],
          backgroundColor: 'rgba(237, 2, 34,0.8)',
          format: 'BIG FORMAT'
        },
      ],
    },
    filterDatas: {
      period: ['2022-01', '2022-02', '2022-03', '2022-04'],
      auditdate: ['2022-04-02', '2022-04-03', '2022-04-04', '2022-04-05'],
      format: ['BIG FORMAT', 'SMALL FORMAT'],
      type: ['category', 'customer'],
    }
  }
  const [filterDatas, setFilterDatas] = React.useState(fulldatas.filterDatas);

  const [data1, setData1] = React.useState(fulldatas.data1);
  const [data2, setData2] = React.useState(fulldatas.data2);
  const [data3, setData3] = React.useState(fulldatas.data3);
  const [data4, setData4] = React.useState(fulldatas.data4);
  const options1 = {
    plugins: {
      title: {
        display: true,
        text: 'Pass and Fail stores by Customer',
      },
    },
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const options2 = {
    plugins: {
      title: {
        display: true,
        text: 'Pass and Fail stores by Category',
      },
    },
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const options3 = {
    plugins: {
      title: {
        display: true,
        text: 'Pass and Fail percent by Customer',
      },
    },
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const options4 = {
    plugins: {
      title: {
        display: true,
        text: 'Pass and Fail percent by Category',
      },
    },
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };



  React.useEffect(() => {

  }, [filterDatas])

  return (
    <Row>
      <Col span={24}>
        <Card
          title={"Pass and Fail store"}
          bordered={false}
          style={{ width: '100%' }}
          extra={
            <Form name="filter" layout="inline">

              <Form.Item
                name="type"
                label="Type"
              >
                <Select placeholder="Please choose" mode="multiple" style={{ minWidth: 150 }}
                  defaultValue={filterDatas.type} allowClear
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.type = value;
                    setFilterDatas(_filterDatas)
                  }}
                >
                  <Select.Option value="customer">Customer</Select.Option>
                  <Select.Option value="category">Category</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="period"
                label="Period"
              >
                <Select placeholder="Please choose" mode="multiple" style={{ minWidth: 150 }}
                  defaultValue={filterDatas.period} allowClear
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.period = value;
                    setFilterDatas(_filterDatas)
                  }}
                >
                  <Select.Option value="2022-04">04/2022</Select.Option>
                  <Select.Option value="2022-03">03/2022</Select.Option>
                  <Select.Option value="2022-02">02/2022</Select.Option>
                  <Select.Option value="2022-01">01/2022</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="format"
                label="Format"
              >
                <Select placeholder="Please choose" mode="multiple" style={{ minWidth: 150 }}
                  defaultValue={filterDatas.format} allowClear
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.format = value;
                    setFilterDatas(_filterDatas)
                  }}
                >
                  <Select.Option value="BIG FORMAT">BIG FORMAT</Select.Option>
                  <Select.Option value="SMALL FORMAT">SMALL FORMAT</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          }
        >

          {filterDatas.type.includes('customer') && <Row>
            <Col span={8}>
              {!!data1 && <Bar options={options1} data={data1} />}
              {!!data3 && <Bar options={options3} data={data3} />}

            </Col>
            <Col span={16}>
              <Table
                columns={[
                  {
                    title: 'Customers',
                    dataIndex: 'Customer',
                    key: 'Customer',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>{record.name}</Text>
                      </div>
                    },
                  },
                  {
                    title: 'Pass Qty',
                    dataIndex: 'pass',
                    key: 'pass',
                    render: (value, record) => {
                      return <Text style={{ textTransform: 'uppercase' }}>{record.data[0]}</Text>
                    },
                  },
                  {
                    title: 'Fail Qty',
                    dataIndex: 'fail',
                    key: 'fail',
                    render: (value, record) => {
                      return <Text style={{ textTransform: 'uppercase' }}>{record.data[1]}</Text>
                    },
                  },
                  {
                    title: 'Pass %',
                    dataIndex: 'passpc',
                    key: 'passpc',
                    render: (value, record) => {
                      return <Text style={{ textTransform: 'uppercase' }}>{record.data[2]}</Text>
                    },
                  },
                  {
                    title: 'Fail %',
                    dataIndex: 'failpc',
                    key: 'failpc',
                    render: (value, record) => {
                      return <Text style={{ textTransform: 'uppercase' }}>{record.data[3]}</Text>
                    },
                  },
                ]}

                dataSource={rootData.customers || []}
                rowKey={(record) => record.id}
                pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
              />
              <Row style={{ marginTop: 15 }}>
                <Col span={12} style={{ paddingRight: 5 }}>
                  <Table
                    columns={[
                      {
                        title: 'SOS',
                        dataIndex: 'sos',
                        key: 'sos',
                        render: (value, record) => {
                          return <div>
                            <Text style={{ textTransform: 'uppercase' }}>{record.name}</Text>
                          </div>
                        },
                      },
                      {
                        title: 'Unilever',
                        dataIndex: 'unilever',
                        key: 'unilever',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[0]}</Text>
                        },
                      },
                      {
                        title: 'Store',
                        dataIndex: 'store',
                        key: 'store',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[1]}</Text>
                        },
                      },
                      {
                        title: 'Rate',
                        dataIndex: 'rate',
                        key: 'rate',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[2]}</Text>
                        },
                      },
                    ]}

                    dataSource={rootData.customers || []}
                    rowKey={(record) => record.id}
                    pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                  />
                </Col>
                <Col span={12} style={{ paddingLeft: 5 }}>
                  <Table
                    columns={[
                      {
                        title: 'OOL',
                        dataIndex: 'sos',
                        key: 'sos',
                        render: (value, record) => {
                          return <div>
                            <Text style={{ textTransform: 'uppercase' }}>{record.name}</Text>
                          </div>
                        },
                      },
                      {
                        title: 'Unilever',
                        dataIndex: 'unilever',
                        key: 'unilever',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[0]}</Text>
                        },
                      },
                      {
                        title: 'Total',
                        dataIndex: 'total',
                        key: 'total',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[1]}</Text>
                        },
                      },
                      {
                        title: 'Rate',
                        dataIndex: 'rate',
                        key: 'rate',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[2]}</Text>
                        },
                      },
                    ]}

                    dataSource={rootData.customers || []}
                    rowKey={(record) => record.id}
                    pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>}
          {filterDatas.type.length > 1 && <Divider />}
          {filterDatas.type.includes('category') && <Row>
            <Col span={8}>

              {!!data2 && <Bar options={options2} data={data2} />}
              {!!data4 && <Bar options={options4} data={data4} />}
            </Col>
            <Col span={16}>
              <Table
                columns={[
                  {
                    title: 'Categories',
                    dataIndex: 'categories',
                    key: 'categories',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>{record.name}</Text>
                      </div>
                    },
                  },
                  {
                    title: 'Pass Qty',
                    dataIndex: 'pass',
                    key: 'pass',
                    render: (value, record) => {
                      return <Text style={{ textTransform: 'uppercase' }}>{record.data[0]}</Text>
                    },
                  },
                  {
                    title: 'Fail Qty',
                    dataIndex: 'fail',
                    key: 'fail',
                    render: (value, record) => {
                      return <Text style={{ textTransform: 'uppercase' }}>{record.data[1]}</Text>
                    },
                  },
                  {
                    title: 'Pass %',
                    dataIndex: 'passpc',
                    key: 'passpc',
                    render: (value, record) => {
                      return <Text style={{ textTransform: 'uppercase' }}>{record.data[2]}</Text>
                    },
                  },
                  {
                    title: 'Fail %',
                    dataIndex: 'failpc',
                    key: 'failpc',
                    render: (value, record) => {
                      return <Text style={{ textTransform: 'uppercase' }}>{record.data[3]}</Text>
                    },
                  },
                ]}

                dataSource={rootData.category || []}
                rowKey={(record) => record.id}
                pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
              />
              <Row style={{ marginTop: 15 }}>
                <Col span={12} style={{ paddingRight: 5 }}>
                  <Table
                    columns={[
                      {
                        title: 'SOS',
                        dataIndex: 'sos',
                        key: 'sos',
                        render: (value, record) => {
                          return <div>
                            <Text style={{ textTransform: 'uppercase' }}>{record.name}</Text>
                          </div>
                        },
                      },
                      {
                        title: 'Unilever',
                        dataIndex: 'unilever',
                        key: 'unilever',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[0]}</Text>
                        },
                      },
                      {
                        title: 'Store',
                        dataIndex: 'store',
                        key: 'store',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[1]}</Text>
                        },
                      },
                      {
                        title: 'Rate',
                        dataIndex: 'rate',
                        key: 'rate',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[2]}</Text>
                        },
                      },
                    ]}

                    dataSource={rootData.category || []}
                    rowKey={(record) => record.id}
                    pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                  />
                </Col>
                <Col span={12} style={{ paddingLeft: 5 }}>
                  <Table
                    columns={[
                      {
                        title: 'OOL',
                        dataIndex: 'sos',
                        key: 'sos',
                        render: (value, record) => {
                          return <div>
                            <Text style={{ textTransform: 'uppercase' }}>{record.name}</Text>
                          </div>
                        },
                      },
                      {
                        title: 'Unilever',
                        dataIndex: 'unilever',
                        key: 'unilever',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[0]}</Text>
                        },
                      },
                      {
                        title: 'Total',
                        dataIndex: 'total',
                        key: 'total',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[1]}</Text>
                        },
                      },
                      {
                        title: 'Rate',
                        dataIndex: 'rate',
                        key: 'rate',
                        render: (value, record) => {
                          return <Text style={{ textTransform: 'uppercase' }}>{record.data[2]}</Text>
                        },
                      },
                    ]}

                    dataSource={rootData.category || []}
                    rowKey={(record) => record.id}
                    pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>}

        </Card>
        <Card
          title={"Pass and Fail store by date"}
          bordered={false}
          style={{ width: '100%' }}
          extra={
            <Form name="filter" layout="inline">
              <Form.Item
                name="date"
                label="Audit Date"
              >
                <Select placeholder="Please choose" mode="multiple" style={{ minWidth: 150 }}
                  defaultValue={filterDatas.auditdate} allowClear
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.auditdate = value;
                    setFilterDatas(_filterDatas)
                  }}
                >
                  <Select.Option value="2022-04-02">02/04/2022</Select.Option>
                  <Select.Option value="2022-04-03">03/04/2022</Select.Option>
                  <Select.Option value="2022-04-04">04/04/2022</Select.Option>
                  <Select.Option value="2022-04-05">05/04/2022</Select.Option>
                  <Select.Option value="2022-04-06">06/04/2022</Select.Option>
                  <Select.Option value="2022-04-07">07/04/2022</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          }
        >
          <Table
            dataSource={rootData.category || []}
            rowKey={(record) => record.id}
            pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
          >
            <Table.Column title={<span>Category</span>} dataIndex={'category'} key={'category'}
              width={40}
              align='center'
              render={(v, record) => {
                return (<Text style={{ fontSize: 12 }}>{record.name}</Text>);
              }}
            />
            {filterDatas.auditdate.map((_date, index) => {
              return <Table.ColumnGroup title={<span>{_date}</span>} key={'category' + index}>
                <Table.Column title={<span style={{ fontSize: 10 }}>Pass qty</span>} dataIndex={`pass-${_date}-index`} key={`pass-${_date}-index`}
                  width={40}
                  align='center'
                  render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.data[0]}</Text>);
                  }}
                />
                <Table.Column title={<span style={{ fontSize: 10 }}>Fail qty</span>} dataIndex={`fail-${_date}-index`} key={`fail-${_date}-index`}
                  width={40}
                  align='center'
                  render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.data[1]}</Text>);
                  }}
                />
                <Table.Column title={<span style={{ fontSize: 10 }}>Pass %</span>} dataIndex={`passpc-${_date}-index`} key={`passpc-${_date}-index`}
                  width={40}
                  align='center'
                  render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.data[2]}</Text>);
                  }}
                />
                <Table.Column title={<span style={{ fontSize: 10 }}>Fail %</span>} dataIndex={`failpc-${_date}-index`} key={`failpc-${_date}-index`}
                  width={40}
                  align='center'
                  render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.data[3]}</Text>);
                  }}
                />
              </Table.ColumnGroup>
            })}
          </Table>
        </Card>
      </Col >
    </Row >
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(PassAndFail);
