import React, { useState } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Image,
  Space,
  Form,
  Button,
  DatePicker,
  Tabs,
  Switch,
  List as AntList,
  Divider,
  Input,
  Collapse,
  Dropdown,
  Menu

} from 'antd';
import { green, gold, red, grey } from '@ant-design/colors';
import { StarFilled } from '@ant-design/icons';
import { DownOutlined, CaretRightOutlined, LoginOutlined, LogoutOutlined, MoreOutlined, CloudDownloadOutlined, PictureOutlined, UnorderedListOutlined, DatabaseOutlined } from '@ant-design/icons';

// import { Carousel } from 'react-responsive-carousel';
// import List from '../../components/List';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
// import CategoryList from '../../components/CategoryList';
// import Expandtable from './Expandtable';
// import ListItem from './ListItem/index';
import ListItem from './ListItem/IndexTable';


import { connect } from 'react-redux';
import { homeActions } from './redux/actions';
// import NoImage from '../../images/no-image.png';
import { removeVietnameseTones } from '../../../src/utils/string'
const url = process.env.REACT_APP_API_URL;



const { Text } = Typography;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const CheckInCheckOutReviewLayout = ({ dispatch, home }) => {
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
  const { listCheckInCheckOut, checkList, isLoading, fileData, listPhotos, isUpdateItemSuccess } = home;
  const [form] = Form.useForm();

  const [date, setDate] = useState([]);
  const [forReview, setForReview] = useState(true);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [lastRecordExpand, setLastRecordExpand] = useState(null);
  const [currentDataExpand, setCurrentDataExpand] = useState({
    check_list: 0,
    category: '',
    sub_category: '',
  });
  const [currentDataExpandCompare, setCurrentDataExpandCompare] = useState({
    check_list: 0,
    category: '',
    sub_category: '',
  });
  const [checkListDataByRecord, setCheckListDataByRecord] = useState(null);
  const [checkListTypeBySubCategorySelected, setCheckListTypeBySubCategorySelected] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentVis, setCurrentVis] = useState(0);
  const itemPerRow = {
    C2A: 2,
    NPD: 2,
    OLBIG: 1,
    OSA: 2,
    PROCMS: 2,
    PROOL: 2,
    RENTAL: 2,
    SOS: 2,
  }
  const [filterDatas, setFilterDatas] = useState({
    user: null,
    customer: null,
    shop: null,
    format_type: null,
    created_at: null,
  });
  const [columnFilter, setColumnFilter] = useState({
    userids: [],
    customers: [],
    format_type: [],
    shop: [],
    created_at: []
  });

  React.useEffect(() => {
    dispatch(homeActions.getCheckInCheckOut(date, true));
  }, [dispatch, date]);
  const downloadRecord = (checklist_id, type) => {
    dispatch(homeActions.downloadPhotos(checklist_id, type));
  };

  React.useEffect(() => {

    if (lastRecordExpand) {
      let _checkListDataByRecord = { ...checkListDataByRecord };
      if (checkList) {

        _checkListDataByRecord['recordId' + lastRecordExpand.id] = checkList;
      }
      setCheckListDataByRecord(_checkListDataByRecord);
    }
  }, [isLoading, checkList]);
  const listPhotosRender = React.useMemo(() => {

    let __listPhotos = (listPhotos) ? [...listPhotos] : [];
    let _listPhotos = __listPhotos.filter((itemPhoto) => {
      return ((currentDataExpand.check_list == currentDataExpandCompare.check_list) && (currentDataExpand.category == currentDataExpandCompare.category) && itemPhoto.custom_attributes.category.includes(currentDataExpand.category));
    });
    return (_listPhotos.length ? <div style={{ position: 'sticky', paddingLeft: 10, top: 0 }}>
      <Card
        title="Photos"
        bordered={false}
        style={{ width: '100%' }}
      ><div>

          <Swiper
            spaceBetween={15}
            loop={false}
            navigation
            breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 1,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 2,
              },
            }}
            pagination={{ clickable: true }}
          >
            {_listPhotos.map((item, index) => {
              return (<SwiperSlide key={'slide_' + index} >
                <Image
                  preview={{ visible: false }}
                  src={item.image}
                  style={{ width: '100%', objectFit: 'cover' }}
                  onClick={() => { setCurrentVis(index); setVisible(true) }}
                />
              </SwiperSlide >);
            })}
          </Swiper>
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis), current: currentVis }}>
              {_listPhotos.map((item, index) => {
                return <Image key={'image' + index} src={item.image} />
              })}
            </Image.PreviewGroup>
          </div>
        </div>
      </Card></div> : null);
  }, [listPhotos, currentDataExpand, currentDataExpandCompare, currentVis, visible])
  // React.useEffect(() => {
  //   // console.log('listPhotos', listPhotos);
  // }, [listPhotos]);

  React.useEffect(() => {
    if (lastRecordExpand) {
      // console.log('lastRecordExpand', lastRecordExpand);
      let checklistids = [];
      for (let i = 0; i < lastRecordExpand.checklists.length; i++) {
        checklistids.push(lastRecordExpand.checklists[i].id);
      }
      dispatch(homeActions.getCheckListById(checklistids, forReview));
    }

  }, [lastRecordExpand]);
  const getListPhotos = (checklist_id, category, sub_category) => {
    setCurrentDataExpand({ checklist_id, category, sub_category });
    // console.log('{ checklist_id, category, sub_category }', { checklist_id, category, sub_category });
    setCurrentDataExpandCompare({ checklist_id, category, sub_category })
    dispatch(homeActions.getListPhotos(checklist_id, category, sub_category));
  }
  const filters = (arr) => {
    return _.sortBy(_.uniqBy(arr, 'text'), ['text']);
  };
  // const onSelect = (selectedKeys, info) => {
  //   if (info && info.selectedNodes.length && info.selectedNodes[0].isEndTree) {
  //     const { checklist_id, category, sub_category } = info.selectedNodes[0].data;
  //     getListPhotos(checklist_id, category, sub_category);
  //   }
  // };
  const onFinish = (values) => {
    setFilterDatas({
      user: null,
      customer: null,
      shop: null,
      format_type: null,
      created_at: null,
    });
    setExpandedRowKeys([]);
    setLastRecordExpand(null);
    setCheckListTypeBySubCategorySelected([]);
    const { date } = values;
    const newDate = [];
    if (date && date.length > 0) {
      newDate.push(moment.utc(date[0]).tz(moment.tz.guess(true)).format('DD-MM-YYYY'));
    }
    if (date && date.length > 1) {
      newDate.push(moment.utc(date[1]).tz(moment.tz.guess(true)).format('DD-MM-YYYY'));
    }

    setDate(newDate);
  };
  const updateFilter = () => {
    let _listCheckInCheckOut = listCheckInCheckOut.filter((_item) => {
      let allow = true;
      if (filterDatas.user) {
        allow = filterDatas.user.includes(_item.user.id);
      }
      if (allow && filterDatas.customer) {
        allow = _item.shop.custom_attributes.customer ? filterDatas.customer.includes(_item.shop.custom_attributes.customer) : false;
      }
      if (allow && filterDatas.shop) {
        allow = filterDatas.shop.includes(_item.shop.id);
      }
      if (allow && filterDatas.format_type) {
        allow = _item.shop.custom_attributes.format_type ? filterDatas.format_type.includes(_item.shop.custom_attributes.format_type) : false;
      }
      if (allow && filterDatas.created_at) {
        const checkinTime = moment
          .utc(_item.created_at)
          .tz(moment.tz.guess(true))
          .format('DD-MM-YYYY');
        allow = filterDatas.created_at.includes(checkinTime);
      }
      return allow;
    });
    let userids = [],
      customers = [],
      format_type = [],
      shop = [],
      created_at = [];
    let _columnFilter = {
      userids: [],
      customers: [],
      format_type: [],
      shop: [],
      created_at: []
    };
    _listCheckInCheckOut.map((_item) => {
      let customerName = _item.shop.custom_attributes.customer || '';
      if (customerName && !customers.includes(customerName)) {
        customers.push(customerName);
        _columnFilter.customers.push(
          {
            text: customerName,
            value: customerName,
          }
        );
      }
      if (_item.user.id && !userids.includes(_item.user.id)) {
        userids.push(_item.user.id);
        _columnFilter.userids.push(
          {
            text: _item.user.name + ' (' + _item.user.username + ')',
            value: _item.user.id,
          }
        );
      }
      if (_item.shop.id && !shop.includes(_item.shop.id)) {
        shop.push(_item.shop.id);
        _columnFilter.shop.push(
          {
            text: `${_item.shop.name} (${_item.shop.id} - ${_item.shop.custom_attributes.ess_store_code})`,
            value: _item.shop.id,
          }
        );
      }
      if (_item.shop.custom_attributes.format_type && !format_type.includes(_item.shop.custom_attributes.format_type)) {
        format_type.push(_item.shop.custom_attributes.format_type);
        _columnFilter.format_type.push(
          {
            text: _item.shop.custom_attributes.format_type,
            value: _item.shop.custom_attributes.format_type
          }
        );
      }
      const checkinTime = moment
        .utc(_item.created_at)
        .tz(moment.tz.guess(true))
        .format('DD-MM-YYYY');
      if (!created_at.includes(checkinTime)) {
        created_at.push(checkinTime);
        _columnFilter.created_at.push(
          {
            text: checkinTime,
            value: checkinTime
          }
        );
      }
    });
    // return false;
    setColumnFilter(_columnFilter);
  }

  React.useEffect(() => {
    if (listCheckInCheckOut && listCheckInCheckOut.length) {
      updateFilter();
    }

  }, [filterDatas, listCheckInCheckOut]);
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setFilterDatas(filters);
  }

  // const onChangePass = (id, checked) => {
  //   const newData = data.map((d) => {
  //     if (d.id === id) {
  //       d = {
  //         ...d,
  //         data: {
  //           ...d.data,
  //           pass: checked,
  //         },
  //       };
  //     }

  //     return d;
  //   });
  //   setData(newData);
  // };

  // const onChangeQcNode = (id, e) => {
  //   const newData = data.map((d) => {
  //     if (d.id === id) {
  //       d = {
  //         ...d,
  //         data: {
  //           ...d.data,
  //           qc_note: e.target.value,
  //         },
  //       };
  //     }

  //     return d;
  //   });
  //   setData(newData);
  // };
  const collapseCallback = (key) => {
    console.log('collapseCallback', key);
  }
  const showGallery = (subcatekey, category, recordChecklistTypes, _checklists) => {
    if (subcatekey) {
      let subcatekeys = subcatekey.split('_subcate_', 2);
      if (subcatekeys.length == 2) {
        let combineKey = removeVietnameseTones(category + '-' + subcatekeys[1], '-');
        let ids = recordChecklistTypes[combineKey];
        let _checkListTypeBySubCategorySelected = [];
        if (ids.length) {
          for (let i = 0; i < ids.length; i++) {
            let keychecklist = 'ckl_' + ids[i];
            _checkListTypeBySubCategorySelected.push({
              id: ids[i],
              name: _checklists[keychecklist],
              combineKey,
              category,
              subcategory: subcatekeys[1]
            })
          }
        }
        setCheckListTypeBySubCategorySelected(_checkListTypeBySubCategorySelected);
        let checklist_id = (recordChecklistTypes && recordChecklistTypes[combineKey].length) ? recordChecklistTypes[combineKey][0] : 0;
        if (checklist_id) {
          getListPhotos(checklist_id, category, subcatekeys[1]);
        }

      } else {
        setCheckListTypeBySubCategorySelected(null);
      }
    } else {
      setCheckListTypeBySubCategorySelected(null);
    }

  }
  // const onChangeTab = (tabkey) => {
  //   if (tabkey) {
  //     let tabkeys = tabkey.split('ckl_', 2);
  //     if (tabkeys.length == 2) {
  //       let index = parseInt(tabkeys[1]);
  //       let checklistrecord = checkListTypeBySubCategorySelected[index];
  //       if (checklistrecord) {
  //         getListPhotos(checklistrecord.id, checklistrecord.category, checklistrecord.subcategory);
  //       }

  //     }
  //   }
  // }
  const onChangeTab = (tabkey) => {
    if (tabkey) {
      let tabkeys = tabkey.split('ckl_', 2);
      // console.log('tabkeys', tabkeys);
      if (tabkeys.length == 2) {
        let index = parseInt(tabkeys[1]);
        // console.log('index', index);
        // let checklistrecord = checkListTypeBySubCategorySelected[index];
        let checklistrecord = checkListTypeBySubCategorySelected.find((_checkListTypeBySubCategorySelected) => {
          return _checkListTypeBySubCategorySelected.id == index;
        });
        // console.log('checklistrecord', checklistrecord);
        if (checklistrecord) {
          getListPhotos(checklistrecord.id, checklistrecord.category, checklistrecord.subcategory);
        }

      }
    }
  }
  const submit = (item, keyr, combineKey2) => {
    // setCombineKeySubmit(combineKey2+'___item___'+item.id);
    dispatch(
      homeActions.submitChecklistItem(item.id, {
        review_pass: item.data.review_pass || true,
        review_note: item.data.review_note || '',
        final_result: item.data.final_result || ''
      }),
    );
    let _checkListDataByRecord = { ...checkListDataByRecord };

    for (let i = 0; i < _checkListDataByRecord[keyr].checklist_items[combineKey2].length; i++) {
      if (_checkListDataByRecord[keyr].checklist_items[combineKey2][i].id === item.id) {
        _checkListDataByRecord[keyr].checklist_items[combineKey2][i] = item;
        break;
      }
    }
    setCheckListDataByRecord(_checkListDataByRecord)
  };
  // React.useEffect(() => {
  //   if (isUpdateItemSuccess && combineKeySubmit) {
  //     let keys = combineKeySubmit.split('___item___');
  //     let _checkList = { ...checkList };
  //     for(let i=0;i<_checkList.checklist_items[keys[0]].length;i++){
  //       if(_checkList.checklist_items[keys[0]][i].id==parseInt(keys[1])){

  //       }
  //     }
  //   }


  // }, [isUpdateItemSuccess]);
  return (
    <Row>
      <Col span={24}>
        <Card
          title="CICO Review"
          bordered={false}
          style={{ width: '100%' }}
          size={'small'}
          extra={
            <Form form={form} name="filter" layout="inline" onFinish={onFinish}>
              <Form.Item title="Date" name="date" rules={[{ type: 'array', required: false }]}>
                <RangePicker style={{ width: '100%' }} format="DD-MM-YYYY" />
              </Form.Item>
              <Switch checkedChildren="Chỉ chưa pass" onChange={() => {
                setForReview(!forReview);
              }} unCheckedChildren="Tất cả" checked={forReview} style={{ marginRight: 10 }} />
              <Form.Item shouldUpdate={true}>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              </Form.Item>
            </Form>
          }
        >
          <Row>
            <Col span={17} style={{ overflowX: 'auto' }}>
              <Row>
                <Col span={24}>
                  <Table
                    onChange={onChangeTable}
                    size={'small'}
                    columns={[
                      {
                        title: 'User',
                        dataIndex: 'user',
                        key: 'user',
                        render: (u, record) => {
                          let isQc = !!(record.qc_user_id);
                          return <div>
                            {isQc && <StarFilled style={{ color: isQc ? green[6] : grey[6] }} />}
                            <Text style={{ fontSize: 12, color: isQc ? green[6] : grey[6] }}> {u.name} </Text>
                            {isQc && <Text style={{ fontSize: 10, color: red[6], textAlign: 'center' }}> (Đã qc)</Text>}

                          </div>
                        },
                        filters: columnFilter.userids,
                        filteredValue: filterDatas.user || null,
                        filterSearch: true,
                        onFilter: (value, record) => {
                          return record.user.id === value;
                        },
                      },
                      {
                        title: 'Shop',
                        dataIndex: 'shop',
                        key: 'shop',
                        render: (v, record) => {
                          let isQc = !!(record.qc_user_id);
                          const shopName = record.shop !== null ? record.shop.name : '';
                          return (
                            <Space direction="vertical" >
                              <Text style={{ fontSize: 12, color: isQc ? green[6] : grey[6] }}>{shopName}</Text>
                              <Text style={{ fontSize: 12 }} italic>({record.shop.custom_attributes?.ess_store_code || ""})</Text>
                            </Space>
                          );
                        },
                        filters: columnFilter.shop,
                        filteredValue: filterDatas.shop || null,
                        onFilter: (value, record) => {
                          return record.shop.id === value;
                        },
                        filterSearch: true,
                      },
                      {
                        title: 'Customer',
                        dataIndex: 'customer',
                        key: 'customer',
                        render: (v, record) => {
                          const customerName = record.shop !== null ? record.shop.custom_attributes.customer : '';
                          let isQc = !!(record.qc_user_id);
                          return (
                            <Space direction="vertical">
                              <Text style={{ fontSize: 12, color: isQc ? green[6] : grey[6] }}>{customerName}</Text>
                            </Space>
                          );
                        },
                        filters: columnFilter.customers,
                        filteredValue: filterDatas.customer || null,
                        onFilter: (value, record) => {
                          return record.shop.custom_attributes.customer === value;
                        },
                      },
                      {
                        title: 'Time',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        width: '250px',
                        render: (v, record) => {
                          const timeCheckin = moment
                            .utc(v)
                            .tz(moment.tz.guess(true))
                            .format('DD-MM-YYYY HH:mm');
                          const timeCheckout =
                            record.user_checkout !== null && record.user_checkout.created_at !== null
                              ? moment
                                .utc(record.user_checkout.created_at)
                                .tz(moment.tz.guess(true))
                                .format('DD-MM-YYYY HH:mm')
                              : '';
                          const noteCheckIn = record.note !== 'undefined' ? record.note : '';
                          // console.log('record', record);
                          const noteCheckOut =
                            record.user_checkout != null && record.user_checkout.note !== 'undefined'
                              ? record.user_checkout.note
                              : '';
                          const datacheckout =
                            record.user_checkout != null && record.user_checkout.data !== 'undefined'
                              ? record.user_checkout.data
                              : null;
                          return (
                            <div style={{ fontSize: 12 }}>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><div title="Checkin time" style={{ color: green[6] }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}><LoginOutlined style={{ marginRight: 2 }} /> {timeCheckin} <span title="Checkin Id"> ({record.id})</span></div>
                                {noteCheckIn ? <Text>{noteCheckIn}</Text> : null}
                              </div>
                                {record.photos && record.photos.length ? record.photos.map((photo, indexphoto) => {
                                  return <div style={{ marginLeft: 5 }} key={'indexphoto' + indexphoto}><Image
                                    src={process.env.REACT_APP_API_URL + photo.image}
                                    height={40}
                                    width={40}
                                    style={{ objectFit: 'cover' }}
                                    preview={true}
                                  /></div>
                                }) : null}
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                {timeCheckout ? <div title="Checkout time" style={{ color: gold[6] }}>
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}> <LogoutOutlined style={{ marginRight: 2 }} /> {timeCheckout} <span title="Checkout Id"> ({record.user_checkout.id})</span></div>
                                  {noteCheckOut ? <Text>{noteCheckOut}</Text> : null}
                                  {datacheckout ? <div>
                                    {datacheckout.audit_status ? <Text>{datacheckout.audit_status}</Text> : null}
                                    <hr style={{ margin: '2px 0', borderTop: 'none', borderBottom: '1px solid #eaeaea' }} />
                                    {datacheckout.predefined_note ? <Text>{datacheckout.predefined_note}</Text> : null}
                                  </div> : null}
                                </div> : null}
                                {record.user_checkout.photos &&
                                  record.user_checkout.photos.length > 0 && <div>
                                    {record.user_checkout.photos.map((photo, photoindex) => {
                                      return <div style={{ marginLeft: 5 }} key={'photoindex' + photoindex} title={photo.custom_attributes.photo_type == 'confirmation_document' ? 'PXN Picture' : 'Checkout Picture'}><Image
                                        src={`${url}${photo.image}`}
                                        height={40}
                                        width={40}
                                        style={{ objectFit: 'cover', border: (photo.custom_attributes.photo_type == 'confirmation_document' ? '1px solid red' : '1px solid blue') }}
                                        preview={true}
                                      /></div>
                                    })}</div>}


                              </div>

                            </div>
                          );
                        },
                        filters: columnFilter.created_at,
                        filteredValue: filterDatas.created_at,
                        onFilter: (value, record) => {
                          const checkin = moment
                            .utc(record.created_at)
                            .tz(moment.tz.guess(true))
                            .format('DD-MM-YYYY');
                          return checkin === value;
                        },
                      },
                      {
                        title: <div style={{ textAlign: 'center' }}><CloudDownloadOutlined style={{ fontSize: 20 }} /></div>,
                        dataIndex: 'download',
                        key: 'download',
                        render: (v, record) => {
                          const menu = (
                            <Menu>
                              <Menu.Item onClick={() => downloadRecord(record.id, 'photo')} title="Download Photo">
                                <PictureOutlined />
                              </Menu.Item>
                              <Menu.Item onClick={() => downloadRecord(record.id, 'data')} title="Download Data">
                                <DatabaseOutlined />
                              </Menu.Item>
                            </Menu>
                          );
                          return (
                            <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column' }}>
                              {/* <Dropdown overlay={menu} placement="bottomLeft">
                                <Button><MoreOutlined /></Button>
                              </Dropdown> */}
                              <Button style={{ marginBottom: 5 }} type="primary" onClick={() => downloadRecord(record.id, 'photo')} title="Download Photo"><PictureOutlined /></Button>

                              <Button style={{ marginBottom: 5 }} type="primary" danger onClick={() => downloadRecord(record.id, 'data')} title="Download Data"><DatabaseOutlined /></Button>
                            </div>
                          );
                        },
                      },
                    ]}
                    expandable={{
                      expandedRowRender: (record) => {
                        let keyr = 'recordId' + record.id;

                        let _checklists = {};
                        for (let i = 0; i < record.checklists.length; i++) {
                          let keychecklist = 'ckl_' + record.checklists[i].id;
                          _checklists[keychecklist] = record.checklists[i].checklist_type.toUpperCase();
                        }
                        let categories = (checkListDataByRecord && checkListDataByRecord[keyr]) ? checkListDataByRecord[keyr].categories : {};
                        let recordChecklistTypes = (checkListDataByRecord && checkListDataByRecord[keyr]) ? checkListDataByRecord[keyr].checklistTypes : {};
                        let _categories = Object.keys(categories).sort();
                        // Object.keys(categories).sort().map((key) => {
                        //   let children = [];
                        //   let subcategory = categories[key].subcategory;
                        //   Object.keys(subcategory).sort().map((key2) => {
                        //     children.push({
                        //       title: subcategory[key2].sub_category_vn,
                        //       key: key + '-' + key2,
                        //       isEndTree: true,
                        //       data: { checklist_id: record.id, category: key, sub_category: key2 }
                        //     })
                        //   });
                        //   trees.push({
                        //     title: key,
                        //     isEndTree: true,
                        //     data: {},
                        //     key: categories[key].category_code,
                        //     children
                        //   });
                        // });
                        return (_categories.length ? <Collapse accordion key={'keyr' + keyr} onChange={(k) => {
                          let _currentDataExpand = { ...currentDataExpand }
                          if (!k) {
                            _currentDataExpand.category = '';
                            setCheckListTypeBySubCategorySelected([]);
                          } else {
                            let arr = k.split("-cate-", 2);
                            if (arr.length == 2) {
                              _currentDataExpand.category = arr[1];
                            }
                          }
                          setCurrentDataExpand(_currentDataExpand);
                        }}>
                          {_categories.map((key) => {
                            let subcategory = categories[key].subcategory;
                            return <Collapse.Panel header={categories[key].category_vn} key={keyr + '-cate-' + key} destroyInactivePanel={true}>
                              <Collapse onChange={(subcatekey) => showGallery(subcatekey, key, recordChecklistTypes, _checklists)} accordion key={'collapse-' + keyr + '-cate-' + key} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} >
                                {Object.keys(subcategory).sort().map((key2, index) => {
                                  let combineKey = removeVietnameseTones(key + '-' + key2, '-');
                                  return (<Collapse.Panel header={subcategory[key2].sub_category_vn} key={keyr + '-' + combineKey + '_subcate_' + key2} style={{ marginBottom: 5 }}>

                                    <Tabs defaultActiveKey={combineKey + '_ckl_' + recordChecklistTypes[combineKey][0]}
                                      onChange={(tabkey) => { onChangeTab(tabkey, key, key2) }}
                                    >
                                      {recordChecklistTypes[combineKey].map((checklistId) => {
                                        let keychecklist = 'ckl_' + checklistId;
                                        let checklistType = _checklists[keychecklist];
                                        let combineKey2 = combineKey + '-' + checklistType;
                                        let __datas = checkListDataByRecord[keyr].checklist_items[combineKey2];

                                        // console.log('checkListDataByRecord[keyr].checklist_items', checkListDataByRecord[keyr].checklist_items);
                                        return (checklistType ? <TabPane tab={checklistType} key={combineKey + '_' + keychecklist} >
                                          {/* <ListItem datas={__datas}
                                            submit={item => submit(item, keyr, combineKey2)}
                                            itemPerRow={itemPerRow[checklistType.toUpperCase()]}
                                            isReview={true}
                                          /> */}
                                          <ListItem datas={__datas} onSubmit={item => submit(item, keyr, combineKey2)}
                                            checklistType={checklistType}
                                            format_type={record.shop.custom_attributes.format_type}
                                            itemPerRow={itemPerRow[checklistType.toUpperCase()]}
                                            isReview={true}
                                          />

                                        </TabPane> : null)
                                      })}
                                    </Tabs>
                                  </Collapse.Panel>)
                                })
                                }
                              </Collapse>
                            </Collapse.Panel>
                          })}
                        </Collapse> : <div style={{ color: red[6] }}>Danh sách trống</div>)

                        // return null;
                        // return (
                        //   <Tabs defaultActiveKey={record.id} >
                        //     {record.checklists.map((checklist) => {
                        //       console.log('checklist.id', checklist.id);
                        //       let _datas = (data && data['checklistdata' + checklist.id] && data['checklistdata' + checklist.id].length) ? data['checklistdata' + checklist.id] : [];
                        //       return (<TabPane tab={checklist.checklist_type} key={checklist.id}>
                        //         <AntList
                        //           grid={{
                        //             gutter: 16,
                        //             xs: 1,
                        //             sm: 2,
                        //             md: 4,
                        //             lg: 4,
                        //             xl: 5,
                        //             xxl: 5,
                        //           }}
                        //           pagination={{
                        //             showSizeChanger: true,
                        //             onChange: page => {
                        //               console.log(page);
                        //             },
                        //             position: 'bottomCenter',
                        //             // total:{(data && data['checklistdata' + checklist.id] && data['checklistdata' + checklist.id].length) ? data['checklistdata' + checklist.id].length : 0},
                        //             hideOnSinglePage: true
                        //           }}

                        //           dataSource={_datas}
                        //           renderItem={(item, index) => {
                        //             // return <span key={'index' + index}>{item.id}</span>
                        //             return <ListItem onSubmit={(item) => submit(item)} key={item.id} item={item} />
                        //           }}
                        //         />
                        //       </TabPane>
                        //       )
                        //     })}
                        //   </Tabs>
                        // );
                      },
                      onExpand: (expanded, record) => {
                        let _currentDataExpand = { ...currentDataExpand }
                        let _expandedRowKeys = [...expandedRowKeys]
                        if (expanded) {
                          setLastRecordExpand(record);
                          _expandedRowKeys.push(record.id)

                        } else {
                          _expandedRowKeys = _expandedRowKeys.filter(key => key !== record.id)
                          _currentDataExpand.check_list = 0;
                          setCheckListTypeBySubCategorySelected([]);
                          setCurrentDataExpand(_currentDataExpand);
                        }
                        console.log(_expandedRowKeys);
                        setExpandedRowKeys(_expandedRowKeys)
                      },
                      rowExpandable: (record) => record.checklists && record.checklists.length > 0,
                    }}
                    dataSource={listCheckInCheckOut || []}
                    rowKey={(record) => record.id}
                    pagination={{ position: ['bottomCenter'] }}
                    expandedRowKeys={expandedRowKeys}

                  />
                </Col>
              </Row>
            </Col>
            <Col span={7}>
              {listPhotosRender}
              {/* <div style={{ position: 'sticky', paddingLeft: 10, top: 0 }}>
                <Card
                  title="Photos"
                  bordered={false}
                  style={{ width: '100%' }}
                >
                  {checkListTypeBySubCategorySelected && checkListTypeBySubCategorySelected.length ? <Tabs
                    onChange={onChangeTab}
                  >
                    {checkListTypeBySubCategorySelected.map((checklistrecord, index) => {
                      let keychecklist = 'ckl_' + index;
                      // let checklistType = _checklists[keychecklist];
                      // let combineKey2 = combineKeySelected + '-' + checklistType;
                      // let __datas = checkListDataByRecord[keyr].checklist_items[combineKey2];
                      // console.log('checkListTypeBySubCategorySelected', checkListTypeBySubCategorySelected);
                      // console.log('checklistrecord', checklistrecord);
                      // console.log('checkListTypeBySubCategorySelected', combineKey2);
                      // console.log('checkListDataByRecord[keyr].checklist_items', checkListDataByRecord[keyr].checklist_items);
                      return (<TabPane tab={checklistrecord.name} key={keychecklist} >
                        {(listPhotos && listPhotos.length && (currentDataExpand.check_list == currentDataExpandCompare.check_list) && (currentDataExpand.category == currentDataExpandCompare.category) && (currentDataExpand.sub_category == currentDataExpandCompare.sub_category)) ? <div style={{ paddingRight: 10, paddingLeft: 10 }}>

                          <Swiper
                            spaceBetween={15}
                            loop={false}
                            navigation
                            breakpoints={{
                              // when window width is >= 640px
                              640: {
                                slidesPerView: 1,
                              },
                              // when window width is >= 768px
                              768: {
                                slidesPerView: 2,
                              },
                              1024: {
                                slidesPerView: 2,
                              },
                            }}
                            pagination={{ clickable: true }}
                          >
                            {listPhotos.map((item, index) => {
                              return (<SwiperSlide key={'slide_' + index} >
                                <Image
                                  preview={{ visible: false }}
                                  src={item.image}
                                  style={{ width: '100%', objectFit: 'cover' }}
                                  onClick={() => { setCurrentVis(index); setVisible(true) }}
                                />
                              </SwiperSlide >);
                            })}
                          </Swiper>
                          <div style={{ display: 'none' }}>
                            <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis), current: currentVis }}>
                              {listPhotos.map((item, index) => {
                                return <Image key={'image' + index} src={item.image} />
                              })}
                            </Image.PreviewGroup>
                          </div>
                        </div> : null}
                      </TabPane>
                      )
                    })}
                  </Tabs> : null}

                </Card>

              </div> */}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};
export default connect(mapStateToProps)(CheckInCheckOutReviewLayout);
