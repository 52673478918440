import React from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Image,
  Space,
  Form,
  Button,
  DatePicker,
  Tabs,
  Switch,
  List as AntList,
  Divider,
  Input,
  Collapse,
  Dropdown,
  Menu,
  Modal,
  Select

} from 'antd';

import { connect } from 'react-redux';
// import { homeActions } from '../redux/actions';
// import authActions from '../../auth/redux/actions';
// import history from '../../../common/history';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const AuditStatus = ({ dispatch, home, user }) => {
  const { Text, Title } = Typography;
  const [form] = Form.useForm();
  const rootData = [
    {
      name: '7 Eleven',
      data: [
        { 'type': 'Cancel vì siêu thị đóng cửa', bigamount: 0, smallamount: 0 },
        { 'type': 'Ok', bigamount: 0, smallamount: 0 }
      ]
    },
    {
      name: 'BSMART',
      data: [
        { 'type': 'Cancel vì siêu thị đóng cửa', bigamount: 0, smallamount: 0 },
        { 'type': 'Không cho chụp hình', bigamount: 0, smallamount: 0 },
        { 'type': 'Ok', bigamount: 0, smallamount: 0 }
      ]
    },
    {
      name: 'Circle K',
      data: [
        { 'type': 'Cancel vì siêu thị đóng cửa', bigamount: 0, smallamount: 0 },
        { 'type': 'Cancel vì siêu thị không cho vào', bigamount: 0, smallamount: 0 },
        { 'type': 'Cancel vì store đang re-layout - xếp hàng', bigamount: 0, smallamount: 0 },
        { 'type': 'Không chấm đủ KPI/ngành hàng', bigamount: 0, smallamount: 0 },
        { 'type': 'Không cho chụp hình', bigamount: 0, smallamount: 0 },
        { 'type': 'Ok', bigamount: 0, smallamount: 0 },
      ]
    },
    {
      name: 'CITI',
      data: [
        { 'type': 'Không cho chụp hình', bigamount: 0, smallamount: 0 },
        { 'type': 'Ok', bigamount: 0, smallamount: 0 },
      ]
    },
    {
      name: 'coopfood',
      data: [
        { 'type': 'Cancel vì siêu thị đóng cửa', bigamount: 0, smallamount: 0 },
        { 'type': 'Cancel vì siêu thị không cho vào', bigamount: 0, smallamount: 0 },
        { 'type': 'Không chấm đủ KPI/ngành hàng', bigamount: 0, smallamount: 0 },
        { 'type': 'Không cho chụp hình', bigamount: 0, smallamount: 0 },
        { 'type': 'Không cho chụp hình và không cho đo bằng kích thước', bigamount: 0, smallamount: 0 },
        { 'type': 'Ok', bigamount: 0, smallamount: 0 },
      ]
    },
    {
      name: 'coopmart',
      data: [
        { 'type': 'Cancel vì không liên hệ được SR', bigamount: 6, smallamount: 0 },
        { 'type': 'Cancel vì siêu thị không cho vào', bigamount: 4, smallamount: 0 },
        { 'type': 'Cancel vì store đang re-layout - xếp hàng', bigamount: 1, smallamount: 0 },
        { 'type': 'Không cho chụp hình', bigamount: 21, smallamount: 0 },
        { 'type': 'Không cho chụp hình và không cho đo bằng kích thước', bigamount: 1, smallamount: 0 },
        { 'type': 'Ok', bigamount: 45, smallamount: 0 },
      ]
    },
    {
      name: 'family mart',
      data: [
        { 'type': 'Không cho chụp hình', bigamount: 0, smallamount: 0 },
        { 'type': 'Không cho chụp hình và không cho đo bằng kích thước', bigamount: 0, smallamount: 0 },
        { 'type': 'Không cho đo SOS bằng thước', bigamount: 0, smallamount: 0 },
        { 'type': 'Ok', bigamount: 0, smallamount: 0 },
      ]
    },
  ];
  const piedataTotal = {
    labels: ['Ok', 'Không cho chụp hình', 'Other', 'Không chấm đủ KPI/ngành hàng', 'Không cho chụp hình và không cho đo SOS bằng thước'],
    datasets: [
      {
        label: '# Ah',
        data: [1164, 94, 62, 38, 1],
        backgroundColor: [
          'rgba(8, 166, 50, 0.6)',
          'rgba(166, 95, 8, 0.6)',
          'rgba(189, 10, 209, 0.6)',
          'rgba(201, 63, 12, 0.6)',
          'rgba(5, 117, 173, 0.6)',
        ],
        borderColor: [
          'rgba(8, 166, 50, 1)',
          'rgba(166, 95, 8, 1)',
          'rgba(189, 10, 209, 1)',
          'rgba(201, 63, 12, 1)',
          'rgba(5, 117, 173, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const piedataCancel = {
    labels: ['Cancel vì siêu thị không cho vào', 'Cancel vì store đang relayout/sắp xếp hàng', 'Cancel vì không liên hệ được SR', 'Không chấm đủ KPI/ngành hàng', 'Cancel vì siêu thị đóng cửa'],
    datasets: [
      {
        label: '# Cancel',
        data: [24, 3, 7, 5, 23],
        backgroundColor: [
          'rgba(8, 166, 50, 0.6)',
          'rgba(166, 95, 8, 0.6)',
          'rgba(189, 10, 209, 0.6)',
          'rgba(201, 63, 12, 0.6)',
          'rgba(5, 117, 173, 0.6)',
        ],
        borderColor: [
          'rgba(8, 166, 50, 1)',
          'rgba(166, 95, 8, 1)',
          'rgba(189, 10, 209, 1)',
          'rgba(201, 63, 12, 1)',
          'rgba(5, 117, 173, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Audited Store Updated By Date Time',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const data = {
    labels: ['UVN requirement (month)', 'AH Plan', 'Month to date'],
    datasets: [
      {
        label: 'Big Format',
        data: [174, 123, 44],
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'Small Format (Phase 1)',
        data: [1161, 847, 550],
        backgroundColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Small Format (Phase 2)',
        data: [1161, 847, 102],
        backgroundColor: 'rgb(53, 162, 235)',
      },
      {
        label: 'Total',
        data: [2496, 1817, 696],
        backgroundColor: 'rgb(53, 162, 20)',
      },
    ],
  };
  const onOk = (value) => {
    console.log('value', value._d);
  };
  return (
    <Row>
      <Col span={24}>
        <Card
          title="Audit Progress"
          bordered={false}
          style={{ width: '100%' }}
          extra={
            <Form name="filter" layout="inline">
              <Form.Item title="Date" >
                <DatePicker format="YYYY-MM-DD" onOk={onOk} />
              </Form.Item>
              <Form.Item
                name="supervisor"
                label="Supervisor"
              >
                <Select defaultValue={'1'} placeholder="Please choose">
                  <Select.Option value="1">ĐÀO XUÂN TRƯỜNG</Select.Option>
                  <Select.Option value="2">NGÔ THỊ HIỀN HOÀ</Select.Option>
                  <Select.Option value="3">NGUYỄN ĐỨC HÀO</Select.Option>
                  <Select.Option value="4">NGUYỄN ĐỨC HIỆP</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="auditor"
                label="Auditor"
              >
                <Select defaultValue={'1'} placeholder="Please choose">
                  <Select.Option value="1">CAO THANH HẢI</Select.Option>
                  <Select.Option value="2">ĐÀM QUANG HẢI</Select.Option>
                  <Select.Option value="3">ĐÀO HUY HOÀNG</Select.Option>
                  <Select.Option value="4">ĐỖ ĐĂNG KHOA</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="region"
                label="Region Name"
              >
                <Select defaultValue={'1'} placeholder="Please choose">
                  <Select.Option value="1">CENTRAL</Select.Option>
                  <Select.Option value="2">EAST</Select.Option>
                  <Select.Option value="3">HCM</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="customer"
                label="Customer"
              >
                <Select defaultValue={'1'} placeholder="Please choose">
                  <Select.Option value="1">7 - ELEVENT</Select.Option>
                  <Select.Option value="2">BSMART</Select.Option>
                  <Select.Option value="3">CIRCLE K</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          }
        >
          <Row>
            <Col span={12}>
              <Title style={{ fontSize: 20 }}>Audit Status</Title>
              <Row style={{ marginTop: 30 }}>
                <Col span={14}>
                  <div style={{ textAlign: 'center', margin: '0 10px', backgroundColor: '#fcba03' }}>
                    <Text >Total</Text>
                  </div>
                  <Pie key={'uvn'} data={piedataTotal} />

                </Col>
                <Col span={10}>
                  <div style={{ textAlign: 'center', margin: '0 10px', backgroundColor: '#bd0ad1', }}>
                    <Text style={{ color: 'white' }}>Cancel</Text>
                  </div>
                  <Pie key={'ah'} data={piedataCancel} />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Table
                columns={[
                  {
                    title: 'Store',
                    dataIndex: 'store',
                    key: 'store',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>{record.name}</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.type}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'BIG FORMAT',
                    dataIndex: 'bigformat',
                    key: 'bigformat',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.bigamount || '0'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'SMALL FORMAT',
                    dataIndex: 'smallformat',
                    key: 'smallformat',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.smallamount || '0'}</Text></div>
                        })}
                      </div>
                    },
                  },
                ]}

                dataSource={rootData || []}
                rowKey={(record) => record.id}
                pagination={{ position: ['bottomCenter'] }}
              />
            </Col>

          </Row>

        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(AuditStatus);
