import React from 'react';
import _, { filter } from 'lodash';
import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Image,
  Space,
  Form,
  Button,
  DatePicker,
  Tabs,
  Switch,
  List as AntList,
  Divider,
  Input,
  Collapse,
  Dropdown,
  Menu,
  Modal,
  Select

} from 'antd';
import NumberFormat from 'react-number-format';
import { green, gold, red, grey } from '@ant-design/colors';
import { connect } from 'react-redux';
import { homeActions } from '../redux/actions';
import { removeVietnameseTones } from '../../../utils/string'

import moment from 'moment';
// import authActions from '../../auth/redux/actions';
// import history from '../../../common/history';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const AllKpi = ({ dispatch, home, user }) => {
  const { Text, Title } = Typography;
  const [form] = Form.useForm();
  const allKpiDatas = home.allKpiDatas || null;
  const filterHomeData = home.filterDatas || null;
  // if (allKpiDatas) {
  //   console.log('allKpiDatas850', allKpiDatas[850]);
  // }
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const [columns, setColumns] = React.useState([]);
  const [expandColumns, setExpandColumns] = React.useState([]);
  const [expandShopColumns, setExpandShopColumns] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const [lastfilter, setLastfilter] = React.useState(null);
  const [customers, setCustomers] = React.useState([]);
  const [supervisors, setSupervisors] = React.useState([]);
  const [regions, setRegions] = React.useState([]);
  const [phases, setPhases] = React.useState([]);
  const [auditors, setAuditor] = React.useState([]);
  const [lowerOnly, setLowerOnly] = React.useState({
    'osa': 0, 'npd': 0, 'sos': 0, 'procms': 0, 'rental': 0, 'c2a': 0, 'prool': 0, 'olbig': 0
  });
  const allchecklisttype = [
    // 'c2a', 'npd', 'olbig', 'osa', 'procms', 'prool', 'rental', 'sos',
    'osa', 'npd', 'sos', 'procms', 'rental', 'c2a', 'prool', 'olbig'
  ];
  const setHighlight = () => {
    let _filterDatas = { ...filterDatas };
    console.log('lowerOnly1', lowerOnly);
    _filterDatas.lowerOnly = lowerOnly;
    setFilterDatas(_filterDatas)
  }
  const fulldatas = {
    defaultFilter: {
      month: filterHomeData && filterHomeData.month ? moment(filterHomeData.month, 'MM-YYYY') : moment(),
      region: "",
      customer: "",
      supervisor: "",
      phase: "",
      auditor: "",
      format: filterHomeData?.format || 'BIG FORMAT',
      lowerOnly: { 'osa': 0, 'npd': 0, 'sos': 0, 'procms': 0, 'rental': 0, 'c2a': 0, 'prool': 0, 'olbig': 0 },
    }
  }
  const [filterDatas, setFilterDatas] = React.useState(fulldatas.defaultFilter);
  const onOk = (value) => {
    // setLastfilter(null);
    let _filterDatas = { ...fulldatas.defaultFilter };
    form.resetFields();
    let _toDate = '';
    let _fromDate = '';
    let m = moment.utc(value).daysInMonth();
    if (value) {
      _toDate = moment.utc(value).tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
      _fromDate = moment.utc(value).tz(moment.tz.guess(true)).format('01-MM-YYYY');
      _filterDatas.month = moment.utc(value).tz(moment.tz.guess(true)).format('YYYY-MM');
    } else {
      _toDate = moment.utc().tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
      _fromDate = moment.utc().tz(moment.tz.guess(true)).format('01-MM-YYYY');
      _filterDatas.month = moment(moment(), 'YYYY-MM');
    }
    // console.log(_filterDatas);
    setFilterDatas(_filterDatas);
    // getDatas(_fromDate, _toDate);
  };
  const getDatas = async (_fromDate, _toDate) => {
    dispatch(homeActions.getKpiReport({ date_from: _fromDate, date_to: _toDate }));
  }

  React.useEffect(() => {
    let _columns = [
      {
        title: <Row >
          <Col flex='none'><Text>Customer</Text></Col>
          <Col flex="auto" style={{ textAlign: 'right' }}>
            <Form.Item >
              <Button type="primary" htmlType="button"
                onClick={() => {
                  setHighlight();
                }}
              >
                Highlight
              </Button>
            </Form.Item>
          </Col>


        </Row>,
        dataIndex: 'customer',
        key: 'customer',
        fixed: 'left',
        width: 253,
        render: (value, record) => {
          return <div>
            <Text style={{ textTransform: 'uppercase' }}>{record[0]}</Text>
          </div>
        },
      },
    ];
    for (let _i = 0; _i < allchecklisttype.length; _i++) {
      _columns.push(
        {
          title: <div style={{ textAlign: 'center' }}>
            <Form.Item
              name={`lowerOnly-${allchecklisttype[_i]}`}
              label=""
              align="center"

              style={{ marginBottom: 0 }}
            >
              <NumberFormat className="ant-input" navigation={false} style={{ marginBottom: 5, width: '100%', textAlign: 'center' }} placeholder={allchecklisttype[_i].toUpperCase()} value={
                lowerOnly && lowerOnly[allchecklisttype[_i]] ? lowerOnly[allchecklisttype[_i]] : 0
              } displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}
                onValueChange={(values) => {
                  let _lowerOnly = { ...lowerOnly }
                  let v = 0
                  if (values && values.floatValue) {
                    v = values.floatValue;
                  }
                  _lowerOnly[allchecklisttype[_i]] = v;
                  console.log('_lowerOnly', _lowerOnly);
                  setLowerOnly(_lowerOnly);
                }}
              />
            </Form.Item>
            <div style={{ fontSize: 12 }}>{allchecklisttype[_i].toLocaleUpperCase()}</div>
          </div>,
          dataIndex: allchecklisttype[_i],
          key: allchecklisttype[_i],
          width: 100,
          align: "center",
          render: (value, record) => {
            let _recordTotal = (typeof record[1].total[allchecklisttype[_i]] != 'undefined') ? record[1].total[allchecklisttype[_i]] : [0, 0];
            let _shownumber = '-';
            if (allchecklisttype[_i] == 'sos' || allchecklisttype[_i] == 'olbig') {
              _shownumber = (_recordTotal[1] > 0) ? _recordTotal[0] / _recordTotal[1] * 100 : '-';
            } else {
              _shownumber = (_recordTotal[0] > 0) ? _recordTotal[1] / _recordTotal[0] * 100 : '-'
            }
            let _lowerOnly = filterDatas.lowerOnly && filterDatas.lowerOnly[allchecklisttype[_i]] ? filterDatas.lowerOnly[allchecklisttype[_i]] : 0;
            return <div style={{ textAlign: 'center' }}>
              {_shownumber != '-' ? <NumberFormat value={_shownumber} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ color: _shownumber < _lowerOnly ? red[6] : grey[6] }}>{value}</Text>} /> : '-'}
            </div>
          },
        },
      );
    }
    setColumns(_columns);
  }, [lowerOnly, filterDatas]);
  React.useEffect(() => {
    console.log('_filterDatas', filterDatas);
    // getDatas('', '');
  }, []);
  React.useEffect(() => {


    let _expandcolumns = [
      {
        title: 'Shop',
        dataIndex: 'shop',
        key: 'shop',
        fixed: 'left',
        width: 205,
        render: (value, record) => {
          return <div>
            <Text style={{ textTransform: 'uppercase' }}>{record[0]}</Text>
          </div>
        },
      },
    ];
    let _expandShopColumns = [
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        fixed: 'left',
        width: 188,
        render: (value, record) => {
          return <div>
            <Text style={{ textTransform: 'uppercase' }}>{record[0]}</Text>
          </div>
        },
      },
    ];

    let _datas = []
    let _customerDatas = {};
    let checkcolumns = [];
    let _regions = [];
    let _customers = [];
    let _supervisors = [];
    let _phases = [];
    let _auditors = [];
    if (allKpiDatas && allKpiDatas.length) {
      let _allKpiDatas = allKpiDatas.filter((_itemdata) => {
        if (
          (filterDatas.region != '' && filterDatas.region != _itemdata.region) ||
          (filterDatas.customer != '' && filterDatas.customer != _itemdata.customer) ||
          (filterDatas.supervisor != '' && filterDatas.supervisor != _itemdata.supervisor) ||
          (filterDatas.phase != '' && filterDatas.phase != _itemdata.phase) ||
          (filterDatas.auditor != '' && filterDatas.auditor != _itemdata.auditor) ||
          (filterDatas.format != '' && filterDatas.format != _itemdata.format_type)
        ) {
          return false;
        }
        return true;
      });
      for (let i = 0; i < _allKpiDatas.length; i++) {
        if (_allKpiDatas[i].data && _allKpiDatas[i].data.checklist_summary_percentages) {
          let _checklisttype_ = Object.keys(_allKpiDatas[i].data.checklist_summary_percentages);
          // console.log('_allKpiDatas[i]', i, _checklisttype_);
        }
        if (!_customers.includes(_allKpiDatas[i].customer)) {
          _customers.push(_allKpiDatas[i].customer);
          _customerDatas[_allKpiDatas[i].customer] = {
            shops: {},
            shopcategory: {},
            total: {},//(_allKpiDatas[i].data && _allKpiDatas[i].data.checklist_summary_percentages) ? _allKpiDatas[i].data.checklist_summary_percentages : {}
          }
          // _data
        }
        if (_allKpiDatas[i].data.checklist_summary_data) {
          if (_allKpiDatas[i].region && !_regions.includes(_allKpiDatas[i].region)) {
            _regions.push(_allKpiDatas[i].region)
          }
          if (_allKpiDatas[i].customer && !_customers.includes(_allKpiDatas[i].customer)) {
            _customers.push(_allKpiDatas[i].customer)
          }
          if (_allKpiDatas[i].supervisor && !_supervisors.includes(_allKpiDatas[i].supervisor)) {
            _supervisors.push(_allKpiDatas[i].supervisor)
          }
          if (_allKpiDatas[i].phase && !_phases.includes(_allKpiDatas[i].phase)) {
            _phases.push(_allKpiDatas[i].phase)
          }
          if (_allKpiDatas[i].auditor && !_auditors.includes(_allKpiDatas[i].auditor)) {
            _auditors.push(_allKpiDatas[i].auditor)
          }
          // if (i == 0) {
          //   console.log('_allKpiDatas[i]', _allKpiDatas[i]);
          // }
          Object.entries(_allKpiDatas[i].data.checklist_summary_data).map((a) => {

            for (let _i = 0; _i < allchecklisttype.length; _i++) {

              if (!checkcolumns.includes(allchecklisttype[_i])) {

                _expandcolumns.push(
                  {
                    title: <Text style={{ fontSize: 12 }}>{allchecklisttype[_i].toUpperCase()}</Text>,
                    dataIndex: allchecklisttype[_i],
                    key: allchecklisttype[_i],
                    width: 100,
                    align: "center",
                    render: (value, record) => {

                      let _recordTotal = (typeof record[1][allchecklisttype[_i]] != 'undefined') ? record[1][allchecklisttype[_i]] : [0, 0];
                      let _shownumber = '-';
                      if (allchecklisttype[_i] == 'sos' || allchecklisttype[_i] == 'olbig') {
                        _shownumber = (_recordTotal[1] > 0) ? _recordTotal[0] / _recordTotal[1] * 100 : '-';
                      } else {
                        _shownumber = (_recordTotal[0] > 0) ? _recordTotal[1] / _recordTotal[0] * 100 : '-'
                      }
                      let _lowerOnly = filterDatas.lowerOnly && filterDatas.lowerOnly[allchecklisttype[_i]] ? filterDatas.lowerOnly[allchecklisttype[_i]] : 0;
                      return <div>
                        {_shownumber != '-' ? <NumberFormat value={_shownumber} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ color: _shownumber < _lowerOnly ? red[6] : grey[6] }}>{value}</Text>} /> : '-'}
                      </div>
                    },
                  },
                );
                _expandShopColumns.push(
                  {
                    title: <Text style={{ fontSize: 12 }}>{allchecklisttype[_i].toUpperCase()}</Text>,
                    dataIndex: allchecklisttype[_i],
                    key: allchecklisttype[_i],
                    width: 100,
                    align: "center",
                    render: (value, record) => {

                      let _recordTotal = (typeof record[1][allchecklisttype[_i]] != 'undefined') ? record[1][allchecklisttype[_i]] : [0, 0, 0];
                      let _shownumber = '-';
                      if (allchecklisttype[_i] == 'sos' || allchecklisttype[_i] == 'olbig') {
                        _shownumber = (_recordTotal[1] > 0) ? _recordTotal[0] / _recordTotal[1] * 100 : '-';
                      } else {
                        _shownumber = (_recordTotal[0] > 0) ? _recordTotal[1] / _recordTotal[0] * 100 : '-'
                      }
                      let _lowerOnly = filterDatas.lowerOnly && filterDatas.lowerOnly[allchecklisttype[_i]] ? filterDatas.lowerOnly[allchecklisttype[_i]] : 0;
                      return <div>
                        {_shownumber != '-' ? <NumberFormat value={_shownumber} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontStyle: 'italic', color: _shownumber < _lowerOnly ? red[6] : grey[6] }}>{value}</Text>} /> : '-'}
                      </div>
                    },
                  },
                );
                checkcolumns.push(allchecklisttype[_i]);
              }



              if (typeof (_customerDatas[_allKpiDatas[i].customer].shops[a[0]]) === 'undefined') {
                _customerDatas[_allKpiDatas[i].customer].shops[a[0]] = {};

              }

              if (typeof (_customerDatas[_allKpiDatas[i].customer].shopcategory[a[0]]) === 'undefined') {
                _customerDatas[_allKpiDatas[i].customer].shopcategory[a[0]] = {};

              }
              if (typeof (_customerDatas[_allKpiDatas[i].customer].shops[a[0]][allchecklisttype[_i]]) === 'undefined') {
                _customerDatas[_allKpiDatas[i].customer].shops[a[0]][allchecklisttype[_i]] = [0, 0];
              }
              if (typeof (_customerDatas[_allKpiDatas[i].customer].total[allchecklisttype[_i]]) === 'undefined') {
                _customerDatas[_allKpiDatas[i].customer].total[allchecklisttype[_i]] = [0, 0];
              }

              if (a[1][allchecklisttype[_i]] && (typeof (a[1][allchecklisttype[_i]]) !== 'undefined')) {
                Object.entries(a[1][allchecklisttype[_i]]).map((b) => {
                  if (b[0] != 'totals') {
                    if (typeof (_customerDatas[_allKpiDatas[i].customer].shopcategory[a[0]][b[0]]) === 'undefined') {
                      _customerDatas[_allKpiDatas[i].customer].shopcategory[a[0]][b[0]] = {};
                    }
                    if (typeof (_customerDatas[_allKpiDatas[i].customer].shopcategory[a[0]][b[0]][allchecklisttype[_i]]) === 'undefined') {

                      _customerDatas[_allKpiDatas[i].customer].shopcategory[a[0]][b[0]][allchecklisttype[_i]] = [0, 0, 0];
                    }
                    _customerDatas[_allKpiDatas[i].customer].shopcategory[a[0]][b[0]][allchecklisttype[_i]][0] += b[1][0];
                    _customerDatas[_allKpiDatas[i].customer].shopcategory[a[0]][b[0]][allchecklisttype[_i]][1] += b[1][1];
                    _customerDatas[_allKpiDatas[i].customer].shopcategory[a[0]][b[0]][allchecklisttype[_i]][2] += b[1][2];

                    // let _shopcategory = { ...a[1][allchecklisttype[_i]] };
                    // delete _shopcategory.totals;
                    // _customerDatas[_allKpiDatas[i].customer].shopcategory[a[0]][allchecklisttype[_i]] = _shopcategory;
                  } else {
                    _customerDatas[_allKpiDatas[i].customer].total[allchecklisttype[_i]][0] += a[1][allchecklisttype[_i]].totals[0];
                    _customerDatas[_allKpiDatas[i].customer].total[allchecklisttype[_i]][1] += a[1][allchecklisttype[_i]].totals[1];
                  }
                });

                _customerDatas[_allKpiDatas[i].customer].shops[a[0]][allchecklisttype[_i]][0] += a[1][allchecklisttype[_i]].totals[0];
                _customerDatas[_allKpiDatas[i].customer].shops[a[0]][allchecklisttype[_i]][1] += a[1][allchecklisttype[_i]].totals[1];
              }
            }

          });
        }

        // _customerDatas[_allKpiDatas[i].customer].shops

      }
      _datas = Object.entries(_customerDatas);
    }
    // console.log('_customerDatas', _customerDatas);

    setDatas(_datas);

    setExpandColumns(_expandcolumns);
    setExpandShopColumns(_expandShopColumns);
    if (isFirstLoad || !lastfilter) {
      setRegions(_regions);
      setCustomers(_customers);
      setSupervisors(_supervisors);
      setPhases(_phases);
      setAuditor(_auditors);
    } else {
      if (lastfilter == 'customer') {
        if (filterDatas.customer == '') {
          setCustomers(_customers);
        }
        setSupervisors(_supervisors);
        setPhases(_phases);
        setAuditor(_auditors);
        setRegions(_regions);
      } else if (lastfilter == 'region') {
        if (filterDatas.region == '') {
          setRegions(_regions);
        }
        setCustomers(_customers);
        setSupervisors(_supervisors);
        setPhases(_phases);
        setAuditor(_auditors);
      } else if (lastfilter == 'supervisor') {
        if (filterDatas.supervisor == '') {
          setSupervisors(_supervisors);
        }
        setCustomers(_customers);
        setPhases(_phases);
        setAuditor(_auditors);
        setRegions(_regions);
      } else if (lastfilter == 'phase') {
        if (filterDatas.phase == '') {
          setPhases(_phases);
        }
        setCustomers(_customers);
        setSupervisors(_supervisors);
        setAuditor(_auditors);
        setRegions(_regions);
      } else if (lastfilter == 'auditor') {
        if (filterDatas.auditor == '') {
          setAuditor(_auditors);
        }
        setCustomers(_customers);
        setPhases(_phases);
        setSupervisors(_supervisors);
        setRegions(_regions);
      }
    }
    setIsFirstLoad(false);
  }, [allKpiDatas, isFirstLoad, filterDatas]);

  const _saveFilter = async () => {
    let _filterDatas = { ...filterDatas }
    _filterDatas.month = moment(_filterDatas.month).format('MM-YYYY');
    if (_filterDatas.month != 'Invalid date') {
      dispatch(homeActions.saveFilter(_filterDatas));
      // let m = _filterDatas.month.daysInMonth(),
      //   _toDate = _filterDatas.month.format(m + '-MM-YYYY'),
      //   _fromDate = _filterDatas.month.format('01-MM-YYYY');
      // getDatas(_fromDate, _toDate);
    }
  }
  React.useEffect(() => {
    _saveFilter();
  }, [filterDatas]);
  React.useEffect(() => {
    if (filterDatas.month && filterDatas.month != 'Invalid date') {
      let m = filterDatas.month.daysInMonth(),
        _toDate = filterDatas.month.format(m + '-MM-YYYY'),
        _fromDate = filterDatas.month.format('01-MM-YYYY');
      getDatas(_fromDate, _toDate);
    }
  }, [filterDatas.month]);

  return (
    <Row>
      <Col span={24} style={{ backgroundColor: '#f0f2f5' }}>
        <Collapse accordion defaultActiveKey={['filter']} style={{ position: 'sticky', top: 0, zIndex: 4 }}>
          <Collapse.Panel header="Filter" key="filter">
            <Card
              size={'small'}
              bordered={false}
              bodyStyle={{ padding: 0 }}
              style={{ width: '100%' }}>
              <Form name="filter" form={form} layout='vertical'>
                <Row gutter={16}>
                  <Col>
                    <Form.Item title="Month" label="Month" >
                      <DatePicker format="MM-YYYY" picker="month" onChange={onOk} style={{ width: '100%' }}
                        value={filterDatas.month ? moment(filterDatas.month) : moment()}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="customer"
                      label="Customer"
                    >
                      <Select placeholder="Chọn All" value={filterDatas.customer} style={{ minWidth: 150 }} onChange={(value) => {
                        setLastfilter('customer');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.customer = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'customer-1'}>{'Chọn All'}</Select.Option>
                        {customers && customers.length ? customers.filter((__customer) => {
                          if (lastfilter == 'customer') {
                            return true;
                          } else {
                            return (filterDatas.customer) ? __customer == filterDatas.customer : true;
                          }
                        }).map((_customer, index) => {
                          return <Select.Option value={_customer} key={'customer' + index}>{_customer}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="region"
                      label="Region"
                    >
                      <Select placeholder="Chọn All" value={filterDatas.region} style={{ minWidth: 150 }} onChange={(value) => {
                        setLastfilter('region');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.region = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'region-1'}>{'Chọn All'}</Select.Option>
                        {regions && regions.length ? regions.filter((__region) => {
                          if (lastfilter == 'region') {
                            return true;
                          } else {
                            return (filterDatas.region) ? __region == filterDatas.region : true;
                          }
                        }).map((_region, index) => {
                          return <Select.Option value={_region} key={'region' + index}>{_region}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="supervisor"
                      label="Supervisor"
                    >
                      <Select placeholder="Chọn All" value={filterDatas.supervisor} style={{ minWidth: 200 }} onChange={(value) => {
                        setLastfilter('supervisor');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.supervisor = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'supervisor-1'}>{'Chọn All'}</Select.Option>
                        {supervisors && supervisors.length ? supervisors.filter((__supervisor) => {
                          if (lastfilter == 'supervisor') {
                            return true;
                          } else {
                            return (filterDatas.supervisor) ? __supervisor == filterDatas.supervisor : true;
                          }
                        }).map((_supervisor, index) => {
                          return <Select.Option value={_supervisor} key={'supervisor' + index}>{_supervisor}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="phase"
                      label="Phases"
                    >
                      <Select placeholder="Chọn All" value={filterDatas.phase} style={{ minWidth: 100 }} onChange={(value) => {
                        setLastfilter('phase');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.phase = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'phase-1'}>{'Chọn All'}</Select.Option>
                        {phases && phases.length ? phases.map((_phase, index) => {
                          return <Select.Option value={_phase} key={'phase' + index}>{_phase}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="auditor"
                      label="Auditor"
                    >
                      <Select placeholder="Chọn All" value={filterDatas.auditor} style={{ minWidth: 200 }} onChange={(value) => {
                        setLastfilter('auditor');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.auditor = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'auditor-1'}>{'Chọn All'}</Select.Option>
                        {auditors && auditors.length ? auditors.map((_auditor, index) => {
                          return <Select.Option value={_auditor} key={'auditor' + index}>{_auditor}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="format"
                      label="Format"
                      style={{ marginBottom: 0 }}
                    >
                      <Text style={{ fontSize: 0 }}>{filterDatas.format}</Text>
                      <Select placeholder="BIG FORMAT" value={filterDatas.format || 'BIG FORMAT'} style={{ minWidth: 150 }} onChange={(value) => {
                        setLastfilter('format');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.format = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value="BIG FORMAT" key={'format-2'}>BIG FORMAT </Select.Option>
                        <Select.Option value="SMALL FORMAT" key={'format-3'}>SMALL FORMAT</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    {/* <Form.Item >
                      <Button type="primary" htmlType="button"
                        onClick={() => {
                          let _filterDatas = { ...filterDatas };
                          _filterDatas.lowerOnly = lowerOnly;
                          setFilterDatas(_filterDatas)
                        }}
                      >
                        Highlight
                      </Button>
                    </Form.Item> */}
                    {/* <Row gutter={16}>
                      <Col flex={1}>
                        <Row gutter={8}>
                          {Object.entries(lowerOnly).map((_lower) => {
                            let __k = `lowerOnly-${_lower[0]}`;
                            return <Col flex={1} key={__k}>
                              <Form.Item
                                name={`lowerOnly-${_lower[0]}`}
                                label=""
                                style={{ marginBottom: 0 }}
                              >
                                <NumberFormat className="ant-input" navigation={false} style={{ marginBottom: 5, width: '100%' }} placeholder={_lower[0].toUpperCase()} value={_lower[1]} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={` (${_lower[0]})`} decimalScale={2}
                                  onValueChange={(values) => {
                                    let _lowerOnly = { ...lowerOnly }
                                    let v = 0
                                    if (values && values.floatValue) {
                                      v = values.floatValue;
                                    }
                                    _lowerOnly[_lower[0]] = v;
                                    setLowerOnly(_lowerOnly);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          })}
                        </Row>
                      </Col>
                      <Col span={'auto'}>
                        <Form.Item >
                          <Button type="primary" htmlType="button"
                            onClick={() => {
                              let _filterDatas = { ...filterDatas };
                              _filterDatas.lowerOnly = lowerOnly;
                              setFilterDatas(_filterDatas)
                            }}
                          >
                            Highlight
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row> */}
                  </Col>
                </Row>

              </Form>
            </Card>
          </Collapse.Panel>
        </Collapse>


        <Table
          dataSource={datas}
          rowKey={(record) => record[0]}
          pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
          columns={columns}
          style={{ width: '100%', marginTop: 15 }}
          scroll={{ x: 1050 }}
          className='no-border-right-header tablelevel1'
          expandable={{
            expandedRowRender: (record) => {
              let key = record[0];
              let shopDatas = record[1].shops;
              let shopCategory = record[1].shopcategory;
              return <Table
                dataSource={Object.entries(shopDatas)}
                rowKey={(record, index1) => {
                  return key + '__' + (index1) + record[0]
                }}
                className='tablelevel2'
                scroll={{ x: 1050 }}
                // showHeader={false}
                pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                columns={expandColumns}
                expandable={{
                  expandedRowRender: (record) => {
                    let _data = shopCategory[record[0]];
                    delete _data.no_stock_count;
                    delete _data.fail_stock_count;
                    delete _data.void_count;
                    return <Table
                      dataSource={Object.entries(_data)}
                      rowKey={(record, index) => {
                        return key + '__' + (index) + record[0]
                      }}
                      className='tablelevel3'
                      scroll={{ x: 1050 }}
                      //showHeader={false}
                      pagination={{ defaultPageSize: 30, position: ['bottomCenter'], hideOnSinglePage: true }}
                      columns={expandShopColumns}

                    />
                  }
                }}
              />
            }
          }}
        />
      </Col >
    </Row >
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(AllKpi);