const ReasonList = {
    OSA: [
        {
            reason: 'Thiếu stock',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Stock không đúng thực tế',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Không hiện diện',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Hiện diện sản phẩm cũ',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Hình ảnh SP Cũ Mới không xác định',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Thiếu bảng giá',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Bảng giá không đúng với SP',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Bảng giá lệch quá 1/2',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Chấm rớt cho SKU đạt',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Hình nhòe không xác định',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Audit chụp thiếu hình',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Stock không đúng thực tế',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Khác',
            Auditor: 'Reject',
            Store: '',
        },
        {
            reason: 'Sai Vị Trí',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Sai bảng giá ở kệ chính',
            Auditor: 'Reject',
            Store: 'Reject',
        },

    ],
    NPD: [
        {
            reason: 'Không hiện diện',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Thiếu bảng giá',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Bảng giá không đúng với SP',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Bảng giá lệch quá 1/2',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Sai Vị Trí',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Chấm rớt cho SKU đạt',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Hình nhòe không xác định',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Audit chụp thiếu hình',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Khác',
            Auditor: 'Reject',
            Store: '',
        },
    ],
    PROCMS: [
        {
            reason: 'Không hiện diện SP',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Không hiện diện bảng thông báo KM',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Sai nội dung KM',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Sai Vị Trí',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Chấm rớt cho SKU đạt',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Hình nhòe không xác định',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Audit chụp thiếu hình',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Khác',
            Auditor: 'Reject',
            Store: '',
        },
    ],
    PROOL: [
        {
            reason: 'Không hiện diện SP',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Không hiện diện bảng thông báo KM',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Sai nội dung KM',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Sai Vị Trí',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Chấm rớt cho SKU đạt',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Hình nhòe không xác định',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Audit chụp thiếu hình',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Khác',
            Auditor: 'Reject',
            Store: '',
        },
    ],
    SOS: [
        {
            reason: 'Nhập sai độ dài Unilever',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Nhập sai độ dài do DOB',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Nhập sai độ dài do hàng nhập',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Nhập sai độ dài do đo sai sp đối thủ',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Ghi nhận sai brand cùng subcat',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Ghi nhận sai vị trí',
            Auditor: 'Reject',
            Store: 'Reject',
        },

        {
            reason: 'Hình nhòe không xác định',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Audit chụp thiếu hình',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Đo lường không đúng vị trí',
            Auditor: 'Reject',
            Store: '',
        },
        {
            reason: 'Khác',
            Auditor: 'Reject',
            Store: '',
        },
    ],
    RENTAL: [
        {
            reason: 'Không hiện diện rental',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Có sp đối thủ',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Không in line',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Thiếu bảng giá',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Sai Planogram',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Chấm rớt cho Rental đạt',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Hình nhòe không xác định',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Audit chụp thiếu hình',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Khác',
            Auditor: 'Reject',
            Store: '',
        },
    ],
    C2A: [
        {
            reason: 'Không hiện diện C2A',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'C2A không đúng',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Không hiện diện C2A đủ tầng',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Không có sản phẩn phù hợp trên C2A',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Chấm rớt cho C2A đạt',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Hình nhòe không xác định',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Audit chụp thiếu hình',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Khác',
            Auditor: 'Reject',
            Store: '',
        },
    ],
    OLBIG: [
        {
            reason: 'Số lượng Unilever nhiều hơn',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Số lượng Unilever ít hơn',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Số lượng Total nhiều hơn',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Số lượng total ít hơn',
            Auditor: 'Reject',
            Store: 'Reject',
        },
        {
            reason: 'Hình nhòe không xác định',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Audit chụp thiếu hình',
            Auditor: 'Reject',
            Store: 'Pass',
        },
        {
            reason: 'Số lượng Total & Unilever ít hơn',
            title: 'SL Total & U ít hơn',
            Auditor: 'Reject',
            Store: '',
        },
        {
            reason: 'Số lượng Total & Unilever nhiều hơn',
            title: 'SL Total & U nhiều hơn',
            Auditor: 'Reject',
            Store: '',
        },
        {
            reason: 'Số lượng Total ít hơn & Unilever nhiều hơn',
            title: 'SL Total ít hơn & U nhiều hơn',
            Auditor: 'Reject',
            Store: '',
        },
        {
            reason: 'Số lượng Total nhiều hơn & Unilever ít hơn',
            title: 'SL Total nhiều hơn & U ít hơn',
            Auditor: 'Reject',
            Store: '',
        },
        {
            reason: 'Khác',
            Auditor: 'Reject',
            Store: '',
        },
    ]
};
export default ReasonList;