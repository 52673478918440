import React from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Image,
  Space,
  Form,
  Button,
  DatePicker,
  Tabs,
  Switch,
  List as AntList,
  Divider,
  Input,
  Collapse,
  Dropdown,
  Menu,
  Select,
  Modal

} from 'antd';

import { connect } from 'react-redux';
import { homeActions } from '../redux/actions';
// import authActions from '../../auth/redux/actions';
// import history from '../../../common/history';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import virtualdata from './virtualdata';
import moment from 'moment';
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);



const AuditProgress = ({ dispatch, home, user }) => {
  const { Text } = Typography;
  const [form] = Form.useForm();
  const auditProgressDatas = home.auditProgressMixDim || null;
  const auditProgressDatasRight = home.auditProgressIndexShops || null;
  const filterHomeData = home.filterDatas || null;
  if (auditProgressDatas) {
    // console.log('auditProgressDatas', auditProgressDatas[0]);
    // console.log('moment', moment(auditProgressDatas[0].date).format('YYYY-MM'));
  }
  const [datas, setDatas] = React.useState([]);
  const [barDataLeft, setBarDataLeft] = React.useState(null);
  const [columns, setColumns] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [supervisors, setSupervisors] = React.useState([]);
  const [phases, setPhases] = React.useState([]);
  const [auditors, setAuditor] = React.useState([]);
  const [regions, setRegions] = React.useState([]);
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const [lastfilter, setLastfilter] = React.useState(null);
  const [piedataBig, setPiedataBig] = React.useState(null);
  const [bardataBig, setBardataBig] = React.useState(null);
  const [piedataSmall, setPiedataSmall] = React.useState(null);
  const [bardataSmall, setBardataSmall] = React.useState(null);
  let _listMonths = [
    moment.utc().tz(moment.tz.guess(true)).subtract(5, 'months').format('MM-YYYY'),
    moment.utc().tz(moment.tz.guess(true)).subtract(4, 'months').format('MM-YYYY'),
    moment.utc().tz(moment.tz.guess(true)).subtract(3, 'months').format('MM-YYYY'),
    moment.utc().tz(moment.tz.guess(true)).subtract(2, 'months').format('MM-YYYY'),
    moment.utc().tz(moment.tz.guess(true)).subtract(1, 'months').format('MM-YYYY'),
    moment.utc().tz(moment.tz.guess(true)).format('MM-YYYY'),
  ];
  const [listMonths, setListMonths] = React.useState(_listMonths)
  const [totalActualStore, setTotalActualStore] = React.useState({
    big: 0,
    small: 0,
    totalStore: 0
  });
  const [totalActiveStore, setTotalActiveStore] = React.useState({
    big: 0,
    small: 0,
    totalStore: 0
  });
  const [totalPlannedStore, setTotalPlannedStore] = React.useState({
    big: 0,
    small: 0,
    totalStore: 0
  });
  const [totalRequestStore, setTotalRequestStore] = React.useState({
    big: 0,
    small: 0,
    totalStore: 0
  });
  const defaultFilter = {
    customer: "",
    supervisor: "",
    phase: "",
    auditor: "",
    region: "",
    month: filterHomeData && filterHomeData.month ? moment(filterHomeData.month, 'MM-YYYY') : moment(),
    format: filterHomeData?.format || 'BIG FORMAT',
  }
  const [filterDatas, setFilterDatas] = React.useState(defaultFilter);

  const label = [
    { title: 'Ok', datas: {}, backgroundColor: 'rgb(255, 99, 132)', },
    { title: 'Siêu thị không cho chụp hình', datas: {}, backgroundColor: 'rgb(75, 192, 192)', },
    { title: 'Siêu thị không cho đo SOS bằng thước', datas: {}, backgroundColor: 'rgb(53, 162, 235)', },
    { title: 'Siêu thị không cho chụp hình và không cho đo bằng thước', datas: {}, backgroundColor: 'rgb(53, 162, 20)', },
    { title: 'Cancel vì store không cho vào', datas: {}, backgroundColor: "rgba(8, 166, 50, 1)" },
    { title: 'Cancel vì store đang relayout/sắp xếp hàng', datas: {}, backgroundColor: "rgba(166, 95, 8, 1)" },
    { title: 'Cancel vì không liên hệ được SR', datas: {}, backgroundColor: "rgba(189, 10, 209, 1)" },
    { title: 'Cancel vì Auditor nghỉ đột xuất', datas: {}, backgroundColor: "rgba(201, 63, 12, 1)" },
    { title: 'Cancel vì siêu thị đóng cửa', datas: {}, backgroundColor: "rgba(5, 117, 173, 1)" },
    { title: 'Không chấm đủ KPI/ngành hàng', datas: {}, backgroundColor: "rgba(66, 40, 112, 1)" },
    { title: 'Lỗi app', datas: {}, backgroundColor: 'rgb(53, 162, 20)' },
  ];
  const getDatas = async (_fromDate, _toDate, _fromDateOneMonth) => {
    setIsFirstLoad(true);
    await dispatch(homeActions.getAuditProgressMixDims({ date_from: _fromDate, date_to: _toDate }));
    await dispatch(homeActions.getAuditProgressIndexShops({ date_from: _fromDateOneMonth, date_to: _toDate }));
  }
  React.useEffect(() => {
    let m = moment.utc().daysInMonth();
    let _toDate = moment.utc().tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
    let _fromDate = moment.utc().tz(moment.tz.guess(true)).subtract(5, 'months').format('01-MM-YYYY');
    let _fromDateOneMonth = moment.utc().tz(moment.tz.guess(true)).format('01-MM-YYYY');
    getDatas(_fromDate, _toDate, _fromDateOneMonth);
  }, [])
  React.useEffect(() => {
    let _datas = label;
    let months = [];
    let datasets = [];

    let _totalActualStore = { big: 0, small: 0, stores: [], totalStore: 0 };
    let _columns = [
      {
        title: <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Name </Text>,
        dataIndex: 'name',
        key: 'name',
        render: (value, record) => {
          return <Text style={{ fontSize: 16 }}>{record.title || ''} </Text>
        },
      },
    ];
    for (let _k = 0; _k < listMonths.length; _k++) {
      let _month = listMonths[_k];
      let __month = _month.split("-");
      let month = __month[1] + '-' + __month[0];
      _columns.push({
        title: <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{month} </Text>,
        dataIndex: month,
        key: month,
        render: (value, record) => {
          return <Text style={{ fontSize: 16 }}>{record.datas[month] || 0} </Text>
        },
      });
      if (typeof (_datas[0].datas[month]) === 'undefined') {
        _datas[0].datas[month] = 0;
      }
      if (typeof (_datas[1].datas[month]) === 'undefined') {
        _datas[1].datas[month] = 0;
      }
      if (typeof (_datas[2].datas[month]) === 'undefined') {
        _datas[2].datas[month] = 0;
      }
      if (typeof (_datas[3].datas[month]) === 'undefined') {
        _datas[3].datas[month] = 0;
      }
      if (typeof (_datas[4].datas[month]) === 'undefined') {
        _datas[4].datas[month] = 0;
      }
      if (typeof (_datas[5].datas[month]) === 'undefined') {
        _datas[5].datas[month] = 0;
      }
      if (typeof (_datas[6].datas[month]) === 'undefined') {
        _datas[6].datas[month] = 0;
      }
      if (typeof (_datas[7].datas[month]) === 'undefined') {
        _datas[7].datas[month] = 0;
      }
      if (typeof (_datas[8].datas[month]) === 'undefined') {
        _datas[8].datas[month] = 0;
      }
      if (typeof (_datas[9].datas[month]) === 'undefined') {
        _datas[9].datas[month] = 0;
      }
      if (typeof (_datas[10].datas[month]) === 'undefined') {
        _datas[10].datas[month] = 0;
      }

    }
    let _customers = [];
    let _supervisors = [];
    let _phases = [];
    let _auditors = [];
    let _regions = [];


    if (auditProgressDatas && auditProgressDatas.length) {

      let _auditProgressDatas = auditProgressDatas.filter((_itemdata) => {
        if (
          (filterDatas.customer != '' && filterDatas.customer != _itemdata.customer) ||
          (filterDatas.supervisor != '' && filterDatas.supervisor != _itemdata.supervisor) ||
          (filterDatas.phase != '' && filterDatas.phase != _itemdata.phase) ||
          (filterDatas.auditor != '' && filterDatas.auditor != _itemdata.auditor) ||
          (filterDatas.region != '' && filterDatas.region != _itemdata.region) ||
          (filterDatas.format != _itemdata.format_type)
        ) {
          return false;
        }
        return true;
      });
      let filtermonth = filterDatas.month.format('YYYY-MM');
      // console.log('_auditProgressData111', _auditProgressDatas);
      for (let i = 0; i < _auditProgressDatas.length; i++) {
        let _filtermonth = moment(_auditProgressDatas[i].date).format('YYYY-MM');
        // console.log('_auditProgressDatas[i]', _auditProgressDatas[i]);
        // console.log('_filtermonth', _filtermonth);
        // console.log('filtermonth', filtermonth);

        if (filtermonth == _filtermonth) {
          // console.log('_auditProgressDatas[i]', _auditProgressDatas[i]);
          if (_auditProgressDatas[i].format_type == 'SMALL FORMAT') {
            _totalActualStore.small += (_auditProgressDatas[i].data.calls) ? _auditProgressDatas[i].data.calls : 0;
          }
          if (_auditProgressDatas[i].format_type == 'BIG FORMAT') {
            _totalActualStore.big += (_auditProgressDatas[i].data.calls) ? _auditProgressDatas[i].data.calls : 0;
          }
          let stores = (_auditProgressDatas[i].data && _auditProgressDatas[i].data.checklist_summary_data) ? Object.keys(_auditProgressDatas[i].data.checklist_summary_data) : [];
          // console.log('stores', stores, _auditProgressDatas[i].data);
          for (let k = 0; k < stores.length; k++) {
            if (!_totalActualStore.stores.includes(stores[k])) {
              _totalActualStore.stores.push(stores[k]);
              _totalActualStore.totalStore++;
            }
          }

        }

        if (_auditProgressDatas[i].customer && !_customers.includes(_auditProgressDatas[i].customer)) {
          _customers.push(_auditProgressDatas[i].customer)
        }
        if (_auditProgressDatas[i].supervisor && !_supervisors.includes(_auditProgressDatas[i].supervisor)) {
          // if (!_auditProgressDatas[i].supervisor) {
          //   console.log('_auditProgressDatas[i].supervisor', _auditProgressDatas[i].supervisor, _auditProgressDatas[i].id);
          // };
          _supervisors.push(_auditProgressDatas[i].supervisor)
        }
        if (_auditProgressDatas[i].phase && !_phases.includes(_auditProgressDatas[i].phase)) {
          _phases.push(_auditProgressDatas[i].phase)
        }
        if (_auditProgressDatas[i].auditor && !_auditors.includes(_auditProgressDatas[i].auditor)) {
          _auditors.push(_auditProgressDatas[i].auditor)
        }
        if (_auditProgressDatas[i].region && !_regions.includes(_auditProgressDatas[i].region)) {
          _regions.push(_auditProgressDatas[i].region)
        }
        let _data = _auditProgressDatas[i].data;
        let _month = '' + _auditProgressDatas[i].meta_data.year + '-';
        _month += (_auditProgressDatas[i].meta_data.month < 10) ? '0' + _auditProgressDatas[i].meta_data.month : _auditProgressDatas[i].meta_data.month;
        _month += '-01';
        let month = moment(_month).format('YYYY-MM');


        // if (typeof (_datas[0].datas[month]) === 'undefined') {
        //   _datas[0].datas[month] = 0;
        // }
        // if (typeof (_datas[1].datas[month]) === 'undefined') {
        //   _datas[1].datas[month] = 0;
        // }
        // if (typeof (_datas[2].datas[month]) === 'undefined') {
        //   _datas[2].datas[month] = 0;
        // }
        // if (typeof (_datas[3].datas[month]) === 'undefined') {
        //   _datas[3].datas[month] = 0;
        // }
        // if (typeof (_datas[4].datas[month]) === 'undefined') {
        //   _datas[4].datas[month] = 0;
        // }
        // if (typeof (_datas[5].datas[month]) === 'undefined') {
        //   _datas[5].datas[month] = 0;
        // }
        // if (typeof (_datas[6].datas[month]) === 'undefined') {
        //   _datas[6].datas[month] = 0;
        // }
        // if (typeof (_datas[7].datas[month]) === 'undefined') {
        //   _datas[7].datas[month] = 0;
        // }
        // if (typeof (_datas[8].datas[month]) === 'undefined') {
        //   _datas[8].datas[month] = 0;
        // }
        // if (typeof (_datas[9].datas[month]) === 'undefined') {
        //   _datas[9].datas[month] = 0;
        // }
        // if (typeof (_datas[10].datas[month]) === 'undefined') {
        //   _datas[10].datas[month] = 0;
        // }
        // console.log('_data.done_sub_reasons', _auditProgressDatas[i].format_type, month, _data.done_sub_reasons);
        _datas[0].datas[month] += parseFloat(_data.done_sub_reasons[0]) || 0;
        _datas[1].datas[month] += parseFloat(_data.done_sub_reasons[1]) || 0;
        _datas[2].datas[month] += parseFloat(_data.done_sub_reasons[2]) || 0;
        _datas[3].datas[month] += parseFloat(_data.done_sub_reasons[3]) || 0;
        _datas[4].datas[month] += parseFloat(_data.cancelled_sub_reasons[0]) || 0;
        _datas[5].datas[month] += parseFloat(_data.cancelled_sub_reasons[1]) || 0;
        _datas[6].datas[month] += parseFloat(_data.cancelled_sub_reasons[2]) || 0;
        _datas[7].datas[month] += parseFloat(_data.cancelled_sub_reasons[3]) || 0;
        _datas[8].datas[month] += parseFloat(_data.cancelled_sub_reasons[4]) || 0;
        _datas[9].datas[month] += parseFloat(_data.not_yet_sub_reasons) || 0;
        _datas[10].datas[month] += parseFloat(_data.other) || 0;
        if (!months.includes(month)) {
          months.push(month);
          // _columns.push({
          //   title: <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{month} </Text>,
          //   dataIndex: month,
          //   key: month,
          //   render: (value, record) => {
          //     return <Text style={{ fontSize: 16 }}>{record.datas[month] || 0} </Text>
          //   },
          // });
        }
      }
      delete _totalActualStore.stores;
      // console.log('_totalActualStore1', _totalActualStore);
      setTotalActualStore(_totalActualStore);
      for (let i = 0; i < _datas.length; i++) {
        datasets.push({
          label: _datas[i].title,
          data: Object.values(_datas[i].datas),
          backgroundColor: _datas[i].backgroundColor,
        })
      }

    }
    console.log('datasets', datasets);
    setBarDataLeft({
      labels: listMonths,
      datasets: datasets,
    });
    setDatas(_datas);
    if (isFirstLoad || !lastfilter) {
      setCustomers(_customers);
      setSupervisors(_supervisors);
      setPhases(_phases);
      setAuditor(_auditors);
      setRegions(_regions);
    } else {
      if (lastfilter == 'customer') {
        if (filterDatas.customer == '') {
          setCustomers(_customers);
        }
        setSupervisors(_supervisors);
        setPhases(_phases);
        setAuditor(_auditors);
        setRegions(_regions);
      } else if (lastfilter == 'supervisor') {
        if (filterDatas.supervisor == '') {
          setSupervisors(_supervisors);
        }
        setCustomers(_customers);
        setPhases(_phases);
        setAuditor(_auditors);
        setRegions(_regions);
      } else if (lastfilter == 'phase') {
        if (filterDatas.phase == '') {
          setPhases(_phases);
        }
        setCustomers(_customers);
        setSupervisors(_supervisors);
        setAuditor(_auditors);
        setRegions(_regions);
      } else if (lastfilter == 'auditor') {
        if (filterDatas.auditor == '') {
          setAuditor(_auditors);
        }
        setCustomers(_customers);
        setPhases(_phases);
        setSupervisors(_supervisors);
        setRegions(_regions);
      } else if (lastfilter == 'region') {
        if (filterDatas.region == '') {
          setRegions(_regions);
        }
        setCustomers(_customers);
        setPhases(_phases);
        setAuditor(_auditors);
        setSupervisors(_supervisors);
      }
    }


    setColumns(_columns);
    setIsFirstLoad(false);
  }, [auditProgressDatas, filterDatas, lastfilter, isFirstLoad]);
  React.useEffect(() => {
    let _label = ['Actual store vs Request store',];
    if (totalPlannedStore.big && totalRequestStore.big) {
      const dataBig = {
        labels: _label,
        datasets: [
          {
            label: 'Actual store vs Request store',
            data: [totalActualStore.big * 100 / totalRequestStore.big],
            backgroundColor: ['rgb(255, 99, 132)'],
          },
          {
            label: 'Actual store vs AH Plan',
            data: [totalActualStore.big * 100 / totalPlannedStore.big],
            backgroundColor: ['rgb(53, 162, 235)'],
          },
        ],
      };
      console.log('totalActualStore.big', totalActualStore);
      console.log('totalRequestStore', totalRequestStore);
      console.log('totalPlannedStore', totalPlannedStore);
      console.log('dataBig', dataBig);
      setBardataBig(dataBig);
    }
    if (totalPlannedStore.small && totalRequestStore.small) {
      const dataSmall = {
        labels: _label,
        datasets: [
          {
            label: 'Actual store vs Request store',
            data: [totalActualStore.small * 100 / totalRequestStore.small],
            backgroundColor: ['rgb(255, 99, 132)'],
          },
          {
            label: 'Actual store vs AH Plan',
            data: [totalActualStore.small * 100 / totalPlannedStore.small],
            backgroundColor: ['rgb(53, 162, 235)'],
          },
        ],
      };
      setBardataSmall(dataSmall);
    }
  }, [totalActualStore, totalRequestStore, totalPlannedStore]);
  React.useEffect(() => {
    let _totalActiveStore = { big: 0, small: 0 };
    let _totalPlannedStore = { big: 0, small: 0 };
    let _totalRequestStore = { big: 0, small: 0 };
    // console.log('auditProgressDatasRight', auditProgressDatasRight);
    if (auditProgressDatasRight && auditProgressDatasRight.length) {
      let _auditProgressDatas = auditProgressDatasRight.filter((_itemdata) => {
        if (
          (filterDatas.customer != '' && filterDatas.customer != _itemdata.customer) ||
          (filterDatas.supervisor != '' && filterDatas.supervisor != _itemdata.supervisor) ||
          (filterDatas.phase != '' && filterDatas.phase != _itemdata.phase) ||
          (filterDatas.auditor != '' && filterDatas.auditor != _itemdata.auditor) ||
          (filterDatas.region != '' && filterDatas.region != _itemdata.region)
        ) {
          return false;
        }
        return true;
      });
      for (let i = 0; i < _auditProgressDatas.length; i++) {
        if (_auditProgressDatas[i].format_type == 'SMALL FORMAT') {
          _totalActiveStore.small += (_auditProgressDatas[i].data.total_active_shops) ? _auditProgressDatas[i].data.total_active_shops : 0;
          _totalPlannedStore.small += (_auditProgressDatas[i].data.total_planned_shops) ? _auditProgressDatas[i].data.total_planned_shops : 0;
          _totalRequestStore.small += (_auditProgressDatas[i].data.total_required_shops) ? _auditProgressDatas[i].data.total_required_shops : 0;
        }
        if (_auditProgressDatas[i].format_type == 'BIG FORMAT') {
          _totalActiveStore.big += (_auditProgressDatas[i].data.total_active_shops) ? _auditProgressDatas[i].data.total_active_shops : 0;
          _totalPlannedStore.big += (_auditProgressDatas[i].data.total_planned_shops) ? _auditProgressDatas[i].data.total_planned_shops : 0;
          _totalRequestStore.big += (_auditProgressDatas[i].data.total_required_shops) ? _auditProgressDatas[i].data.total_required_shops : 0;
        }

      }
      if (_totalActiveStore.big) {

        let _piedataBig = {
          labels: ['Total Request Store', 'Non Audit'],
          datasets: [
            {
              label: '# Request Vs Active Shops',
              data: [_totalRequestStore.big, _totalActiveStore.big - _totalRequestStore.big],
              backgroundColor: [
                '#e58c52',
                'rgba(102, 104, 103,0.6)'

              ],
              borderColor: [
                '#e58c52',
                'rgba(102, 104, 103,0.8)'
              ],
              borderWidth: 1,
            },
          ],
        };
        setPiedataBig(_piedataBig)
      }
      if (_totalActiveStore.small) {
        let _piedataSmall = {
          labels: ['Total Request Store', 'Non Audit'],
          datasets: [
            {
              label: '# SMALL FORMAT',
              data: [_totalRequestStore.small, _totalActiveStore.small - _totalRequestStore.small],
              backgroundColor: [
                '#e58c52',
                'rgba(102, 104, 103,0.6)'
              ],
              borderColor: [
                '#e58c52',
                'rgba(102, 104, 103,0.8)'
              ],
              borderWidth: 1,
            },
          ],
        };
        setPiedataSmall(_piedataSmall)
      }
      // console.log('_totalActiveStore', _totalActiveStore);
      // console.log('_totalPlannedStore', _totalPlannedStore);
      // console.log('_totalRequestStore', _totalRequestStore);
      setTotalActiveStore(_totalActiveStore);
      setTotalPlannedStore(_totalPlannedStore);
      setTotalRequestStore(_totalRequestStore);

    }
  }, [auditProgressDatasRight, filterDatas, lastfilter])


  // const piedataAh = {
  //   labels: ['Big Format', 'Small Format (Phase 1)', 'Small Format (Phase 1)', 'Total'],
  //   datasets: [
  //     {
  //       label: '# Mtd',
  //       data: [35.77, 64.94, 12.04, 38.3],
  //       backgroundColor: [
  //         'rgba(255, 99, 132, 0.2)',
  //         'rgba(54, 162, 235, 0.2)',
  //         'rgba(255, 206, 86, 0.2)',
  //         'rgba(75, 192, 192, 0.2)',
  //       ],
  //       borderColor: [
  //         'rgba(255, 99, 132, 1)',
  //         'rgba(54, 162, 235, 1)',
  //         'rgba(255, 206, 86, 1)',
  //         'rgba(75, 192, 192, 1)',
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  const optionsBar = {
    plugins: {
      title: {
        display: true,
        text: 'AUDIT STATUS MOM',
        font: {
          size: 20
        }
      },
      tooltip: {
        enabled: true
      },
      legend: {
        display: false,
        position: 'bottom',
        align: 'start',
        labels: {
          // boxWidth: 12,
          usePointStyle: true
        }

      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        color: '#fff',
        borderColor: 'white',
        borderRadius: 25,
        // borderWidth: 2,
        // color: 'white',
        display: function (context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > count * 1.5;
        },
        font: {
          weight: 'bold',
          size: 18
        },
        padding: 6,
        formatter: Math.round
      }
    },


    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 18,
          }
        }
      },
      y: {
        stacked: true,
        ticks: {
          font: {
            size: 14,
          }
        }
      },
    },

  };
  const optionsBar2 = {
    plugins: {
      title: {
        display: true,
        text: 'BIG FORMAT',
      },
      tooltip: {
        enabled: true
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          // boxWidth: 12,
          usePointStyle: true
        }

      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        color: '#fff',
        borderColor: 'white',
        borderRadius: 25,
        // borderWidth: 2,
        // color: 'white',
        display: function (context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > count * 1.5;
        },
        font: {
          weight: 'bold',
          size: 14
        },
        padding: 6,
        formatter: (value, ctx) => {
          let percentage = value.toFixed(2) + "%";
          return percentage;
        },
      }
    },


    responsive: true,
    scales: {
      x: {
        stacked: false,
        ticks: {
          font: {
            size: 0
          }
        }
      },
      y: {
        stacked: false,
      },
    },

  };
  const optionsBar3 = {
    plugins: {
      title: {
        display: true,
        text: 'SMALL FORMAT',
      },
      tooltip: {
        enabled: true
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          // boxWidth: 12,
          usePointStyle: true
        }

      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        color: '#fff',
        borderColor: 'white',
        borderRadius: 25,
        // borderWidth: 2,
        // color: 'white',
        display: function (context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > count * 1.5;
        },
        font: {
          weight: 'bold',
          size: 14
        },
        padding: 6,
        formatter: (value, ctx) => {
          let percentage = value.toFixed(2) + "%";
          return percentage;
        },
      }
    },


    responsive: true,
    scales: {
      x: {
        stacked: false,
        ticks: {
          font: {
            size: 0
          }
        }
      },
      y: {
        stacked: false,
      },
    },
  };
  const optionsPie1 = {
    plugins: {
      title: {
        display: true,
        text: 'BIG FORMAT: REQUEST vs ACTIVE STORES',
        color: '#e58c52',
        font: {
          size: 14
        }
      },
      tooltip: {
        enabled: true
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          // boxWidth: 12,
          usePointStyle: true
        }
      },
      datalabels: {
        backgroundColor: function (context) {
          return 'rgba(255,255,255,0.7)';
        },
        borderColor: 'white',
        borderRadius: 25,
        // borderWidth: 2,
        // color: 'white',
        display: function (context) {
          return true;
          // return context.dataIndex;
          // var dataset = context.dataset;
          // var count = dataset.data.length;
          // var value = dataset.data[context.dataIndex];
          // return value > count * 1.5;
        },
        font: {
          weight: 'bold',
          size: 14
        },
        padding: 6,
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          let percentage = (value * 100 / sum).toFixed(2) + "%(" + value + ")";
          return percentage;
        },
      }
    },
    responsive: true,
  };
  const optionsPie2 = {
    plugins: {
      title: {
        display: true,
        text: 'SMALL FORMAT: REQUEST vs ACTIVE STORES',
        color: '#e58c52',
        font: {
          size: 14
        }
      },
      tooltip: {
        enabled: true
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          // boxWidth: 12,
          usePointStyle: true
        }
      },
      datalabels: {
        backgroundColor: function (context) {
          return 'rgba(255,255,255,0.7)';
        },
        borderColor: 'white',
        borderRadius: 25,
        // borderWidth: 2,
        // color: 'white',
        display: function (context) {
          return true;
          // var dataset = context.dataset;
          // var count = dataset.data.length;
          // var value = dataset.data[context.dataIndex];
          // return value > count * 1.5;
        },
        font: {
          weight: 'bold',
          size: 14
        },
        padding: 6,
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          let percentage = (value * 100 / sum).toFixed(2) + "%(" + value + ")";
          return percentage;
        },
      }
    },
    responsive: true,
  };
  const optionsPie3 = {
    plugins: {
      title: {
        display: true,
        text: 'SMALL FORMAT',
      },
      tooltip: {
        enabled: true
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          // boxWidth: 12,
          usePointStyle: true
        }
      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderColor: 'white',
        borderRadius: 25,
        display: function (context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > count * 1.5;
        },
        font: {
          weight: 'bold'
        },
        padding: 6,
        formatter: Math.round
      }
    },
    responsive: true,
  };
  const onOk = (value) => {
    setLastfilter(null);
    let _filterDatas = { ...defaultFilter };
    form.resetFields();
    let _toDate = '';
    let _fromDate = '';
    let _fromDateOneMonth = '';
    let m = moment.utc(value).daysInMonth();
    let _listMonths = [];
    if (value) {
      _listMonths = [
        moment.utc(value).tz(moment.tz.guess(true)).subtract(5, 'months').format('MM-YYYY'),
        moment.utc(value).tz(moment.tz.guess(true)).subtract(4, 'months').format('MM-YYYY'),
        moment.utc(value).tz(moment.tz.guess(true)).subtract(3, 'months').format('MM-YYYY'),
        moment.utc(value).tz(moment.tz.guess(true)).subtract(2, 'months').format('MM-YYYY'),
        moment.utc(value).tz(moment.tz.guess(true)).subtract(1, 'months').format('MM-YYYY'),
        moment.utc(value).tz(moment.tz.guess(true)).format('MM-YYYY'),
      ];
      _toDate = moment.utc(value).tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
      _fromDate = moment.utc(value).tz(moment.tz.guess(true)).subtract(5, 'months').format('01-MM-YYYY');
      _fromDateOneMonth = moment.utc(value).tz(moment.tz.guess(true)).format('01-MM-YYYY');
      _filterDatas.month = moment.utc(value).tz(moment.tz.guess(true));
    } else {
      _listMonths = [
        moment.utc().tz(moment.tz.guess(true)).subtract(5, 'months').format('MM-YYYY'),
        moment.utc().tz(moment.tz.guess(true)).subtract(4, 'months').format('MM-YYYY'),
        moment.utc().tz(moment.tz.guess(true)).subtract(3, 'months').format('MM-YYYY'),
        moment.utc().tz(moment.tz.guess(true)).subtract(2, 'months').format('MM-YYYY'),
        moment.utc().tz(moment.tz.guess(true)).subtract(1, 'months').format('MM-YYYY'),
        moment.utc().tz(moment.tz.guess(true)).format('MM-YYYY'),
      ];
      _toDate = moment.utc().tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
      _fromDate = moment.utc().tz(moment.tz.guess(true)).subtract(5, 'months').format('01-MM-YYYY');
      _fromDateOneMonth = moment.utc().tz(moment.tz.guess(true)).format('01-MM-YYYY');
      _filterDatas.month = moment(moment(), 'YYYY-MM');
    }
    // console.log('_filterDatas', _filterDatas.month);
    setFilterDatas(_filterDatas);
    setListMonths(_listMonths);
    getDatas(_fromDate, _toDate, _fromDateOneMonth);
  };
  const _saveFilter = async () => {
    let _filterDatas = { ...filterDatas }
    _filterDatas.month = moment(_filterDatas.month).format('MM-YYYY');
    console.log(' _filterDatas.month1', _filterDatas.month);
    if (_filterDatas.month != 'Invalid date') {
      dispatch(homeActions.saveFilter(_filterDatas));
    }
  }
  React.useEffect(() => {
    _saveFilter();
  }, [filterDatas]);
  return (
    <Row>
      <Col span={24} style={{ backgroundColor: '#f0f2f5' }}>
        <Collapse accordion defaultActiveKey={['filter']} style={{ position: 'sticky', top: 0, zIndex: 4 }}>
          <Collapse.Panel header="Filter" key="filter">
            <Card
              size={'small'}
              bordered={false}
              bodyStyle={{ padding: 0 }}
              style={{ width: '100%' }}>
              <Form name="filter" form={form} layout="vertical">
                <Row gutter={16}>
                  <Col>
                    <Form.Item title="Date" label="Month">
                      <DatePicker format="MM-YYYY" picker="month" onChange={onOk}
                        value={filterDatas.month ? moment(filterDatas.month) : moment()}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="customer"
                      label="Customer"
                    >
                      <Select placeholder="Chọn All" value={filterDatas.customer} style={{ minWidth: 150 }} onChange={(value) => {
                        setLastfilter('customer');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.customer = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'customer-1'}>{'Chọn All'}</Select.Option>
                        {customers && customers.length ? customers.filter((__customer) => {
                          if (lastfilter == 'customer') {
                            return true;
                          } else {
                            return (filterDatas.customer) ? __customer == filterDatas.customer : true;
                          }
                        }).map((_customer, index) => {
                          return <Select.Option value={_customer} key={'customer' + index}>{_customer}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="supervisor"
                      label="Supervisor"
                    >
                      <Select placeholder="Chọn All" value={filterDatas.supervisor} style={{ minWidth: 150 }} onChange={(value) => {
                        setLastfilter('supervisor');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.supervisor = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'supervisor-1'}>{'Chọn All'}</Select.Option>
                        {supervisors && supervisors.length ? supervisors.filter((__supervisor) => {
                          if (lastfilter == 'supervisor') {
                            return true;
                          } else {
                            return (filterDatas.supervisor) ? __supervisor == filterDatas.supervisor : true;
                          }
                        }).map((_supervisor, index) => {
                          return <Select.Option value={_supervisor} key={'supervisor' + index}>{_supervisor}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="phase"
                      label="Phases"
                    >
                      <Select placeholder="Chọn All" value={filterDatas.phase} style={{ minWidth: 100 }} onChange={(value) => {
                        setLastfilter('phase');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.phase = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'phase-1'}>{'Chọn All'}</Select.Option>
                        {phases && phases.length ? phases.map((_phase, index) => {
                          return <Select.Option value={_phase} key={'phase' + index}>{_phase}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="auditor"
                      label="Auditor"
                    >
                      <Select placeholder="Chọn All" value={filterDatas.auditor} style={{ minWidth: 200 }} onChange={(value) => {
                        setLastfilter('auditor');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.auditor = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'auditor-1'}>{'Chọn All'}</Select.Option>
                        {auditors && auditors.length ? auditors.map((_auditor, index) => {
                          return <Select.Option value={_auditor} key={'auditor' + index}>{_auditor}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="region"
                      label="Region"
                    >
                      <Select placeholder="Chọn All" value={filterDatas.region} style={{ minWidth: 100 }} onChange={(value) => {
                        setLastfilter('region');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.region = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'region-1'}>{'Chọn All'}</Select.Option>
                        {regions && regions.length ? regions.map((_region, index) => {
                          return <Select.Option value={_region} key={'region' + index}>{_region}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="format"
                      label="Format"
                      style={{ marginBottom: 0 }}
                    >
                      <Text style={{ fontSize: 0 }}>{filterDatas.format}</Text>
                      <Select placeholder="BIG FORMAT" value={filterDatas.format || 'BIG FORMAT'} style={{ minWidth: 150 }} onChange={(value) => {
                        setLastfilter('format');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.format = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value="BIG FORMAT" key={'format-2'}>BIG FORMAT </Select.Option>
                        <Select.Option value="SMALL FORMAT" key={'format-3'}>SMALL FORMAT</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col style={{ flexDirection: 'column', display: 'flex' }}>
                    <div className="ant-col ant-form-item-label">
                      <label className="ant-form-item-no-colon">&nbsp;</label>
                    </div>
                    <Button htmlType="button" onClick={() => {
                      setFilterDatas(defaultFilter)
                      form.resetFields();
                    }}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>

          </Collapse.Panel>
        </Collapse>
        <Card
          title="Audit Progress"
          bordered={false}
          style={{ width: '100%', marginTop: 10 }}
        >
          <Row>
            <Col xl={14} lg={24}>
              {!!barDataLeft && <Bar height={250} options={optionsBar} data={barDataLeft} />}
              <Table
                columns={columns}

                dataSource={datas || []}
                rowKey={(record, index) => index.toString()}
                pagination={{
                  position: ['bottomCenter'], hideOnSinglePage: true, defaultPageSize: 20
                }}
              />
            </Col>
            <Col xl={10} lg={24}>
              <Row style={{ marginTop: 30 }}>
                {filterDatas.format == 'BIG FORMAT' && piedataBig ? <Col xl={12} offset={6} lg={12}>
                  <Pie key={'bigformat'} options={optionsPie1} data={piedataBig} />

                </Col> : null}
                {filterDatas.format == 'SMALL FORMAT' && piedataSmall ? <Col xl={12} offset={6} lg={12}>
                  <Pie key={'smallformat'} options={optionsPie2} data={piedataSmall} />
                </Col> : null}
              </Row>
              <h3 style={{ marginTop: 30, fontWeight: 'bold', textAlign: 'center' }}>ACTUAL vs REQUEST STORES vs PLAN</h3>
              <Row style={{ marginTop: 30 }}>

                {filterDatas.format == 'BIG FORMAT' && bardataBig ? <Col xl={12} offset={6} lg={12}>
                  <Bar height={250} options={optionsBar2} data={bardataBig} />
                </Col> : null}
                {filterDatas.format == 'SMALL FORMAT' && bardataSmall ? <Col xl={12} offset={6} lg={12}>
                  <Bar height={250} options={optionsBar3} data={bardataSmall} />
                  {/* {piedataSmall ? <Pie key={'smallformat'} options={optionsPie2} data={piedataSmall} /> : null} */}
                </Col> : null}
              </Row>

            </Col>
          </Row>

        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(AuditProgress);
