import React from 'react';
import {
    Row,
    Col,
    Card,
    Table,
    Typography,
    Image,
    Space,
    Form,
    Button,
    DatePicker,
    Tabs,
    Switch,
    Input,
    Alert
} from 'antd';
import NumberFormat from 'react-number-format';
function Osa(props) {
    const item = props.item || {};
    let data = { ...item.data };
    delete data.pass;
    delete data.qc_note;
    delete data.predefined_qc_note;
    const { Text } = Typography;
    let totalAvailable = 0;
    let totalKey = 0;
    return <div key={'item' + item.id}>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Tồn kho tối thiểu </b></Text>
            <Text>{item.quantity || ''}</Text>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Barcode:</b></Text>
            <Text>
                <i>
                    {item.custom_attributes.barcode || ''}
                </i>
            </Text>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>PPT Note:</b></Text>
            <Text>
                <i>
                    {item.custom_attributes.ppt_note || ''}
                </i>
            </Text>
        </Row>
        {Object.entries(data).length ? <table style={{ marginBottom: 5 }}>
            <tbody className="ant-table-tbody">
                <tr>
                    <td style={{ padding: 5 }}>Vị trí chấm</td>
                    <td style={{ padding: 5 }}>{data.Location || ''}</td>
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Tồn kho</td>
                    <td style={{ padding: 5 }}>{data.Available || ''}</td>
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Có bảng giá và đúng sản phẩm</td>
                    <td style={{ padding: 5 }}>{data.Void || ''}</td>
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Cửa hàng đồng ý audit điều chỉnh bảng giá</td>
                    <td style={{ padding: 5 }}>{data['Allow Price Tag Adjustment'] || ''}</td>
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Bảng giá đạt vị trí sau khi audit điều chỉnh</td>
                    <td style={{ padding: 5 }}>{data['Price Tag Passes After Adjustment'] || ''}</td>
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Ghi chú</td>
                    <td style={{ padding: 5 }}>{data.Note || ''}</td>
                </tr>
                {/* <tr>
                    <th style={{ padding: 5 }}>Tổng tồn kho thực tế</th>
                    <td colSpan={totalKey} style={{ padding: 5 }}><NumberFormat value={totalAvailable} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={0} renderText={(value) => <Text>{value}</Text>} /></td>
                </tr> */}
                {/* <tr>
                    <td style={{ padding: 5 }}>Có bảng giá và đúng sản phẩm</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Void';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{<Button size='small' type={data[key][k] == 'Y' ? 'primary' : 'danger'}>{data[key][k]}</Button>}</td> : null
                    })}
                </tr> */}
                {/* <tr>
                    <td style={{ padding: 5 }}>Ghi chú</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Note';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{data[key][k]}</td> : null
                    })}
                </tr> */}
            </tbody>
        </table> : null}
        {/* {Object.entries(data).map(([key, value]) => {
            return (value.Checked ? <div key={key} style={{ border: '1px solid #eaeaea', padding: 5, marginBottom: 3 }}>
                <Row key={key} style={{ justifyContent: 'space-between' }}>
                    <Text><b>{`${key}`}</b></Text>
                    <Text>{value.Checked ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</Text>
                </Row>
                {Object.entries(value).map(([k, v]) => {
                    return (k != 'Checked' ? <Row key={k} style={{ justifyContent: 'space-between', paddingLeft: 10, borderTop: '1px solid #f5f5f5', paddingTop: 2, paddingBottom: 2 }}>
                        <Text><i>{(k == 'ol_type') ? 'OL type' : k}:</i></Text>
                        <Text>{v}</Text>
                    </Row> : null)
                })}
            </div> : null)
        })} */}
    </div>
}
export default React.memo(Osa);