import React from 'react';
import {
    Row,
    Typography,
    Button
} from 'antd';
function Rental(props) {
    const item = props.item || {};
    let data = { ...item.data };
    let format_type = item.custom_attributes.format_type;
    delete data.pass;
    delete data.qc_note;
    delete data.predefined_qc_note;
    const { Text } = Typography;
    return <div key={'item' + item.id}>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>PNG:</b></Text>
            <Text>
                {item.custom_attributes.png_code || ''}
            </Text>
        </Row>

        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Brand:</b></Text>
            <Text>
                {item.custom_attributes.brand || ''}
            </Text>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Note:</b></Text>
            <Text>
                {item.custom_attributes?.ppt_note || ''}
            </Text>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Có hiện diện</b></Text>
            <Button size='small' type={data.Presence == 'Y' ? 'primary' : 'danger'}>{data.Presence}</Button>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Có bảng giá đầy đủ:</b></Text>
            <Button size='small' type={data.Price == 'Y' ? 'primary' : 'danger'}>{data.Price}</Button>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>{format_type == 'SMALL FORMAT' ? 'Đúng vị trí' : 'Đúng inline'}</b></Text>
            <Button size='small' type={data.Location == 'Y' ? 'primary' : 'danger'}>{data.Location}</Button>
        </Row>
        {/* <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Đúng sơ đồ trưng bày:</b></Text>
            <Button size='small' type={data.Planogram == 'Y' ? 'primary' : 'danger'}>{data.Planogram}</Button>
        </Row> */}
        {format_type == 'BIG FORMAT' && <>

            <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
                <Text><b>Đúng sơ đồ trưng bày:</b></Text>
                <Button size='small' type={data.Planogram == 'Y' ? 'primary' : 'danger'}>{data.Planogram}</Button>
            </Row>
        </>}
        <Row style={{ justifyContent: 'space-between', marginBottom: 3 }}>
            <Text><b>Ghi chú:</b></Text>
            <Text>
                <i>{data.Note || ''}</i>
            </Text>
        </Row>
    </div>
}
export default React.memo(Rental);