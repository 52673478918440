import React from 'react';
import _ from 'lodash';

import { Row, Col, Card, Table, Button, Modal, Form, Input, Space } from 'antd';

import { connect } from 'react-redux';
import { homeActions } from './redux/actions';
import authActions from '../auth/redux/actions';
import history from '../../common/history';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const Dashboard = ({ dispatch, home, user }) => {

  return (
    <Row>
      <Col span={24}>
        <Card
          title="UNDER CONSTRUCTION"
          bordered={false}
          style={{ width: '100%' }}
        >
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(Dashboard);
