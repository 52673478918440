import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Image, Space, Spin, Modal, Button, Typography, Input, Tag } from 'antd';
import { ZoomInOutlined, SearchOutlined } from '@ant-design/icons';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const { Title, Text } = Typography;

const InOutReview = () => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const getTokenFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('token');
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = getTokenFromUrl();
      const response = await fetch(`http://13.213.254.82/checkin_checkouts/view_photos?view_photos_token=${token}`);
      const data = await response.json();

      const flatPhotos = Object.values(data.photos).flat();
      setPhotos(flatPhotos);

      const categoryMap = {};
      flatPhotos.forEach(photo => {
        photo.custom_attributes.category.forEach(cat => {
          if (!categoryMap[cat]) {
            categoryMap[cat] = true;
          }
        });
      });

      const categoryList = Object.keys(categoryMap);
      setCategories(categoryList);

      if (categoryList.length > 0) {
        setSelectedCategory(categoryList[0]);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };

  const filteredPhotos = selectedCategory
    ? photos.filter(photo => photo.custom_attributes.category.includes(selectedCategory))
    : photos;

    const renderFilterSection = () => (
        <Card title="Filters" style={{ height: '100%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Input
              placeholder="Search categories"
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchTerm(e.target.value)}
              // style={{ marginBottom: 2 }}
            />
              {categories
                .filter(category => category.toLowerCase().includes(searchTerm.toLowerCase()))
                .map(category => (
                  <Button
                    key={category}
                    type={selectedCategory === category ? 'primary' : 'default'}
                    style={{width:"100%" , paddingHorizontal:"10px"}}

                    onClick={() => handleCategoryClick(category)}
                  >
                    {category}
                  </Button>
                ))}
          </Space>
        </Card>
      );
      const renderPhotoSection = () => (
        <Card
          title="Photos"
          style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)', height: 'calc(100vh )',    overflowY: 'auto', 
          }}
        >
          {loading ? (
            <Spin size="large" />
          ) : (
            <div
              style={{
                height: 'calc(100% - 10px)', 
                overflowY: 'auto',
                padding: '8px',
              }}
            >
              <Row gutter={[16, 16]}>
                {filteredPhotos.map(photo => (
                  <Col key={photo.id} xs={24} sm={12} md={8} lg={6}>
                    <Card
                      hoverable
                      cover={
                        <div style={{ position: 'relative', height: 200, overflow: 'hidden' }}>
                          <Image
                            onClick={() => setZoomedImage(`http://13.213.254.82${photo.path}`)}
                            src={`http://13.213.254.82${photo.path}`}
                            alt={`Photo ${photo.id}`}
                            style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                            preview={false}
                          />
                        </div>
                      }
                    >
                      <Card.Meta
                        title={`Photo ${photo.id}`}
                        description={
                          <>
                            <Text strong>Category: </Text>
                            {photo.custom_attributes.category.map((cat, index) => (
                              <Tag key={index} color="blue">{cat}</Tag>
                            ))}
                            <br />
                            <Text strong>Subcategory: </Text>
                            {photo.custom_attributes.sub_category.map((subCat, index) => (
                              <Tag key={index} color="green">{subCat}</Tag>
                            ))}
                          </>
                        }
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Card>
      );
      
      

  return (
    <Row gutter={[16, 16]} style={{width:"100%"}}>
      <Col span={4}>{renderFilterSection()}</Col>
      <Col span={20}>{renderPhotoSection()}</Col>
      <Modal
  visible={!!zoomedImage}
  footer={null}
  onCancel={() => setZoomedImage(null)}
  width="60%"
  centered 
  style={{
    textAlign: 'center',
    backgroundColor: 'transparent', 
    boxShadow: 'none',
  }}
  bodyStyle={{
    backgroundColor: 'transparent', 
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100%',
    padding: 0,
  }}
  maskStyle={{
    backgroundColor: 'rgba(0, 0, 0, 0.8)', 
  }}
>
  <TransformWrapper>
    <TransformComponent>
      <img
        alt="Zoomed photo"
        style={{
          maxWidth: '100%',
          maxHeight: '90vh', 
          backgroundColor: 'black', 
          margin: '0 auto', 
        }}
        src={zoomedImage || ''}
      />
    </TransformComponent>
  </TransformWrapper>
</Modal>

    </Row>
  );
};

export default InOutReview;

