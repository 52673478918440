import React from 'react';
import {
    Row,
    Col,
    Card,
    Table,
    Typography,
    Image,
    Space,
    Form,
    Button,
    DatePicker,
    Tabs,
    Switch,
    Input,
    Alert
} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
function C2a(props) {
    const item = props.item || {};
    let data = { ...item.data };
    delete data.pass;
    delete data.qc_note;
    delete data.predefined_qc_note;
    const { Text } = Typography;
    return <div key={'item' + item.id}>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Nhãn hiệu:</b></Text>
            <Text>
                {item.custom_attributes.brand || ''}
            </Text>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Code:</b></Text>
            <Text>
                {item.custom_attributes.c2a_code || ''}
            </Text>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Có hiện diện thông điệp sản phẩm:</b></Text>
            <Button size='small' type={data.Presence == 'Y' ? 'primary' : 'danger'}>{data.Presence}</Button>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Có ít nhất 1 sản phẩm đúng:</b></Text>
            <Button size='small' type={data['Presence at least one'] == 'Y' ? 'primary' : 'danger'}>{data['Presence at least one']}</Button>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Ghi chú:</b></Text>
            <Text>
                <i>{data.Note || ''}</i>
            </Text>
        </Row>
    </div>
}
export default React.memo(C2a);