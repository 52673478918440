import React from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Image,
  Space,
  Form,
  Button,
  DatePicker,
  Tabs,
  Switch,
  List as AntList,
  Divider,
  Input,
  Collapse,
  Dropdown,
  Menu,
  Modal,
  Select

} from 'antd';

import { connect } from 'react-redux';
// import { homeActions } from '../redux/actions';
// import authActions from '../../auth/redux/actions';
// import history from '../../../common/history';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const ActualVsPlan = ({ dispatch, home, user }) => {
  const { Text, Title } = Typography;
  const fulldatas = {
    data1: {
      labels: ['2022-01', '2022-02', '2022-03', '2022-04'],
      datasets: [
        {
          label: 'Ah Plan - BIG FORMAT',
          data: [151, 123, 125, 138],
          backgroundColor: 'rgba(4, 201, 17,0.5)',
          format: 'BIG FORMAT'
        },
        {
          label: 'UVN requirement - BIG FORMAT',
          data: [178, 174, 154, 179],
          backgroundColor: 'rgba(5, 35, 232,0.5)',
          format: 'BIG FORMAT'
        },
        {
          label: 'Actual - BIG FORMAT',
          data: [95, 46, 103, 119],
          backgroundColor: 'rgba(232, 198, 5,0.5)',
          format: 'BIG FORMAT'
        },
        {
          label: 'Ah Plan - SMALL FORMAT',
          data: [1338, 1694, 1960, 1802],
          backgroundColor: 'rgba(111, 5, 232,0.5)',
          format: 'SMALL FORMAT'
        },
        {
          label: 'UVN requirement - SMALL FORMAT',
          data: [2322, 2322, 2053, 1838],
          backgroundColor: 'rgba(212, 4, 177,0.5)',
          format: 'SMALL FORMAT'
        },
        {
          label: 'Actual - SMALL FORMAT',
          data: [1005, 652, 1258, 1279],
          backgroundColor: 'rgba(2, 153, 150,0.5)',
          format: 'SMALL FORMAT'
        },

      ],
    },
    data2: {
      labels: ['2022-01', '2022-02', '2022-03', '2022-04'],
      datasets: [
        {
          label: 'Ah Plan - BIG FORMAT - Phase 1',
          data: [151, 123, 125, 138],
          backgroundColor: 'rgba(194, 121, 4,0.8)',
          format: 'BIG FORMAT',
          phases: '1'
        },
        {
          label: 'UVN requirement - BIG FORMAT - Phase 1',
          data: [178, 174, 154, 179],
          backgroundColor: 'rgba(214, 22, 4,0.8)',
          format: 'BIG FORMAT',
          phases: '1'
        },
        {
          label: 'Actual - BIG FORMAT - Phase 1',
          data: [95, 46, 103, 119],
          backgroundColor: 'rgba(230, 181, 5,0.4)',
          format: 'BIG FORMAT',
          phases: '1'
        },
        {
          label: 'Ah Plan - SMALL FORMAT - Phase 1',
          data: [669, 847, 980, 901],
          backgroundColor: 'rgba(111, 5, 232,0.8)',
          format: 'SMALL FORMAT',
          phases: '1'
        },
        {
          label: 'Ah Plan - SMALL FORMAT - Phase 2',
          data: [669, 847, 980, 901],
          backgroundColor: 'rgba(111, 5, 232,1)',
          format: 'SMALL FORMAT',
          phases: '2'
        },
        {
          label: 'UVN requirement - SMALL FORMAT - Phase 1',
          data: [1161, 1161, 1027, 919],
          backgroundColor: 'rgba(212, 4, 177,0.4)',
          format: 'SMALL FORMAT',
          phases: '1'
        },
        {
          label: 'UVN requirement - SMALL FORMAT - Phase 2',
          data: [1161, 1161, 1027, 919],
          backgroundColor: 'rgba(212, 4, 177,1)',
          format: 'SMALL FORMAT',
          phases: '2'
        },
        {
          label: 'Actual - SMALL FORMAT - Phase 1',
          data: [512, 550, 688, 690],
          backgroundColor: 'rgba(2, 153, 150,0.5)',
          format: 'SMALL FORMAT',
          phases: '1'
        },
        {
          label: 'Actual - SMALL FORMAT - Phase 2',
          data: [493, 102, 570, 599],
          backgroundColor: 'rgba(2, 153, 150,1)',
          format: 'SMALL FORMAT',
          phases: '2'
        },

      ],
    },
    filterDatas: {
      period: ['2022-01', '2022-02', '2022-03', '2022-04'],
      format: ['BIG FORMAT', 'SMALL FORMAT'],
      phases: ['1', '2'],
    }
  }
  const [filterDatas, setFilterDatas] = React.useState(fulldatas.filterDatas);

  const [data1, setData1] = React.useState(null);
  const [data2, setData2] = React.useState(null);

  const options1 = {
    plugins: {
      title: {
        display: true,
        text: 'Actual Vs Plan',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
  };
  const options2 = {
    plugins: {
      title: {
        display: true,
        text: 'Actual Vs Plan by Phases',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
  };
  React.useEffect(() => {
    let _datas1 = fulldatas.data1;
    let _datas2 = fulldatas.data2;
    if (!filterDatas.format.length || !filterDatas.period.length) {
      _datas1 = null;
      _datas2 = null;
    } else {
      if (!filterDatas.phases.length) {
        _datas2 = null;
      }
      _datas1.datasets = _datas1.datasets.filter(dataset => {
        return filterDatas.format.includes(dataset.format);
      })
      let labels = filterDatas.period;
      for (let ii = 0; ii < _datas1.datasets.length; ii++) {
        let data = [];
        for (let i = 0; i < fulldatas.data1.labels.length; i++) {
          if (labels.includes(fulldatas.data1.labels[i])) {
            data.push(fulldatas.data1.datasets[ii].data[i])
          }
        }
        _datas1.datasets[ii].data = data;


      }
      _datas1.labels = labels;
      if (_datas2) {
        _datas2.datasets = _datas2.datasets.filter(dataset => {
          return filterDatas.format.includes(dataset.format) && filterDatas.phases.includes(dataset.phases);
        })
        for (let ii = 0; ii < _datas2.datasets.length; ii++) {
          let data = [];
          for (let i = 0; i < fulldatas.data2.labels.length; i++) {
            if (labels.includes(fulldatas.data2.labels[i])) {
              data.push(fulldatas.data2.datasets[ii].data[i])
            }
          }
          _datas2.datasets[ii].data = data;
        }
        _datas2.labels = labels;
      }
    }
    setData1(_datas1);
    setData2(_datas2);
  }, [filterDatas])

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Actual Vs Plan"
          bordered={false}
          style={{ width: '100%' }}
          extra={
            <Form name="filter" layout="inline">
              <Form.Item
                name="period"
                label="Period"
              >
                <Select placeholder="Please choose" mode="multiple" style={{ minWidth: 150 }}
                  defaultValue={filterDatas.period} allowClear
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.period = value;
                    setFilterDatas(_filterDatas)
                  }}
                >
                  <Select.Option value="2022-04">04/2022</Select.Option>
                  <Select.Option value="2022-03">03/2022</Select.Option>
                  <Select.Option value="2022-02">02/2022</Select.Option>
                  <Select.Option value="2022-01">01/2022</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="format"
                label="Format"
              >
                <Select placeholder="Please choose" mode="multiple" style={{ minWidth: 150 }}
                  defaultValue={filterDatas.format} allowClear
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.format = value;
                    setFilterDatas(_filterDatas)
                  }}
                >
                  <Select.Option value="BIG FORMAT">BIG FORMAT</Select.Option>
                  <Select.Option value="SMALL FORMAT">SMALL FORMAT</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="phases"
                label="Phases"
              >
                <Select placeholder="Please choose" mode="multiple" style={{ minWidth: 150 }}
                  defaultValue={filterDatas.phases} allowClear
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.phases = value;
                    setFilterDatas(_filterDatas)
                  }}
                >
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          }
        >
          <Row>
            <Col span={12}>
              {!!data1 && <Bar options={options1} data={data1} />}
            </Col>
            <Col span={12}>
              {!!data2 && <Bar options={options2} data={data2} />}
            </Col>

          </Row>

        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(ActualVsPlan);
