import React from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Image,
  Space,
  Form,
  Button,
  DatePicker,
  Tabs,
  Switch,
  List as AntList,
  Divider,
  Input,
  Collapse,
  Dropdown,
  Menu,
  Modal,
  Select

} from 'antd';

import { connect } from 'react-redux';

import { green, gold, red, grey } from '@ant-design/colors';
import { homeActions } from '../redux/actions';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import OsaNpd from './OsaNpdProProol/OsaNpd';
import Procms from './OsaNpdProProol/Procms';
import Prool from './OsaNpdProProol/Prool';
import DetailKpiTable from './OsaNpdProProol/DetailKpiTable';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const OsaNpdPro = ({ dispatch, home, user }) => {
  const { Text, Title } = Typography;
  const [form] = Form.useForm();
  // const kpiCheckList = home.kpiCheckListInMonth || null;
  // const allKpiDatas = home.allKpiDatas || null;
  const filterHomeData = home.filterDatas || null;
  const [lastfilter, setLastfilter] = React.useState(null);
  const [isChangeDate, setIsChangeDate] = React.useState(false);
  const [isOpenDate, setIsOpenDate] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);
  const [regions, setRegions] = React.useState([]);
  const [supervisors, setSupervisors] = React.useState([]);
  const [phases, setPhases] = React.useState([]);

  const [auditors, setAuditor] = React.useState([]);
  const [lowerOnly, setLowerOnly] = React.useState({
    osa: 0,
    npd: 0,
    prool: 0,
    procms: 0,
  });

  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const [kpiCheckList, setKpiCheckList] = React.useState([]);
  const [kpiCheckListChart, setKpiCheckListChart] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const [dataBar, setDataBar] = React.useState(null);
  const [datasDetail, setDatasDetail] = React.useState([]);
  const [allCategories, setAllCategories] = React.useState([]);
  const [months, setMonths] = React.useState([]);
  const [proDatas, setProDatas] = React.useState([]);
  const [proolDatas, setProolDatas] = React.useState([]);
  const [openChangeFromMonth, setOpenChangeFromMonth] = React.useState(false);
  const [openChangeToMonth, setOpenChangeToMonth] = React.useState(false);
  const [chooseMonth, setChooseMonth] = React.useState({
    frommonth: false,
    tomonth: false,
  });
  const [isAllowFetdata, setIsAllowFetdata] = React.useState(true);
  const getDatasDetail = async (_fromDate, _toDate) => {
    console.log('getDatasDetail', _fromDate, _toDate);
    // if (_fromDate && _toDate) {
    //   let res = await dispatch(homeActions.getKpiReportCheckListItemByCategory({ date_from: _fromDate, date_to: _toDate }));
    //   setKpiCheckList(res);
    // }
  };
  const optionsBar = {
    plugins: {
      title: {
        display: true,
        text: (months) ? moment(months[0], 'YYYY-MM').format('MM/YYYY') + ' - ' + moment(months[2], 'YYYY-MM').format('MM/YYYY') : '-',
      },
      tooltip: {
        enabled: true
      },
      legend: {
        display: false,
        position: 'bottom',
        align: 'start',
        labels: {
          // boxWidth: 12,
          usePointStyle: true
        }

      },
      datalabels: {
        backgroundColor: function (context) {
          return 'transparent'; //dataset.backgroundColor;
        },
        color: '#000',
        borderColor: 'white',
        borderRadius: 25,
        // borderWidth: 2,
        // color: 'white',
        display: function (context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > count * 1.5;
        },
        font: {
          weight: 'bold',
          size: 0
        },
        padding: 6,
        formatter: (value, ctx) => {
          let percentage = value.toFixed(2);
          return percentage;
        },
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: false,
        font: {
          size: 10
        },
      },
      y: {
        stacked: false,
      },
    },
  };
  const fulldatas = {
    defaultFilter: {
      region: "",
      customer: "",
      supervisor: "",
      phase: "",
      auditor: "",
      month: filterHomeData && filterHomeData.month ? moment(filterHomeData.month, 'MM-YYYY') : moment(),
      frommonth: filterHomeData && filterHomeData.month ? moment(filterHomeData.month, 'MM-YYYY') : moment(),
      format: filterHomeData?.format || 'BIG FORMAT',
      lowerOnly: 0,
    }
  }
  // const [rangeFilter, setRangeFilter] = React.useState({
  //   month: filterHomeData && filterHomeData.month ? moment(filterHomeData.month, 'MM-YYYY') : moment(),
  //   frommonth: filterHomeData && filterHomeData.month ? moment(filterHomeData.month, 'MM-YYYY') : moment()
  // });
  const [filterDatas, setFilterDatas] = React.useState(null);
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  // const onOk = (value) => {
  //   // setLastfilter(null);
  //   let _filterDatas = { ...fulldatas.defaultFilter };
  //   form.resetFields();
  //   let _toDate = '';
  //   let _fromDate = '';
  //   let m = moment.utc(value).daysInMonth();
  //   if (value) {
  //     _toDate = moment.utc(value).tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
  //     _fromDate = moment.utc(value).tz(moment.tz.guess(true)).format('01-MM-YYYY');
  //     _filterDatas.month = moment.utc(value).tz(moment.tz.guess(true)).format('YYYY-MM');
  //   } else {
  //     _toDate = moment.utc().tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
  //     _fromDate = moment.utc().tz(moment.tz.guess(true)).format('01-MM-YYYY');
  //     _filterDatas.month = moment(moment(), 'YYYY-MM');
  //   }
  //   // console.log(_filterDatas);
  //   setFilterDatas(_filterDatas);
  //   // getDatas(_fromDate);
  //   // _getKpiReportCheckListItemByCategory(_fromDate, _toDate)
  // };
  // const setdatefilter = () => {
  //   setIsChangeDate(false);
  //   form.resetFields();
  //   // let _filterDatas = { ...fulldatas.defaultFilter, ...rangeFilter };

  //   setFilterDatas(_filterDatas);

  // }
  // React.useEffect(() => {
  //   if (!isOpenDate && isChangeDate) {
  //     setdatefilter();
  //   }
  // }, [isChangeDate, isOpenDate]);
  const onChangeFromMonth = (value) => {
    setIsAllowFetdata(false);
    let _filterDatas = {
      ...filterDatas,
      region: "",
      customer: "",
      supervisor: "",
      phase: "",
      auditor: "",
      format: filterHomeData?.format || 'BIG FORMAT',
      lowerOnly: 0
    };

    // let _toDate = '';
    // let _fromDate = '';
    // let m = moment.utc(value).daysInMonth();
    if (value) {
      console.log('value-onChangeFromMonth', value.format('MM-YYYY'));
      // _toDate = moment.utc(value).tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
      // _fromDate = moment.utc(value).tz(moment.tz.guess(true)).format('01-MM-YYYY');
      _filterDatas.frommonth = value;
    } else {
      // _toDate = moment.utc().tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
      // _fromDate = moment.utc().tz(moment.tz.guess(true)).format('01-MM-YYYY');
      _filterDatas.frommonth = moment();
    }
    // console.log('_filterDatas', _filterDatas);
    // return true;
    let _chooseMonth = { ...chooseMonth }
    _chooseMonth.frommonth = true;
    setChooseMonth(_chooseMonth);
    // console.log('_chooseMonth_onChangeFromMonth', _chooseMonth);
    setFilterDatas(_filterDatas);
    if (_chooseMonth.frommonth && _chooseMonth.tomonth) {
      // setIsAllowFetdata(true);
    } else {
      // setOpenChangeToMonth(true);
    }
    setOpenChangeFromMonth(false);
  }
  // React.useEffect(() => {
  //   if (chooseMonth.frommonth && !chooseMonth.tomonth) {
  //     setOpenChangeToMonth(true);
  //   }
  //   if (!chooseMonth.frommonth && chooseMonth.tomonth) {
  //     setOpenChangeFromMonth(true);
  //   }
  // }, [filterDatas, openChangeFromMonth, chooseMonth]);
  const onChangeToMonth = (value) => {
    setIsAllowFetdata(false);
    let _filterDatas = {
      ...filterDatas, region: "",
      customer: "",
      supervisor: "",
      phase: "",
      auditor: "",
      format: filterHomeData?.format || 'BIG FORMAT',
      lowerOnly: 0
    };
    // let _toDate = '';
    // let _fromDate = '';

    if (value) {
      console.log('value-onChangeToMonth', value.format('MM-YYYY'));
      // let m = moment.utc(value).daysInMonth();
      // _toDate = moment.utc(value).tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
      // _fromDate = moment.utc(value).tz(moment.tz.guess(true)).format('01-MM-YYYY');
      _filterDatas.month = value;
    } else {
      // let m = moment.utc().daysInMonth();
      // _toDate = moment.utc().tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
      // _fromDate = moment.utc().tz(moment.tz.guess(true)).format('01-MM-YYYY');
      _filterDatas.month = moment();
    }
    // console.log('from-month', _filterDatas.frommonth.format('MM-YYYY'), _filterDatas.month.format('MM-YYYY'));
    setFilterDatas(_filterDatas);

    let _chooseMonth = { ...chooseMonth }
    _chooseMonth.tomonth = true;
    setChooseMonth(_chooseMonth);
    setFilterDatas(_filterDatas);
    if (_chooseMonth.frommonth && _chooseMonth.tomonth) {
      // setIsAllowFetdata(true);
    } else {
      // setOpenChangeFromMonth(true);
    }
    setOpenChangeToMonth(false);
  }


  // const onOk = (value) => {
  //   // setLastfilter(null);
  //   let _rangeFilter = { ...rangeFilter };
  //   setIsChangeDate(true);
  //   // let _toDate = '';
  //   // let _fromDate = '';
  //   // let m = moment.utc(value[1]).daysInMonth();
  //   console.log('21');
  //   if (value && value[0] && value[1]) {
  //     // _toDate = moment.utc(value[1]).tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
  //     // _fromDate = moment.utc(value[0]).tz(moment.tz.guess(true)).format('01-MM-YYYY');
  //     _rangeFilter.month = moment.utc(value[1]).tz(moment.tz.guess(true)).format('YYYY-MM');
  //     _rangeFilter.frommonth = moment.utc(value[0]).tz(moment.tz.guess(true)).format('YYYY-MM');
  //   } else {
  //     // _toDate = moment.utc().tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
  //     // _fromDate = moment.utc().tz(moment.tz.guess(true)).format('01-MM-YYYY');
  //     _rangeFilter.month = moment(moment(), 'YYYY-MM');
  //     _rangeFilter.frommonth = moment(moment(), 'YYYY-MM');
  //   }
  //   // console.log(_filterDatas);
  //   // setRangeFilter(_rangeFilter);
  //   // getDatas(_fromDate);
  //   // _getKpiReportCheckListItemByCategory(_fromDate, _toDate)
  // };
  const _saveFilter = async () => {
    let _filterDatas = { ...filterDatas }
    _filterDatas.month = moment(_filterDatas.month).format('MM-YYYY');
    if (_filterDatas.month != 'Invalid date') {
      dispatch(homeActions.saveFilter(_filterDatas));
    }
  }
  React.useEffect(() => {
    _saveFilter();
  }, [filterDatas]);
  const analyticDataPROOL = (ckltype, data, _customerDatas) => {
    let keydata = `${ckltype}_by_category`;
    //_kpiCheckList[i].data.npd_by_category
    let positions = [
      "GE", "MS", "PI", "SB", "SK", "SS", "ALT", "COC", "CSM", "ICC", "KOC", "SHF", "GE-C", "SSBF", "SSHF", "HGR"

    ];
    if (data[keydata]) {
      if (Object.entries(data[keydata]).length) {

        Object.entries(data[keydata]).map(([key, value]) => {
          // if ((key == 'total_shops')) {
          //   console.log('valuevalue2', value);
          // }
          if ((key != 'total_shops') && (key != 'uniq_shop_names')) {

            if (typeof (_customerDatas.detail[key]) === 'undefined') {
              _customerDatas.detail[key] = {
                shops: {
                  "GE": [],
                  "MS": [],
                  "PI": [],
                  "HGR": [],
                  "SB": [],
                  "SK": [],
                  "SS": [],
                  "ALT": [],
                  "COC": [],
                  "CSM": [],
                  "ICC": [],
                  "KOC": [],
                  "SHF": [],
                  "GE-C": [],
                  "SSBF": [],
                  "SSHF": [],
                },
                "totals": {
                  "GE": [0, 0, 0],
                  "MS": [0, 0, 0],
                  "PI": [0, 0, 0],
                  "HGR": [0, 0, 0],
                  "SB": [0, 0, 0],
                  "SK": [0, 0, 0],
                  "SS": [0, 0, 0],
                  "ALT": [0, 0, 0],
                  "COC": [0, 0, 0],
                  "CSM": [0, 0, 0],
                  "ICC": [0, 0, 0],
                  "KOC": [0, 0, 0],
                  "SHF": [0, 0, 0],
                  "GE-C": [0, 0, 0],
                  "SSBF": [0, 0, 0],
                  "SSHF": [0, 0, 0],
                  "totals": [0, 0, 0],
                }
              };
            }

            // _customerDatas.detail[key].shops = [..._customerDatas.detail[key].shops, ...value.uniq_shop_names];
            if (Object.entries(value).length) {
              Object.entries(value).map(([key1, value2]) => {
                if (key1 == 'total_shops') {

                }
                if ((key1 != 'uniq_shop_names') && (key1 != 'total_shops') && (key1 != 'shops')) {
                  let key2 = value2.name;
                  if (typeof (_customerDatas.detail[key][key2]) === 'undefined') {
                    _customerDatas.detail[key][key2] = {
                      "GE": [0, 0, 0],
                      "MS": [0, 0, 0],
                      "PI": [0, 0, 0],
                      "HGR": [0, 0, 0],
                      "SB": [0, 0, 0],
                      "SK": [0, 0, 0],
                      "SS": [0, 0, 0],
                      "ALT": [0, 0, 0],
                      "COC": [0, 0, 0],
                      "CSM": [0, 0, 0],
                      "ICC": [0, 0, 0],
                      "KOC": [0, 0, 0],
                      "SHF": [0, 0, 0],
                      "GE-C": [0, 0, 0],
                      "SSBF": [0, 0, 0],
                      "SSHF": [0, 0, 0],
                      "totals": [0, 0, 0],
                      shops: {
                        "GE": [],
                        "MS": [],
                        "PI": [],
                        "HGR": [],
                        "SB": [],
                        "SK": [],
                        "SS": [],
                        "ALT": [],
                        "COC": [],
                        "CSM": [],
                        "ICC": [],
                        "KOC": [],
                        "SHF": [],
                        "GE-C": [],
                        "SSBF": [],
                        "SSHF": [],
                      }
                    };
                  }
                  for (let x = 0; x < positions.length; x++) {
                    // console.log('value2', value2);
                    _customerDatas.detail[key][key2][positions[x]][0] += (value2[positions[x]]) ? value2[positions[x]].totals[0] : 0;
                    _customerDatas.detail[key][key2][positions[x]][1] += (value2[positions[x]]) ? value2[positions[x]].totals[1] : 0;
                    _customerDatas.detail[key][key2][positions[x]][2] += (value2[positions[x]]) ? value2[positions[x]].totals[2] : 0;

                    _customerDatas.detail[key][key2]['totals'][0] += (value2[positions[x]]) ? value2[positions[x]].totals[0] : 0;
                    _customerDatas.detail[key][key2]['totals'][1] += (value2[positions[x]]) ? value2[positions[x]].totals[1] : 0;
                    _customerDatas.detail[key][key2]['totals'][2] += (value2[positions[x]]) ? value2[positions[x]].totals[2] : 0;

                    // _customerDatas.detail[key][key2]['shops'][positions[x]] += (value2[positions[x]]) ? value2[positions[x]].total_shops : 0;
                    // _customerDatas.detail[key][key2]['shops']['totals'] += (value2[positions[x]]) ? value2[positions[x]].total_shops : 0;

                    _customerDatas.detail[key]['totals'][positions[x]][0] += (value2[positions[x]]) ? value2[positions[x]].totals[0] : 0;
                    _customerDatas.detail[key]['totals'][positions[x]][1] += (value2[positions[x]]) ? value2[positions[x]].totals[1] : 0;
                    _customerDatas.detail[key]['totals'][positions[x]][2] += (value2[positions[x]]) ? value2[positions[x]].totals[2] : 0;

                    // _customerDatas.detail[key]['shops'][positions[x]] += (value2[positions[x]]) ? value2[positions[x]].total_shops : 0;
                    // _customerDatas.detail[key]['shops']['totals'] += (value2[positions[x]]) ? value2[positions[x]].total_shops : 0;

                    _customerDatas.detail[key]['totals']['totals'][0] += (value2[positions[x]]) ? value2[positions[x]].totals[0] : 0;
                    _customerDatas.detail[key]['totals']['totals'][1] += (value2[positions[x]]) ? value2[positions[x]].totals[1] : 0;
                    _customerDatas.detail[key]['totals']['totals'][2] += (value2[positions[x]]) ? value2[positions[x]].totals[2] : 0;

                    _customerDatas.totals[positions[x]][0] += (value2[positions[x]]) ? value2[positions[x]].totals[0] : 0;
                    _customerDatas.totals[positions[x]][1] += (value2[positions[x]]) ? value2[positions[x]].totals[1] : 0;
                    _customerDatas.totals[positions[x]][2] += (value2[positions[x]]) ? value2[positions[x]].totals[2] : 0;

                    _customerDatas.totals['totals'][0] += (value2[positions[x]]) ? value2[positions[x]].totals[0] : 0;
                    _customerDatas.totals['totals'][1] += (value2[positions[x]]) ? value2[positions[x]].totals[1] : 0;
                    _customerDatas.totals['totals'][2] += (value2[positions[x]]) ? value2[positions[x]].totals[2] : 0;

                    // _customerDatas.shops[positions[x]] += (value2[positions[x]]) ? value2[positions[x]].total_shops : 0;
                    // _customerDatas.shops['totals'] += (value2[positions[x]]) ? value2[positions[x]].total_shops : 0;
                    if (value2 && value2[positions[x]] && value2[positions[x]].uniq_shop_names.length) {
                      for (var i = 0; i < value2[positions[x]].uniq_shop_names.length; i++) {
                        let shopname = value2[positions[x]].uniq_shop_names[i];
                        if (!_customerDatas.detail[key][key2]['shops'][positions[x]].includes(shopname)) {
                          _customerDatas.detail[key][key2]['shops'][positions[x]].push(shopname);
                        }
                        if (!_customerDatas.detail[key]['shops'][positions[x]].includes(shopname)) {
                          _customerDatas.detail[key]['shops'][positions[x]].push(shopname);
                        }
                        if (!_customerDatas.shops[positions[x]].includes(shopname)) {
                          _customerDatas.shops[positions[x]].push(shopname);
                        }
                      }
                    }
                  }
                }
              });
            }
          }
        })
      }
    }
    return _customerDatas;
  }
  React.useEffect(() => {
    let _datas = [];
    let _categories = [];
    let _realcategory = [];
    let _categoriesFixOrder = [
      'ICE CREAM', 'CULINARY', 'LIQUID', 'POWDER', 'FABRIC SENSATIONS', 'FLOOR CARE', 'DISHWASH', 'TOILET', 'SURFACE CLEANING', 'HAIR WOMEN', 'HAIR MEN', 'FACE', 'LOTION', 'BODY WASH', 'HANDWASH & SOAP', 'SANITIZER', 'DEODORANTS', 'TOOTHPASTE & MOUTHWASH', 'TOOTHBRUSH'
    ];
    let _customers = [];
    let _regions = [];
    let _supervisors = [];
    let _phases = [];
    let _auditors = [];
    let _customerDatas = {
    };

    if (kpiCheckList && kpiCheckList.length) {
      let _kpiCheckList = kpiCheckList.filter((_itemdata) => {
        if (
          (filterDatas.region != '' && filterDatas.region != _itemdata.region) ||
          (filterDatas.customer != '' && filterDatas.customer != _itemdata.customer) ||
          (filterDatas.supervisor != '' && filterDatas.supervisor != _itemdata.supervisor) ||
          (filterDatas.phase != '' && filterDatas.phase != _itemdata.phase) ||
          (filterDatas.auditor != '' && filterDatas.auditor != _itemdata.auditor) ||
          (filterDatas.format != '' && filterDatas.format != _itemdata.format_type)
        ) {
          return false;
        }
        return true;
      });
      for (let i = 0; i < _kpiCheckList.length; i++) {
        let _customer = _kpiCheckList[i].customer || null;
        if (_customer && !_customers.includes(_customer)) {
          _customers.push(_customer);
        }
        if (_kpiCheckList[i].supervisor && !_supervisors.includes(_kpiCheckList[i].supervisor)) {
          _supervisors.push(_kpiCheckList[i].supervisor)
        }
        if (_kpiCheckList[i].region && !_regions.includes(_kpiCheckList[i].region)) {
          _regions.push(_kpiCheckList[i].region)
        }
        if (_kpiCheckList[i].phase && !_phases.includes(_kpiCheckList[i].phase)) {
          _phases.push(_kpiCheckList[i].phase)
        }
        if (_kpiCheckList[i].auditor && !_auditors.includes(_kpiCheckList[i].auditor)) {
          _auditors.push(_kpiCheckList[i].auditor)
        }
        let result = analyticData(_kpiCheckList[i].data, _customerDatas, _categories);
        _customerDatas = result['_customerDatas'];
        _categories = result['_categories'];
        // _customerDatas = { ...__customerDatas }
        // _customerDatas.npd[_customer].



      }
      // console.log('_customerDatas', _customerDatas);
      _datas = Object.entries(_customerDatas);
    }
    for (let _i = 0; _i < _categoriesFixOrder.length; _i++) {
      if (_categories.includes(_categoriesFixOrder[_i])) {
        _realcategory.push(_categoriesFixOrder[_i]);
      }
    }
    for (let _i = 0; _i < _categories.length; _i++) {
      if (!_realcategory.includes(_categories[_i])) {
        _realcategory.push(_categories[_i]);
      }
    }
    // console.log('_categories', _categories);
    // console.log('_customerDatas', _customerDatas);
    setAllCategories(_realcategory);
    // console.log('kpiCheckList', kpiCheckList);
    setDatasDetail(_datas);
    // setProDatas(_proDatas);
    // setProolDatas(_proolDatas);
    // setColumns(_columns);
    // setExpandColumns(_expandcolumns);
    // setColumnsProol(_columnsProol);
    // setExpandCategoryColumns(_expandCategorycolumns);
    // if (isFirstLoad || !lastfilter) {
    //   setCustomers(_customers);
    //   setSupervisors(_supervisors);
    //   setPhases(_phases);
    //   setAuditor(_auditors);
    //   setRegions(_regions);
    // } else {
    //   if (lastfilter == 'customer') {
    //     if (filterDatas.customer == '') {
    //       setCustomers(_customers);
    //     }
    //     setSupervisors(_supervisors);
    //     setPhases(_phases);
    //     setAuditor(_auditors);
    //     setRegions(_regions);
    //   } else if (lastfilter == 'region') {
    //     if (filterDatas.region == '') {
    //       setRegions(_regions);
    //     }
    //     setCustomers(_customers);
    //     setSupervisors(_supervisors);
    //     setPhases(_phases);
    //     setAuditor(_auditors);
    //   } else if (lastfilter == 'supervisor') {
    //     if (filterDatas.supervisor == '') {
    //       setSupervisors(_supervisors);
    //     }
    //     setCustomers(_customers);
    //     setPhases(_phases);
    //     setAuditor(_auditors);
    //     setRegions(_regions);
    //   } else if (lastfilter == 'phase') {
    //     if (filterDatas.phase == '') {
    //       setPhases(_phases);
    //     }
    //     setCustomers(_customers);
    //     setSupervisors(_supervisors);
    //     setAuditor(_auditors);
    //     setRegions(_regions);
    //   } else if (lastfilter == 'auditor') {
    //     if (filterDatas.auditor == '') {
    //       setAuditor(_auditors);
    //     }
    //     setCustomers(_customers);
    //     setPhases(_phases);
    //     setSupervisors(_supervisors);
    //     setRegions(_regions);
    //   }
    // }
    // setIsFirstLoad(false);
  }, [kpiCheckList, isFirstLoad, filterDatas]);
  const analyticData = (data, _customerDatas, _categories) => {
    let keydata = 'all_kpi_by_customer';
    // console.log('keydata', keydata);
    //_kpiCheckList[i].data.npd_by_category
    let cs = 0;
    if (data[keydata]) {
      if (Object.entries(data[keydata]).length) {
        Object.entries(data[keydata]).map(([key, value]) => {
          // key la customer
          if (!key) {
            // console.log('data[keydata]', data[keydata]);
          }
          if (key && key != 'totals') {
            if (typeof (_customerDatas[key]) === 'undefined') {
              _customerDatas[key] = { totals: {}, totalCustomer: [0, 0] }; // customer
            }
            if (Object.entries(value).length) {

              Object.entries(value).map(([key1, value2]) => {

                if (key1 != 'totals') {
                  if (typeof (_customerDatas[key][key1]) === 'undefined') {
                    _customerDatas[key][key1] = { totals: [0, 0] }; //key1 la shops
                  }
                  if (Object.entries(value2).length) {
                    Object.entries(value2).map(([key2, value3]) => {

                      if (key2 != 'totals') {

                        if (typeof (_customerDatas[key][key1][key2]) === 'undefined') {
                          _customerDatas[key][key1][key2] = [0, 0]; // key2 la category
                        }
                        if (typeof (_customerDatas[key]['totals'][key2]) === 'undefined') {
                          _customerDatas[key]['totals'][key2] = [0, 0]; // key2 la category
                        }
                        if (!_categories.includes(key2)) {
                          _categories.push(key2);
                        }
                        _customerDatas[key][key1].totals[0] += (value3) ? value3[0] : 0;
                        _customerDatas[key][key1].totals[1] += (value3) ? value3[1] : 0;

                        _customerDatas[key][key1][key2][0] += (value3) ? value3[0] : 0;
                        _customerDatas[key][key1][key2][1] += (value3) ? value3[1] : 0;


                        // _customerDatas[key][key1]['totals'][0] += (value3) ? value3[0] : 0;
                        // _customerDatas[key][key1]['totals'][1] += (value3) ? value3[1] : 0;

                        // _customerDatas[key]['totals'][0] += (value3) ? value3[0] : 0;
                        // _customerDatas[key]['totals'][1] += (value3) ? value3[1] : 0;
                        // console.log('value3', value3);
                        _customerDatas[key]['totals'][key2][0] += (value3) ? value3[0] : 0;
                        _customerDatas[key]['totals'][key2][1] += (value3) ? value3[1] : 0;

                        _customerDatas[key].totalCustomer[0] += (value3) ? value3[0] : 0;
                        _customerDatas[key].totalCustomer[1] += (value3) ? value3[1] : 0;
                      }

                    })
                  }
                }

              });
            }
          }
        })
      }
    }
    return { _customerDatas, _categories };
  }
  const generetorColor = (length) => {
    if (length == 3) {
      let graphColors = ['rgba(235, 69, 95,0.2)', 'rgba(43, 52, 103,0.2)', 'rgba(60, 207, 78,0.2)'];
      let graphOutlines = ['rgba(235, 69, 95,1)', 'rgba(43, 52, 103,1)', 'rgba(60, 207, 78,1)'];
      let hoverColor = ['rgba(235, 69, 95,0.6)', 'rgba(43, 52, 103,0.6)', 'rgba(60, 207, 78,0.6)'];
      return { graphColors, graphOutlines, hoverColor };
    }
    let graphColors = [];
    let graphOutlines = [];
    let hoverColor = [];
    let i = 0;
    while (i <= length) {
      let randomR = Math.floor((i / length * 130) + 100);
      let randomG = Math.floor(((length - i) / length * 65) + 100);
      let randomB = Math.floor(((length - i) / length * 130) + 100);

      let graphBackground = "rgba("
        + randomR + ", "
        + randomG + ", "
        + randomB + ",0.2)";
      graphColors.push(graphBackground);

      let graphOutline = "rgb("
        + randomR + ", "
        + randomG + ", "
        + randomB + ")";
      graphOutlines.push(graphOutline);

      let hoverColors = "rgba("
        + (randomR + 25) + ", "
        + (randomG + 25) + ", "
        + (randomB + 25) + ",0.6)";
      hoverColor.push(hoverColors);

      i++;
    };
    return { graphColors, graphOutlines, hoverColor };
  }
  React.useEffect(() => {
    let _customerDatasChart = {}
    if (kpiCheckListChart && kpiCheckListChart.length) {
      let _kpiCheckListChart = kpiCheckListChart.filter((_itemdata) => {
        if (
          (filterDatas.region != '' && filterDatas.region != _itemdata.region) ||
          (filterDatas.customer != '' && filterDatas.customer != _itemdata.customer) ||
          (filterDatas.supervisor != '' && filterDatas.supervisor != _itemdata.supervisor) ||
          (filterDatas.phase != '' && filterDatas.phase != _itemdata.phase) ||
          (filterDatas.auditor != '' && filterDatas.auditor != _itemdata.auditor) ||
          (filterDatas.format != '' && filterDatas.format != _itemdata.format_type)
        ) {
          return false;
        }
        return true;
      });
      for (let i = 0; i < _kpiCheckListChart.length; i++) {
        let yearMonth = moment(_kpiCheckListChart[i].date).format('YYYY-MM');
        // if (yearMonth != '2022-09') {
        //   console.log(_kpiCheckListChart[i].data);
        // }
        _customerDatasChart = analyticDataChart(_kpiCheckListChart[i].data, _customerDatasChart, yearMonth);


      }
      let labels = Object.keys(_customerDatasChart);
      // console.log('_customerDatasChart', _customerDatasChart);
      // _months
      let colors = generetorColor(3);
      let _datasets = [];

      for (let j = 0; j < months.length; j++) {
        let data = {
          label: months[j],
          data: [],
          backgroundColor: colors.hoverColor[j],

        };
        for (let i = 0; i < labels.length; i++) {
          let _data = _customerDatasChart[labels[i]];

          let total = _data && _data[months[j]] ? _data[months[j]][0] : 0;
          let detail = _data && _data[months[j]] ? _data[months[j]][1] : 0;

          let percent = (total > 0) ? Math.round(detail * 10000 / total) / 100 : 0;
          data.data.push(percent)
        }
        _datasets.push(data);
      }
      let _datasChart = {
        labels: labels,
        datasets: _datasets,
      }
      setDataBar(_datasChart)
    }

  }, [kpiCheckListChart, filterDatas]);
  const analyticDataChart = (data, _customerDatas, yearMonth) => {
    let keydata = 'all_kpi_by_customer';
    // console.log('keydata', keydata);
    //_kpiCheckList[i].data.npd_by_category
    // console.log('datadata', data);
    if (data[keydata]) {
      if (Object.entries(data[keydata]).length) {
        Object.entries(data[keydata]).map(([key, value]) => {
          // key la customer
          if (!key) {
            // console.log('data[keydata]', data[keydata]);
          }
          if (key && key != 'totals') {
            if (typeof (_customerDatas[key]) === 'undefined') {
              _customerDatas[key] = {}; // customer
            }
            if (typeof (_customerDatas[key][yearMonth]) === 'undefined') {
              _customerDatas[key][yearMonth] = [0, 0]; // customer
            }
            if (Object.entries(value).length) {
              // console.log('valuevalue', value);
              _customerDatas[key][yearMonth][0] += value && value.totals ? value.totals[0] : 0;
              _customerDatas[key][yearMonth][1] += value && value.totals ? value.totals[1] : 0;
              // console.log('valuevalue111', value.totals);
              // Object.entries(value).map(([key1, value2]) => {

              //   if (key1 == 'totals') {
              //     _customerDatas[key][0] += value2[0];
              //     _customerDatas[key][1] += value2[1];
              //   }

              // });
            }
          }
        })
      }
    }
    return _customerDatas;
  }
  const analyticDataPRO = (ckltype, data, _customerDatas) => {
    let keydata = `${ckltype}_by_category`;
    // console.log('keydata', keydata);
    //_kpiCheckList[i].data.npd_by_category
    if (data[keydata]) {
      if (Object.entries(data[keydata]).length) {
        Object.entries(data[keydata]).map(([key, value]) => {
          if (typeof (_customerDatas.detail[key]) === 'undefined') {
            _customerDatas.detail[key] = { totals: [0, 0, 0], shops: [] };
          }
          if (Object.entries(value).length) {
            Object.entries(value).map(([key1, value2]) => {
              if ((key1 != 'total_shops') && (key1 != 'uniq_shop_names')) {
                let key2 = value2.name;
                if (key2) {
                  if (typeof (_customerDatas.detail[key][key2]) === 'undefined') {
                    _customerDatas.detail[key][key2] = [0, 0, 0, { shops: [] }];
                    // _customerDatas.detail[key].shopsnew = [];
                  }
                  _customerDatas.detail[key][key2][0] += (value2.totals) ? value2.totals[0] : 0;
                  _customerDatas.detail[key][key2][1] += (value2.totals) ? value2.totals[1] : 0;
                  _customerDatas.detail[key][key2][2] += (value2.totals) ? value2.totals[2] : 0;

                  _customerDatas.detail[key]['totals'][0] += (value2.totals) ? value2.totals[0] : 0;
                  _customerDatas.detail[key]['totals'][1] += (value2.totals) ? value2.totals[1] : 0;
                  _customerDatas.detail[key]['totals'][2] += (value2.totals) ? value2.totals[2] : 0;


                  _customerDatas.totals[0] += (value2.totals) ? value2.totals[0] : 0;
                  _customerDatas.totals[1] += (value2.totals) ? value2.totals[1] : 0;
                  _customerDatas.totals[2] += (value2.totals) ? value2.totals[2] : 0;

                  if (value2.uniq_shop_names.length) {
                    for (var i = 0; i < value2.uniq_shop_names.length; i++) {
                      if (!_customerDatas.shops.includes(value2.uniq_shop_names[i])) {
                        _customerDatas.shops.push(value2.uniq_shop_names[i]);
                      }
                      if (!_customerDatas.detail[key].shops.includes(value2.uniq_shop_names[i])) {
                        _customerDatas.detail[key].shops.push(value2.uniq_shop_names[i]);
                      }
                      if (!_customerDatas.detail[key][key2][3].shops.includes(value2.uniq_shop_names[i])) {
                        _customerDatas.detail[key][key2][3].shops.push(value2.uniq_shop_names[i]);
                      }
                    }
                  }
                }
              }
            });
          }
        })
      }
    }
    return _customerDatas;
  }
  const analyticDataOSANPD = (ckltype, data, _customerDatas) => {
    let keydata = `${ckltype}_by_category`;
    let totalKey = `total_${ckltype}_counts`;
    //_kpiCheckList[i].data.npd_by_category
    if (data[keydata]) {
      if (Object.entries(data[keydata]).length) {
        Object.entries(data[keydata]).map(([key, value]) => {

          if ((key != 'uniq_shop_names') && (key != 'total_shops')) {
            if (typeof (_customerDatas[ckltype].detail[key]) === 'undefined') {
              _customerDatas[ckltype].detail[key] = {
                total_fail_stock_counts: [0, 0, 0],
                total_no_stock_counts: [0, 0, 0],
                // total_npd_counts: [0, 0, 0],
                total_oos_counts: [0, 0, 0],
                total_void_counts: [0, 0, 0],
                shops: []
              };
              _customerDatas[ckltype].detail[key][totalKey] = [0, 0, 0];
            }
            if (Object.entries(value).length) {
              Object.entries(value).map(([key1, value2]) => {
                //total by product
                if ((key1 != 'total_shops') && (key1 != 'uniq_shop_names')) {
                  let key2 = value2.name;
                  if (key2) {
                    if (typeof (_customerDatas[ckltype].detail[key][key2]) === 'undefined') {
                      _customerDatas[ckltype].detail[key][key2] = {
                        total_fail_stock_counts: [0, 0, 0],
                        total_no_stock_counts: [0, 0, 0],
                        // total_npd_counts: [0, 0, 0],
                        total_oos_counts: [0, 0, 0],
                        total_void_counts: [0, 0, 0],
                        shops: [],
                      }
                      _customerDatas[ckltype].detail[key][key2][totalKey] = [0, 0, 0];
                    }

                    _customerDatas[ckltype].detail[key][key2]['total_fail_stock_counts'][0] += (value2.total_fail_stock_counts) ? value2.total_fail_stock_counts[0] : 0;
                    _customerDatas[ckltype].detail[key][key2]['total_fail_stock_counts'][1] += (value2.total_fail_stock_counts) ? value2.total_fail_stock_counts[1] : 0;
                    _customerDatas[ckltype].detail[key][key2]['total_fail_stock_counts'][2] += (value2.total_fail_stock_counts) ? value2.total_fail_stock_counts[2] : 0;

                    _customerDatas[ckltype].detail[key][key2]['total_no_stock_counts'][0] += (value2.total_no_stock_counts) ? value2.total_no_stock_counts[0] : 0;
                    _customerDatas[ckltype].detail[key][key2]['total_no_stock_counts'][1] += (value2.total_no_stock_counts) ? value2.total_no_stock_counts[1] : 0;
                    _customerDatas[ckltype].detail[key][key2]['total_no_stock_counts'][2] += (value2.total_no_stock_counts) ? value2.total_no_stock_counts[2] : 0;

                    _customerDatas[ckltype].detail[key][key2][totalKey][0] += (value2[totalKey]) ? value2[totalKey][0] : 0;
                    _customerDatas[ckltype].detail[key][key2][totalKey][1] += (value2[totalKey]) ? value2[totalKey][1] : 0;
                    _customerDatas[ckltype].detail[key][key2][totalKey][2] += (value2[totalKey]) ? value2[totalKey][2] : 0;

                    // _customerDatas[ckltype].detail[key][key2].shops += (value2.total_shops) ? value2.total_shops : 0;

                    _customerDatas[ckltype].detail[key][key2]['total_oos_counts'][0] += (value2.total_oos_counts) ? value2.total_oos_counts[0] : 0;
                    _customerDatas[ckltype].detail[key][key2]['total_oos_counts'][1] += (value2.total_oos_counts) ? value2.total_oos_counts[1] : 0;
                    _customerDatas[ckltype].detail[key][key2]['total_oos_counts'][2] += (value2.total_oos_counts) ? value2.total_oos_counts[2] : 0;

                    _customerDatas[ckltype].detail[key][key2]['total_void_counts'][0] += (value2.total_void_counts) ? value2.total_void_counts[0] : 0;
                    _customerDatas[ckltype].detail[key][key2]['total_void_counts'][1] += (value2.total_void_counts) ? value2.total_void_counts[1] : 0;
                    _customerDatas[ckltype].detail[key][key2]['total_void_counts'][2] += (value2.total_void_counts) ? value2.total_void_counts[2] : 0;
                    //total by cate
                    _customerDatas[ckltype].detail[key]['total_fail_stock_counts'][0] += (value2.total_fail_stock_counts) ? value2.total_fail_stock_counts[0] : 0;
                    _customerDatas[ckltype].detail[key]['total_fail_stock_counts'][1] += (value2.total_fail_stock_counts) ? value2.total_fail_stock_counts[1] : 0;
                    _customerDatas[ckltype].detail[key]['total_fail_stock_counts'][2] += (value2.total_fail_stock_counts) ? value2.total_fail_stock_counts[2] : 0;

                    _customerDatas[ckltype].detail[key]['total_no_stock_counts'][0] += (value2.total_no_stock_counts) ? value2.total_no_stock_counts[0] : 0;
                    _customerDatas[ckltype].detail[key]['total_no_stock_counts'][1] += (value2.total_no_stock_counts) ? value2.total_no_stock_counts[1] : 0;
                    _customerDatas[ckltype].detail[key]['total_no_stock_counts'][2] += (value2.total_no_stock_counts) ? value2.total_no_stock_counts[2] : 0;

                    _customerDatas[ckltype].detail[key][totalKey][0] += (value2[totalKey]) ? value2[totalKey][0] : 0;
                    _customerDatas[ckltype].detail[key][totalKey][1] += (value2[totalKey]) ? value2[totalKey][1] : 0;
                    _customerDatas[ckltype].detail[key][totalKey][2] += (value2[totalKey]) ? value2[totalKey][2] : 0;

                    // _customerDatas[ckltype].detail[key].shops += (value2.total_shops) ? value2.total_shops : 0;

                    _customerDatas[ckltype].detail[key]['total_oos_counts'][0] += (value2.total_oos_counts) ? value2.total_oos_counts[0] : 0;
                    _customerDatas[ckltype].detail[key]['total_oos_counts'][1] += (value2.total_oos_counts) ? value2.total_oos_counts[1] : 0;
                    _customerDatas[ckltype].detail[key]['total_oos_counts'][2] += (value2.total_oos_counts) ? value2.total_oos_counts[2] : 0;

                    _customerDatas[ckltype].detail[key]['total_void_counts'][0] += (value2.total_void_counts) ? value2.total_void_counts[0] : 0;
                    _customerDatas[ckltype].detail[key]['total_void_counts'][1] += (value2.total_void_counts) ? value2.total_void_counts[1] : 0;
                    _customerDatas[ckltype].detail[key]['total_void_counts'][2] += (value2.total_void_counts) ? value2.total_void_counts[2] : 0;

                    // total
                    _customerDatas[ckltype].total['total_fail_stock_counts'][0] += (value2.total_fail_stock_counts) ? value2.total_fail_stock_counts[0] : 0;
                    _customerDatas[ckltype].total['total_fail_stock_counts'][1] += (value2.total_fail_stock_counts) ? value2.total_fail_stock_counts[1] : 0;
                    _customerDatas[ckltype].total['total_fail_stock_counts'][2] += (value2.total_fail_stock_counts) ? value2.total_fail_stock_counts[2] : 0;

                    _customerDatas[ckltype].total['total_no_stock_counts'][0] += (value2.total_no_stock_counts) ? value2.total_no_stock_counts[0] : 0;
                    _customerDatas[ckltype].total['total_no_stock_counts'][1] += (value2.total_no_stock_counts) ? value2.total_no_stock_counts[1] : 0;
                    _customerDatas[ckltype].total['total_no_stock_counts'][2] += (value2.total_no_stock_counts) ? value2.total_no_stock_counts[2] : 0;
                    // console.log('value2value2x', value2);
                    // _customerDatas[ckltype].shops += (value2.total_shops) ? value2.total_shops : 0;
                    // _customerDatas[ckltype].shops = _customerDatas[ckltype].shops.filter(onlyUnique);

                    _customerDatas[ckltype].total[totalKey][0] += (value2[totalKey]) ? value2[totalKey][0] : 0;
                    _customerDatas[ckltype].total[totalKey][1] += (value2[totalKey]) ? value2[totalKey][1] : 0;
                    _customerDatas[ckltype].total[totalKey][2] += (value2[totalKey]) ? value2[totalKey][2] : 0;

                    _customerDatas[ckltype].total['total_oos_counts'][0] += (value2.total_oos_counts) ? value2.total_oos_counts[0] : 0;
                    _customerDatas[ckltype].total['total_oos_counts'][1] += (value2.total_oos_counts) ? value2.total_oos_counts[1] : 0;
                    _customerDatas[ckltype].total['total_oos_counts'][2] += (value2.total_oos_counts) ? value2.total_oos_counts[2] : 0;

                    _customerDatas[ckltype].total['total_void_counts'][0] += (value2.total_void_counts) ? value2.total_void_counts[0] : 0;
                    _customerDatas[ckltype].total['total_void_counts'][1] += (value2.total_void_counts) ? value2.total_void_counts[1] : 0;
                    _customerDatas[ckltype].total['total_void_counts'][2] += (value2.total_void_counts) ? value2.total_void_counts[2] : 0;

                    if (value2.uniq_shop_names.length) {
                      for (var i = 0; i < value2.uniq_shop_names.length; i++) {
                        if (!_customerDatas[ckltype].shops.includes(value2.uniq_shop_names[i])) {
                          _customerDatas[ckltype].shops.push(value2.uniq_shop_names[i]);
                        }
                        if (!_customerDatas[ckltype].detail[key].shops.includes(value2.uniq_shop_names[i])) {
                          _customerDatas[ckltype].detail[key].shops.push(value2.uniq_shop_names[i]);
                        }
                        if (!_customerDatas[ckltype].detail[key][key2].shops.includes(value2.uniq_shop_names[i])) {
                          _customerDatas[ckltype].detail[key][key2].shops.push(value2.uniq_shop_names[i]);
                        }
                      }
                    }

                  } else {
                    console.log('nonamekey1', key1, value2);
                  }
                }
              })

            }
          }
        });
      }
    } else {
      // console.log('keydata', keydata, totalKey);
    }
    return _customerDatas;
  }
  React.useEffect(() => {
    let _datas = [];
    let _proDatas = [];
    let _proolDatas = [];
    let _customers = [];
    let _regions = [];
    let _supervisors = [];
    let _phases = [];
    let _auditors = [];
    let _customerDatas = {
      osa: {
        shops: [],
        total: {
          total_fail_stock_counts: [0, 0, 0],
          total_no_stock_counts: [0, 0, 0],
          total_osa_counts: [0, 0, 0],
          total_oos_counts: [0, 0, 0],
          total_void_counts: [0, 0, 0],
        },
        detail: {}
      },
      npd: {
        shops: [],
        total: {
          total_fail_stock_counts: [0, 0, 0],
          total_no_stock_counts: [0, 0, 0],
          total_npd_counts: [0, 0, 0],
          total_oos_counts: [0, 0, 0],
          total_void_counts: [0, 0, 0],
        },
        detail: {}
      },

    };
    let _customerDatas2 = {
      totals: [0, 0, 0],
      shops: [],
      detail: {

      }
    };
    let _customerDatas3 = {
      shops: {
        "GE": [],
        "MS": [],
        "PI": [],
        "HGR": [],
        "SB": [],
        "SK": [],
        "SS": [],
        "ALT": [],
        "COC": [],
        "CSM": [],
        "ICC": [],
        "KOC": [],
        "SHF": [],
        "GE-C": [],
        "SSBF": [],
        "SSHF": [],
      },
      totals: {
        "GE": [0, 0, 0],
        "MS": [0, 0, 0],
        "PI": [0, 0, 0],
        "HGR": [0, 0, 0],
        "SB": [0, 0, 0],
        "SK": [0, 0, 0],
        "SS": [0, 0, 0],
        "ALT": [0, 0, 0],
        "COC": [0, 0, 0],
        "CSM": [0, 0, 0],
        "ICC": [0, 0, 0],
        "KOC": [0, 0, 0],
        "SHF": [0, 0, 0],
        "GE-C": [0, 0, 0],
        "SSBF": [0, 0, 0],
        "SSHF": [0, 0, 0],
        "totals": [0, 0, 0],
      },
      detail: {

      }

    };
    if (kpiCheckList && kpiCheckList.length) {
      let _kpiCheckList = kpiCheckList.filter((_itemdata) => {
        if (
          (filterDatas.region != '' && filterDatas.region != _itemdata.region) ||
          (filterDatas.customer != '' && filterDatas.customer != _itemdata.customer) ||
          (filterDatas.supervisor != '' && filterDatas.supervisor != _itemdata.supervisor) ||
          (filterDatas.phase != '' && filterDatas.phase != _itemdata.phase) ||
          (filterDatas.auditor != '' && filterDatas.auditor != _itemdata.auditor) ||
          (filterDatas.format != '' && filterDatas.format != _itemdata.format_type)
        ) {
          return false;
        }
        return true;
      });
      let checkListTypes = ['osa', 'npd', 'prool', 'procms'];
      for (let i = 0; i < _kpiCheckList.length; i++) {
        let _customer = _kpiCheckList[i].customer || null;
        if (_customer && !_customers.includes(_customer)) {
          _customers.push(_customer);
        }
        if (_kpiCheckList[i].supervisor && !_supervisors.includes(_kpiCheckList[i].supervisor)) {
          _supervisors.push(_kpiCheckList[i].supervisor)
        }
        if (_kpiCheckList[i].region && !_regions.includes(_kpiCheckList[i].region)) {
          _regions.push(_kpiCheckList[i].region)
        }
        if (_kpiCheckList[i].phase && !_phases.includes(_kpiCheckList[i].phase)) {
          _phases.push(_kpiCheckList[i].phase)
        }
        if (_kpiCheckList[i].auditor && !_auditors.includes(_kpiCheckList[i].auditor)) {
          _auditors.push(_kpiCheckList[i].auditor)
        }
        checkListTypes.map((__checkListType) => {

          if (__checkListType == 'osa' || __checkListType == 'npd') {
            _customerDatas = analyticDataOSANPD(__checkListType, _kpiCheckList[i].data, _customerDatas);
          } else if (__checkListType == 'procms') {
            _customerDatas2 = analyticDataPRO(__checkListType, _kpiCheckList[i].data, _customerDatas2);
          } else if (__checkListType == 'prool') {

            _customerDatas3 = analyticDataPROOL(__checkListType, _kpiCheckList[i].data, _customerDatas3);
          }

        });
        // _customerDatas = { ...__customerDatas }
        // _customerDatas.npd[_customer].



      }

      _datas = Object.entries(_customerDatas);
      _proDatas = Object.entries({ procms: _customerDatas2 });
      _proolDatas = Object.entries({ prool: _customerDatas3 });
    }
    // console.log('_customerDatas', _customerDatas);

    setDatas(_datas);
    setProDatas(_proDatas);
    setProolDatas(_proolDatas);
    // setColumns(_columns);
    // setExpandColumns(_expandcolumns);
    // setColumnsProol(_columnsProol);
    // setExpandCategoryColumns(_expandCategorycolumns);
    if (isFirstLoad || !lastfilter) {
      setCustomers(_customers);
      setSupervisors(_supervisors);
      setPhases(_phases);
      setAuditor(_auditors);
      setRegions(_regions);;
    } else {
      if (lastfilter == 'customer') {
        if (filterDatas.customer == '') {
          setCustomers(_customers);
        }
        setSupervisors(_supervisors);
        setPhases(_phases);
        setAuditor(_auditors);
        setRegions(_regions);
      } else if (lastfilter == 'region') {
        if (filterDatas.region == '') {
          setRegions(_regions);
        }
        setCustomers(_customers);
        setSupervisors(_supervisors);
        setPhases(_phases);
        setAuditor(_auditors);
      } else if (lastfilter == 'supervisor') {
        if (filterDatas.supervisor == '') {
          setSupervisors(_supervisors);
        }
        setCustomers(_customers);
        setPhases(_phases);
        setAuditor(_auditors);
        setRegions(_regions);
      } else if (lastfilter == 'phase') {
        if (filterDatas.phase == '') {
          setPhases(_phases);
        }
        setCustomers(_customers);
        setSupervisors(_supervisors);
        setAuditor(_auditors);
        setRegions(_regions);
      } else if (lastfilter == 'auditor') {
        if (filterDatas.auditor == '') {
          setAuditor(_auditors);
        }
        setCustomers(_customers);
        setPhases(_phases);
        setSupervisors(_supervisors);
        setRegions(_regions);
      }
    }
    setIsFirstLoad(false);
  }, [kpiCheckList, isFirstLoad, filterDatas]);
  const getCustomerData = async (_customer) => {
    let _m = moment().format('01-MM-YYYY');
    if (1) {
      _m = moment(filterDatas.month, 'YYYY-MM').format('01-MM-YYYY');
    }
    let abc = await dispatch(homeActions.getKpiReportIndexCheckListInMonthByCustomer({ customer: _customer, date_in_month: _m }));
  }
  const getRangeDatas = async (rangeMonth) => {
    let datas = [];
    dispatch(homeActions.setLoading(true));
    for (let i = 0; i < rangeMonth.length; i++) {
      let _datas = await getDatas(rangeMonth[i][0], rangeMonth[i][1]);
      // console.log('_datas', _datas.length);
      if (_datas && _datas.length) {
        datas = [...datas, ..._datas];
      }

    }
    dispatch(homeActions.setLoading(false));
    setKpiCheckList(datas);
  }
  const getDatas = async (_fromDate, _toDate) => {
    console.log('getDatas', _fromDate, _toDate);
    // if (_fromDate && _toDate) {
    let res = await dispatch(homeActions.getKpiReportCheckListItemByCategory({ date_from: _fromDate, date_to: _toDate }));
    return res;
    //   if (res && res.length) {
    //     console.log('resres', res[0]);
    //   }

    //   setKpiCheckList(res);
    // }
    // if (_fromDate) {
    // dispatch(homeActions.getKpiReportIndexCheckListInMonth({ date_in_month: _fromDate }));
    // }
    // 
    // dispatch(homeActions.getKpiReportIndexCheckList({ date_from: _fromDate, date_to: _toDate }));
    // dispatch(homeActions.getKpiReport({ date_from: _fromDate, date_to: _toDate }));
  };
  const _getKpiReportCheckListItemByCategory = async (_fromDate, _toDate) => {

    // if (_fromDate && _toDate) {
    //   dispatch(homeActions.getKpiReportCheckListItemByCategory({ date_from: _fromDate, date_to: _toDate }));
    // }
    // 
    // dispatch(homeActions.getKpiReportIndexCheckList({ date_from: _fromDate, date_to: _toDate }));
    // dispatch(homeActions.getKpiReport({ date_from: _fromDate, date_to: _toDate }));
  };
  const getDatasChart = async (_fromDate, _toDate) => {
    // console.log('getDatasChart', _fromDate, _toDate);
    if (_fromDate && _toDate) {
      let res = await dispatch(homeActions.getKpiReportCheckListItemByCategory({ date_from: _fromDate, date_to: _toDate }));

      setKpiCheckListChart(res);
    }
  }
  React.useEffect(() => {
    setFilterDatas(fulldatas.defaultFilter);
  }, [])
  React.useEffect(() => {
    let _fromDate, _toDate;
    console.log('isAllowFetdata', isAllowFetdata);
    // console.log('filterDatas', filterDatas?.frommonth?.format('MM-YYYY'), filterDatas?.month?.format('MM-YYYY'),);
    if (isAllowFetdata && filterDatas?.frommonth && filterDatas?.month) {
      setIsAllowFetdata(false);
      setChooseMonth({
        frommonth: false,
        tomonth: false,
      });
      let _df = moment(filterDatas.frommonth.format('01-MM-YYYY'), 'DD-MM-YYYY');
      let _d = moment(filterDatas.month.format('01-MM-YYYY'), 'DD-MM-YYYY');
      // let m = _d.daysInMonth();

      var date = _df.startOf('month');
      let betweenMonths = [];
      while (date < _d.endOf('month')) {
        let m = date.daysInMonth();
        _fromDate = date.format('01-MM-YYYY');
        _toDate = date.format(m + '-MM-YYYY');
        betweenMonths.push([_fromDate, _toDate]);
        date.add(1, 'month');
      }
      // console.log('filterDatas.frommonth', filterDatas.frommonth.format('MM-YYYY'));
      // console.log('filterDatas.month', filterDatas.month.format('MM-YYYY'));
      // console.log('betweenMonths', betweenMonths);
      // console.log(_d);
      // return false;
      // console.log('betweenMonths', betweenMonths);
      // console.log('_fromDate, _toDate', _fromDate, _toDate);
      getRangeDatas(betweenMonths);

      let _dc = moment(filterDatas.month.format('01-MM-YYYY'), 'DD-MM-YYYY');
      let _lm = moment(filterDatas.month.format('01-MM-YYYY'), 'DD-MM-YYYY');

      let _fromDateChart = _dc.subtract(2, 'months');
      let lastMonth = _lm.subtract(1, 'months');
      let _months = [_fromDateChart.format('YYYY-MM'), lastMonth.format('YYYY-MM'), _d.format('YYYY-MM')];
      setMonths(_months);
      // getDatasDetail(_fromDate, _toDate);
      getDatasChart(_fromDateChart.format('01-MM-YYYY'), _toDate);
    }
  }, [filterDatas?.month, filterDatas?.frommonth, isAllowFetdata, chooseMonth]);

  return (
    <Row>
      <Col span={24} style={{ backgroundColor: '#f0f2f5' }}>
        <Collapse accordion defaultActiveKey={['filter']} style={{ position: 'sticky', top: 0, zIndex: 4 }}>
          <Collapse.Panel header="Filter" key="filter">
            <Card
              size={'small'}
              bordered={false}
              bodyStyle={{ padding: 0 }}
              style={{ width: '100%' }}>
              <Form name="filter" form={form} layout='vertical'>
                <Row gutter={16}>
                  <Col>
                    {/* {(filterDatas) ? moment(filterDatas.frommonth).format('MM-YYYY') : ''} */}
                    <Form.Item title="From Month" label="From Month" >
                      <DatePicker format="MM-YYYY" picker="month"
                        disabledDate={(current) => {
                          // console.log('current1', current);
                          return chooseMonth.tomonth && current && filterDatas.month && filterDatas.month < current;
                        }}
                        onChange={onChangeFromMonth}
                        open={openChangeFromMonth}
                        onOpenChange={(open) => setOpenChangeFromMonth(open)}
                        style={{ width: '100%' }}
                        value={(filterDatas?.frommonth) ? moment(filterDatas?.frommonth) : moment()}
                      />
                    </Form.Item>
                    {/* <Form.Item title="Month Range" label='Month Range'>
                      <DatePicker.RangePicker format="MM-YYYY" picker="month" onChange={onOk} style={{ width: '100%' }}
                        onOpenChange={(open) => {
                          setIsOpenDate(open);
                        }}
                        value={[rangeFilter.frommonth ? moment(rangeFilter.frommonth) : moment(), rangeFilter.month ? moment(rangeFilter.month) : moment()]}
                      />
                    </Form.Item> */}
                  </Col>
                  <Col>
                    <Form.Item title="To Month" label="To Month" >
                      <DatePicker format="MM-YYYY" picker="month"
                        onChange={onChangeToMonth}
                        disabledDate={(current) => {
                          return chooseMonth.frommonth && current && filterDatas.frommonth && filterDatas.frommonth > current;
                        }}
                        open={openChangeToMonth}
                        onOpenChange={(open) => setOpenChangeToMonth(open)}
                        style={{ width: '100%' }}
                        value={(filterDatas?.month) ? moment(filterDatas?.month) : moment()}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item title="" label="&nbsp;">
                      <Button type="primary" htmlType="button"
                        disabled={!filterDatas?.frommonth || !filterDatas?.month}
                        onClick={() => {
                          setIsAllowFetdata(true);
                        }}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col>
                    <Form.Item
                      name="region"
                      label="Region"
                    >
                      <Select placeholder="Chọn All" value={filterDatas?.region} style={{ minWidth: 100 }} onChange={(value) => {
                        setLastfilter('region');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.region = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'region-1'}>{'Chọn All'}</Select.Option>
                        {regions && regions.length ? regions.filter((__region) => {
                          if (lastfilter == 'region') {
                            return true;
                          } else {
                            return (filterDatas?.region) ? __region == filterDatas.region : true;
                          }
                        }).map((_region, index) => {
                          return <Select.Option value={_region} key={'region' + index}>{_region}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="customer"
                      label="Customer"
                    >
                      <Select placeholder="Chọn All" value={filterDatas?.customer} style={{ minWidth: 150 }} onChange={(value) => {
                        setLastfilter('customer');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.customer = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'customer-1'}>{'Chọn All'}</Select.Option>
                        {customers && customers.length ? customers.filter((__customer) => {
                          if (lastfilter == 'customer') {
                            return true;
                          } else {
                            return (filterDatas?.customer) ? __customer == filterDatas.customer : true;
                          }
                        }).map((_customer, index) => {
                          return <Select.Option value={_customer} key={'customer' + index}>{_customer}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="supervisor"
                      label="Supervisor"
                    >
                      <Select placeholder="Chọn All" value={filterDatas?.supervisor} style={{ minWidth: 200 }} onChange={(value) => {
                        setLastfilter('supervisor');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.supervisor = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'supervisor-1'}>{'Chọn All'}</Select.Option>
                        {supervisors && supervisors.length ? supervisors.filter((__supervisor) => {
                          if (lastfilter == 'supervisor') {
                            return true;
                          } else {
                            return (filterDatas?.supervisor) ? __supervisor == filterDatas.supervisor : true;
                          }
                        }).map((_supervisor, index) => {
                          return <Select.Option value={_supervisor} key={'supervisor' + index}>{_supervisor}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="phase"
                      label="Phases"
                    >
                      <Select placeholder="Chọn All" value={filterDatas?.phase} style={{ minWidth: 100 }} onChange={(value) => {
                        setLastfilter('phase');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.phase = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'phase-1'}>{'Chọn All'}</Select.Option>
                        {phases && phases.length ? phases.map((_phase, index) => {
                          return <Select.Option value={_phase} key={'phase' + index}>{_phase}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="auditor"
                      label="Auditor"
                    >
                      <Select placeholder="Chọn All" value={filterDatas?.auditor} style={{ minWidth: 200 }} onChange={(value) => {
                        setLastfilter('auditor');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.auditor = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        <Select.Option value={''} key={'auditor-1'}>{'Chọn All'}</Select.Option>
                        {auditors && auditors.length ? auditors.map((_auditor, index) => {
                          return <Select.Option value={_auditor} key={'auditor' + index}>{_auditor}</Select.Option>
                        }) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="format"
                      label="Format"
                    >
                      <Text style={{ fontSize: 0 }}>{filterDatas?.format || ""}</Text>
                      <Select placeholder="BIG FORMAT" value={filterDatas?.format || "BIG FORMAT"} style={{ minWidth: 150 }} onChange={(value) => {
                        setLastfilter('format');
                        let _filterDatas = { ...filterDatas }
                        _filterDatas.format = value;
                        setFilterDatas(_filterDatas);
                      }}>
                        {/* <Select.Option value={''} key={'format-1'}>{'Chọn All'}</Select.Option> */}
                        <Select.Option value="BIG FORMAT" key={'format-2'}>BIG FORMAT</Select.Option>
                        <Select.Option value="SMALL FORMAT" key={'format-3'}>SMALL FORMAT</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col>
                    <Form.Item
                      name="lowerOnlyOsa"
                      label="Highlight OSA"
                      tooltip={'Highlight OSA if percent less than this value'}
                      style={{ marginBottom: 0 }}
                    >
                      <NumberFormat className="ant-input" navigation={false} style={{ marginBottom: 5, width: '100%' }} placeholder="0" value={lowerOnly.osa} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                        onValueChange={(values) => {

                          if (values && values.floatValue) {
                            setLowerOnly({ ...lowerOnly, osa: values.floatValue });
                          } else {
                            setLowerOnly({ ...lowerOnly, osa: 0 });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="lowerOnlyNpd"
                      label="Highlight NPD"
                      tooltip={'Highlight NPD if percent less than this value'}
                      style={{ marginBottom: 0 }}
                    >
                      <NumberFormat className="ant-input" navigation={false} style={{ marginBottom: 5, width: '100%' }} placeholder="0" value={lowerOnly.npd} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                        onValueChange={(values) => {

                          if (values && values.floatValue) {
                            setLowerOnly({ ...lowerOnly, npd: values.floatValue });
                          } else {
                            setLowerOnly({ ...lowerOnly, npd: 0 });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col style={{ flexDirection: 'column', display: 'flex' }}>
                    <div className="ant-col ant-form-item-label">
                      <label className="ant-form-item-no-colon">&nbsp;</label>
                    </div>
                    <Button type="primary" htmlType="button"
                      onClick={() => {
                        let _filterDatas = { ...filterDatas };
                        _filterDatas.lowerOnly = lowerOnly;
                        setFilterDatas(_filterDatas)
                      }}
                    >
                      Highlight
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Collapse.Panel>
        </Collapse>
        <Card
          title={"OSA & NPD"}
          bordered={false}
          style={{ width: '100%', marginTop: 15 }}
        >
          <OsaNpd datas={datas} filterDatas={filterDatas} />
        </Card>
        <Procms datas={proDatas} />
        <Prool datas={proolDatas} filterDatas={filterDatas} />
        <div
          style={{ width: '100%', marginTop: 15 }}
        >
          <DetailKpiTable datas={datasDetail} filterDatas={filterDatas} allCategories={allCategories} />
        </div>
        <Card
          title={"Chart"}
          bordered={false}
          style={{ width: '100%', marginTop: 15 }}
        >
          {!!dataBar && <Bar options={optionsBar} data={dataBar} />}
        </Card>
      </Col >
    </Row >
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(OsaNpdPro);
