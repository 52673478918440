import React from 'react';
import { List, } from 'antd';
import ListItem from './Item';
const ChecklistType = (props) => {
    const { datas, checklistType } = props;
    const [newCodes, setNewCodes] = React.useState([]);
    React.useEffect(() => {

        let _datas = [...datas];
        // console.log('_datas', _datas);
        if ((checklistType === 'osa') && (_datas?.length)) {
            let _newCodes = [];
            // console.log(' _datas[0]', _datas[0].custom_attributes.stock_id);
            for (let i = 0; i < _datas.length; i++) {


                const _newCode = _datas[i]?.custom_attributes?.new_code;
                if (_newCode && !_newCodes.includes(_newCode)) {
                    _newCodes.push(_newCode);
                }
            }
            console.log('_newCodes', _newCodes);

            setNewCodes(_newCodes);
        }
    }, [datas]);
    return <List
        grid={{
            gutter: 16,
            column: props.itemPerRow,
        }}
        pagination={{
            onChange: page => {
                console.log(page);
            },
            pageSize: 10,
            //   total: __datas.length,
            hideOnSinglePage: true
        }}

        dataSource={datas}
        renderItem={(item, index) => {
            if (props?.sendSupport) {
                return <ListItem isReview={props.isReview || false} newCodes={newCodes} sendSupport={props?.sendSupport} onSubmit={(props.submit) ? (item) => props.submit(item) : null} key={item.id + '-' + index} item={item} />
            } else {
                return <ListItem isReview={props.isReview || false} newCodes={newCodes} onSubmit={(props.submit) ? (item) => props.submit(item) : null} key={item.id + '-' + index} item={item} />
            }

        }}
    />
}
export default React.memo(ChecklistType);