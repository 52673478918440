const initialState = {
  isLoading: false,
  isDownLoading: false,
  isOffline: false,
  kpiCheckList: null,
  kpiCheckListInMonth: null,
  filterDatas: null,
};

export default initialState;
