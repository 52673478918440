import React, { useState } from 'react'

import { Row, Col, Card, Form, Select, Button, Upload, Input, Space, Alert, Typography, InputNumber, DatePicker } from 'antd';
import { UndoOutlined, UserOutlined, ShopOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';
import { homeActions } from './redux/actions';
import history from '../../common/history';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const LLVmanager = ({ dispatch, errMessage, successMessage, user }) => {
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const [maxdate, setMaxdate] = useState(null);
  const [mindate, setMindate] = useState(null);
  React.useEffect(() => {
    if (!user || !['tester', 'admin'].includes(user.role)) {
      // history.push('/');
    }
  }, [user]);
  const [datas, setDatas] = useState({
    shop_importing_id: 0,
    from_date: null,
    to_date: '',
    username: null,
  });
  const onFinish = () => {
    // console.log(datas);
    dispatch(homeActions.rescheduleChecklists(datas));
    setSubmitted(true)
  };
  function onChangeFrom(date, dateString) {
    setSubmitted(false);
    let _datas = { ...datas };
    _datas.from_date = dateString;
    setDatas(_datas);
    // setMindate(date)
  }
  function disabledDateFrom(current) {
    return current && maxdate && (current > maxdate);
  }
  function disabledDateTo(current) {
    return current && mindate && (current < mindate);
  }
  function onChangeTo(date, dateString) {
    setSubmitted(false);
    let _datas = { ...datas };
    _datas.to_date = dateString;
    setDatas(_datas);
    // setMaxdate(date)
  }
  const onChangeShop = (shop_importing_id) => {
    setSubmitted(false);
    let _datas = { ...datas };
    _datas.shop_importing_id = shop_importing_id;
    setDatas(_datas);
  };
  const onChangeUsername = (event) => {
    setSubmitted(false);
    let _datas = { ...datas };
    _datas.username = event.target.value;
    setDatas(_datas);
  };

  return (
    <Row>
      <Col span={24}>
        <Card title="LLV" bordered={false} style={{ width: '100%' }}>
          <Form
            {...layout}
            name="upload-form"
            defaultValue={{ option: 'full' }}
            onFinish={onFinish}
            form={form}
          >
            <Text>Shop</Text>
            <Form.Item
              name="shop_importing_id"
              key='shop_importing_id'
              rules={[{ required: true, message: 'Vui lòng nhập!' }]}
            >

              <InputNumber
                addonBefore={<ShopOutlined />}
                placeholder="Nhập shop ID"
                onChange={onChangeShop}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Text>Vào ngày</Text>
            <Form.Item
              name="from_date"
              key='from_date'
              rules={[{ required: true, message: 'Vui lòng nhập!' }]}
            >

              <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} placeholder={'Vào ngày'}
                onChange={onChangeFrom}
                disabledDate={disabledDateFrom}
              />
            </Form.Item>
            <Text>Đến ngày</Text>
            <Form.Item
              name="to_date"
              key='to_date'
              rules={[{ required: false }]}
            >

              <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} placeholder={'Đến ngày'} onChange={onChangeTo} disabledDate={disabledDateTo} />
            </Form.Item>
            <Text>Đến nhân viên</Text>
            <Form.Item
              name="username"
              key='username'
              rules={[{ required: false }]}
            >

              <Input
                addonBefore={<UserOutlined />}
                placeholder="Nhập username"
                onChange={(value) => onChangeUsername(value)}
                style={{ width: '100%' }}
              />
            </Form.Item>
            {submitted && errMessage ? <Alert message={errMessage} style={{ marginBottom: 10, width: '50%' }} type="error" showIcon /> : null}
            {submitted && successMessage ? <Alert message={successMessage} style={{ marginBottom: 10, width: '50%' }} type="success" showIcon /> : null}
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" style={{ marginRight: '10px' }}>
                <UndoOutlined />
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    errMessage: state.home.rescheduleCheckListErrorMessage,
    successMessage: state.home.rescheduleCheckListSuccessMessage,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(LLVmanager);
