import React from 'react';
import {
    Row,
    Typography
} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
function Sos(props) {
    const item = props.item || {};
    let data = { ...item.data };
    delete data.pass;
    delete data.qc_note;
    delete data.predefined_qc_note;
    const isBigFormat = (item && item.custom_attributes && item.custom_attributes.format_type == 'BIG FORMAT');
    const { Text } = Typography;
    return <div key={'key' + item.id}>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Nhãn hiệu:</b></Text>
            <Text>
                {item.custom_attributes.brand || ''}
            </Text>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Quy tắc đo:</b></Text>
            <Text>
                <i>
                    {item.custom_attributes.measurement_instructions || ''}
                </i>
            </Text>
        </Row>
        {Object.entries(data).filter((item) => {
            console.log('item', item);
            return item && item.length == 2 && (item[0] == 'MS' || item[0] == 'OL') && item[1] && item[1].Checked;
        }).length ? <table style={{ marginBottom: 5 }}>
            <thead className="ant-table-thead">
                <tr>
                    <th style={{ padding: 5 }}> </th>
                    {Object.entries(data).map(([key, value]) => {
                        return value && value.Checked ? <th key={'key' + key} style={{ padding: 5 }}>{`${key}`}</th> : null
                    })}
                </tr>
            </thead>
            <tbody className="ant-table-tbody">
                {data && data.OL && data.OL.Checked ? <tr>
                    <td style={{ padding: 5 }}>Loại</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'ol_type';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{data[key][k] || ''}</td> : null
                    })}
                </tr> : null}
                <tr>
                    <td style={{ padding: 5 }}>Chiều dài Unilever (cm)</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Length of Unilever';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{data[key][k]}</td> : null
                    })}
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Chiều dài ngành hàng siêu thị (cm)</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Length of Total';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{data[key][k]}</td> : null
                    })}
                </tr>
                {isBigFormat && <tr>
                    <td style={{ padding: 5 }}>Chiều dài nhãn riêng (cm)</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Length of DOB';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{data[key][k]}</td> : null
                    })}
                </tr>}
                {isBigFormat && <tr>
                    <td style={{ padding: 5 }}>Chiều dài hàng nhập khẩu (cm)</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Length of Export';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{data[key][k]}</td> : null
                    })}
                </tr>}
                <tr>
                    <td style={{ padding: 5 }}>Note</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'ol_type';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{data[key][k]}</td> : null
                    })}
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Ghi chú</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Note';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{data[key][k]}</td> : null
                    })}
                </tr>
            </tbody>
        </table> : null}
        {/* {Object.entries(data).map(([key, value]) => {
            return (value.Checked ? <div key={key} style={{ border: '1px solid #ddd', padding: 5, marginBottom: 3 }}>
                <Row key={key} style={{ justifyContent: 'space-between' }}>
                    <Text><b>{`${key}`}</b></Text>
                    <Text>{value.Checked ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</Text>
                </Row>
                {Object.entries(value).map(([k, v]) => {
                    return (k != 'Checked' ? <Row key={k} style={{ justifyContent: 'space-between', paddingLeft: 10, borderTop: '1px solid #f5f5f5', paddingTop: 3, paddingBottom: 3 }}>
                        <Text><i>{(k == 'ol_type') ? 'OL type' : k}:</i></Text>
                        <Text>{v}</Text>
                    </Row> : null)
                })}
            </div> : null)
        })} */}
    </div>
}
export default React.memo(Sos);