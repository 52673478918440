import React from 'react';
import {
    Table, Typography
} from 'antd';
import NumberFormat from 'react-number-format';
import { red, grey } from '@ant-design/colors';
const AllCustomersTable = (props) => {
    const { Text } = Typography;
    const { datas } = props;
    const colWidth = 100;
    const allCategories = [
        { key: 'store_audit', title: '# Store audit' },
        { key: 'store_qc', title: '# Store QC' },
        { key: 'qc_total', title: '% Pass total' },
        { key: 'c2a', title: 'C2A' },
        { key: 'npd', title: 'NPD' },
        { key: 'olbig', title: 'OLBIG' },
        { key: 'osa', title: 'OSA' },
        { key: 'procms', title: 'PROCMS' },
        { key: 'prool', title: 'PROOL' },
        { key: 'rental', title: 'RENTAL' },
        { key: 'sos', title: 'SOS' },
    ];

    // const datas = JSON.parse(_datas);
    // console.log('datasdatas', datas);
    const defaultColumns = [
        {
            title: 'Customer/Store/Category',
            dataIndex: 'Customer-Store-Category',
            key: 'Customer-Store-Category',
            render: (value, record) => {
                return <Text style={{ textTransform: 'uppercase', fontSize: 12 }}>{record[0]}</Text>
            },
        },

    ];
    const [columns, setColumns] = React.useState(defaultColumns);
    const [expandColumns, setExpandColumns] = React.useState(defaultColumns);
    const [expandCategoryColumns, setExpandCategoryColumns] = React.useState(defaultColumns);
    React.useEffect(() => {
        let _columns = [...defaultColumns];
        let _expandColumns = [...defaultColumns];
        let _expandCategoryColumns = [...defaultColumns];
        if (allCategories && allCategories.length) {

            for (let i = 0; i < allCategories.length; i++) {
                _columns.push(
                    {
                        title: allCategories[i].title,
                        dataIndex: allCategories[i].key,
                        width: colWidth,
                        key: allCategories[i].key,
                        align: 'center',
                        render: (value, record) => {
                            // console.log('record[1].totals', record[1].totals[allCategories[i].key]);
                            let _num = 0;
                            let suffix = '';
                            if ((allCategories[i].key == 'store_audit') || (allCategories[i].key == 'store_qc')) {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key]) ? record[1].totals[allCategories[i].key].length : '';

                            } else if (allCategories[i].key == 'qc_total') {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                suffix = '%';
                            } else {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                suffix = '%';

                            }
                            // let detail = 50;
                            return _num ? <NumberFormat placeholder="0" value={_num} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={suffix} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                        },
                    }
                );
                _expandColumns.push(
                    {
                        title: allCategories[i],
                        dataIndex: allCategories[i],
                        key: allCategories[i],
                        width: colWidth,
                        align: 'center',
                        render: (value, record) => {
                            let _num = 0;
                            let suffix = '';
                            if ((allCategories[i].key == 'store_audit') || (allCategories[i].key == 'store_qc')) {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key]) ? record[1].totals[allCategories[i].key].length : '';

                            } else if (allCategories[i].key == 'qc_total') {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                suffix = '%';
                            } else {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                suffix = '%';

                            }
                            return _num ? <NumberFormat placeholder="0" value={_num} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={suffix} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                        },
                    }
                );
                _expandCategoryColumns.push(
                    {
                        title: allCategories[i],
                        dataIndex: allCategories[i],
                        key: allCategories[i],
                        width: colWidth,
                        align: 'center',
                        render: (value, record) => {
                            let _num = 0;
                            let suffix = '';
                            if ((allCategories[i].key == 'store_audit') || (allCategories[i].key == 'store_qc')) {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key]) ? record[1].totals[allCategories[i].key].length : '';

                            } else if (allCategories[i].key == 'qc_total') {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                suffix = '%';
                            } else {
                                _num = (record[1] && record[1][allCategories[i].key] && record[1][allCategories[i].key][0]) ? record[1][allCategories[i].key][1] * 100 / record[1][allCategories[i].key][0] : '';
                                suffix = '%';

                            }
                            return _num ? <NumberFormat placeholder="0" value={_num} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={suffix} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                        },
                    }
                );
            }
        }
        setColumns(_columns);
        setExpandColumns(_expandColumns);
        setExpandCategoryColumns(_expandCategoryColumns);

    }, []);



    return <Table
        dataSource={datas}
        rowKey={(record) => record[0]}
        pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
        columns={columns}
        scroll={{ x: (allCategories) ? allCategories.length * colWidth + 400 : 400 }}
        expandable={{
            expandedRowRender: (record) => {
                let key = record[0];
                let shopDatas = { ...record[1] };
                delete shopDatas.totals;

                return <Table
                    dataSource={Object.entries(shopDatas)}
                    rowKey={(record, index1) => {
                        return key + '__' + (index1) + record[0]
                    }}
                    showHeader={false}
                    pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                    columns={expandColumns}
                    expandable={{
                        expandedRowRender: (record) => {
                            let key = record[0];
                            let shopDatas = { ...record[1] };
                            delete shopDatas.totals;
                            return <Table
                                dataSource={Object.entries(shopDatas)}
                                rowKey={(record, index1) => {
                                    return key + '__' + (index1) + record[0]
                                }}
                                showHeader={false}
                                pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                                columns={expandCategoryColumns}

                            />
                        }
                    }}
                />
            }
        }}
    />
}
export default AllCustomersTable;