import React from 'react';
import {
    Table, Typography, Card
} from 'antd';
import NumberFormat from 'react-number-format';
import { red, grey } from '@ant-design/colors';
const Procms = (props) => {
    const { Text, Title } = Typography;
    const { datas } = props;
    return <Card
        title={"PROCMS"}
        bordered={false}
        style={{ width: '100%', marginTop: 15 }}>
        <Table
            dataSource={datas}
            style={{ marginTop: 10 }}
            rowKey={(record) => record[0]}
            pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
            columns={[
                {
                    title: 'Category/SKUs',
                    dataIndex: '0',
                    key: '0',
                    render: (value, record) => {
                        return <div>
                            <Text style={{ textTransform: 'uppercase' }}>TOTALS</Text>
                        </div>
                    },
                },
                {
                    title: <Text >Stores</Text>,
                    dataIndex: 'Stores',
                    width: '18vw',
                    key: 'Stores',
                    render: (value, record) => {
                        // let total = (record[1] && record[1].totals) ? record[1].totals[0] : '';
                        let total = record[1]?.shops?.length || 0;
                        return <div>
                            {total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'}
                        </div>
                    },
                },
                {
                    title: 'Fail (SKU)',
                    dataIndex: 'Fail-SKU',
                    width: '18vw',
                    key: 'Fail-SKU',
                    render: (value, record) => {
                        let total1 = (record[1] && record[1].totals) ? record[1].totals[0] : 0;
                        let total2 = (record[1] && record[1].totals) ? record[1].totals[1] : 0;
                        return <div>
                            {total1 ? <NumberFormat placeholder="0" value={total1 - total2} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'}
                        </div>
                    },
                },
                {
                    title: '% Fail',
                    dataIndex: 'pFailSKU',
                    width: '18vw',
                    key: 'pFailSKU',
                    render: (value, record) => {
                        let total1 = (record[1] && record[1].totals) ? record[1].totals[0] : 0;
                        let total2 = (record[1] && record[1].totals) ? record[1].totals[1] : 0;
                        return <div>
                            {total1 ? <NumberFormat placeholder="0" value={100 - total2 * 100 / total1} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'}
                        </div>
                    },
                },
            ]}
            expandable={{
                expandedRowRender: (record) => {
                    let key = record[0];
                    let shopDatas = record[1].detail;
                    delete shopDatas.total_shops;
                    delete shopDatas.uniq_shop_names;
                    return <Table
                        dataSource={Object.entries(shopDatas)}
                        rowKey={(record, index1) => {
                            return key + '__' + (index1) + record[0]
                        }}
                        showHeader={false}
                        pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                        columns={[
                            {
                                title: '0',
                                dataIndex: '0',
                                key: '0',
                                render: (value, record) => {
                                    return <div>
                                        <Text style={{ textTransform: 'uppercase' }}>{record[0]}</Text>
                                    </div>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase' }}>Stores</Text>,
                                dataIndex: 'Stores',
                                width: '18vw',
                                key: 'Stores',
                                render: (value, record) => {
                                    let total = record[1]?.shops?.length || 0;
                                    // let total = (record[1] && record[1].totals) ? record[1].totals[0] : '';
                                    return <div>
                                        {total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'}
                                    </div>
                                },
                            },
                            {
                                title: 'Fail-SKU',
                                dataIndex: 'Fail-SKU',
                                width: '18vw',
                                key: 'Fail-SKU',
                                render: (value, record) => {
                                    let total1 = (record[1] && record[1].totals) ? record[1].totals[0] : 0;
                                    let total2 = (record[1] && record[1].totals) ? record[1].totals[1] : 0;
                                    return <div>
                                        {total1 ? <NumberFormat placeholder="0" value={total1 - total2} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'}
                                    </div>
                                },
                            },
                            {
                                title: '% Fail SKU',
                                dataIndex: 'pFailSKU',
                                width: '18vw',
                                key: 'pFailSKU',
                                render: (value, record) => {
                                    let total1 = (record[1] && record[1].totals) ? record[1].totals[0] : 0;
                                    let total2 = (record[1] && record[1].totals) ? record[1].totals[1] : 0;
                                    return <div>
                                        {total1 ? <NumberFormat placeholder="0" value={100 - total2 * 100 / total1} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'}
                                    </div>
                                },
                            },
                        ]}
                        expandable={{
                            expandedRowRender: (record) => {
                                let key = record[0];
                                let categoryDatas = { ...record[1] };
                                delete categoryDatas.totals;
                                delete categoryDatas.shops;
                                return <Table
                                    dataSource={Object.entries(categoryDatas)}
                                    rowKey={(record, index1) => {
                                        return key + '__' + (index1) + record[0]
                                    }}
                                    showHeader={false}
                                    pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                                    columns={[
                                        {
                                            title: '0',
                                            dataIndex: '0',
                                            key: '0',
                                            render: (value, record) => {
                                                return <div>
                                                    <Text style={{ textTransform: 'uppercase' }}>{record[0]}</Text>
                                                </div>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase' }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            width: '18vw',
                                            key: 'Stores',
                                            render: (value, record) => {
                                                let total = (record[1] && record[1][3] && record[1][3].shops) ? record[1][3].shops.length : '0';
                                                return <div>
                                                    {total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'}
                                                </div>
                                            },
                                        },
                                        {
                                            title: 'Fail-SKU',
                                            dataIndex: 'Fail-SKU',
                                            width: '18vw',
                                            key: 'Fail-SKU',
                                            render: (value, record) => {
                                                let total1 = (record[1] && record[1].totals) ? record[1].totals[0] : 0;
                                                let total2 = (record[1] && record[1].totals) ? record[1].totals[1] : 0;
                                                return <div>
                                                    {total1 ? <NumberFormat placeholder="0" value={total1 - total2} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'}
                                                </div>
                                            },
                                        },
                                        {
                                            title: '% Fail SKU',
                                            dataIndex: 'pFailSKU',
                                            width: '18vw',
                                            key: 'pFailSKU',
                                            render: (value, record) => {
                                                let total1 = (record[1]) ? record[1][0] : 0;
                                                let total2 = (record[1]) ? record[1][1] : 0;
                                                return <div>
                                                    {total1 ? <NumberFormat placeholder="0" value={100 - total2 * 100 / total1} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'}
                                                </div>
                                            },
                                        },
                                    ]}


                                />
                            }
                        }}


                    />
                }
            }}
        />
    </Card>
}
export default Procms;