import React, { useState } from 'react';

import {
    Row,
    Col,
    Card,
    Table,
    Typography,
    Image,
    Space,
    Form,
    Button,
    DatePicker,
    Tabs,
    Switch,
    List,
    Tree,
    Input,
    Collapse,
    Alert

} from 'antd';
import { StarFilled } from '@ant-design/icons';

// import ListItem from './Item';
import { green, grey } from '@ant-design/colors';
import NumberFormat from 'react-number-format';
// import ReasonList from './ReasonList';
function IndexTable(props) {
    const { Text } = Typography;
    const [datas, setDatas] = useState([]);
    const [showReview, setShowReview] = useState(true);
    const [showMsg, setShowMsg] = useState(0);
    let timeout = null;
    const [widthOfTable, setWidthOfTable] = useState({
        'C2A': (props.isReview) ? 1770 : 1070,
        'NPD': (props.isReview) ? 1770 : 1070,
        'OLBIG': (props.isReview) ? 2900 : 2200,
        'OSA': (props.isReview) ? 1470 : 770,
        'PROCMS': (props.isReview) ? 1670 : 970,
        'PROOL': (props.isReview) ? 1700 : 1000,
        'RENTAL': (props.format_type == 'BIG FORMAT') ? ((props.isReview) ? 1970 : 1270) : ((props.isReview) ? 1700 : 700),
        'SOS': ((props.isReview) ? 1700 : 700),
    });
    const positions = [
        'MS',
        'SS',
        'GE',
        'HGR',
        'GE-C',
        'PI',
        'ALT',
        'COC',
        'KOC',
        'SB',
        'SK',
        'SSHF',
        'SHF',
        'ICC',
        'SSBF',
        'CSM',

    ];
    // const handleChange = (pagination, filters, sorter) => {
    //     setFilteredInfo(filters);
    // };
    React.useEffect(() => {
        if (props.checklistType == 'SOS') {
            let _datas = [...props.datas];
            if (_datas.length) {
                let _data = {
                    custom_attributes: {
                        stock_name: 'SUM',
                        isLast: true
                    },
                    data: {
                        position: [],
                        totalU: 0,
                        totalT: 0,
                        totalD: 0,
                        totalE: 0,
                    }
                }
                for (let i = 0; i < _datas.length; i++) {
                    if (_datas[i].data.MS && _datas[i].data.MS.Checked) {
                        if (!_data.data.position.includes('MS')) {
                            _data.data.position.push('MS')
                        };
                        _data.data.totalU += parseFloat(_datas[i].data.MS['Length of Unilever'] || 0);
                        _data.data.totalT = parseFloat(_datas[i].data.MS['Length of Total'] || 0);
                        _data.data.totalD = parseFloat(_datas[i].data.MS['Length of DOB'] || 0);
                        _data.data.totalE = parseFloat(_datas[i].data.MS['Length of Export'] || 0);
                    }
                    if (_datas[i].data.OL && _datas[i].data.OL.Checked) {
                        if (!_data.data.position.includes('OL')) {
                            _data.data.position.push('OL')
                        };
                        _data.data.totalU += parseFloat(_datas[i].data.OL['Length of Unilever'] || 0);
                        _data.data.totalT = parseFloat(_datas[i].data.OL['Length of Total'] || 0);
                        _data.data.totalD = parseFloat(_datas[i].data.OL['Length of DOB'] || 0);
                        _data.data.totalE = parseFloat(_datas[i].data.OL['Length of Export'] || 0);
                    }
                }
                _datas.push(_data);
            }
            setDatas(_datas);
        } else {
            setDatas(props.datas);
        }

    }, [props.datas, props.checklistType]);
    const onChangeReviewPass = (item, checked) => {
        let _item = { ...item, data: { ...item.data, review_pass: checked } }
        let _datas = [...datas];
        _datas[_datas.findIndex(el => el.id === _item.id)] = _item;
        setDatas(_datas);
        // setItem({ ...item, data: { ...item.data, review_pass: checked } });

    };
    const onChangeReviewNode = (item, e) => {
        let _item = {
            ...item,
            data: {
                ...item.data,
                review_note: e.target.value,
            },
        }
        let _datas = [...datas];
        _datas[_datas.findIndex(el => el.id === _item.id)] = _item;
        setDatas(_datas);
    };
    const onChangeSrResponse = (item, e) => {
        let _item = {
            ...item,
            data: {
                ...item.data,
                sr_response: e.target.value,
            },
        }
        let _datas = [...datas];
        _datas[_datas.findIndex(el => el.id === _item.id)] = _item;
        setDatas(_datas);
    };
    const onChangeFinalResult = (item, e) => {
        let _item = {
            ...item,
            data: {
                ...item.data,
                final_result: e.target.value,
            },
        };
        let _datas = [...datas];
        _datas[_datas.findIndex(el => el.id === _item.id)] = _item;
        setDatas(_datas);
    };
    const onChangeFinalResultSOS = (item, fieldName, value) => {
        let _item = {
            ...item,
            data: {
                ...item.data,
                final_result: { ...item.data.final_result, [fieldName]: value },
            },
        };

        let _datas = [...datas];
        _datas[_datas.findIndex(el => el.id === _item.id)] = _item;
        setDatas(_datas);
    };
    const onChangePredefinedQcNodeOlbig = (item, position, value) => {
        let _item = {
            ...item,
            data: {
                ...item.data,
                predefined_qc_note: { ...item.data.predefined_qc_note, [position]: value },
            },
        };
        let _datas = [...datas];
        _datas[_datas.findIndex(el => el.id === _item.id)] = _item;
        setDatas(_datas);
    };
    const onChangePredefinedQcNode = (item, value) => {
        let _item = {
            ...item,
            data: {
                ...item.data,
                predefined_qc_note: value,
            },
        };
        let _datas = [...datas];
        _datas[_datas.findIndex(el => el.id === _item.id)] = _item;
        setDatas(_datas);
    };
    const submit = async (item) => {
        setShowMsg(item.id);
        await props.onSubmit(item);

        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => { setShowMsg(0) }, 3000);
    };
    return (datas && datas.length ? <div style={{ overflowX: 'auto', maxWidth: 'calc(70vw - 290px)' }}><Table
        // onChange={handleChange}
        // columns={columns}
        bordered={true}
        dataSource={datas}
        rowKey={(record) => record.id}
        scroll={{ x: widthOfTable[props.checklistType] || 700 }}
        size='small'
        pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
    >

        <Table.Column
            title="Name"
            dataIndex={'stock_name'}
            key={'stock_name'}
            width={250}
            render={(v, record) => {
                return <div style={{ fontSize: 12, color: (props.isReview && (!!record.data.qc_user_name)) ? green[6] : grey[6] }}
                    title={(props.isReview && (!!record.data.qc_user_name)) ? 'Đã qc' : ''}
                >
                    {(props.isReview && (!!record.data.qc_user_name)) ? <StarFilled /> : null} {record.custom_attributes.stock_name}

                </div>
            }}
        />


        {props.checklistType == 'C2A' ? <>
            {/* chua xong */}
            <Table.Column title={<span style={{ fontSize: 10 }}>Tên nhãn hàng</span>} dataIndex="c2a-brand" key="c2a-brand"
                align="center"
                render={(v, record) => {
                    // console.log('recordc2a', record);
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.brand || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>C2A_ID</span>} dataIndex="c2a-c2a_id" key="c2a-c2a_id"
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.stock_id || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Call to Action</span>} dataIndex="c2a-c2a" key="c2a-c2a"
                width={200}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.c2a || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>MS</span>} dataIndex="c2a-ms" key="c2a-ms"
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.location == 'MS' ? '1' : ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>GE</span>} dataIndex="c2a-ge" key="c2a-ge"
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.location == 'GE' ? '1' : ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Plugin</span>} dataIndex="c2a-plugin" key="c2a-plugin"
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.location == 'PI' ? '1' : ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Kết quả</span>} dataIndex="c2a-actual_c2a" key="c2a-actual_c2a"
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.actual_c2a || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Ghi chú</span>} dataIndex="c2a-note" key="c2a-note"
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.data.note || ''}</Text>);
                }}
            />
        </> : null}
        {props.checklistType == 'NPD' ? <>
            <Table.Column title={<span style={{ fontSize: 10 }}>Item code</span>} dataIndex="npd-product_id" key="npd-product_id"
                width={70}
                align="center"
                render={(v, record) => {
                    // console.log('recordnpd', record);
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.product_id || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Target</span>} dataIndex="npd-quantity" key="npd-quantity"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.quantity || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Stock</span>} dataIndex="npd-available" key="npd-available"
                width={70}
                align="center"
                render={(v, record) => {
                    let Available = record.data.Available || 0;
                    return (<Text style={{ fontSize: 12 }}>{Available}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Void</span>} dataIndex="npd-void" key="npd-void"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.void}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>NPD</span>} dataIndex="npd-npd" key="npd-npd"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.actual_npd}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Ghi chú</span>} dataIndex="npd-note" key="npd-note"
                width={70}
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>
                        {record.data.MS && record.data.MS.Checked ? record.data.MS.Note : ''}{"\n"}
                        {record.data.OL && record.data.OL.Checked ? record.data.OL.Note : ''}
                    </Text>);
                }}
            />

        </> : null}
        {props.checklistType == 'OLBIG' ? <>
            {positions.map((position, index) => {
                let u = null;
                let s = null;
                return (position != 'MS' ? <Table.ColumnGroup title={position} key={'position' + index}>
                    <Table.Column title={<span style={{ fontSize: 10 }}>SL U</span>} dataIndex={'olbig-u' + position + '-' + index} key={'olbig-u' + position + '-' + index}
                        width={40}
                        align='center'
                        render={(v, record) => {
                            // console.log('recordol', record);
                            u = record.data[position] && record.data[position][position + '-U'] ? record.data[position][position + '-U'] : null;
                            return (<Text style={{ fontSize: 12 }}>{u || '-'}</Text>);
                        }}
                    />
                    <Table.Column title={<span style={{ fontSize: 10 }}>SL Tổng</span>} dataIndex={'olbig-s' + position + '-' + index} key={'olbig-s' + position + '-' + index}
                        width={40}
                        align='center'
                        render={(v, record) => {
                            s = record.data[position] && record.data[position][position + '-S'] ? record.data[position][position + '-S'] : null;
                            return (<Text style={{ fontSize: 12 }}>{s || '-'}</Text>);
                        }}
                    />
                    <Table.Column title={<span style={{ fontSize: 10 }}>% OL</span>} dataIndex={'olbig-p' + position + '-' + index} key={'olbig-p' + position + '-' + index}
                        width={40}
                        align='center'
                        render={(v, record) => {
                            return (<Text style={{ fontSize: 12 }}>{(u !== null && s !== null && s !== 0) ? Math.round(10000 * u / s) / 100 : '-'}</Text>);
                        }}
                    />
                </Table.ColumnGroup> : null)
            })}
            {/* {Object.entries(data).length?Object.entries(data).map(([key, value])=> {
            return<Table.ColumnGroup title="GE">
                <Table.Column title={<span style={{ fontSize: 10 }}>Stock</span>} dataIndex="osa-ms-available" key="osa-ms-available"
                    width={40}
                    render={(v, record) => {
                        return (<Text style={{ fontSize: 12 }}>{record.data.MS && record.data.MS.Checked ? record.data.MS.Available : '-'}</Text>);
                    }}
                />
                <Table.Column title={<span style={{ fontSize: 10 }}>Void</span>} dataIndex="osa-ms-void" key="osa-ms-void"
                    width={40}
                    render={(v, record) => {
                        return (<Text style={{ fontSize: 12 }}>{record.data.MS && record.data.MS.Checked ? <Button size='small' type={record.data.MS.Void == 'Y' ? 'primary' : 'danger'}>{record.data.MS.Void}</Button> : '-'}</Text>);
                    }}
                />

                <Table.Column title={<span style={{ fontSize: 10 }}>Ghi chú</span>} dataIndex="osa-ms-note" key="osa-ms-note"
                    width={70}
                    render={(v, record) => {
                        return (<Text style={{ fontSize: 12 }}>{record.data.MS && record.data.MS.Checked ? record.data.MS.Note : '-'}</Text>);
                    }}
                />

            </Table.ColumnGroup>
        }):null} */}
        </> : null}
        {props.checklistType == 'OSA' ? <>
            <Table.Column title={<span style={{ fontSize: 10 }}>Item code</span>} dataIndex="osa-product_id" key="osa-product_id"
                width={70}
                align="center"
                render={(v, record) => {

                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.product_id || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Target</span>} dataIndex="osa-quantity" key="osa-quantity"
                width={40}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.quantity || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Stock</span>} dataIndex="osa-available" key="osa-available"
                width={40}
                align="center"
                render={(v, record) => {
                    let Available = record.data.Available || 0;
                    return (<Text style={{ fontSize: 12 }}>{Available}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Void</span>} dataIndex="osa-void" key="osa-void"
                width={40}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.void || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>OSA</span>} dataIndex="osa-osa" key="osa-osa"
                width={40}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.actual_osa || ''}</Text>);
                }}
            />


            <Table.Column title={<span style={{ fontSize: 10 }}>Ghi chú</span>} dataIndex="osa-note" key="osa-note"
                width={70}
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>
                        {record.data.MS && record.data.MS.Checked ? record.data.MS.Note : ''}{"\n"}
                        {record.data.OL && record.data.OL.Checked ? record.data.OL.Note : ''}
                    </Text>);
                }}
            />

        </> : null}
        {props.checklistType == 'PROCMS' ? <>
            <Table.Column title={<span style={{ fontSize: 10 }}>Item code</span>} dataIndex="procms-product_id" key="procms-product_id"
                width={70}
                align="center"
                render={(v, record) => {
                    // console.log('recordcsm', record);
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.product_id || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Mechanic</span>} dataIndex="procms-mechanic" key="procms-mechanic"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.mechanic}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Vị trí</span>} dataIndex="procms-location" key="procms-location"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.location}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Hiện diện SP</span>} dataIndex="procms-presence" key="procms-presence"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.presence}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Nội dung KM</span>} dataIndex="procms-content" key="procms-content"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.content}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Thông báo KM</span>} dataIndex="procms-notice" key="procms-notice"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.notice}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Kết quả</span>} dataIndex="procms-actual_pro" key="procms-actual_pro"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.actual_pro}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Ghi chú</span>} dataIndex="procms-Note" key="procms-Note"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.data.Note}</Text>);
                }}
            />
        </> : null}
        {props.checklistType == 'PROOL' ? <>
            <Table.Column title={<span style={{ fontSize: 10 }}>Item code</span>} dataIndex="prool-product_id" key="prool-product_id"
                width={70}
                align="center"
                render={(v, record) => {
                    // console.log('record', record);
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.product_id || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Mechanic</span>} dataIndex="prool-mechanic" key="prool-mechanic"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.mechanic}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Vị trí</span>} dataIndex="prool-location" key="prool-location"
                width={70}
                align="center"
                render={(v, record) => {
                    let _data = { ...record.data }
                    delete _data.qc_note;
                    delete _data.pass;
                    delete _data.predefined_qc_note;
                    return (Object.entries(_data).length ? Object.entries(_data).map(([key, value], index) => {
                        return (value && value.Checked ? <div key={'index' + index}>
                            {key}
                        </div> : null)
                    }) : null)
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Hiện diện SP</span>} dataIndex="prool-Presence" key="prool-Presence"
                width={70}
                align="center"
                render={(v, record) => {
                    let _data = { ...record.data }
                    delete _data.qc_note;
                    delete _data.pass;
                    delete _data.predefined_qc_note;
                    return (Object.entries(_data).length ? Object.entries(_data).map(([key, value], index) => {
                        return (value && value.Checked ? <div key={'index' + index}>
                            <Text style={{ fontSize: 12 }}>{record.custom_attributes[key].presence}</Text>
                        </div> : null)
                    }) : null)
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Nội dung KM</span>} dataIndex="prool-content" key="prool-content"
                width={70}
                align="center"
                render={(v, record) => {
                    let _data = { ...record.data }
                    delete _data.qc_note;
                    delete _data.pass;
                    delete _data.predefined_qc_note;
                    return (Object.entries(_data).length ? Object.entries(_data).map(([key, value], index) => {
                        return (value && value.Checked ? <div key={'index' + index}>
                            <Text style={{ fontSize: 12 }}>{record.custom_attributes[key].content}</Text>
                        </div> : null)
                    }) : null)
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Thông báo KM</span>} dataIndex="prool-notice" key="prool-notice"
                width={70}
                align="center"
                render={(v, record) => {
                    let _data = { ...record.data }
                    delete _data.qc_note;
                    delete _data.pass;
                    delete _data.predefined_qc_note;
                    return (Object.entries(_data).length ? Object.entries(_data).map(([key, value], index) => {
                        return (value && value.Checked ? <div key={'index' + index}>
                            <Text style={{ fontSize: 12 }}>{record.custom_attributes[key].notice}</Text>
                        </div> : null)
                    }) : null)
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Kết quả</span>} dataIndex="prool-actual_pro" key="prool-actual_pro"
                width={70}
                align="center"
                render={(v, record) => {
                    let _data = { ...record.data }
                    delete _data.qc_note;
                    delete _data.pass;
                    delete _data.predefined_qc_note;
                    return (Object.entries(_data).length ? Object.entries(_data).map(([key, value], index) => {
                        return (value && value.Checked ? <div key={'index' + index}>
                            <Text style={{ fontSize: 12 }}>{record.custom_attributes[key].actual_pro}</Text>
                        </div> : null)
                    }) : null)
                }}
            />


        </> : null}
        {props.checklistType == 'RENTAL' ? <>
            {(props.format_type == 'BIG FORMAT') && <Table.Column title={<span style={{ fontSize: 10 }}>Rental ID</span>} dataIndex="rental-rentalid" key="rental-rentalid"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.stock_id || ''}</Text>);
                }}
            />}
            {(props.format_type == 'BIG FORMAT') && <Table.Column title={<span style={{ fontSize: 10 }}>Rental</span>} dataIndex="rental-Rental" key="rental-Rental"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.rental_type_vn || ''}</Text>);
                }}
            />}
            {(props.format_type == 'BIG FORMAT') && <Table.Column title={<span style={{ fontSize: 10 }}>PNG code</span>} dataIndex="rental-pngcode" key="rental-pngcode"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.png_code || ''}</Text>);
                }}
            />}
            <Table.Column title={<span style={{ fontSize: 10 }}>
                Hiện diện {'&'} đúng vị trí
            </span>} dataIndex="rental-presence" key="rental-presence"
                width={70}
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.presence || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Trưng bày đúng ngành hàng</span>} dataIndex="rental-location" key="rental-location"
                width={70}
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.location || ''}</Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Bảng giá</span>} dataIndex="rental-price" key="rental-price"
                width={70}
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.price || ''}</Text>);
                }}
            />
            {(props.format_type == 'BIG FORMAT') && <Table.Column title={<span style={{ fontSize: 10 }}>Đúng PNG/Đủ mặt</span>} dataIndex="rental-planogram" key="rental-planogram"
                width={70}
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.planogram || ''}</Text>);
                }}
            />}
            <Table.Column title={<span style={{ fontSize: 10 }}>Kết quả</span>} dataIndex="rental-result" key="rental-result"
                width={70}
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.custom_attributes.actual_rental || ''}</Text>);
                }}
            />
            {(props.format_type == 'BIG FORMAT') && <Table.Column title={<span style={{ fontSize: 10 }}>Ghi chú</span>} dataIndex="rental-note" key="rental-note"
                width={70}
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>{record.data.Note}</Text>);
                }}
            />}
        </> : null}
        {props.checklistType == 'SOS' ? <>

            <Table.Column title={<span style={{ fontSize: 10 }}>Vị trí</span>} dataIndex="sos-u" key="sos-u"
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>
                        {record.custom_attributes.isLast ? <Text style={{ fontSize: 12 }}>{record.data.position.join(', ')}</Text> : <Text style={{ fontSize: 12 }}>
                            {record.data.MS && record.data.MS.Checked ? 'MS' : ''}
                            {record.data.OL && record.data.OL.Checked ? (record.data.MS && record.data.MS.Checked ? "\nOL" : 'OL') : ''}
                        </Text>}
                    </Text>);
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Chiều dài U</span>} dataIndex="sos-u" key="sos-u"
                align="center"
                render={(v, record) => {
                    if (record.custom_attributes.isLast) {
                        return (props.isReview ? <NumberFormat value={record.data.totalU} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>cm</sup></Text>} /> : <NumberFormat value={record.data.totalU / 100} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>m</sup></Text>} />)
                    } else {
                        let total = record.data.MS && record.data.MS.Checked ? parseFloat(record.data.MS['Length of Unilever'] || 0) : 0;
                        total += record.data.OL && record.data.OL.Checked ? parseFloat(record.data.OL['Length of Unilever'] || 0) : 0;
                        return (props.isReview ? <NumberFormat value={total || 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>cm</sup></Text>} /> : <NumberFormat value={total / 100 || 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>m</sup></Text>} />)
                    }
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Chiều dài ngành hàng</span>} dataIndex="sos-t" key="sos-t"
                align="center"
                render={(v, record) => {
                    if (record.custom_attributes.isLast) {
                        return (props.isReview ? <NumberFormat value={record.data.totalT} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>cm</sup></Text>} /> : <NumberFormat value={record.data.totalT / 100} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>m</sup></Text>} />)
                    } else {
                        let total = record.data.MS && record.data.MS.Checked ? parseFloat(record.data.MS['Length of Total'] || 0) : 0;
                        total += record.data.OL && record.data.OL.Checked ? parseFloat(record.data.OL['Length of Total'] || 0) : 0;
                        return (props.isReview ? <NumberFormat value={total || 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>cm</sup></Text>} /> : <NumberFormat value={total / 100 || 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>m</sup></Text>} />)
                    }
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Chiều dài nhãn riêng</span>} dataIndex="sos-d" key="sos-d"
                align="center"
                render={(v, record) => {
                    if (record.custom_attributes.isLast) {
                        return (props.isReview ? <NumberFormat value={record.data.totalD} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>cm</sup></Text>} /> : <NumberFormat value={record.data.totalD / 100} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>m</sup></Text>} />)
                    } else {
                        let total = record.data.MS && record.data.MS.Checked ? parseFloat(record.data.MS['Length of DOB'] || 0) : 0;
                        total += record.data.OL && record.data.OL.Checked ? parseFloat(record.data.OL['Length of DOB'] || 0) : 0;
                        return (props.isReview ? <NumberFormat value={total || 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value} <sup>cm</sup></Text>} /> : <NumberFormat value={total / 100 || 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value} <sup>m</sup></Text>} />)
                    }
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Chiều dài hàng nhập khẩu</span>} dataIndex="sos-e" key="sos-e"
                align="center"
                render={(v, record) => {
                    if (record.custom_attributes.isLast) {
                        return (props.isReview ? <NumberFormat value={record.data.totalE} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>cm</sup></Text>} /> : <NumberFormat value={record.data.totalE / 100} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>m</sup></Text>} />)
                    } else {
                        let total = record.data.MS && record.data.MS.Checked ? parseFloat(record.data.MS['Length of Export'] || 0) : 0;
                        total += record.data.OL && record.data.OL.Checked ? parseFloat(record.data.OL['Length of Export'] || 0) : 0;
                        return (props.isReview ? <NumberFormat value={total || 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>cm</sup></Text>} /> : <NumberFormat value={total / 100 || 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>m</sup></Text>} />)
                    }
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>% SOS</span>} dataIndex="sos-percent" key="sos-percent"
                align="center"
                render={(v, record) => {
                    if (record.custom_attributes.isLast) {
                        return <NumberFormat value={(record.data.totalT) ? record.data.totalU * 100 / record.data.totalT : 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}<sup>%</sup></Text>} />
                    } else {
                        return <Text style={{ fontSize: 12 }}>-</Text>
                    }
                }}
            />
            <Table.Column title={<span style={{ fontSize: 10 }}>Ghi chú</span>} dataIndex="sos-n" key="sos-n"
                align="center"
                render={(v, record) => {
                    return (<Text style={{ fontSize: 12 }}>
                        {record.data.MS && record.data.MS.Checked ? record.data.MS['Note'] : ''}{"\n"}
                        {record.data.OL && record.data.OL.Checked ? record.data.OL['Note'] : ''}
                    </Text>);
                }}
            />

        </> : null}
        {!!props.isReview && <Table.Column width={250} title={<span style={{}}>QC Info</span>} dataIndex="qcinfo" key="qcinfo"
            align="center"
            render={(v, record) => {
                // console.log('recordc2a', record);
                return (<div>
                    <Row style={{}}>
                        <Text>QC Note: &nbsp;</Text>
                        {props.checklistType !== 'OLBIG' ? <Text> {record.data && record.data.qc_note ? record.data.qc_note : '-'}</Text> : <div style={{ fontSize: 10 }}>
                            <div>
                                {record.data && record.data.qc_note && Object.entries(record.data.qc_note).length ? Object.entries(record.data.qc_note).map(([key, value]) => {
                                    return <div key={key}><b>{`${key}: `}</b>{` ${value}`}</div>
                                }) : '-'}
                            </div>
                        </div>}
                    </Row>
                    {record.data.qc_user_name ? <Row style={{ marginTop: 5 }}>
                        <Text>QC user: &nbsp;</Text>
                        <Text style={{ fontWeight: 'bold' }}>{record.data.qc_user_name}</Text>
                    </Row> : null}
                    {(!record.data.pass && record.data.predefined_qc_note ? <Row style={{ marginTop: 5 }}>
                        <Text>Lý do reject: &nbsp;</Text>
                        <Text>{record.data.predefined_qc_note}</Text>
                    </Row> : null)}
                </div>);
            }}
        />}
        {!!props.isReview && <Table.Column width={100} title={<span style={{ fontSize: 12 }}>Reviewer</span>} dataIndex="reviewer" key="reviewer"
            align="center"
            render={(v, record) => {
                // console.log('recordc2a', record);
                return (<Text style={{ fontSize: 10 }}>{record.data.review_user_name || '-'}</Text>);
            }}
        />}
        {!!props.isReview && <Table.Column width={250} title={<span style={{ fontSize: 12 }}>Review

        </span>} dataIndex="review" key="review"
            align="center"
            render={(v, record) => {
                return (record.custom_attributes.stock_name == 'SUM' ? <div>-</div> : <div>
                    <Row style={{ justifyContent: 'space-between', paddingTop: 1, marginBottom: 5 }}>
                        <Switch
                            checkedChildren="Passed"
                            disabled={!showReview}
                            unCheckedChildren="Chưa pass"
                            checked={!!record.data.review_pass}
                            onChange={(checked) => {
                                onChangeReviewPass(record, checked)
                            }}
                        />
                    </Row>
                    <Row style={{ justifyContent: 'space-between', paddingTop: 1, marginBottom: 5 }}>
                        <Text>SR</Text>

                        <Input
                            style={{ width: '100%' }}
                            value={record.data.sr_response}
                            placeholder="SR"
                            onChange={(e) => {
                                onChangeSrResponse(record, e)
                            }}
                        />
                    </Row>
                    <Row style={{ justifyContent: 'space-between', paddingTop: 1, marginBottom: 5 }}>
                        <Text>Review note</Text>

                        <Input
                            style={{ width: '100%' }}
                            value={record.data.review_note}
                            placeholder="Review note"
                            onChange={(e) => {
                                onChangeReviewNode(record, e)
                            }}
                        />
                    </Row>
                    <Row style={{ paddingTop: 1, marginBottom: 5 }}>
                        {props.checklistType !== 'SOS' ?
                            <div style={{ width: '100%' }}>
                                <div style={{ width: '100%', textAlign: 'left' }}>Final Result</div>
                                <Input
                                    style={{ width: '100%' }}
                                    value={record.data.final_result}
                                    placeholder="Final Result"
                                    onChange={(e) => {
                                        onChangeFinalResult(record, e)
                                    }}
                                />
                            </div> : <div style={{ width: '100%' }}>
                                <div style={{ width: '100%', textAlign: 'left' }}>Length of Total</div>
                                <NumberFormat className="ant-input" style={{ marginBottom: 5 }} placeholder="Final Result - Length of Total" value={record.data.final_result && record.data.final_result['Length of Total'] ? record.data.final_result['Length of Total'] : ''} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                                    onValueChange={(values) => {
                                        if (values && values.floatValue) {
                                            onChangeFinalResultSOS(record, 'Length of Total', values.floatValue);
                                        } else {
                                            onChangeFinalResultSOS(record, 'Length of Total', '');
                                        }
                                    }}
                                />
                                <div style={{ width: '100%', textAlign: 'left' }}>Length of Unilever</div>
                                <NumberFormat className="ant-input" style={{ marginBottom: 5 }} placeholder="Final Result - Length of Unilever" value={record.data.final_result && record.data.final_result['Length of Unilever'] ? record.data.final_result['Length of Unilever'] : ''} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                                    onValueChange={(values) => {
                                        if (values && values.floatValue) {
                                            onChangeFinalResultSOS(record, 'Length of Unilever', values.floatValue);
                                        } else {
                                            onChangeFinalResultSOS(record, 'Length of Unilever', '');
                                        }
                                    }}
                                />
                                <div style={{ width: '100%', textAlign: 'left' }}>Length of Export</div>
                                <NumberFormat className="ant-input" style={{ marginBottom: 5 }} placeholder="Final Result - Length of Export" value={record.data.final_result && record.data.final_result['Length of Export'] ? record.data.final_result['Length of Export'] : ''} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                                    onValueChange={(values) => {
                                        if (values && values.floatValue) {
                                            onChangeFinalResultSOS(record, 'Length of Export', values.floatValue);
                                        } else {
                                            onChangeFinalResultSOS(record, 'Length of Export', '');
                                        }
                                    }}
                                />
                                <div style={{ width: '100%', textAlign: 'left' }}>Length of DOB</div>
                                <NumberFormat className="ant-input" style={{ marginBottom: 5 }} placeholder="Final Result - Length of DOB" value={record.data.final_result && record.data.final_result['Length of DOB'] ? record.data.final_result['Length of DOB'] : ''} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                                    onValueChange={(values) => {
                                        if (values && values.floatValue) {
                                            onChangeFinalResultSOS(record, 'Length of DOB', values.floatValue);
                                        } else {
                                            onChangeFinalResultSOS(record, 'Length of DOB', '');
                                        }
                                    }}
                                />
                                <div style={{ width: '100%', textAlign: 'left' }}>SOS</div>
                                <NumberFormat className="ant-input" style={{ marginBottom: 5 }} placeholder="Final Result - SOS" value={record.data.final_result && record.data.final_result['SOS'] ? record.data.final_result['SOS'] : ''} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                                    onValueChange={(values) => {
                                        if (values && values.floatValue) {
                                            onChangeFinalResultSOS(record, 'SOS', values.floatValue);
                                        } else {
                                            onChangeFinalResultSOS(record, 'SOS', '');
                                        }
                                    }}
                                />
                            </div>}
                        <div>
                            {showMsg && showMsg == record.id ? <Alert style={{ marginTop: 5 }}
                                message="Submitted"
                                type="success"
                                showIcon
                            /> : null}
                            <Button type="primary" style={{ marginTop: 5 }}
                                disabled={!!timeout}
                                onClick={() => {
                                    submit(record)
                                }}>Submit Review</Button>
                        </div>
                    </Row>
                </div>);
            }}
        />}
    </Table >
    </div > : null)
}
export default React.memo(IndexTable);