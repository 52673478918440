import React from 'react';
import {
    Row,
    Col,
    Card,
    Table,
    Typography,
    Image,
    Space,
    Form,
    Button,
    DatePicker,
    Tabs,
    Switch,
    Input,
    Alert
} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
function Npd(props) {
    const item = props.item || {};
    let data = { ...item.data };
    delete data.pass;
    delete data.qc_note;
    delete data.predefined_qc_note;
    const { Text } = Typography;
    return <div key={'item' + item.id}>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Tồn kho tối thiểu:</b></Text>
            <Text>
                {item.quantity || ''}
            </Text>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: 1 }}>
            <Text><b>Barcode:</b></Text>
            <Text>
                {item.custom_attributes.barcode || ''}
            </Text>
        </Row>
        {Object.entries(data).length ? <table style={{ marginBottom: 5 }}>
            {/* <thead className="ant-table-thead">
                <tr>
                    <th style={{ padding: 5 }}></th>
                    {Object.entries(data).map(([key, value]) => {
                        return value && value.Checked ? <th key={'key' + key} style={{ padding: 5 }}>{`${key}`}</th> : null
                    })}
                </tr>
            </thead> */}
            <tbody className="ant-table-tbody">
                <tr>
                    <td style={{ padding: 5 }}>Vị trí chấm</td>
                    <td style={{ padding: 5 }}>{data.Location || ''}</td>
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Tồn kho</td>
                    <td style={{ padding: 5 }}>{data.Available || ''}</td>
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Có bảng giá và đúng sản phẩm</td>
                    <td style={{ padding: 5 }}>{data.Void || ''}</td>
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Cửa hàng đồng ý audit điều chỉnh bảng giá</td>
                    <td style={{ padding: 5 }}>{data['Allow Price Tag Adjustment'] || ''}</td>
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Bảng giá đạt vị trí sau khi audit điều chỉnh</td>
                    <td style={{ padding: 5 }}>{data['Price Tag Passes After Adjustment'] || ''}</td>
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Ghi chú</td>
                    <td style={{ padding: 5 }}>{data.Note || ''}</td>
                </tr>
                {/* <tr>
                    <td style={{ padding: 5 }}>Tồn kho</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Available';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{data[key][k]}</td> : null
                    })}
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Có bảng giá và đúng sản phẩm</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Void';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{<Button size='small' type={data[key][k] == 'Y' ? 'primary' : 'danger'}>{data[key][k]}</Button>}</td> : null
                    })}
                </tr> */}
                {/* <tr>
                    <td style={{ padding: 5 }}>Ghi chú</td>
                    {Object.entries(data).map(([key, value]) => {
                        let k = 'Note';
                        return value && value.Checked ? <td key={'key' + key + k} style={{ padding: 5 }}>{data[key][k]}</td> : null
                    })}
                </tr> */}
            </tbody>
        </table> : null}
        {/* {Object.entries(data).map(([key, value]) => {
            return (value.Checked ? <div key={key} style={{ border: '1px solid #eaeaea', padding: 5, marginBottom: 3 }}>
                <Row key={key} style={{ justifyContent: 'space-between' }}>
                    <Text><b>{`${key}`}</b></Text>
                    <Text>{value.Checked ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</Text>
                </Row>
                {Object.entries(value).map(([k, v]) => {
                    return (k != 'Checked' ? <Row key={k} style={{ justifyContent: 'space-between', paddingLeft: 10, borderTop: '1px solid #f5f5f5', paddingTop: 3, paddingBottom: 3 }}>
                        <Text><i>{k}:</i></Text>
                        {k == 'Void' ? <Button type={v == 'Y' ? 'primary' : 'danger'}>{v}</Button> : <Text>{v}</Text>}
                    </Row> : null)
                })}
            </div> : null)
        })} */}
    </div>
}
export default React.memo(Npd);