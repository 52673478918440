import React from 'react';
import {
    Table, Typography
} from 'antd';
import NumberFormat from 'react-number-format';
import { red, grey } from '@ant-design/colors';
const OsaNpd = (props) => {
    const { Text } = Typography;
    const { datas, filterDatas } = props;
    const columns = [
        {
            title: 'Category/SKUs',
            dataIndex: 'Category',
            key: 'Category',
            fixed: 'left',
            width: 353,
            render: (value, record) => {
                return <div>
                    <Text style={{ textTransform: 'uppercase' }}>{record[0]}</Text>
                </div>
            },
        },
        {
            title: <Text style={{ fontSize: 14 }}>Stores</Text>,
            dataIndex: 'Stores',
            key: 'Stores',
            width: 180,
            render: (value, record) => {
                // console.log('record[1].shopsrecord[1].shops', record[1].shops);
                // console.log('_customerDatas[ckltype].shops', record);
                // let _k = `total_${record[0].toLowerCase()}_counts`;
                let total = (record[1]?.shops?.length) || 0;

                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'
            },
        },
        {
            title: '% Pass',
            dataIndex: 'Pass',
            key: 'Pass',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total.total_oos_counts;
                let _show = ((_detail[0]) ? (100 - _detail[1] * 100 / _detail[0]) : null)
                let _shownumber = (_show !== null) ? (_show) : 0
                return _detail[0] ? <NumberFormat placeholder="0" value={(_show)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ color: _shownumber < filterDatas.lowerOnly[record[0]] ? red[6] : grey[6] }}>{value}</Text>} /> : '0'
            },
        },
        {
            title: '% OOS',
            dataIndex: 'OOS',
            key: 'OOS',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total.total_oos_counts;
                // let _shownumber = (_detail[0]) ? ((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : '0'
            },
        },
        {
            title: '% No stock',
            dataIndex: 'no-stock',
            key: 'no-stock',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total.total_no_stock_counts;
                // let _shownumber = (_detail[0]) ? ((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text >{value}</Text>} /> : '0'
            },
        },
        {
            title: '%Fail stock',
            dataIndex: 'Fail-SKU',
            key: 'Fail-SKU',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total.total_fail_stock_counts;
                // let _shownumber = (_detail[0]) ? ((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : '0'
            },
        },
        {
            title: '% Fail price list',
            dataIndex: 'fail-pricelist',
            key: 'fail-pricelist',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total.total_void_counts;
                // let _shownumber = (_detail[0]) ? ((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : '0'
            },
        },
    ];
    const expandColumns = [
        {
            title: 'CATEGORY',
            dataIndex: 'Category',
            key: 'Category',
            fixed: 'left',
            width: 305,
            render: (value, record) => {
                return <div>
                    <Text style={{ textTransform: 'uppercase' }}>{record[0]}</Text>
                </div>
            },
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 14 }}>Stores</Text>,
            dataIndex: 'Stores',
            key: 'Stores',
            width: 180,
            render: (value, record) => {
                // console.log('record[1].shopsrecord[1].shops', record[1].shops);
                // let total = 0;
                // if (typeof record[1].total_osa_counts != 'undefined') {
                //     total = record[1].total_osa_counts[0];
                // } else if ((typeof record[1].total_npd_counts != 'undefined')) {
                //     total = record[1].total_npd_counts[0];
                // }
                let total = (record[1]?.shops?.length) || 0;
                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'
            },
        },
        {
            title: '% Pass',
            dataIndex: 'Pass',
            key: 'Pass',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total_oos_counts;
                let checklisttype = '';
                if (typeof record[1].total_osa_counts != 'undefined') {
                    checklisttype = 'osa'
                } else if ((typeof record[1].total_npd_counts != 'undefined')) {
                    checklisttype = 'npd'
                }
                let _show = (_detail[0]) ? (100 - _detail[1] * 100 / _detail[0]) : 0;
                let _shownumber = (_detail[0]) ? (_show) : 0;
                return _detail[0] ? <NumberFormat placeholder="0" value={_show} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ color: _shownumber < filterDatas.lowerOnly[checklisttype] ? red[6] : grey[6] }}>{value}</Text>} /> : '0'
            },
        },
        {
            title: '% OOS',
            dataIndex: 'OOS',
            key: 'OOS',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total_oos_counts;
                let checklisttype = '';
                if (typeof record[1].total_osa_counts != 'undefined') {
                    checklisttype = 'osa'
                } else if ((typeof record[1].total_npd_counts != 'undefined')) {
                    checklisttype = 'npd'
                }
                let _shownumber = (_detail[0]) ? ((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : '0'
            },
        },
        {
            title: '% No stock',
            dataIndex: 'no-stock',
            key: 'no-stock',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total_no_stock_counts;
                let checklisttype = '';
                if (typeof record[1].total_osa_counts != 'undefined') {
                    checklisttype = 'osa'
                } else if ((typeof record[1].total_npd_counts != 'undefined')) {
                    checklisttype = 'npd'
                }
                let _shownumber = (_detail[0]) ? ((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : '0'
            },
        },
        {
            title: 'Fail (SKU)',
            dataIndex: 'Fail-SKU',
            key: 'Fail-SKU',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total_fail_stock_counts;
                let checklisttype = '';
                if (typeof record[1].total_osa_counts != 'undefined') {
                    checklisttype = 'osa'
                } else if ((typeof record[1].total_npd_counts != 'undefined')) {
                    checklisttype = 'npd'
                }
                let _shownumber = (_detail[0]) ? ((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : '0'
            },
        },
        {
            title: '% Fail SKU PriceList',
            dataIndex: 'fail-pricelist',
            key: 'fail-pricelist',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total_void_counts;
                let checklisttype = '';
                if (typeof record[1].total_osa_counts != 'undefined') {
                    checklisttype = 'osa'
                } else if ((typeof record[1].total_npd_counts != 'undefined')) {
                    checklisttype = 'npd'
                }
                let _shownumber = (_detail[0]) ? ((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : '0'
            },
        },
    ];
    const expandCategoryColumns = [
        {
            title: 'SKUs',
            dataIndex: 'SKUs',
            key: 'SKUs',
            fixed: 'left',
            width: 308,
            render: (value, record) => {
                return <div>
                    <Text style={{ textTransform: 'uppercase' }}>{record[0]}</Text>
                </div>
            },
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 14 }}>Stores</Text>,
            dataIndex: 'Stores',
            key: 'Stores',
            width: 180,
            render: (value, record) => {
                // console.log('record[1]1', record[1]);
                let total = (record[1]?.shops?.length) || 0;
                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 14 }}>{value}</Text>} /> : '0'
            },
        },
        {
            title: '% Pass',
            dataIndex: 'Pass',
            key: 'Pass',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total_oos_counts;
                let checklisttype = '';
                if (typeof record[1].total_osa_counts != 'undefined') {
                    checklisttype = 'osa'
                } else if ((typeof record[1].total_npd_counts != 'undefined')) {
                    checklisttype = 'npd'
                }
                let _show = (_detail[0]) ? (100 - _detail[1] * 100 / _detail[0]) : 0;
                let _shownumber = (_detail[0]) ? (_show) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={_show} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ color: _shownumber < filterDatas.lowerOnly[checklisttype] ? red[6] : grey[6] }}>{value}</Text>} /> : '0'
            },
        },
        {
            title: '% OOS',
            dataIndex: 'OOS',
            key: 'OOS',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total_oos_counts;
                let checklisttype = '';
                if (typeof record[1].total_osa_counts != 'undefined') {
                    checklisttype = 'osa'
                } else if ((typeof record[1].total_npd_counts != 'undefined')) {
                    checklisttype = 'npd'
                }
                let _shownumber = (_detail[0]) ? ((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text >{value}</Text>} /> : '0'
            },
        },
        {
            title: '% No stock',
            dataIndex: 'no-stock',
            key: 'no-stock',
            width: 180,
            render: (value, record) => {
                let checklisttype = '';
                if (typeof record[1].total_osa_counts != 'undefined') {
                    checklisttype = 'osa'
                } else if ((typeof record[1].total_npd_counts != 'undefined')) {
                    checklisttype = 'npd'
                }
                let _detail = record[1].total_no_stock_counts;
                let _shownumber = (_detail[0]) ? ((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : '0'
            },
        },
        {
            title: 'Fail (SKU)',
            dataIndex: 'Fail-SKU',
            key: 'Fail-SKU',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total_fail_stock_counts;
                let checklisttype = '';
                if (typeof record[1].total_osa_counts != 'undefined') {
                    checklisttype = 'osa'
                } else if ((typeof record[1].total_npd_counts != 'undefined')) {
                    checklisttype = 'npd'
                }
                let _shownumber = (_detail[0]) ? (_detail[1] * 100 / _detail[0]) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : '0'
            },
        },
        {
            title: '% Fail SKU PriceList',
            dataIndex: 'fail-pricelist',
            key: 'fail-pricelist',
            width: 180,
            render: (value, record) => {
                let _detail = record[1].total_void_counts;
                let checklisttype = '';
                if (typeof record[1].total_osa_counts != 'undefined') {
                    checklisttype = 'osa'
                } else if ((typeof record[1].total_npd_counts != 'undefined')) {
                    checklisttype = 'npd'
                }
                let _shownumber = (_detail[0]) ? ((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0) : '0'
                return _detail[0] ? <NumberFormat placeholder="0" value={((_detail[0]) ? _detail[1] * 100 / _detail[0] : 0)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : '0'
            },
        },
    ];
    return <Table
        dataSource={datas}
        rowKey={(record) => record[0]}
        pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
        scroll={{ x: 2000 }}
        className='scrollx tablelevel1'
        columns={columns}
        expandable={{
            expandedRowRender: (record) => {
                let key = record[0];
                let shopDatas = record[1].detail;
                delete shopDatas.shops;
                return <Table
                    dataSource={Object.entries(shopDatas)}
                    rowKey={(record, index1) => {
                        return key + '__' + (index1) + record[0]
                    }}
                    scroll={{ x: 2000 }}
                    // showHeader={false}
                    pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                    className='tablelevel2'
                    columns={expandColumns}
                    expandable={{
                        expandedRowRender: (record) => {
                            let key = record[0];
                            let categoryDatas = { ...record[1] };
                            delete categoryDatas.total_fail_stock_counts;
                            delete categoryDatas.total_no_stock_counts;
                            delete categoryDatas.total_oos_counts;
                            delete categoryDatas.total_osa_counts;
                            delete categoryDatas.total_npd_counts;
                            delete categoryDatas.total_void_counts;
                            delete categoryDatas.shops;
                            return <Table
                                dataSource={Object.entries(categoryDatas)}
                                rowKey={(record, index1) => {
                                    return key + '__' + (index1) + record[0]
                                }}
                                className='tablelevel3'
                                scroll={{ x: 2000 }}
                                // showHeader={false}
                                pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                                columns={expandCategoryColumns}


                            />
                        }
                    }}
                />
            }
        }}
    />
}
export default OsaNpd;