const downloadXlsFromBase64 = (b64, fileName, format) => {
  let link = document.createElement('a');
  link.download = `${fileName}.${format}`;
  link.href = 'data:application/octet-stream;base64,' + b64;
  link.click();
};
const downloadZipBase64 = (b64) => {
  var byteString = atob(b64);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: 'application/zip' });
  const blobUrl = URL.createObjectURL(blob);

  window.location = blobUrl;
};
const downloadZipBinary = (binary) => {

  const blob = new Blob([binary], { type: 'application/zip' });
  const blobUrl = URL.createObjectURL(blob);
  // console.log('blobUrl', blobUrl);
  window.location = blobUrl;
};
export { downloadXlsFromBase64, downloadZipBase64, downloadZipBinary }; 