import React from 'react';
import {
    Table, Typography
} from 'antd';
import NumberFormat from 'react-number-format';
import { red, grey } from '@ant-design/colors';
const AllCustomersTable = (props) => {
    const { Text } = Typography;
    const { datas } = props;
    const colWidth = 100;
    const allCategories = [
        { key: 'store_audit', title: '# Store audit' },
        { key: 'store_qc', title: '# Store QC' },
        { key: 'qc_total', title: '% Pass total' },
        { key: 'last_month', title: 'Last Month' },
        { key: 'last_3_months', title: 'Last 3 months' },
        { key: 'ytd', title: 'YTD' },
        { key: 'images', title: '% Photos fail' },
        { key: 'c2a', title: 'C2A' },
        { key: 'npd', title: 'NPD' },
        { key: 'olbig', title: 'OLBIG' },
        { key: 'osa', title: 'OSA' },
        { key: 'procms', title: 'PROCMS' },
        { key: 'prool', title: 'PROOL' },
        { key: 'rental', title: 'RENTAL' },
        { key: 'sos', title: 'SOS' },
    ];
    const defaultColumns = [
        {
            title: 'Auditor/Customer/Store/Category',
            dataIndex: 'Customer-Store-Category',
            key: 'Customer-Store-Category',
            render: (value, record) => {
                return <Text style={{ textTransform: 'uppercase', fontSize: 12 }}>{record[0]}</Text>
            },
        },

    ];
    const defaultAuditColumns = [
        {
            title: 'Auditor/Customer/Store/Category',
            dataIndex: 'Customer-Store-Category',
            key: 'Customer-Store-Category',
            render: (value, record) => {
                let color = 'rgba(0, 0, 0, 0.85)';
                let _num = (record[1] && record[1].totals && record[1].totals.qc_total && record[1].totals.qc_total[0]) ? record[1].totals.qc_total[1] * 10000 / record[1].totals.qc_total[0] / 100 : '';
                if (_num !== '') {
                    if (_num < 95) {
                        color = '#ff7875';
                    } else if (_num < 97) {
                        color = '#f3d60b';
                    }

                }

                return <Text style={{ textTransform: 'uppercase', fontSize: 12, color }}>{record[0]}</Text>
            },
        },

    ];
    const [auditColumns, setAuditColumns] = React.useState(defaultAuditColumns);
    const [columns, setColumns] = React.useState(defaultColumns);
    const [expandColumns, setExpandColumns] = React.useState(defaultColumns);
    const [expandCategoryColumns, setExpandCategoryColumns] = React.useState(defaultColumns);
    React.useEffect(() => {
        let _auditColumns = [...defaultAuditColumns];
        let _columns = [...defaultColumns];
        let _expandColumns = [...defaultColumns];
        let _expandCategoryColumns = [...defaultColumns];
        if (allCategories && allCategories.length) {

            for (let i = 0; i < allCategories.length; i++) {
                _auditColumns.push(
                    {
                        title: allCategories[i].title,
                        dataIndex: allCategories[i].key,
                        width: colWidth,
                        key: allCategories[i].key,
                        align: 'center',
                        render: (value, record) => {
                            let _num = 0;
                            let suffix = '';
                            let color = 'rgba(0, 0, 0, 0.85)';
                            if ((allCategories[i].key == 'store_audit') || (allCategories[i].key == 'store_qc')) {


                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key]) ? record[1].totals[allCategories[i].key].length : '';
                                // console.log('_num', _num);
                                // if (_num !== '' && _num < 97) {
                                //     color = '#ff7875';
                                // }
                            } else if (allCategories[i].key == 'qc_total') {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'last_month') {
                                _num = (record[1] && record[1].last_month && record[1].last_month[0]) ? record[1].last_month[1] * 100 / record[1].last_month[0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'last_3_months') {
                                _num = (record[1] && record[1].last_3_months && record[1].last_3_months[0]) ? record[1].last_3_months[1] * 100 / record[1].last_3_months[0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'ytd') {
                                _num = (record[1] && record[1].ytd && record[1].ytd[0]) ? record[1].ytd[1] * 100 / record[1].ytd[0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'images') {
                                let _tmpnum = (record[1] && record[1].image_totals && record[1].image_totals[0]) ? record[1].image_totals[1] * 100 / record[1].image_totals[0] : ''; //  PASS photo
                                let _tmpqctotal = (record[1] && record[1].totals && record[1].totals['qc_total'] && record[1].totals['qc_total'][0]) ? record[1].totals['qc_total'][1] * 100 / record[1].totals['qc_total'][0] : ''; // pass qc
                                if (_tmpqctotal !== '') {
                                    _num = (_tmpnum != '') ? (_tmpqctotal == 100 ? 0 : (100 * (100 - _tmpnum) / (100 - _tmpqctotal))) : 0;
                                } else {
                                    _num = ''
                                }

                                // if (_num !== '' && _num < 97) {
                                //     color = '#ff7875';
                                // }
                                suffix = '%';
                            } else {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';

                            }
                            // let detail = 50;
                            return (_num !== '') ? <NumberFormat placeholder="0" value={_num} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={suffix} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10, color }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                        },
                    }
                );
                _columns.push(
                    {
                        title: allCategories[i].title,
                        dataIndex: allCategories[i].key,
                        width: colWidth,
                        key: allCategories[i].key,
                        align: 'center',
                        render: (value, record) => {

                            let _num = 0;
                            let suffix = '';
                            let color = 'rgba(0, 0, 0, 0.85)';
                            if ((allCategories[i].key == 'store_audit') || (allCategories[i].key == 'store_qc')) {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key]) ? record[1].totals[allCategories[i].key].length : '';
                                // if (_num !== '' && _num < 97) {
                                //     color = '#ff7875';
                                // }
                            } else if (allCategories[i].key == 'qc_total') {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'last_month') {
                                _num = (record[1] && record[1].last_month && record[1].last_month[0]) ? record[1].last_month[1] * 100 / record[1].last_month[0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'last_3_months') {
                                _num = (record[1] && record[1].last_3_months && record[1].last_3_months[0]) ? record[1].last_3_months[1] * 100 / record[1].last_3_months[0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'ytd') {
                                _num = (record[1] && record[1].ytd && record[1].ytd[0]) ? record[1].ytd[1] * 100 / record[1].ytd[0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'images') {
                                // _num = (record[1] && record[1].image_totals && record[1].image_totals[0]) ? record[1].image_totals[1] * 100 / record[1].image_totals[0] : '';
                                let _tmpnum = (record[1] && record[1].image_totals && record[1].image_totals[0]) ? record[1].image_totals[1] * 100 / record[1].image_totals[0] : '';// % pass photo
                                let _tmpqctotal = (record[1] && record[1].totals && record[1].totals['qc_total'] && record[1].totals['qc_total'][0]) ? record[1].totals['qc_total'][1] * 100 / record[1].totals['qc_total'][0] : ''; // % pass qc

                                if (_tmpqctotal !== '') {
                                    _num = (_tmpnum != '') ? (_tmpqctotal == 100 ? 0 : (100 * (100 - _tmpnum) / (100 - _tmpqctotal))) : 0;
                                } else {
                                    _num = ''
                                }
                                // if (_num !== '' && _num < 97) {
                                //     color = '#ff7875';
                                // }
                                suffix = '%';
                            } else {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';

                            }
                            // let detail = 50;
                            return (_num !== '') ? <NumberFormat placeholder="0" value={_num} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={suffix} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10, color }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                        },
                    }
                );
                _expandColumns.push(
                    {
                        title: allCategories[i].title,
                        dataIndex: allCategories[i].key,
                        width: colWidth,
                        key: allCategories[i].key,
                        align: 'center',
                        render: (value, record) => {
                            let _num = 0;
                            let suffix = '';
                            let color = 'rgba(0, 0, 0, 0.85)';
                            if ((allCategories[i].key == 'store_audit') || (allCategories[i].key == 'store_qc')) {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key]) ? record[1].totals[allCategories[i].key].length : '';
                                // if (_num !== '' && _num < 97) {
                                //     color = '#ff7875';
                                // }
                            } else if (allCategories[i].key == 'qc_total') {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'last_month') {
                                _num = (record[1] && record[1].last_month && record[1].last_month[0]) ? record[1].last_month[1] * 100 / record[1].last_month[0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'last_3_months') {
                                _num = (record[1] && record[1].last_3_months && record[1].last_3_months[0]) ? record[1].last_3_months[1] * 100 / record[1].last_3_months[0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'ytd') {
                                _num = (record[1] && record[1].ytd && record[1].ytd[0]) ? record[1].ytd[1] * 100 / record[1].ytd[0] : '';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                                suffix = '%';
                            } else if (allCategories[i].key == 'images') {
                                // _num = (record[1] && record[1].image_totals && record[1].image_totals[0]) ? record[1].image_totals[1] * 100 / record[1].image_totals[0] : '';
                                // if (_num !== '' && _num < 97) {
                                //     color = '#ff7875';
                                // }
                                let _tmpnum = (record[1] && record[1].image_totals && record[1].image_totals[0]) ? record[1].image_totals[1] * 100 / record[1].image_totals[0] : '';
                                let _tmpqctotal = (record[1] && record[1].totals && record[1].totals['qc_total'] && record[1].totals['qc_total'][0]) ? record[1].totals['qc_total'][1] * 100 / record[1].totals['qc_total'][0] : '';
                                if (_tmpqctotal !== '') {
                                    _num = (_tmpnum != '') ? (_tmpqctotal == 100 ? 0 : (100 * (100 - _tmpnum) / (100 - _tmpqctotal))) : 0;
                                } else {
                                    _num = ''
                                }

                                suffix = '%';
                            } else {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                suffix = '%';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                            }
                            // let detail = 50;
                            return (_num !== '') ? <NumberFormat placeholder="0" value={_num} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={suffix} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10, color }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                        },
                    }
                );
                _expandCategoryColumns.push(
                    {
                        title: allCategories[i].title,
                        dataIndex: allCategories[i].key,
                        width: colWidth,
                        key: allCategories[i].key,
                        align: 'center',
                        render: (value, record) => {
                            let _num = 0;
                            let suffix = '';
                            let color = 'rgba(0, 0, 0, 0.85)';
                            if ((allCategories[i].key == 'store_audit') || (allCategories[i].key == 'store_qc')) {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key]) ? record[1].totals[allCategories[i].key].length : '';
                                // if (_num !== '' && _num < 97) {
                                //     color = '#ff7875';
                                // }
                            } else if (allCategories[i].key == 'qc_total') {
                                _num = (record[1] && record[1].totals && record[1].totals[allCategories[i].key] && record[1].totals[allCategories[i].key][0]) ? record[1].totals[allCategories[i].key][1] * 100 / record[1].totals[allCategories[i].key][0] : '';
                                suffix = '%';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                            } else {
                                _num = (record[1] && record[1][allCategories[i].key] && record[1][allCategories[i].key][0]) ? record[1][allCategories[i].key][1] * 100 / record[1][allCategories[i].key][0] : '';
                                suffix = '%';
                                if (_num !== '' && _num < 97) {
                                    color = '#ff7875';
                                }
                            }
                            // let detail = 50;
                            return _num ? <NumberFormat placeholder="0" value={_num} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={suffix} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10, color }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                        },
                    }
                );
            }
        }
        setAuditColumns(_auditColumns);
        setColumns(_columns);
        setExpandColumns(_expandColumns);
        setExpandCategoryColumns(_expandCategoryColumns);

    }, []);



    return <Table
        dataSource={datas}
        rowKey={(record) => record[0]}
        pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
        columns={auditColumns}
        scroll={{ x: (allCategories) ? allCategories.length * colWidth + 400 : 400 }}
        expandable={{
            expandedRowRender: (record) => {
                // console.log('recordrecord', record);
                let key = record[0];
                let shopDatas = { ...record[1] };
                delete shopDatas.totals;
                delete shopDatas.image_totals;
                delete shopDatas.last_3_months;
                delete shopDatas.last_month;
                delete shopDatas.ytd;
                // console.log('shopDatasshopDatas', shopDatas);

                return <Table
                    dataSource={Object.entries(shopDatas)}
                    showHeader={false}
                    rowKey={(record) => record[0]}
                    pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record) => {
                            let key = record[0];
                            let shopDatas = { ...record[1] };
                            delete shopDatas.totals;
                            delete shopDatas.image_totals;
                            delete shopDatas.last_3_months;
                            delete shopDatas.last_month;
                            delete shopDatas.ytd;
                            return <Table
                                dataSource={Object.entries(shopDatas)}
                                rowKey={(record, index1) => {
                                    return key + '__' + (index1) + record[0]
                                }}
                                showHeader={false}
                                pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                                columns={expandColumns}
                                expandable={{
                                    expandedRowRender: (record) => {
                                        let key = record[0];
                                        let shopDatas = { ...record[1] };

                                        delete shopDatas.totals;
                                        delete shopDatas.image_totals;
                                        delete shopDatas.last_3_months;
                                        delete shopDatas.last_month;
                                        delete shopDatas.ytd;
                                        return <Table
                                            dataSource={Object.entries(shopDatas)}
                                            rowKey={(record, index1) => {
                                                return key + '__' + (index1) + record[0]
                                            }}
                                            showHeader={false}
                                            pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                                            columns={expandCategoryColumns}

                                        />
                                    }
                                }}
                            />
                        }
                    }}
                />
            }
        }}
    />
}
export default AllCustomersTable;