import React from 'react';
import {
    Table, Typography, Card
} from 'antd';
import NumberFormat from 'react-number-format';
import { red, grey } from '@ant-design/colors';
const Prool = (props) => {
    const { Text, Title } = Typography;
    const { datas } = props;
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    let columnsProol = [
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Category</Text>,
            dataIndex: 'Category',
            key: 'Category',
            fixed: 'left',
            width: 353,
            render: (value, record) => {
                // console.log('recordrecord', record);
                return <div style={{ width: 360 }}><Text style={{ textTransform: 'uppercase', fontSize: 10 }}>TOTALS</Text></div>
            },
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>CSM</Text>,
            dataIndex: 'CSM',
            key: 'CSM',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    width: 75,
                    key: 'CSM-Stores',
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['CSM']) ? record[1].shops['CSM'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    width: 75,
                    key: 'CSM-Fail',
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['CSM']) ? record[1].totals['CSM'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['CSM']) ? record[1].totals['CSM'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>GE</Text>,
            dataIndex: 'GE',
            key: 'GE',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    width: 75,
                    key: 'GE-Stores',
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['GE']) ? record[1].shops['GE'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail</Text>,
                    dataIndex: 'Fail',
                    width: 75,
                    key: 'GE-Fail',
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['GE']) ? record[1].totals['GE'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['GE']) ? record[1].totals['GE'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },

        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>MS</Text>,
            dataIndex: 'MS',
            key: 'MS',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'MS-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['MS']) ? record[1].shops['MS'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'MS-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['MS']) ? record[1].totals['MS'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['MS']) ? record[1].totals['MS'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>ALT</Text>,
            dataIndex: 'ALT',
            key: 'ALT',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'ALT-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['ALT']) ? record[1].shops['ALT'].length : 0;
                        // let total = (record[1] && record[1].shops && record[1].shops['ALT']) ? record[1].shops['ALT'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'ALT-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['ALT']) ? record[1].totals['ALT'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['ALT']) ? record[1].totals['ALT'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>SHF</Text>,
            dataIndex: 'SHF',
            key: 'SHF',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'SHF-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['SHF']) ? record[1].shops['SHF'].length : 0;
                        //let total = (record[1] && record[1].shops && record[1].shops['SHF']) ? record[1].shops['SHF'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'SHF-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['SHF']) ? record[1].totals['SHF'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['SHF']) ? record[1].totals['SHF'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>SS</Text>,
            dataIndex: 'SS',
            key: 'SS',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'SS-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['SS']) ? record[1].shops['SS'].length : 0;
                        //let total = (record[1] && record[1].shops && record[1].shops['SS']) ? record[1].shops['SS'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'SS-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['SS']) ? record[1].totals['SS'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['SS']) ? record[1].totals['SS'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>COC</Text>,
            dataIndex: 'COC',
            key: 'COC',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'COC-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['COC']) ? record[1].shops['COC'].length : 0;
                        //let total = (record[1] && record[1].shops && record[1].shops['COC']) ? record[1].shops['COC'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'COC-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['COC']) ? record[1].totals['COC'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['COC']) ? record[1].totals['COC'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>GE-C</Text>,
            dataIndex: 'GE-C',
            key: 'GE-C',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'GE-C-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['GE-C']) ? record[1].shops['GE-C'].length : 0;
                        //let total = (record[1] && record[1].shops && record[1].shops['GE-C']) ? record[1].shops['GE-C'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'GE-C-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['GE-C']) ? record[1].totals['GE-C'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['GE-C']) ? record[1].totals['GE-C'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>ICC</Text>,
            dataIndex: 'ICC',
            key: 'ICC',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'ICC-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['ICC']) ? record[1].shops['ICC'].length : 0;
                        //let total = (record[1] && record[1].shops && record[1].shops['ICC']) ? record[1].shops['ICC'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'ICC-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['ICC']) ? record[1].totals['ICC'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['ICC']) ? record[1].totals['ICC'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>KOC</Text>,
            dataIndex: 'KOC',
            key: 'KOC',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'KOC-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['KOC']) ? record[1].shops['KOC'].length : 0;
                        //let total = (record[1] && record[1].shops && record[1].shops['KOC']) ? record[1].shops['KOC'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'KOC-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['KOC']) ? record[1].totals['KOC'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['KOC']) ? record[1].totals['KOC'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>PI</Text>,
            dataIndex: 'PI',
            key: 'PI',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'PI-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['PI']) ? record[1].shops['PI'].length : 0;
                        //let total = (record[1] && record[1].shops && record[1].shops['PI']) ? record[1].shops['PI'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'PI-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['PI']) ? record[1].totals['PI'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['PI']) ? record[1].totals['PI'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>GE</Text>,
            dataIndex: 'HGR',
            key: 'HGR',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    width: 75,
                    key: 'HGR-Stores',
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['HGR']) ? record[1].shops['HGR'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail</Text>,
                    dataIndex: 'Fail',
                    width: 75,
                    key: 'GE-Fail',
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['GE']) ? record[1].totals['GE'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['GE']) ? record[1].totals['GE'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>SB</Text>,
            dataIndex: 'SB',
            key: 'SB',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'SB-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['SB']) ? record[1].shops['SB'].length : 0;
                        //let total = (record[1] && record[1].shops && record[1].shops['SB']) ? record[1].shops['SB'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'SB-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['SB']) ? record[1].totals['SB'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['SB']) ? record[1].totals['SB'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>SK</Text>,
            dataIndex: 'SK',
            key: 'SK',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'SK-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['SK']) ? record[1].shops['SK'].length : 0;
                        //let total = (record[1] && record[1].shops && record[1].shops['SK']) ? record[1].shops['SK'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'SK-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['SK']) ? record[1].totals['SK'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['SK']) ? record[1].totals['SK'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>SSBF</Text>,
            dataIndex: 'SSBF',
            key: 'SSBF',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'SSBF-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['SSBF']) ? record[1].shops['SSBF'].length : 0;
                        //let total = (record[1] && record[1].shops && record[1].shops['SSBF']) ? record[1].shops['SSBF'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'SSBF-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['SSBF']) ? record[1].totals['SSBF'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['SSBF']) ? record[1].totals['SSBF'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>SSHF</Text>,
            dataIndex: 'SSHF',
            key: 'SSHF',
            children: [
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                    dataIndex: 'Stores',
                    key: 'SSHF-Stores',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].shops && record[1].shops['SSHF']) ? record[1].shops['SSHF'].length : 0;
                        //let total = (record[1] && record[1].shops && record[1].shops['SSHF']) ? record[1].shops['SSHF'].length : 0;
                        return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
                {
                    title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                    dataIndex: 'Fail',
                    key: 'SSHF-Fail',
                    width: 75,
                    render: (value, record) => {
                        let total = (record[1] && record[1].totals && record[1].totals['SSHF']) ? record[1].totals['SSHF'][0] : 0;
                        let fail = (record[1] && record[1].totals && record[1].totals['SSHF']) ? record[1].totals['SSHF'][1] : 0;
                        return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                    },
                },
            ]
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Total Stores</Text>,
            dataIndex: 'TotalStores',
            key: 'TotalStores',
            width: 75,
            render: (value, record) => {
                let total = (record[1] && record[1].shops && record[1].shops['GE']) ? record[1].shops['GE'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['MS']) ? record[1].shops['MS'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['PI']) ? record[1].shops['PI'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['HGR']) ? record[1].shops['HGR'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['SB']) ? record[1].shops['SB'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['SK']) ? record[1].shops['SK'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['SS']) ? record[1].shops['SS'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['ALT']) ? record[1].shops['ALT'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['COC']) ? record[1].shops['COC'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['COC']) ? record[1].shops['COC'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['CSM']) ? record[1].shops['CSM'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['ICC']) ? record[1].shops['ICC'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['KOC']) ? record[1].shops['KOC'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['SHF']) ? record[1].shops['SHF'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['GE-C']) ? record[1].shops['GE-C'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['SSBF']) ? record[1].shops['SSBF'].length : 0;
                total += (record[1] && record[1].shops && record[1].shops['SSHF']) ? record[1].shops['SSHF'].length : 0;
                //let total = (record[1] && record[1].totals && record[1].totals['totals']) ? record[1].totals['totals'][0] : 0;
                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
            },
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Total Fail %</Text>,
            dataIndex: 'TotalFail',
            key: 'TotalFail',
            width: 75,
            render: (value, record) => {
                let total = (record[1] && record[1].totals && record[1].totals['totals']) ? record[1].totals['totals'][0] : 0;
                let fail = (record[1] && record[1].totals && record[1].totals['totals']) ? record[1].totals['totals'][1] : 0;
                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
            },
        },

    ];
    return <Card
        title={"PROOL"}
        bordered={false}
        style={{ width: '100%', marginTop: 15 }}>
        <Table
            dataSource={datas}
            scroll={{ x: 2900 }}
            size='small'
            style={{ marginTop: 10 }}
            rowKey={(record) => record[0]}
            className='scrollx tablelevel1'
            pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
            columns={columnsProol}
            expandable={{
                expandedRowRender: (record) => {
                    let key = record[0];
                    let shopDatas = record[1].detail;

                    return <Table
                        dataSource={Object.entries(shopDatas)}
                        rowKey={(record, index1) => {
                            return key + '__' + (index1) + record[0]
                        }}
                        className='tablelevel2'
                        scroll={{ x: 2900 }}
                        // showHeader={false}
                        pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                        columns={[
                            {
                                title: <Text style={{ fontSize: 10 }} strong>Category</Text>,
                                dataIndex: <Text style={{ fontSize: 10 }} strong>Category</Text>,
                                key: <Text style={{ fontSize: 10 }} strong>Category</Text>,
                                fixed: 'left',
                                width: 305,
                                render: (value, record) => {
                                    return <div style={{ width: 330 }}>
                                        <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>{record[0]}</Text>
                                    </div>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'CSM-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['CSM']) ? record[1].shops['CSM'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['CSM']) ? record[1].shops['CSM'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'CSM-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['CSM']) ? record[1].totals['CSM'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['CSM']) ? record[1].totals['CSM'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },

                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                width: 75,
                                key: 'GE-Stores',
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['GE']) ? record[1].shops['GE'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['GE']) ? record[1].shops['GE'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                width: 75,
                                key: 'GE-Fail',
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['GE']) ? record[1].totals['GE'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['GE']) ? record[1].totals['GE'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },

                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'MS-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['MS']) ? record[1].shops['MS'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['MS']) ? record[1].shops['MS'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'MS-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['MS']) ? record[1].totals['MS'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['MS']) ? record[1].totals['MS'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'ALT-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['ALT']) ? record[1].shops['ALT'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['ALT']) ? record[1].shops['ALT'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'ALT-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['ALT']) ? record[1].totals['ALT'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['ALT']) ? record[1].totals['ALT'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'SHF-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['SHF']) ? record[1].shops['SHF'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['SHF']) ? record[1].shops['SHF'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'SHF-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['SHF']) ? record[1].totals['SHF'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['SHF']) ? record[1].totals['SHF'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'SS-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['SS']) ? record[1].shops['SS'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['SS']) ? record[1].shops['SS'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'SS-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['SS']) ? record[1].totals['SS'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['SS']) ? record[1].totals['SS'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },

                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'COC-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['COC']) ? record[1].shops['COC'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['COC']) ? record[1].shops['COC'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'COC-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['COC']) ? record[1].totals['COC'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['COC']) ? record[1].totals['COC'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'GE-C-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['GE-C']) ? record[1].shops['GE-C'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['GE-C']) ? record[1].shops['GE-C'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'GE-C-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['GE-C']) ? record[1].totals['GE-C'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['GE-C']) ? record[1].totals['GE-C'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'ICC-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['ICC']) ? record[1].shops['ICC'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['ICC']) ? record[1].shops['ICC'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'ICC-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['ICC']) ? record[1].totals['ICC'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['ICC']) ? record[1].totals['ICC'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },

                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'KOC-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['KOC']) ? record[1].shops['KOC'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['KOC']) ? record[1].shops['KOC'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'KOC-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['KOC']) ? record[1].totals['KOC'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['KOC']) ? record[1].totals['KOC'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },

                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'PI-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['PI']) ? record[1].shops['PI'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['PI']) ? record[1].shops['PI'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'PI-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['PI']) ? record[1].totals['PI'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['PI']) ? record[1].totals['PI'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'HGR-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['HGR']) ? record[1].totals['HGR'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['HGR']) ? record[1].totals['HGR'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },

                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'SB-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['SB']) ? record[1].shops['SB'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['SB']) ? record[1].shops['SB'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'SB-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['SB']) ? record[1].totals['SB'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['SB']) ? record[1].totals['SB'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },

                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'SK-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['SK']) ? record[1].shops['SK'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['SK']) ? record[1].shops['SK'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'SK-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['SK']) ? record[1].totals['SK'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['SK']) ? record[1].totals['SK'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },

                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'SSBF-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['SSBF']) ? record[1].shops['SSBF'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['SSBF']) ? record[1].shops['SSBF'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'SSBF-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['SSBF']) ? record[1].totals['SSBF'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['SSBF']) ? record[1].totals['SSBF'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },

                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                dataIndex: 'Stores',
                                key: 'SSHF-Stores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['SSHF']) ? record[1].shops['SSHF'].length : 0;
                                    //let total = (record[1] && record[1].shops && record[1].shops['SSHF']) ? record[1].shops['SSHF'].length : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                dataIndex: 'Fail',
                                key: 'SSHF-Fail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['SSHF']) ? record[1].totals['SSHF'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['SSHF']) ? record[1].totals['SSHF'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },

                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Total Stores</Text>,
                                dataIndex: 'TotalStores',
                                key: 'TotalStores',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].shops && record[1].shops['GE']) ? record[1].shops['GE'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['MS']) ? record[1].shops['MS'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['PI']) ? record[1].shops['PI'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['SB']) ? record[1].shops['SB'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['SK']) ? record[1].shops['SK'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['SS']) ? record[1].shops['SS'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['ALT']) ? record[1].shops['ALT'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['COC']) ? record[1].shops['COC'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['COC']) ? record[1].shops['COC'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['CSM']) ? record[1].shops['CSM'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['ICC']) ? record[1].shops['ICC'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['KOC']) ? record[1].shops['KOC'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['SHF']) ? record[1].shops['SHF'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['GE-C']) ? record[1].shops['GE-C'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['SSBF']) ? record[1].shops['SSBF'].length : 0;
                                    total += (record[1] && record[1].shops && record[1].shops['SSHF']) ? record[1].shops['SSHF'].length : 0;
                                    //let total = (record[1] && record[1].totals && record[1].totals['totals']) ? record[1].totals['totals'][0] : 0;
                                    return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                            {
                                title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Total Fail %</Text>,
                                dataIndex: 'TotalFail',
                                key: 'TotalFail',
                                width: 75,
                                render: (value, record) => {
                                    let total = (record[1] && record[1].totals && record[1].totals['totals']) ? record[1].totals['totals'][0] : 0;
                                    let fail = (record[1] && record[1].totals && record[1].totals['totals']) ? record[1].totals['totals'][1] : 0;
                                    return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                },
                            },
                        ]}
                        expandable={{
                            expandedRowRender: (record) => {
                                let key = record[0];
                                let categoryDatas = { ...record[1] };
                                delete categoryDatas.totals;
                                delete categoryDatas.shops;
                                return <Table
                                    dataSource={Object.entries(categoryDatas)}
                                    rowKey={(record, index1) => {
                                        return key + '__' + (index1) + record[0]
                                    }}
                                    scroll={{ x: 2900 }}
                                    className='tablelevel3'
                                    // showHeader={false}
                                    pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                                    columns={[
                                        {
                                            title: <Text style={{ fontSize: 10 }} strong>SKUs</Text>,
                                            dataIndex: <Text style={{ fontSize: 10 }} strong>SKUs</Text>,
                                            key: <Text style={{ fontSize: 10 }} strong>SKUs</Text>,
                                            fixed: 'left',
                                            width: 308,
                                            render: (value, record) => {
                                                return <div style={{ width: 340 }}>
                                                    <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>{record[0]}</Text>
                                                </div>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'CSM-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['CSM']) ? record[1].shops['CSM'].length : 0;
                                                //let total = (record[1] && record[1]['CSM']) ? record[1]['CSM'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'CSM-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['CSM']) ? record[1]['CSM'][0] : 0;
                                                let fail = (record[1] && record[1]['CSM']) ? record[1]['CSM'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },

                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            width: 75,
                                            key: 'GE-Stores',
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['GE']) ? record[1].shops['GE'].length : 0;
                                                //let total = (record[1] && record[1]['GE']) ? record[1]['GE'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            width: 75,
                                            key: 'GE-Fail',
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['GE']) ? record[1]['GE'][0] : 0;
                                                let fail = (record[1] && record[1]['GE']) ? record[1]['GE'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },

                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'MS-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['MS']) ? record[1].shops['MS'].length : 0;
                                                //let total = (record[1] && record[1]['MS']) ? record[1]['MS'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'MS-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['MS']) ? record[1]['MS'][0] : 0;
                                                let fail = (record[1] && record[1]['MS']) ? record[1]['MS'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'ALT-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['ALT']) ? record[1].shops['ALT'].length : 0;
                                                //let total = (record[1] && record[1]['ALT']) ? record[1]['ALT'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'ALT-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['ALT']) ? record[1]['ALT'][0] : 0;
                                                let fail = (record[1] && record[1]['ALT']) ? record[1]['ALT'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'SHF-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['SHF']) ? record[1].shops['SHF'].length : 0;
                                                //let total = (record[1] && record[1]['SHF']) ? record[1]['SHF'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'SHF-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['SHF']) ? record[1]['SHF'][0] : 0;
                                                let fail = (record[1] && record[1]['SHF']) ? record[1]['SHF'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'SS-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['SS']) ? record[1].shops['SS'].length : 0;
                                                //let total = (record[1] && record[1]['SS']) ? record[1]['SS'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'SS-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['SS']) ? record[1]['SS'][0] : 0;
                                                let fail = (record[1] && record[1]['SS']) ? record[1]['SS'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },

                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'COC-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['COC']) ? record[1].shops['COC'].length : 0;
                                                //let total = (record[1] && record[1]['COC']) ? record[1]['COC'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'COC-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['COC']) ? record[1]['COC'][0] : 0;
                                                let fail = (record[1] && record[1]['COC']) ? record[1]['COC'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'GE-C-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['GE-C']) ? record[1].shops['GE-C'].length : 0;
                                                //let total = (record[1] && record[1]['GE-C']) ? record[1]['GE-C'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'GE-C-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['GE-C']) ? record[1]['GE-C'][0] : 0;
                                                let fail = (record[1] && record[1]['GE-C']) ? record[1]['GE-C'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'ICC-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['ICC']) ? record[1].shops['ICC'].length : 0;
                                                //let total = (record[1] && record[1]['ICC']) ? record[1]['ICC'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'ICC-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['ICC']) ? record[1]['ICC'][0] : 0;
                                                let fail = (record[1] && record[1]['ICC']) ? record[1]['ICC'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },

                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'KOC-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['KOC']) ? record[1].shops['KOC'].length : 0;
                                                //let total = (record[1] && record[1]['KOC']) ? record[1]['KOC'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'KOC-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['KOC']) ? record[1]['KOC'][0] : 0;
                                                let fail = (record[1] && record[1]['KOC']) ? record[1]['KOC'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },

                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'PI-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['PI']) ? record[1].shops['PI'].length : 0;
                                                //let total = (record[1] && record[1]['PI']) ? record[1]['PI'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'PI-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['PI']) ? record[1]['PI'][0] : 0;
                                                let fail = (record[1] && record[1]['PI']) ? record[1]['PI'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'HGR-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['HGR']) ? record[1]['HGR'][0] : 0;
                                                let fail = (record[1] && record[1]['HGR']) ? record[1]['HGR'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },

                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'SB-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['SB']) ? record[1].shops['SB'].length : 0;
                                                //let total = (record[1] && record[1]['SB']) ? record[1]['SB'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'SB-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['SB']) ? record[1]['SB'][0] : 0;
                                                let fail = (record[1] && record[1]['SB']) ? record[1]['SB'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },

                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'SK-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['SK']) ? record[1].shops['SK'].length : 0;
                                                //let total = (record[1] && record[1]['SK']) ? record[1]['SK'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'SK-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['SK']) ? record[1]['SK'][0] : 0;
                                                let fail = (record[1] && record[1]['SK']) ? record[1]['SK'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },

                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'SSBF-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['SSBF']) ? record[1].shops['SSBF'].length : 0;
                                                //let total = (record[1] && record[1]['SSBF']) ? record[1]['SSBF'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'SSBF-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['SSBF']) ? record[1]['SSBF'][0] : 0;
                                                let fail = (record[1] && record[1]['SSBF']) ? record[1]['SSBF'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },

                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Stores</Text>,
                                            dataIndex: 'Stores',
                                            key: 'SSHF-Stores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['SSHF']) ? record[1].shops['SSHF'].length : 0;
                                                //let total = (record[1] && record[1]['SSHF']) ? record[1]['SSHF'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Fail %</Text>,
                                            dataIndex: 'Fail',
                                            key: 'SSHF-Fail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['SSHF']) ? record[1]['SSHF'][0] : 0;
                                                let fail = (record[1] && record[1]['SSHF']) ? record[1]['SSHF'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },

                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Total Stores</Text>,
                                            dataIndex: 'TotalStores',
                                            key: 'TotalStores',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1].shops && record[1].shops['GE']) ? record[1].shops['GE'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['MS']) ? record[1].shops['MS'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['PI']) ? record[1].shops['PI'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['HGR']) ? record[1].shops['HGR'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['SB']) ? record[1].shops['SB'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['SK']) ? record[1].shops['SK'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['SS']) ? record[1].shops['SS'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['ALT']) ? record[1].shops['ALT'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['COC']) ? record[1].shops['COC'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['COC']) ? record[1].shops['COC'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['CSM']) ? record[1].shops['CSM'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['ICC']) ? record[1].shops['ICC'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['KOC']) ? record[1].shops['KOC'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['SHF']) ? record[1].shops['SHF'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['GE-C']) ? record[1].shops['GE-C'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['SSBF']) ? record[1].shops['SSBF'].length : 0;
                                                total += (record[1] && record[1].shops && record[1].shops['SSHF']) ? record[1].shops['SSHF'].length : 0;
                                                //let total = (record[1] && record[1]['totals']) ? record[1]['totals'][0] : 0;
                                                return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                        {
                                            title: <Text style={{ textTransform: 'uppercase', fontSize: 10 }}>Total Fail %</Text>,
                                            dataIndex: 'TotalFail',
                                            key: 'TotalFail',
                                            width: 75,
                                            render: (value, record) => {
                                                let total = (record[1] && record[1]['totals']) ? record[1]['totals'][0] : 0;
                                                let fail = (record[1] && record[1]['totals']) ? record[1]['totals'][1] : 0;
                                                return total ? <NumberFormat placeholder="0" value={100 - fail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                                            },
                                        },
                                    ]}


                                />
                            }
                        }}


                    />
                }
            }}
        />
    </Card>
}
export default Prool;