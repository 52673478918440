import React from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Image,
  Space,
  Form,
  Button,
  DatePicker,
  Tabs,
  Switch,
  List as AntList,
  Divider,
  Input,
  Collapse,
  Dropdown,
  Menu,
  Modal,
  Select

} from 'antd';

import { connect } from 'react-redux';
// import { homeActions } from '../redux/actions';
// import authActions from '../../auth/redux/actions';
// import history from '../../../common/history';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const AuditStatusMom = ({ dispatch, home, user }) => {
  const { Text, Title } = Typography;
  const [form] = Form.useForm();
  //SIEUTIEC70
  const rootData = [
    {
      name: 'Done',
      // '2022-01': '%', '2022-02': '%','2022-03':'%','2022-04':'%'
      data: [
        { 'type': 'Total', '2022-01': '95.80%', '2022-02': '94.56%', '2022-03': '95.44%', '2022-04': '97.27%' },
        { 'type': 'Ok', '2022-01': '84.10%', '2022-02': '85.82%', '2022-03': '85.65%', '2022-04': '89.15%' },
        { 'type': 'Không cho chụp hình', '2022-01': '9.96%', '2022-02': '8.45%', '2022-03': '6.92%', '2022-04': '7.47%' },
        { 'type': 'Không cho chụp hình và không cho đo bằng thước', '2022-01': '1.10%', '2022-02': '0.00%', '2022-03': '2.80%', '2022-04': '0.65%' },
        { 'type': 'Không cho đo SOS bằng thước', '2022-01': '0.64%', '2022-02': '0.29%', '2022-03': '0.07%', '2022-04': '0.00%' },

      ]
    },
    {
      name: 'Cancelled',
      data: [
        { 'type': 'Total', '2022-01': '1.55%', '2022-02': '4.73%', '2022-03': '4.19%', '2022-04': '2.51%' },
        { 'type': 'Cancel vì siêu thị không cho vào', '2022-01': '0.64%', '2022-02': '2.44%', '2022-03': '1.77%', '2022-04': '0.00%' },
        { 'type': 'Cancel vì siêu thị đóng cửa', '2022-01': '0.18%', '2022-02': '1.29%', '2022-03': '1.69%', '2022-04': '0.57%' },
        { 'type': 'Cancel vì store đang re-layout - xếp hàng', '2022-01': '0.37%', '2022-02': '0.72%', '2022-03': '0.22%', '2022-04': '0.65%' },
        { 'type': 'Cancel vì store không cho vào', '2022-01': '0.00%', '2022-02': '0.00%', '2022-03': '0.00%', '2022-04': '1.15%' },
        { 'type': 'Không liên hệ được SR', '2022-01': '0.18%', '2022-02': '0.29%', '2022-03': '0.52%', '2022-04': '0.14%' },
        { 'type': 'Vì auditor nghỉ đột xuất', '2022-01': '0.18%', '2022-02': '0.00%', '2022-03': '0.00%', '2022-04': '0.00%' },
      ]
    },
    {
      name: 'Not yet',
      data: [
        { 'type': 'Total', '2022-01': '2.01%', '2022-02': '0.14%', '2022-03': '0.37%', '2022-04': '0.22%' },
        { 'type': 'Không chấm đủ KPI/ngành hàng', '2022-01': '1.92%', '2022-02': '0.14%', '2022-03': '0.37%', '2022-04': '0.22%' },
        { 'type': 'Cancel vì siêu thị đóng cửa', '2022-01': '0.09%', '2022-02': '0.00%', '2022-03': '0.00%', '2022-04': '0.00%' },
      ]
    },
    {
      name: 'Lỗi App',
      data: [
        { 'type': 'Total', '2022-01': '0.64%', '2022-02': '0.57%', '2022-03': '0.00%', '2022-04': '0.00%' },
        { 'type': 'Lỗi app', '2022-01': '0.64%', '2022-02': '0.57%', '2022-03': '0.00%', '2022-04': '0.00%' },
      ]
    },
  ];

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Audit status MOM',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const data = {
    labels: ['2022-01', '2022-02', '2022-03', '2022-04'],
    datasets: [
      {
        label: 'Lỗi app',
        data: [7, 4, 0, 0],
        backgroundColor: '#ed071a',
      },
      {
        label: 'Not yet - Cancel vì siêu thị đóng cửa',
        data: [1, 0, 0, 0],
        backgroundColor: '#bf5900',
      },
      {
        label: 'Not yet - Không chấm đủ KPI/ngành hàng',
        data: [21, 1, 5, 3],
        backgroundColor: '#c79a12',
      },
      {
        label: 'Cancelled - Cancel vì Auditor nghỉ đột xuất',
        data: [2, 0, 0, 0],
        backgroundColor: '#c71279',
      },
      {
        label: 'Cancelled - Cancel vì không liên hệ được SR',
        data: [2, 2, 7, 2],
        backgroundColor: '#e805ce',
      },
      {
        label: 'Cancelled - Cancel vì store không cho vào',
        data: [0, 0, 0, 16],
        backgroundColor: '#830ac9',
      },
      {
        label: 'Cancelled - Cancel vì store đang re-layout - xếp hàng',
        data: [4, 5, 3, 9],
        backgroundColor: '#33018a',
      },
      {
        label: 'Cancelled - Cancel vì siêu thị đóng cửa',
        data: [2, 9, 23, 8],
        backgroundColor: '#a60352',
      },
      {
        label: 'Cancelled - Cancel vì siêu thị không cho vào',
        data: [7, 17, 24, 0],
        backgroundColor: '#0f67bf',
      },
      {
        label: 'Done - Không cho đo SOS bằng thước',
        data: [7, 2, 1, 0],
        backgroundColor: '#018a5a',
      },
      {
        label: 'Done - Không cho chụp hình và không cho đo bằng thước',
        data: [12, 0, 38, 9],
        backgroundColor: '#026131',
      },
      {
        label: 'Done - Không cho chụp hình',
        data: [109, 59, 94, 104],
        backgroundColor: '#04bf3c',
      },
      {
        label: 'Done - OK',
        data: [920, 599, 1164, 1241],
        backgroundColor: '#49cc02',
      },
    ],
  };
  const onOk = (value) => {
    console.log('value', value._d);
  };
  return (
    <Row>
      <Col span={24}>
        <Card
          title="Audit Progress"
          bordered={false}
          style={{ width: '100%' }}
          extra={
            <Form name="filter" layout="inline">
              <Form.Item title="Period" >
                <DatePicker.MonthPicker format="YYYY-MM" onOk={onOk} />
              </Form.Item>
              <Form.Item
                name="supervisor"
                label="Supervisor"
              >
                <Select defaultValue={'1'} placeholder="Please choose">
                  <Select.Option value="1">ĐÀO XUÂN TRƯỜNG</Select.Option>
                  <Select.Option value="2">NGÔ THỊ HIỀN HOÀ</Select.Option>
                  <Select.Option value="3">NGUYỄN ĐỨC HÀO</Select.Option>
                  <Select.Option value="4">NGUYỄN ĐỨC HIỆP</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="auditor"
                label="Auditor"
              >
                <Select defaultValue={'1'} placeholder="Please choose">
                  <Select.Option value="1">CAO THANH HẢI</Select.Option>
                  <Select.Option value="2">ĐÀM QUANG HẢI</Select.Option>
                  <Select.Option value="3">ĐÀO HUY HOÀNG</Select.Option>
                  <Select.Option value="4">ĐỖ ĐĂNG KHOA</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="region"
                label="Region Name"
              >
                <Select defaultValue={'1'} placeholder="Please choose">
                  <Select.Option value="1">CENTRAL</Select.Option>
                  <Select.Option value="2">EAST</Select.Option>
                  <Select.Option value="3">HCM</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="customer"
                label="Customer"
              >
                <Select defaultValue={'1'} placeholder="Please choose">
                  <Select.Option value="1">7 - ELEVENT</Select.Option>
                  <Select.Option value="2">BSMART</Select.Option>
                  <Select.Option value="3">CIRCLE K</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          }
        >
          <Row>
            <Col span={12}>
              <Bar options={options} data={data} />
            </Col>
            <Col span={12}>
              <Table
                columns={[
                  {
                    title: 'Store audited',
                    dataIndex: 'store-audited',
                    key: 'store-audited',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>{record.name}</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.type}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: '2022-01',
                    dataIndex: '2022-01',
                    key: '2022-01',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type['2022-01'] || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: '2022-02',
                    dataIndex: '2022-02',
                    key: '2022-02',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type['2022-02'] || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: '2022-03',
                    dataIndex: '2022-03',
                    key: '2022-03',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type['2022-03'] || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: '2022-04',
                    dataIndex: '2022-04',
                    key: '2022-04',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type['2022-04'] || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                ]}

                dataSource={rootData || []}
                rowKey={(record) => record.id}
                pagination={{ position: ['bottomCenter'] }}
              />
            </Col>

          </Row>

        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(AuditStatusMom);
