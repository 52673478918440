import React, { useState } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Image,
  Space,
  Form,
  Button,
  DatePicker,
  Tabs,
  Switch,
  Select,
  List as AntList,
  Divider,
  Input,
  Collapse,

} from 'antd';
import NumberFormat from 'react-number-format';
import { green, gold } from '@ant-design/colors'
import { DownOutlined, CaretRightOutlined, LoginOutlined, LogoutOutlined } from '@ant-design/icons';
// import { Carousel } from 'react-responsive-carousel';
// import List from '../../components/List';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
// import CategoryList from '../../components/CategoryList';
// import Expandtable from './Expandtable';
import ListItem from './ListItem/IndexTable';


import { connect } from 'react-redux';
import { homeActions } from './redux/actions';
import history from '../../common/history';
import { removeVietnameseTones } from '../../../src/utils/string'
const url = process.env.REACT_APP_API_URL;



const { Text } = Typography;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const ReportDetailLayout = ({ dispatch, home, user }) => {
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
  const { listCheckInCheckOut, checkList, isLoading, listPhotos } = home;
  const [form] = Form.useForm();
  const [date, setDate] = useState([]);
  let totalsSummary = null;

  const [lastRecordExpand, setLastRecordExpand] = useState(null);
  const [currentDataExpand, setCurrentDataExpand] = useState({
    check_list: 0,
    category: '',
    sub_category: '',
  });
  const [currentDataExpandCompare, setCurrentDataExpandCompare] = useState({
    check_list: 0,
    category: '',
    sub_category: '',
  });
  const [checkListDataByRecord, setCheckListDataByRecord] = useState(null);
  const [checkListTypeBySubCategorySelected, setCheckListTypeBySubCategorySelected] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentVis, setCurrentVis] = useState(0);
  const [currentCheckListType, setCurrentCheckListType] = useState('');
  const itemPerRow = {
    C2A: 2,
    NPD: 2,
    OLBIG: 1,
    OSA: 2,
    PROCMS: 2,
    PROOL: 2,
    RENTAL: 2,
    SOS: 2,
  };
  const reportLabels = {
    C2A: [
      'C2A Target',
      'C2A Đạt',
      'C2A % Đạt',
    ],
    NPD: [
      'NPD Target',
      'NPD Đạt',
      'NPD % Đạt',
    ],
    OLBIG: [
      'OL U',
      'OL Siêu thị',
      '% OL',
    ],
    OSA: [
      'OSA Target',
      'OSA Đạt',
      'OSA % Đạt',
    ],
    PROCMS: [
      'PROCMS Target',
      'PROCMS Đạt',
      'PROCMS % Đạt',
    ],
    PROOL: [
      'PROOL Target',
      'PROOL Đạt',
      'PROOL % Đạt',
    ],
    RENTAL: [
      'RENTAL Target',
      'RENTAL Đạt',
      'RENTAL % Đạt',
    ],
    SOS: [
      'SOS U',
      'SOS siêu thị',
      '% SOS',
    ],
  }
  const [filterDataSelected, setFilterDataSelected] = useState({
    store_code: [],
    format_type: [],
    sup: [],
    sr: [],
    customer: [],
    shop: [],
    user: [],
  });
  const [filterDatas, setFilterDatas] = useState({
    user: null,
    customer: null,
    shop: null,
    format_type: null,
    created_at: null,
    ess_store_code: null,
    supervisor: null,
    sr_name: null,
  });
  const [columnFilter, setColumnFilter] = useState({
    userids: [],
    customers: [],
    format_type: [],
    shop: [],
    created_at: [],
    ess_store_code: [],
    supervisor: [],
    sr_name: [],
  });
  let keyDatas = [];
  React.useEffect(() => {
    let delayed = true;
    dispatch(homeActions.getCheckInCheckOut(date, delayed));
  }, [dispatch, date]);

  React.useEffect(() => {
    if (lastRecordExpand) {
      let _checkListDataByRecord = { ...checkListDataByRecord };
      if (checkList) {

        _checkListDataByRecord['recordId' + lastRecordExpand.id] = checkList;
      }
      setCheckListDataByRecord(_checkListDataByRecord);
    }
  }, [isLoading, checkList]);
  React.useEffect(() => {
    if (!user || !['tester', 'admin'].includes(user.role)) {
      // history.push('/');
    }
  }, [user]);
  React.useEffect(() => {
    if (lastRecordExpand) {
      let checklistids = [];
      for (let i = 0; i < lastRecordExpand.checklists.length; i++) {
        checklistids.push(lastRecordExpand.checklists[i].id);
      }
      dispatch(homeActions.getCheckListById(checklistids));
    }

  }, [lastRecordExpand]);
  const getListPhotos = (checklist_id, category, sub_category) => {
    setCurrentDataExpand({ checklist_id, category, sub_category });
    setCurrentDataExpandCompare({ checklist_id, category, sub_category })
    dispatch(homeActions.getListPhotos(checklist_id, category, sub_category));
  }
  const filters = (arr) => {
    return _.sortBy(_.uniqBy(arr, 'text'), ['text']);
  };
  // const onSelect = (selectedKeys, info) => {
  //   if (info && info.selectedNodes.length && info.selectedNodes[0].isEndTree) {
  //     const { checklist_id, category, sub_category } = info.selectedNodes[0].data;
  //     getListPhotos(checklist_id, category, sub_category);
  //   }
  // };
  const onFinish = (values) => {
    setFilterDatas({
      user: null,
      customer: null,
      shop: null,
      format_type: null,
      created_at: null,
      ess_store_code: null,
      supervisor: null,
      sr_name: null,
    });
    setFilterDataSelected({
      store_code: [],
      format_type: [],
      sup: [],
      sr: [],
      customer: [],
      shop: [],
      user: [],
    });
    setCheckListTypeBySubCategorySelected([]);
    setLastRecordExpand(null);
    const { date } = values;
    const newDate = [];
    if (date && date.length > 0) {
      newDate.push(moment.utc(date[0]).tz(moment.tz.guess(true)).format('DD-MM-YYYY'));
    }
    if (date && date.length > 1) {
      newDate.push(moment.utc(date[1]).tz(moment.tz.guess(true)).format('DD-MM-YYYY'));
    }

    setDate(newDate);
  };
  const updateFilter = () => {
    let _listCheckInCheckOut = listCheckInCheckOut.filter((_item) => {
      let _ess_store_code = _item.shop.custom_attributes.ess_store_code || 'Khác';
      let _supervisor = _item.shop.custom_attributes.supervisor || 'Khác';
      let _sr_name = _item.shop.custom_attributes.sr_name || 'Khác';
      let allow = true;
      if (filterDatas.user && filterDatas.user.length) {
        allow = filterDatas.user.includes(_item.user.id);
      }
      if (allow && filterDatas.customer && filterDatas.customer.length) {
        allow = _item.shop.custom_attributes.customer ? filterDatas.customer.includes(_item.shop.custom_attributes.customer) : false;
      }
      if (allow && filterDatas.shop && filterDatas.shop.length) {
        allow = filterDatas.shop.includes(_item.shop.id);
      }
      if (allow && filterDatas.ess_store_code && filterDatas.ess_store_code.length) {
        allow = filterDatas.ess_store_code.includes(_ess_store_code);
      }
      if (allow && filterDatas.supervisor && filterDatas.supervisor.length) {
        allow = filterDatas.supervisor.includes(_supervisor);
      }
      if (allow && filterDatas.sr_name && filterDatas.sr_name.length) {
        allow = filterDatas.sr_name.includes(_sr_name);
      }
      if (allow && filterDatas.format_type && filterDatas.format_type.length) {
        allow = _item.shop.custom_attributes.format_type ? filterDatas.format_type.includes(_item.shop.custom_attributes.format_type) : false;
      }
      if (allow && filterDatas.created_at) {
        const checkinTime = moment
          .utc(_item.created_at)
          .tz(moment.tz.guess(true))
          .format('DD-MM-YYYY');
        allow = filterDatas.created_at.includes(checkinTime);
      }
      return allow;
    });

    let userids = [],
      customers = [],
      format_type = [],
      shop = [],
      created_at = [],
      ess_store_code = [],
      supervisor = [],
      sr_name = []
      ;
    let _columnFilter = {
      userids: [],
      customers: [],
      format_type: [],
      shop: [],
      created_at: [],
      ess_store_code: [],
      supervisor: [],
      sr_name: [],
    };
    _listCheckInCheckOut.map((_item) => {

      let _ess_store_code = _item.shop.custom_attributes.ess_store_code || 'Khác';
      let _supervisor = _item.shop.custom_attributes.supervisor || 'Khác';
      let _sr_name = _item.shop.custom_attributes.sr_name || 'Khác';

      let customerName = _item.shop.custom_attributes.customer || '';
      if (customerName && !customers.includes(customerName)) {
        customers.push(customerName);
        _columnFilter.customers.push(
          {
            text: customerName,
            value: customerName,
          }
        );
      }
      if (_item.user.id && !userids.includes(_item.user.id)) {
        userids.push(_item.user.id);
        _columnFilter.userids.push(
          {
            text: _item.user.name + ' (' + _item.user.username + ')',
            value: _item.user.id,
          }
        );
      }
      if (_item.shop.id && !shop.includes(_item.shop.id)) {
        shop.push(_item.shop.id);
        _columnFilter.shop.push(
          {
            text: _item.shop.name + " (" + _item.shop.id + ")",
            value: _item.shop.id,
          }
        );
      }
      if (_item.shop.custom_attributes.format_type && !format_type.includes(_item.shop.custom_attributes.format_type)) {
        format_type.push(_item.shop.custom_attributes.format_type);
        _columnFilter.format_type.push(
          {
            text: _item.shop.custom_attributes.format_type,
            value: _item.shop.custom_attributes.format_type
          }
        );
      }

      if (!ess_store_code.includes(_ess_store_code)) {
        ess_store_code.push(_ess_store_code);
        _columnFilter.ess_store_code.push(
          {
            text: _ess_store_code,
            value: _ess_store_code
          }
        );
      }

      if (!supervisor.includes(_supervisor)) {
        supervisor.push(_supervisor);
        _columnFilter.supervisor.push(
          {
            text: _supervisor,
            value: _supervisor
          }
        );
      }

      if (!sr_name.includes(_sr_name)) {
        sr_name.push(_sr_name);
        _columnFilter.sr_name.push(
          {
            text: _sr_name,
            value: _sr_name
          }
        );
      }
      const checkinTime = moment
        .utc(_item.created_at)
        .tz(moment.tz.guess(true))
        .format('DD-MM-YYYY');
      if (!created_at.includes(checkinTime)) {
        created_at.push(checkinTime);
        _columnFilter.created_at.push(
          {
            text: checkinTime,
            value: checkinTime
          }
        );
      }
    });
    // return false;
    setColumnFilter(_columnFilter);
  }

  React.useEffect(() => {
    if (listCheckInCheckOut && listCheckInCheckOut.length) {
      updateFilter();
    }

  }, [filterDatas, listCheckInCheckOut]);
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setFilterDatas(filters);
  }
  // const onChangeTab = (key) => {
  //   if (Number(key) !== checkList?.id) {
  //     console.log('key', key);
  //     dispatch(homeActions.getCheckListById(key));
  //   }
  // };

  // const onChangePass = (id, checked) => {
  //   const newData = data.map((d) => {
  //     if (d.id === id) {
  //       d = {
  //         ...d,
  //         data: {
  //           ...d.data,
  //           pass: checked,
  //         },
  //       };
  //     }

  //     return d;
  //   });
  //   setData(newData);
  // };

  // const onChangeQcNode = (id, e) => {
  //   const newData = data.map((d) => {
  //     if (d.id === id) {
  //       d = {
  //         ...d,
  //         data: {
  //           ...d.data,
  //           qc_note: e.target.value,
  //         },
  //       };
  //     }

  //     return d;
  //   });
  //   setData(newData);
  // };
  const collapseCallback = (key) => {
    console.log('collapseCallback', key);
  }
  const showGallery = (subcatekey, category, recordChecklistTypes, _checklists) => {
    if (subcatekey) {
      let subcatekeys = subcatekey.split('_subcate_', 2);
      if (subcatekeys.length == 2) {
        let combineKey = removeVietnameseTones(category + '-' + subcatekeys[1], '-');
        let ids = recordChecklistTypes[combineKey];
        let _checkListTypeBySubCategorySelected = [];
        if (ids.length) {
          for (let i = 0; i < ids.length; i++) {

            let keychecklist = 'ckl_' + ids[i];
            if (!i) {
              setCurrentCheckListType(_checklists[keychecklist]);
              totalsSummary = null;
            }
            _checkListTypeBySubCategorySelected.push({
              id: ids[i],
              name: _checklists[keychecklist],
              combineKey,
              category,
              subcategory: subcatekeys[1]
            })
          }
        }
        setCheckListTypeBySubCategorySelected(_checkListTypeBySubCategorySelected);
        let checklist_id = (recordChecklistTypes && recordChecklistTypes[combineKey].length) ? recordChecklistTypes[combineKey][0] : 0;
        // console.log('subcatekey', subcatekey);
        // console.log('combineKey', combineKey);
        // console.log('checklist_id', checklist_id);
        if (checklist_id) {
          getListPhotos(checklist_id, category, subcatekeys[1]);
        }

      } else {
        setCheckListTypeBySubCategorySelected(null);
        setCurrentCheckListType('');
      }
    } else {
      setCheckListTypeBySubCategorySelected(null);
      setCurrentCheckListType('');
    }

  }
  const onChangeTab = (tabkey) => {
    if (tabkey) {
      let tabkeys = tabkey.split('ckl_', 2);
      if (tabkeys.length == 2) {
        let index = parseInt(tabkeys[1]);
        let checklistrecord = checkListTypeBySubCategorySelected[index];
        if (checklistrecord) {
          getListPhotos(checklistrecord.id, checklistrecord.category, checklistrecord.subcategory);
        }

      }
    }
  }
  const submit = (item) => {

    dispatch(
      homeActions.submitChecklistItem(item.id, {
        pass: item.data.pass,
        qc_note: item.data.qc_note,
      }),
    );
  };
  return (
    <Row>
      <Col span={24}>
        <Card
          title="Chi tiết"
          bordered={false}
          style={{ width: '100%' }}
          extra={
            <Form form={form} name="filter" layout="inline" onFinish={onFinish}>
              <Form.Item title="Date" name="date" rules={[{ type: 'array', required: false }]}>
                <RangePicker style={{ width: '100%' }} format="DD-MM-YYYY" />
              </Form.Item>
              <Form.Item shouldUpdate={true}>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              </Form.Item>
            </Form>
          }
        >
          <div style={{ marginBottom: 10 }}>
            <Row>
              <Col span={6} style={{ padding: '0 10px' }}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn mã siêu thị"
                  value={filterDataSelected.store_code || []}
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.ess_store_code = value;
                    setFilterDatas(_filterDatas);
                    let _filterDataSelected = { ...filterDataSelected };
                    _filterDataSelected.store_code = value;
                    setFilterDataSelected(_filterDataSelected);
                  }}
                >
                  {columnFilter && columnFilter.ess_store_code && columnFilter.ess_store_code.length ? columnFilter.ess_store_code.map((_item) => {
                    return <Select.Option key={_item.value}>{_item.text}</Select.Option>
                  }) : null}
                </Select>
              </Col>
              <Col span={6} style={{ padding: '0 10px' }}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn loại Format"
                  value={filterDataSelected.format_type || []}
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.format_type = value;
                    setFilterDatas(_filterDatas);
                    let _filterDataSelected = { ...filterDataSelected };
                    _filterDataSelected.format_type = value;
                    setFilterDataSelected(_filterDataSelected);
                  }}
                >
                  {columnFilter && columnFilter.format_type && columnFilter.format_type.length ? columnFilter.format_type.map((_item) => {
                    return <Select.Option key={_item.value}>{_item.text}</Select.Option>
                  }) : null}
                </Select>
              </Col>
              <Col span={6} style={{ padding: '0 10px' }}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn sup"
                  value={filterDataSelected.sup || []}
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.supervisor = value;
                    setFilterDatas(_filterDatas);
                    let _filterDataSelected = { ...filterDataSelected };
                    _filterDataSelected.sup = value;
                    setFilterDataSelected(_filterDataSelected);
                  }}
                >
                  {columnFilter && columnFilter.supervisor && columnFilter.supervisor.length ? columnFilter.supervisor.map((_item) => {
                    return <Select.Option key={_item.value}>{_item.text}</Select.Option>
                  }) : null}
                </Select>
              </Col>
              <Col span={6} style={{ padding: '0 10px' }}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn SR"
                  value={filterDataSelected.sr || []}
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.sr_name = value;
                    setFilterDatas(_filterDatas);
                    let _filterDataSelected = { ...filterDataSelected };
                    _filterDataSelected.sr = value;
                    setFilterDataSelected(_filterDataSelected);
                  }}
                >
                  {columnFilter && columnFilter.sr_name && columnFilter.sr_name.length ? columnFilter.sr_name.map((_item) => {
                    return <Select.Option key={_item.value}>{_item.text}</Select.Option>
                  }) : null}
                </Select>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={8} style={{ padding: '0 10px' }}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn customer"
                  value={filterDataSelected.customer || []}
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.customer = value;
                    setFilterDatas(_filterDatas);
                    let _filterDataSelected = { ...filterDataSelected };
                    _filterDataSelected.customer = value;
                    setFilterDataSelected(_filterDataSelected);
                  }}
                >
                  {columnFilter && columnFilter.customers && columnFilter.customers.length ? columnFilter.customers.map((customer) => {
                    return <Select.Option key={customer.value}>{customer.text}</Select.Option>
                  }) : null}
                </Select>
              </Col>
              <Col span={8} style={{ padding: '0 10px' }}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn cửa hàng"
                  value={filterDataSelected.shop || []}
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.shop = [];
                    if (value) {
                      for (let i = 0; i < value.length; i++) {
                        _filterDatas.shop.push(parseInt(value[i]));
                      }
                    }
                    setFilterDatas(_filterDatas);
                    let _filterDataSelected = { ...filterDataSelected };
                    _filterDataSelected.shop = value;
                    setFilterDataSelected(_filterDataSelected);
                  }}
                >
                  {columnFilter && columnFilter.shop && columnFilter.shop.length ? columnFilter.shop.map((shop) => {
                    return <Select.Option key={shop.value + '___' + shop.text}>{shop.text}</Select.Option>
                  }) : null}
                </Select>
              </Col>
              <Col span={8} style={{ padding: '0 10px' }}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn user"
                  value={filterDataSelected.user}
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.user = [];
                    if (value) {
                      for (let i = 0; i < value.length; i++) {
                        _filterDatas.user.push(parseInt(value[i]));
                      }
                    }
                    setFilterDatas(_filterDatas);
                    let _filterDataSelected = { ...filterDataSelected };
                    _filterDataSelected.user = value;
                    setFilterDataSelected(_filterDataSelected);
                  }}
                >
                  {columnFilter && columnFilter.userids && columnFilter.userids.length ? columnFilter.userids.map((user) => {
                    return <Select.Option key={user.value + '___' + user.text}>{user.text}</Select.Option>
                  }) : null}
                </Select>
              </Col>

            </Row>
          </div>
          <Row>
            <Col span={17}>
              <Row>
                <Col span={24}>
                  <Table
                    onChange={onChangeTable}
                    columns={[
                      {
                        title: 'User',
                        dataIndex: 'user',
                        key: 'user',
                        render: (u, record) => {
                          return <Text style={{ fontSize: 12 }}>{u.name}</Text>;
                        },
                        // filters: columnFilter.userids,
                        // filteredValue: filterDatas.user || null,
                        // filterSearch: true,
                        onFilter: (value, record) => {
                          return record.user.id === value;
                        },
                      },
                      {
                        title: 'Shop',
                        dataIndex: 'shop',
                        key: 'shop',
                        render: (v, record) => {
                          const shopName = record.shop !== null ? record.shop.name : '';
                          return (
                            <Space direction="vertical">
                              <Text style={{ fontSize: 12 }}>{shopName}</Text>
                            </Space>
                          );
                        },
                        // filters: columnFilter.shop,
                        // filteredValue: filterDatas.shop || null,
                        onFilter: (value, record) => {
                          console.log('valuevalue', value);
                          return record.shop.id === value;
                        },
                        // filterSearch: true,
                      },
                      {
                        title: 'Customer',
                        dataIndex: 'customer',
                        key: 'customer',
                        render: (v, record) => {
                          const customerName = record.shop !== null ? record.shop.custom_attributes.customer : '';
                          return (
                            <Space direction="vertical">
                              <Text style={{ fontSize: 12 }}>{customerName}</Text>
                            </Space>
                          );
                        },
                        // filters: columnFilter.customers,
                        // filteredValue: filterDatas.customer || null,
                        onFilter: (value, record) => {
                          return record.shop.custom_attributes.customer === value;
                        },
                      },
                      {
                        title: 'Format',
                        dataIndex: 'format',
                        key: 'format',
                        render: (v, record) => {
                          return (
                            <Space direction="vertical">
                              <Text style={{ fontSize: 12 }}>{record.shop.custom_attributes.format_type}</Text>
                            </Space>
                          );
                        },
                      },
                      {
                        title: 'Picture',
                        dataIndex: 'created_at',
                        key: 'created_at',
                        width: '250px',
                        render: (v, record) => {
                          const timeCheckin = moment
                            .utc(v)
                            .tz(moment.tz.guess(true))
                            .format('DD-MM-YYYY HH:mm:ss');
                          const timeCheckout =
                            record.user_checkout !== null && record.user_checkout.created_at !== null
                              ? moment
                                .utc(record.user_checkout.created_at)
                                .tz(moment.tz.guess(true))
                                .format('DD-MM-YYYY HH:mm:ss')
                              : '';
                          const noteCheckIn = record.note !== 'undefined' ? record.note : '';
                          const noteCheckOut =
                            record.user_checkout != null && record.user_checkout.note !== 'undefined'
                              ? record.user_checkout.note
                              : '';
                          const datacheckout =
                            record.user_checkout != null && record.user_checkout.data !== 'undefined'
                              ? record.user_checkout.data
                              : null;
                          return (
                            <div style={{ fontSize: 12 }}>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><div title="Checkin time" style={{ color: green[6] }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}><LoginOutlined style={{ marginRight: 2 }} /></div>
                                {noteCheckIn ? <Text>{noteCheckIn}</Text> : null}
                              </div>
                                {record.photos && record.photos.length ? record.photos.map((photo, indexphoto) => {
                                  return <div style={{ marginLeft: 5 }} key={'indexphoto' + indexphoto}><Image
                                    src={process.env.REACT_APP_API_URL + photo.image}
                                    height={40}
                                    width={40}
                                    style={{ objectFit: 'cover' }}
                                    preview={true}
                                  /></div>
                                }) : null}
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderTop: timeCheckout ? '1px solid #eaeaea' : 'none', paddingTop: timeCheckout ? 5 : 0 }}>
                                {timeCheckout ? <div title="Checkout time" style={{ color: gold[6] }}>
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}> <LogoutOutlined style={{ marginRight: 2 }} /></div>
                                  {noteCheckOut ? <Text>{noteCheckOut}</Text> : null}
                                  {datacheckout ? <div>
                                    {datacheckout.audit_status ? <Text>{datacheckout.audit_status}</Text> : null}
                                    <hr style={{ margin: '2px 0', borderTop: 'none', borderBottom: '1px solid #eaeaea' }} />
                                    {datacheckout.predefined_note ? <Text>{datacheckout.predefined_note}</Text> : null}
                                  </div> : null}
                                </div> : null}
                                {record.user_checkout.photos &&
                                  record.user_checkout.photos.length > 0 && (
                                    <div style={{ marginLeft: 5 }}><Image
                                      src={`${url}${record.user_checkout.photos[0].image}`}
                                      height={40}
                                      width={40}
                                      style={{ objectFit: 'cover' }}
                                      preview={true}
                                    /></div>
                                  )}

                              </div>

                            </div>
                          );
                        },
                      },
                    ]}
                    expandable={{
                      expandedRowRender: (record) => {
                        let keyr = 'recordId' + record.id;

                        let _checklists = {};
                        for (let i = 0; i < record.checklists.length; i++) {
                          let keychecklist = 'ckl_' + record.checklists[i].id;
                          _checklists[keychecklist] = record.checklists[i].checklist_type.toUpperCase();
                        }
                        let categories = (checkListDataByRecord && checkListDataByRecord[keyr]) ? checkListDataByRecord[keyr].categories : {};
                        let recordChecklistTypes = (checkListDataByRecord && checkListDataByRecord[keyr]) ? checkListDataByRecord[keyr].checklistTypes : {};
                        let _categories = Object.keys(categories).sort();
                        let nganhhang = [];
                        let totalsSummaryCate = {};
                        return (_categories.length ? <>
                          {record && record.user_checkout && record.user_checkout.custom_attributes && record.user_checkout.custom_attributes.summary_data ? <Collapse accordion>
                            <Collapse.Panel header='SUMMARY' key={'summary_data'} >
                              <div style={{ maxHeight: 300, overflowY: 'auto' }}>
                                <table style={{ marginBottom: 5, fontSize: 12 }}>
                                  <thead className="ant-table-thead" style={{ position: 'sticky', top: 0, zIndex: 4 }}>
                                    <tr>
                                      <th style={{ padding: 5, width: 135, textAlign: 'center' }} rowSpan="2">NGÀNH HÀNG</th>
                                      {Object.entries(record.user_checkout.custom_attributes.summary_data).map(([key2, value2], index) => {
                                        console.log('key2key2', key2);
                                        let nganhhanginobject = (value2) ? Object.keys(value2) : [];
                                        nganhhang = [...new Set([...nganhhang, ...nganhhanginobject])];
                                        return <th key={'th' + index} style={{ padding: 5, textAlign: 'center', borderBottom: '1px solid #f0f0f0' }} colSpan="3">{key2.toUpperCase()}</th>
                                      })}
                                    </tr>
                                    <tr>
                                      {Object.entries(record.user_checkout.custom_attributes.summary_data).map(([key2, value2], index) => {

                                        let key3 = key2.toUpperCase();
                                        return (key3 == 'SOS' ? <React.Fragment key={'index' + index}>
                                          <th style={{ padding: 5, textAlign: 'center' }}>Unilever</th>
                                          <th style={{ padding: 5, textAlign: 'center' }}>Total</th>
                                          <th style={{ padding: 5, textAlign: 'center' }}>%SOS</th>
                                        </React.Fragment>
                                          : <React.Fragment key={'index' + index}>
                                            <th style={{ padding: 5, textAlign: 'center' }}>Chỉ tiêu</th>
                                            <th style={{ padding: 5, textAlign: 'center' }}>Thực đạt</th>
                                            <th style={{ padding: 5, textAlign: 'center' }}>% thực đạt</th>
                                          </React.Fragment>)
                                      })}
                                    </tr>
                                  </thead>
                                  <tbody className="ant-table-tbody">
                                    {nganhhang.length > 0 && nganhhang.map((key2, index) => {
                                      let k2 = key2.toUpperCase();
                                      if (k2 == 'TOTALS') {
                                        Object.entries(record.user_checkout.custom_attributes.summary_data).map(([key3, value3], index) => {
                                          totalsSummaryCate[key3] = (value3 && value3[key2]) ? value3[key2] : [];
                                        })
                                      }
                                      return ((k2 != 'TOTAL' && k2 != 'TOTALS' && (k2 != 'VOID_COUNT') && (k2 != 'NO_STOCK_COUNT') && (k2 != 'FAIL_STOCK_COUNT')) && <tr key={'index' + index}>
                                        <td>{k2}</td>
                                        {Object.entries(record.user_checkout.custom_attributes.summary_data).map(([key3, value3], index) => {
                                          return <React.Fragment key={'index' + key3 + index}>
                                            <td style={{ padding: 5, textAlign: 'center' }}>{value3 && value3[key2] ? <NumberFormat value={value3[key2][0]} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : 0}</td>
                                            <td style={{ padding: 5, textAlign: 'center' }}>{value3 && value3[key2] ? <NumberFormat value={value3[key2][1]} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : 0}</td>
                                            <td style={{ padding: 5, textAlign: 'center' }}>{value3 && value3[key2] ? <NumberFormat value={value3[key2][2]} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /> : 0}</td>
                                          </React.Fragment>
                                        })}
                                      </tr>)
                                    })}
                                    {Object.entries(totalsSummaryCate).length ? <tr>
                                      <td>TOTAL</td>
                                      {Object.entries(record.user_checkout.custom_attributes.summary_data).map(([key3, value3], index) => {
                                        if (!value3) {
                                          return <React.Fragment key={'index' + key3 + index}>
                                            <td style={{ padding: 5, textAlign: 'center' }}></td>
                                            <td style={{ padding: 5, textAlign: 'center' }}></td>
                                            <td style={{ padding: 5, textAlign: 'center' }}></td>
                                          </React.Fragment>
                                        }
                                        let totals = value3['totals'] || value3['TOTALS'];
                                        return <React.Fragment key={'index' + key3 + index}>
                                          <td style={{ padding: 5, textAlign: 'center' }}><NumberFormat value={totals[0]} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /></td>
                                          <td style={{ padding: 5, textAlign: 'center' }}><NumberFormat value={totals[1]} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /></td>
                                          <td style={{ padding: 5, textAlign: 'center' }}><NumberFormat value={totals[2]} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}</Text>} /></td>
                                        </React.Fragment>
                                      })}
                                    </tr> : null}
                                  </tbody>
                                </table>
                              </div>
                            </Collapse.Panel>
                          </Collapse> : null}
                          <Collapse accordion key={'keyr' + keyr} onChange={(k) => {
                            let _currentDataExpand = { ...currentDataExpand }
                            if (!k) {
                              _currentDataExpand.category = '';
                              setCheckListTypeBySubCategorySelected([]);
                            } else {
                              let arr = k.split("-cate-", 2);
                              if (arr.length == 2) {
                                _currentDataExpand.category = arr[1];
                              }
                            }
                            setCurrentDataExpand(_currentDataExpand);
                            setCurrentCheckListType('');
                          }}>
                            {_categories.map((key) => {
                              let subcategory = categories[key].subcategory;
                              return <Collapse.Panel header={categories[key].category_vn} key={keyr + '-cate-' + key} destroyInactivePanel={true}>
                                <Collapse onChange={(subcatekey) => showGallery(subcatekey, key, recordChecklistTypes, _checklists)} accordion key={'collapse-' + keyr + '-cate-' + key} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} >
                                  {Object.keys(subcategory).sort().map((key2, index) => {
                                    let combineKey = removeVietnameseTones(key + '-' + key2, '-');
                                    return (<Collapse.Panel header={subcategory[key2].sub_category_vn} key={keyr + '-' + combineKey + '_subcate_' + key2} style={{ marginBottom: 5 }}>

                                      <Tabs defaultActiveKey={combineKey + '_ckl_' + recordChecklistTypes[combineKey][0]}
                                        // onChange={(tabkey) => { onChangeTab(tabkey, key, key2) }}
                                        onChange={(tabkey) => {
                                          if (tabkey) {
                                            let arr = tabkey.split("_clt_", 2);
                                            if (arr.length == 2) {
                                              setCurrentCheckListType(arr[1]);
                                              totalsSummary = null;
                                            }
                                          }

                                        }}
                                      >
                                        {recordChecklistTypes[combineKey].map((checklistId) => {
                                          let keychecklist = 'ckl_' + checklistId;
                                          let checklistType = _checklists[keychecklist];
                                          let combineKey2 = combineKey + '-' + checklistType;
                                          let __datas = checkListDataByRecord[keyr].checklist_items[combineKey2];

                                          // console.log('checkListDataByRecord[keyr].checklist_items', checkListDataByRecord[keyr].checklist_items);
                                          return ((checklistType) ? <TabPane tab={checklistType} key={combineKey + '_' + keychecklist + '_clt_' + checklistType} >
                                            <ListItem datas={__datas} submit={null} checklistType={checklistType} format_type={record.shop.custom_attributes.format_type} itemPerRow={itemPerRow[checklistType.toUpperCase()]} />
                                          </TabPane> : null)
                                        })}
                                      </Tabs>
                                    </Collapse.Panel>)
                                  })
                                  }
                                </Collapse>
                              </Collapse.Panel>
                            })}
                          </Collapse>
                        </> : null)

                        // return null;
                        // return (
                        //   <Tabs defaultActiveKey={record.id} >
                        //     {record.checklists.map((checklist) => {
                        //       console.log('checklist.id', checklist.id);
                        //       let _datas = (data && data['checklistdata' + checklist.id] && data['checklistdata' + checklist.id].length) ? data['checklistdata' + checklist.id] : [];
                        //       return (<TabPane tab={checklist.checklist_type} key={checklist.id}>
                        //         <AntList
                        //           grid={{
                        //             gutter: 16,
                        //             xs: 1,
                        //             sm: 2,
                        //             md: 4,
                        //             lg: 4,
                        //             xl: 5,
                        //             xxl: 5,
                        //           }}
                        //           pagination={{
                        //             showSizeChanger: true,
                        //             onChange: page => {
                        //               console.log(page);
                        //             },
                        //             position: 'bottomCenter',
                        //             // total:{(data && data['checklistdata' + checklist.id] && data['checklistdata' + checklist.id].length) ? data['checklistdata' + checklist.id].length : 0},
                        //             hideOnSinglePage: true
                        //           }}

                        //           dataSource={_datas}
                        //           renderItem={(item, index) => {
                        //             // return <span key={'index' + index}>{item.id}</span>
                        //             return <ListItem onSubmit={(item) => submit(item)} key={item.id} item={item} />
                        //           }}
                        //         />
                        //       </TabPane>
                        //       )
                        //     })}
                        //   </Tabs>
                        // );
                      },
                      onExpand: (expanded, record) => {
                        let _currentDataExpand = { ...currentDataExpand }
                        if (expanded) {
                          setLastRecordExpand(record);
                        } else {
                          setLastRecordExpand(null);
                          _currentDataExpand.check_list = 0;
                          setCheckListTypeBySubCategorySelected([]);
                          setCurrentDataExpand(_currentDataExpand);
                        }
                      },
                      rowExpandable: (record) => record.checklists && record.checklists.length > 0,
                    }}
                    dataSource={listCheckInCheckOut && listCheckInCheckOut.length ? listCheckInCheckOut.filter((_item) => {
                      let _ess_store_code = _item.shop.custom_attributes.ess_store_code || 'Khác';
                      let _supervisor = _item.shop.custom_attributes.supervisor || 'Khác';
                      let _sr_name = _item.shop.custom_attributes.sr_name || 'Khác';
                      let allow = true;
                      if (filterDatas.user && filterDatas.user.length) {
                        allow = filterDatas.user.includes(_item.user.id);
                      }
                      if (allow && filterDatas.customer && filterDatas.customer.length) {
                        allow = _item.shop.custom_attributes.customer ? filterDatas.customer.includes(_item.shop.custom_attributes.customer) : false;
                      }
                      if (allow && filterDatas.shop && filterDatas.shop.length) {
                        allow = filterDatas.shop.includes(_item.shop.id);
                      }
                      if (allow && filterDatas.ess_store_code && filterDatas.ess_store_code.length) {
                        allow = filterDatas.ess_store_code.includes(_ess_store_code);
                      }
                      if (allow && filterDatas.supervisor && filterDatas.supervisor.length) {
                        allow = filterDatas.supervisor.includes(_supervisor);
                      }
                      if (allow && filterDatas.sr_name && filterDatas.sr_name.length) {
                        allow = filterDatas.sr_name.includes(_sr_name);
                      }
                      if (allow && filterDatas.format_type && filterDatas.format_type.length) {
                        allow = _item.shop.custom_attributes.format_type ? filterDatas.format_type.includes(_item.shop.custom_attributes.format_type) : false;
                      }
                      if (allow) {
                        allow = (_item.user_checkout !== null && _item.user_checkout.created_at !== null)
                      }
                      if (allow && filterDatas.created_at) {
                        const checkinTime = moment
                          .utc(_item.created_at)
                          .tz(moment.tz.guess(true))
                          .format('DD-MM-YYYY');
                        allow = filterDatas.created_at.includes(checkinTime);
                      }
                      return allow;
                    }) : []}
                    rowKey={(record) => record.id}
                    pagination={{ position: ['bottomCenter'] }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={7}>
              <div style={{ position: 'sticky', paddingLeft: 10, top: 0 }}>
                <Card
                  title="Photos"
                  bordered={false}
                  style={{ width: '100%' }}
                >
                  {checkListTypeBySubCategorySelected && checkListTypeBySubCategorySelected.length ? <Tabs
                    onChange={onChangeTab}
                  >
                    {checkListTypeBySubCategorySelected.map((checklistrecord, index) => {
                      let keychecklist = 'ckl_' + index;
                      // let checklistType = _checklists[keychecklist];
                      // let combineKey2 = combineKeySelected + '-' + checklistType;
                      // let __datas = checkListDataByRecord[keyr].checklist_items[combineKey2];
                      // console.log('checkListTypeBySubCategorySelected', checkListTypeBySubCategorySelected);
                      // console.log('checklistrecord', checklistrecord);
                      // console.log('checkListTypeBySubCategorySelected', combineKey2);
                      // console.log('checkListDataByRecord[keyr].checklist_items', checkListDataByRecord[keyr].checklist_items);
                      return (<TabPane tab={checklistrecord.name} key={keychecklist} >
                        {(listPhotos && listPhotos.length && (currentDataExpand.check_list == currentDataExpandCompare.check_list) && (currentDataExpand.category == currentDataExpandCompare.category) && (currentDataExpand.sub_category == currentDataExpandCompare.sub_category)) ? <div style={{ paddingRight: 10, paddingLeft: 10 }}>

                          <Swiper
                            spaceBetween={15}
                            loop={false}
                            navigation
                            breakpoints={{
                              // when window width is >= 640px
                              640: {
                                slidesPerView: 1,
                              },
                              // when window width is >= 768px
                              768: {
                                slidesPerView: 2,
                              },
                              1024: {
                                slidesPerView: 2,
                              },
                            }}
                            pagination={{ clickable: true }}
                          >
                            {listPhotos.map((item, index) => {
                              return (<SwiperSlide key={'slide_' + index} >
                                <Image
                                  preview={{ visible: false }}
                                  src={item.image}
                                  style={{ width: '100%', objectFit: 'cover' }}
                                  onClick={() => { setCurrentVis(index); setVisible(true) }}
                                />
                              </SwiperSlide >);
                            })}
                          </Swiper>
                          <div style={{ display: 'none' }}>
                            <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis), current: currentVis }}>
                              {listPhotos.map((item, index) => {
                                return <Image key={'image' + index} src={item.image} />
                              })}
                            </Image.PreviewGroup>
                          </div>
                        </div> : null}
                      </TabPane>
                      )
                    })}
                  </Tabs> : null}
                </Card>
                {lastRecordExpand && lastRecordExpand.user_checkout && lastRecordExpand.user_checkout.custom_attributes && lastRecordExpand.user_checkout.custom_attributes.summary_data && currentCheckListType ? <Card
                  title="Summary"
                  bordered={false}
                  style={{ width: '100%', }}
                >
                  <div style={{ maxHeight: 300, overflowY: 'auto' }}>
                    <table style={{ marginBottom: 5, fontSize: 12 }}>
                      <thead className="ant-table-thead">
                        <tr>
                          <th style={{ padding: 5, width: 175 }}>{currentCheckListType}</th>
                          {/* {Object.entries(lastRecordExpand.user_checkout.custom_attributes.summary_data).map(([key, value], index) => {

                            if (!index) {
                              keyDatas = [];
                            }
                            if (value) {
                              Object.entries(value).map(([key2]) => {
                                if (!keyDatas.includes(key2)) {
                                  keyDatas.push(key2);
                                }

                              })
                            }
                          })} */}
                          {currentCheckListType == 'SOS' ? <>
                            <th style={{ padding: 5 }}>Unilever</th>
                            <th style={{ padding: 5 }}>Total</th>
                            <th style={{ padding: 5 }}>%SOS</th>
                          </>
                            : <>
                              <th style={{ padding: 5 }}>Chỉ tiêu</th>
                              <th style={{ padding: 5 }}>Thực đạt</th>
                              <th style={{ padding: 5 }}>% thực đạt</th>
                            </>}
                        </tr>
                      </thead>
                      {lastRecordExpand.user_checkout.custom_attributes.summary_data[currentCheckListType.toLowerCase()] ? <tbody className="ant-table-tbody">
                        {Object.entries(lastRecordExpand.user_checkout.custom_attributes.summary_data[currentCheckListType.toLowerCase()]).map(([key2, value2], index) => {
                          let key3 = key2.toUpperCase();
                          if (key3 == 'TOTALS') {
                            totalsSummary = value2;
                            // setTotalsSummary(Object.entries(lastRecordExpand.user_checkout.custom_attributes.summary_data[currentCheckListType.toLowerCase()])[key2]);
                          }
                          return ((key3 != 'TOTAL' && key3 != 'TOTALS') ? <tr key={'tr' + index}>
                            <td style={{ padding: 5 }}>{key3}</td>
                            {value2.length ? value2.map((n, index3) => {
                              return <td key={'index3' + index3} style={{ padding: 5 }}>
                                <NumberFormat value={n} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}</Text>} />
                              </td>
                            }) : null}
                          </tr> : null)
                        })}
                        {totalsSummary ? <tr>
                          <td style={{ padding: 5 }}>TOTAL</td>
                          <td style={{ padding: 5 }}>
                            <NumberFormat value={totalsSummary[0]} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}</Text>} />
                          </td>
                          <td style={{ padding: 5 }}>
                            <NumberFormat value={totalsSummary[1]} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}</Text>} />
                          </td>
                          <td style={{ padding: 5 }}>
                            <NumberFormat value={totalsSummary[2]} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text>{value}</Text>} />
                          </td>
                        </tr> : null}
                      </tbody> : null}
                    </table>
                  </div>
                </Card> : null}


              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(ReportDetailLayout);
