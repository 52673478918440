import React from 'react';
import {
    Row,
    Col,
    Card,
    Table,
    Form,
    Button,
    Switch,
    Input,
    Select,
    List,
    Alert,
    Typography
} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
function Olbig(props) {
    const item = props.item || {};
    const { ReasonList } = props;
    let data = { ...item.data };
    delete data.pass;
    delete data.qc_note;
    delete data.predefined_qc_note;
    const { Text } = Typography;
    const [filterDatas, setFilterDatas] = React.useState([]);
    React.useEffect(() => {
        let data = { ...item.data };
        delete data.pass;
        delete data.qc_note;
        delete data.predefined_qc_note;
        delete data.review_pass;
        const positions = [
            'MS',
            'SS',
            'GE',
            'HGR',
            'GE-C',
            'PI',
            'ALT',
            'COC',
            'KOC',
            'SB',
            'SK',
            'SSHF',
            'SHF',
            'ICC',
            'SSBF',
            'CSM',
        ];
        console.log('data', data);

        for (let i = 0; i < positions.length; i++) {
            if (typeof (data[positions[i]]) == 'undefined') {
                const key1 = `${positions[i]}-S`;
                const key2 = `${positions[i]}-U`;
                data[positions[i]] = {};
                data[positions[i]][key1] = '';
                data[positions[i]][key2] = '';
                data[positions[i]]['Checked'] = false;
            }
        }
        let _filterDatas = Object.entries(data).filter((_item) => {

            return (_item && _item.length >= 2 && _item[1]);
            // return (_item && _item.length >= 2 && _item[1] && _item[1].Checked);
        });
        setFilterDatas(_filterDatas);
    }, [item.data])
    return <div key={'item' + item.id}>
        {Object.entries(data).length ? <table style={{ marginBottom: 5 }}>
            <thead className="ant-table-thead">
                <tr>
                    <th style={{ padding: 5 }}></th>
                    {filterDatas.length ? filterDatas.map(([key, value]) => {
                        return <th key={'key' + key} style={{ padding: 5, width: 200, }}>
                            {`${key}`}

                        </th>
                    }) : <th style={{ padding: 5 }}>Action</th>}
                </tr>
            </thead>
            <tbody className="ant-table-tbody">
                <tr>
                    <td style={{ padding: 5 }}>Siêu thị</td>
                    {filterDatas.length ? filterDatas.map(([key, value]) => {
                        let k = key + '-S';
                        return <td key={'key' + key + k} style={{ padding: 5 }}>
                            <div>{data?.[key]?.[k] || '-'}</div>
                            {props.hasSubmitFields ? <div style={{ width: 200, }}>
                                {!item.data.pass ? <Form.Item name="predefined_qc_note" label="Lý do reject" labelCol={{ span: 24 }}>
                                    {ReasonList.length ? <Select
                                        onChange={(value) => props.onChangePredefinedQcNodeOlbig(k, value)}
                                        showSearch
                                        defaultValue={(item.data.predefined_qc_note && item.data.predefined_qc_note[k]) ? item.data.predefined_qc_note[k] : ''}
                                        placeholder="Chọn lý do"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {ReasonList.map((reason, index) => {
                                            return <Select.Option style={{ fontSize: 12.5 }} key={'reason' + index} value={reason.reason}>{reason.title || reason.reason}</Select.Option>
                                        })}
                                    </Select> : null}
                                </Form.Item> : null}
                                <Input
                                    style={{ width: '100%' }}
                                    value={item.data.qc_note[k] || ''}
                                    placeholder="Qc note"
                                    onChange={(e) => props.onChangeQcNodeOlbig(k, e)}
                                    disabled={props.isReview || false}
                                />
                            </div> : null}
                        </td>
                    }) : <td style={{ padding: 5 }}>
                        <div style={{ width: 200, }}>
                            {!item.data.pass ? <Form.Item name="predefined_qc_note" label="Lý do reject" labelCol={{ span: 24 }}>
                                {ReasonList.length ? <Select
                                    onChange={(value) => props.onChangePredefinedQcNodeOlbig('s', value)}
                                    showSearch
                                    defaultValue={item.data.predefined_qc_note}
                                    placeholder="Chọn lý do"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {ReasonList.map((reason, index) => {
                                        return <Select.Option style={{ fontSize: 12.5 }} key={'reason' + index} value={reason.reason}>{reason.title || reason.reason}</Select.Option>
                                    })}
                                </Select> : null}
                            </Form.Item> : null}
                            <Input
                                style={{ width: '100%' }}
                                value={item.data.qc_note['s'] || ''}
                                placeholder="Qc note"
                                onChange={(e) => props.onChangeQcNodeOlbig('s', e)}
                                disabled={props.isReview || false}
                            />
                        </div>
                    </td>}
                </tr>
                <tr>
                    <td style={{ padding: 5 }}>Unilever</td>
                    {filterDatas.length ? filterDatas.map(([key, value]) => {
                        let k = key + '-U';
                        return value ? <td key={'key' + key + k} style={{ padding: 5 }}>
                            <div>{data?.[key]?.[k] || '-'}</div>
                            {props.hasSubmitFields ? <div style={{ width: 200, }}>
                                {!item.data.pass ? <Form.Item name="predefined_qc_note" label="Lý do reject" labelCol={{ span: 24 }}>
                                    {ReasonList.length ? <Select
                                        onChange={(value) => props.onChangePredefinedQcNodeOlbig(k, value)}
                                        showSearch
                                        defaultValue={(item.data.predefined_qc_note && item.data.predefined_qc_note[k]) ? item.data.predefined_qc_note[k] : ''}
                                        placeholder="Chọn lý do"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {ReasonList.map((reason, index) => {
                                            return <Select.Option style={{ fontSize: 12.5 }} key={'reason' + index} value={reason.reason}>{reason.title || reason.reason}</Select.Option>
                                        })}
                                    </Select> : null}
                                </Form.Item> : null}
                                <Input
                                    style={{ width: '100%' }}
                                    value={item.data.qc_note[k] || ''}
                                    placeholder="Qc note"
                                    onChange={(e) => props.onChangeQcNodeOlbig(k, e)}
                                    disabled={props.isReview || false}
                                />
                            </div> : null}
                        </td> : null
                    }) : <td style={{ padding: 5 }}>
                        <div style={{ width: 200, }}>
                            {!item.data.pass ? <Form.Item name="predefined_qc_note" label="Lý do reject" labelCol={{ span: 24 }}>
                                {ReasonList.length ? <Select
                                    onChange={(value) => props.onChangePredefinedQcNodeOlbig('u', value)}
                                    showSearch
                                    defaultValue={item.data.predefined_qc_note}
                                    placeholder="Chọn lý do"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {ReasonList.map((reason, index) => {
                                        return <Select.Option style={{ fontSize: 12.5 }} key={'reason' + index} value={reason.reason}>{reason.title || reason.reason}</Select.Option>
                                    })}
                                </Select> : null}
                            </Form.Item> : null}
                            <Input
                                style={{ width: '100%' }}
                                value={item.data.qc_note['u'] || ''}
                                placeholder="Qc note"
                                onChange={(e) => props.onChangeQcNodeOlbig('u', e)}
                                disabled={props.isReview || false}
                            />
                        </div></td>}
                </tr>
            </tbody>
        </table> : null}
    </div>
}
export default React.memo(Olbig);