import React, { useState } from 'react'

import { Row, Col, Card, Form, Select, Button, Upload, Input, Space, Alert } from 'antd';
import { UndoOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { connect } from 'react-redux';
import { homeActions } from './redux/actions';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};
const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
const ChangePasswordLayout = ({ dispatch, user, errMessage, successMessage }) => {
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const [datas, setDatas] = useState({
    user_id: user.user_id,
    old_password: '',
    new_password: '',
    retype_new_password: '',
  });
  const onFinish = () => {
    dispatch(homeActions.changePassword(datas));
    setSubmitted(true)
  };
  const onFieldsChange = (field, allFields) => {
    setSubmitted(false);
    let _datas = { ...datas };
    allFields.map((f) => {
      _datas[f.name[0]] = f.value || '';
    });
    setDatas(_datas);
  };

  return (
    <Row>
      <Col span={24}>
        <Card title="Đổi mật khẩu" bordered={false} style={{ width: '100%' }}>
          <Form
            {...layout}
            name="upload-form"
            defaultValue={{ option: 'full' }}
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            form={form}
          >

            <Form.Item
              name="old_password"
              key='old_password'
              rules={[{ required: true, message: 'Please input your current password!' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Old password"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item
              name="new_password"
              key='new_password'
              onchange={(event) => {
                console.log('event', event);
                // StrengthChecker(value);
              }}
              rules={[{ required: true, message: 'Please input your new password!' }, { pattern: strongPassword, message: 'New password must be at least 8 characters and contain one number, one uppercase, one lowercase character and one special character' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="New password"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item
              name="retype_new_password"
              key='retype_new_password'
              rules={[{ required: true, message: 'Please retype your new password!' }]}

            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Retype new password"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            {submitted && errMessage ? <Alert message={errMessage} style={{ marginBottom: 10, width: '50%' }} type="error" showIcon /> : null}
            {submitted && successMessage ? <Alert message={successMessage} style={{ marginBottom: 10, width: '50%' }} type="success" showIcon /> : null}
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" style={{ marginRight: '10px' }}
                disabled={!(datas.old_password.length > 0 && (datas.new_password.length >= 8) && (datas.new_password == datas.retype_new_password) && strongPassword.test(datas.new_password))}

              >
                <UndoOutlined />
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth && state.auth.user ? state.auth.user : null,
    errMessage: state.home.changePasswordErrorMessage,
    successMessage: state.home.changePasswordSuccessMessage
  };
};
export default connect(mapStateToProps)(ChangePasswordLayout);
