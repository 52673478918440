import React from 'react';

import { Layout, Menu, Spin, Image, Avatar, Typography, Button } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UploadOutlined,
  AuditOutlined,
  ImportOutlined,
  DownloadOutlined,
  UserOutlined,
  UndoOutlined,
  DashboardOutlined,
  AlertOutlined,
  BarChartOutlined,
  HomeOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  SettingOutlined,
  RiseOutlined,
  CalendarOutlined,
  GroupOutlined,
  BoxPlotOutlined,
  HeatMapOutlined,
  DeploymentUnitOutlined,
  OneToOneOutlined,
  IdcardOutlined,
  RobotOutlined,
  ControlOutlined
} from '@ant-design/icons';

import { connect } from 'react-redux';
import authActions from '../auth/redux/actions';
import homeActions from '../home/redux/actions/home.actions';
import history from '../../common/history';
import { ColorsList } from '../../components/CustomStyle';
const { Header, Sider, Content } = Layout;

const HomeLayout = ({ children, dispatch, isLoading, user, isDownLoading, isOffline }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [activeKey, setActiveKey] = React.useState([]);
  const [userrole, setUserrole] = React.useState('user');

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logout = () => {
    dispatch(authActions.logout());
  };
  const allRoles = ['tester', 'admin', 'sales', 'qc', 'reviewer', 'indyqc', 'user', 'supervisor', 'userid_266'];
  let menus = [
    {
      path: '/',
      roles: allRoles,
      key: '0',
      icon: <HomeOutlined />,
      title: 'Dashboard',
      children: [
        {
          path: '/audit-progress',
          key: '0.1',
          roles: allRoles,
          icon: <DashboardOutlined />,
          title: 'Audit Progress'
        },
        {
          path: '/audit-status',
          key: '0.2',
          // roles: allRoles,
          roles: [],
          icon: <AuditOutlined />,
          title: 'Audit Status'
        },
        {
          path: '/audit-status-mom',
          key: '0.2',
          // roles: allRoles,
          roles: [],
          icon: <CalendarOutlined />,
          title: 'Audit Status MOM'
        },
        {
          path: '/actual-plan',
          key: '0.3',
          // roles: allRoles,
          roles: [],
          icon: <GroupOutlined />,
          title: 'Actual Vs Plan'
        },
        {
          path: '/pass-and-fail',
          key: '0.4',
          // roles: allRoles,
          roles: [],
          icon: <BoxPlotOutlined />,
          title: 'Pass and Fail'
        },
        {
          path: '#kpi-reports',
          key: '0.6',
          roles: allRoles,
          icon: <DeploymentUnitOutlined />,
          title: 'Kpi Reports',
          children: [
            {
              path: '/all-kpi',
              key: '0.61',
              roles: allRoles,
              icon: <DeploymentUnitOutlined />,
              title: 'All Kpi'
            },

            // {
            //   path: '/osa-npd-pro',
            //   key: '0.62',
            //   roles: allRoles,
            //   icon: <HeatMapOutlined />,
            //   title: 'Osa Npd Pro'
            // },

            {
              path: '/store-kpi',
              key: '0.63',
              // roles: allRoles,
              roles: [],
              icon: <OneToOneOutlined />,
              title: 'Store Kpi'
            },
            {
              path: '/detail-kpi',
              key: '0.64',
              roles: allRoles,
              icon: <OneToOneOutlined />,
              title: 'Detail Kpi'
            },
          ]
        },

        {
          path: '/perf-all-auditors',
          key: '0.9',
          roles: allRoles,
          icon: <ControlOutlined />,
          title: 'Auditor Perf.',
          children: [
            // {
            //   path: '/perf-all-customers',
            //   key: '0.91',
            //   roles: allRoles,
            //   icon: <IdcardOutlined />,
            //   title: 'All Customers'
            // },
            // {
            //   path: '/perf-all-auditors',
            //   key: '0.92',
            //   roles: allRoles,
            //   icon: <RobotOutlined />,
            //   title: 'All Auditors'
            // },
          ]
        },

      ]
    },
    {
      path: '#manager',
      key: '1',
      roles: ['tester', 'admin', 'qc', 'reviewer', 'indyqc', 'userid_266'],
      icon: <ContainerOutlined />,
      title: 'Quản lí',
      children: [
        {
          path: '/in-out',
          key: '1.0',
          roles: ['tester', 'admin', 'qc', 'reviewer', 'userid_266'],
          icon: <ImportOutlined />,
          title: 'CICO'
        },
        {
          path: '/in-out-indie',
          key: '1.1',
          roles: ['tester', 'indyqc', 'userid_266'],
          icon: <ImportOutlined />,
          title: 'CICO Indie'
        },
        {
          path: '/in-out-review',
          key: '1.2',
          roles: ['tester', 'admin', 'reviewer', 'userid_266'],
          icon: <ImportOutlined />,
          title: 'CICO Review'
        },
        {
          path: '/llv-manager',
          key: '1.3',
          roles: ['tester', 'admin', 'userid_266'],
          icon: <AlertOutlined />,
          title: 'LLV'
        },
        {
          path: '/user',
          key: '1.4',
          roles: ['userid_40', 'userid_266'],//fix admin8
          icon: <UserOutlined />,
          title: 'Người dùng'
        },
      ]
    },
    {
      path: '#data',
      key: '2',
      roles: ['tester', 'admin', 'supervisor', 'userid_266'],
      icon: <DatabaseOutlined />,
      title: 'Dữ liệu',
      children: [
        {
          path: '/upload',
          key: '2.1',
          roles: ['tester', 'admin', 'userid_266'],
          icon: <UploadOutlined />,
          title: 'Tải lên'
        },
        {
          path: '/download',
          key: '2.2',
          roles: ['tester', 'admin', 'supervisor', 'userid_266'],
          icon: <DownloadOutlined />,
          title: 'Tải xuống'
        },
      ]
    },
    {
      path: '#report',
      key: '3',
      roles: ['tester', 'admin', 'sales', 'supervisor', 'userid_266'],
      icon: <RiseOutlined />,
      title: 'Báo cáo',
      children: [
        // {
        //   path: '/report-overview',
        //   key: '7',
        //   icon: <AreaChartOutlined />,
        //   title: 'Report Overview'
        // },
        {
          path: '/detail-report',
          key: '3.1',
          roles: ['tester', 'admin', 'sales', 'supervisor', 'userid_266'],
          icon: <BarChartOutlined />,
          title: 'Chi tiết'
        },
      ]
    },
    {
      path: '#settings',
      key: '4',
      roles: allRoles,
      icon: <SettingOutlined />,
      title: 'Cài đặt',
      children: [{
        path: '/changepassword',
        key: '4.1',
        roles: allRoles,
        icon: <UndoOutlined />,
        title: 'Đổi mật khẩu'
      },]
    }

    // {
    //   path: null,
    //   key: '8',
    //   icon: <LogoutOutlined />,
    //   title: 'Logout'
    // },
  ];
  // const roles = {
  //   'tester': ['/', '/auth/login', '/in-out-indie', '/in-out', '/changepassword', '/llv-manager', '/detail-report', '/user', '/upload', '/download', '/report-overview'],
  //   'admin': ['/', '/auth/login', '/in-out', '/changepassword', '/llv-manager', '/detail-report', '/user', '/upload', '/download', '/report-overview'],
  //   'sales': ['/', '/auth/login', '/changepassword', '/detail-report'],
  //   'qc': ['/', '/auth/login', '/in-out', '/changepassword'],
  //   'reviewer': ['/', '/auth/login', '/in-out', '/changepassword'],
  //   'indyqc': ['/', '/in-out-indie', '/auth/login', '/changepassword'],
  //   'user': ['/', '/auth/login', '/changepassword'],
  // };
  const rootSubmenuKeys = [];
  const [openKeys, setOpenKeys] = React.useState([]);

  const handleClick = (e) => {
    setOpenKeys(e.keyPath);
  }
  const onOpenChange = (_openKeys) => {
    if (!_openKeys || !_openKeys.length && !collapsed) {
      let __openKeys = [];
      if (activeKey && activeKey.length) {
        for (let i = 0; i < menus.length; i++) {
          if (activeKey.includes(menus[i].path)) {
            __openKeys = ['submenu_' + menus[i].key];
            break;
          }
          if (menus[i].children.length) {
            for (let ii = 0; ii < menus[i].children.length; ii++) {
              if (activeKey.includes(menus[i].children[ii].path)) {
                __openKeys = ['submenu_' + menus[i].key, 'sub_' + menus[i].key + '_sub_' + menus[i].children[ii].key];

                break;
              }
              if (menus[i].children[ii]?.children?.length) {
                for (let iii = 0; iii < menus[i].children[ii].children.length; iii++) {
                  if (activeKey.includes(menus[i].children[ii].children[iii].path)) {
                    __openKeys = ['submenu_' + menus[i].key, 'sub_' + menus[i].key + '_sub_' + menus[i].children[ii].key, 'sub_' + menus[i].key + '_sub_' + menus[i].children[ii].key + '_sub_' + menus[i].children[ii].children[iii].key];
                    // break;
                  }
                }
              }
            }

          }
        }
      }
      // console.log('__openKeys', __openKeys);
      setOpenKeys(__openKeys);

    } else {

      // console.log('activeKey', activeKey);
      // setOpenKeys(__openKeys);
      const latestOpenKey = openKeys.find(key => openKeys.indexOf(key) === -1);
      if (!latestOpenKey || rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(_openKeys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    }
  };
  React.useEffect(() => {
    const currentLocation = (history && history.location) ? history.location.pathname : '/';
    // console.log(' history.location', history.location);
    if (currentLocation != '/auth/login') {
      let _activeKey = ['/'];
      menus.map((itemmenu) => {
        if (itemmenu && itemmenu.path && itemmenu.path == currentLocation) {
          _activeKey = [itemmenu.key];
        }
      });
      // console.log('currentLocation', currentLocation);
      let _userrole = user.role;
      if (!user.role || !allRoles.includes(user.role)) {
        _userrole = 'user';
      }
      // console.log('_userrole', _userrole);
      if ((!user || !user.user_id) || !allRoles.includes(user.role)) {
        logout();
      }

      // if (roles[_userrole].includes(currentLocation)) {
      _activeKey = [currentLocation]
      // setActiveKey(_activeKey);
      //   setUserrole(_userrole);

      // } else {
      // console.log('_activeKey', _activeKey);
      let __openKeys = [];
      if (_activeKey && _activeKey.length) {
        for (let i = 0; i < menus.length; i++) {
          if (_activeKey.includes(menus[i].path)) {
            __openKeys = ['submenu_' + menus[i].key];
            break;
          }
          if (menus[i].children.length) {
            for (let ii = 0; ii < menus[i].children.length; ii++) {
              if (_activeKey.includes(menus[i].children[ii].path)) {
                __openKeys = ['submenu_' + menus[i].key, 'sub_' + menus[i].key + '_sub_' + menus[i].children[ii].key];

                break;
              }
              if (menus[i].children[ii]?.children?.length) {
                for (let iii = 0; iii < menus[i].children[ii].children.length; iii++) {
                  if (_activeKey.includes(menus[i].children[ii].children[iii].path)) {

                    __openKeys = [
                      `submenu_${menus[i].key}`,
                      `submenu_${menus[i].key}submenu_${menus[i].children[ii].key}`,
                      `sub_${menus[i].key}_sub_${menus[i].children[ii].key}`,
                      `sub_${menus[i].key}_sub_${menus[i].children[ii].key}_sub_${menus[i].children[ii].children[iii].key}`,
                    ];
                    // break;
                  }
                }
              }
            }

          }
        }
      }
      setOpenKeys(__openKeys);
      // console.log('__openKeys', __openKeys);
      setActiveKey(_activeKey);
      setUserrole(_userrole);
      // }
    } else {
      if (user) {
        logout();
      }

    }
  }, [history.location.pathname]);
  const checkLoading = () => {
    dispatch(homeActions.clearLoading());
  }
  React.useEffect(() => {
    if (isLoading && !isDownLoading) {
      const timeout = setTimeout(() => {
        checkLoading();
      }, 90000);
      return () => clearTimeout(timeout);
    }
  }, [isLoading, isDownLoading]);
  React.useEffect(() => {
    checkLoading();
  }, []);
  React.useEffect(() => {
    if (!collapsed) {
      onOpenChange([]);
    }
  }, [collapsed]);
  const { Text } = Typography;
  return (
    <>
      <Spin spinning={isLoading}>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0,
            }}
            width={250}
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <div style={{ padding: 10, maxwidth: '100%' }} >

              <Image
                src={'/assets/images/logoapp.png'}
                height={'auto'}
                width={60}
                preview={false}
                style={{ objectFit: 'cover', margin: 'auto', display: 'block' }}
              />
            </div>
            <div style={{ padding: 10, display: 'flex', flexDirection: (!collapsed ? 'row' : 'column'), alignItems: 'center', justifyContent: 'space-between' }}  >

              <Avatar style={{ verticalAlign: 'middle' }} size="large" >
                {user.role?.charAt(0).toUpperCase() || ''}
              </Avatar>
              <Button
                size="small"
                type="primary" danger
                style={{ marginTop: (collapsed) ? 10 : 0, verticalAlign: 'middle', backgroundColor: ColorsList.danger }}
                onClick={() => logout()}
              >
                Đăng xuất
              </Button>

            </div>
            {menus.length ? <Menu theme="dark" mode="inline" selectedKeys={openKeys}
              openKeys={openKeys}
              onClick={handleClick}
              onOpenChange={onOpenChange}
            >
              {menus.filter((menuitem) => {
                return !menuitem.path || ((menuitem.roles.includes(userrole) || menuitem.roles.includes('userid_' + user.user_id)) && menuitem.path !== '/auth/login')
              }).map((itemmenu, _index) => {
                return (itemmenu.children && itemmenu.children.length ? <Menu.SubMenu key={'submenu_' + itemmenu.key} icon={itemmenu.icon} title={itemmenu.title}

                >
                  {itemmenu.children.filter((menuitem) => {
                    return !menuitem.path || ((menuitem.roles.includes(userrole) || menuitem.roles.includes('userid_' + user.user_id)) && menuitem.path !== '/auth/login')
                  }).map((_itemmenu, __index) => {
                    if (_itemmenu.children && _itemmenu.children.length) {
                      return <Menu.SubMenu key={'submenu_' + itemmenu.key + 'submenu_' + _itemmenu.key} icon={_itemmenu.icon} title={_itemmenu.title}>
                        {_itemmenu.children.filter((mi) => {
                          return !mi.path || ((mi.roles.includes(userrole) || mi.roles.includes('userid_' + user.user_id)) && mi.path !== '/auth/login')
                        }).map((_im, __id) => {
                          return <Menu.Item key={'sub_' + itemmenu.key + '_sub_' + _itemmenu.key + '_sub_' + _im.key} icon={_im.icon}
                            onClick={() => {
                              if (_im.path) {
                                history.push(_im.path)
                              } else {
                                logout();
                              }
                            }}
                          >
                            {_im.title}
                          </Menu.Item>
                        })}
                      </Menu.SubMenu>

                    } else {
                      return <Menu.Item key={'sub_' + itemmenu.key + '_sub_' + _itemmenu.key} icon={_itemmenu.icon}
                        onClick={() => {
                          if (_itemmenu.path) {
                            history.push(_itemmenu.path)
                          } else {
                            logout();
                          }
                        }}
                      >
                        {_itemmenu.title}
                      </Menu.Item>
                    }
                  })}
                </Menu.SubMenu> : <Menu.Item key={'Menu_Item' + itemmenu.key} icon={itemmenu.icon}
                  onClick={() => {
                    if (itemmenu.path) {
                      history.push(itemmenu.path)
                    } else {
                      logout();
                    }
                  }}
                >
                  {itemmenu.title}
                </Menu.Item>)
              })}
            </Menu> : null}
          </Sider>
          <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 250 }}>
            <Header className="site-layout-background" style={{ padding: 0 }}>
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggle,
              })}
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: 24,
                minHeight: 280,
              }}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.home && state.home.isLoading ? state.home.isLoading : false,
    isOffline: state.home && state.home.isOffline ? state.home.isOffline : false,
    isDownLoading: state.home && state.home.isDownLoading ? state.home.isDownLoading : false,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};

export default connect(mapStateToProps)(HomeLayout);
