import React from 'react';
import {
    Table, Typography
} from 'antd';
import NumberFormat from 'react-number-format';
import { red, grey } from '@ant-design/colors';
const DetailKpiTable = (props) => {
    const { Text } = Typography;
    const { datas, filterDatas, allCategories } = props;
    const defaultColumns = [
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 12 }}>Customer/Category</Text>,
            dataIndex: 'Customer-Category',
            fixed: 'left',
            width: 150,
            key: 'Customer-Category',
            render: (value, record) => {
                return <Text style={{ textTransform: 'uppercase', fontSize: 12 }}>{record[0]}</Text>
            },
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 12 }}>TOTALS</Text>,
            dataIndex: 'Totals',
            key: 'Totals',
            align: 'center',
            width: 100,
            render: (value, record) => {

                let total = record[1] ? (record[1].totalCustomer ? record[1].totalCustomer[0] : (record[1].totals ? record[1].totals[0] : 0)) : 0;
                let detail = record[1] ? (record[1].totalCustomer ? record[1].totalCustomer[1] : (record[1].totals ? record[1].totals[1] : 0)) : 0;
                return total ? <NumberFormat placeholder="0" value={detail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' %'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10, color: 'red' }} strong>-</Text>
                // return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
            },
        },

    ];
    const defaultShopColumns = [
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 12 }}>Store</Text>,
            dataIndex: 'Store',
            fixed: 'left',
            width: 158,
            key: 'Store',
            render: (value, record) => {
                return <Text style={{ textTransform: 'uppercase', fontSize: 12 }}>{record[0]}</Text>
            },
        },
        {
            title: <Text style={{ textTransform: 'uppercase', fontSize: 12 }}>TOTALS</Text>,
            dataIndex: 'Totals',
            key: 'Totals',
            align: 'center',
            width: 100,
            render: (value, record) => {
                let total = record[1] ? (record[1].totalCustomer ? record[1].totalCustomer[0] : (record[1].totals ? record[1].totals[0] : 0)) : 0;
                let detail = record[1] ? (record[1].totalCustomer ? record[1].totalCustomer[1] : (record[1].totals ? record[1].totals[1] : 0)) : 0;
                // console.log('record[1]1', total1, record);
                // let total = 0;
                return total ? <NumberFormat placeholder="0" value={detail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' %'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10, color: 'red' }} strong>-</Text>
                // return total ? <NumberFormat placeholder="0" value={total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
            },
        },

    ];
    const [columns, setColumns] = React.useState(defaultColumns);
    const [expandColumns, setExpandColumns] = React.useState(defaultShopColumns);
    const [expandCategoryColumns, setExpandCategoryColumns] = React.useState(defaultColumns);
    React.useEffect(() => {
        let _columns = [...defaultColumns];
        let _expandColumns = [...defaultShopColumns];
        if (allCategories && allCategories.length) {

            for (let i = 0; i < allCategories.length; i++) {
                _columns.push(
                    {
                        title: <Text style={{ textTransform: 'uppercase', fontSize: 12 }}>{allCategories[i]}</Text>,
                        dataIndex: allCategories[i],
                        width: 100,
                        key: allCategories[i],
                        align: 'center',
                        render: (value, record) => {
                            let total = (record[1].totals && record[1].totals[allCategories[i]]) ? record[1].totals[allCategories[i]][0] : 0
                            let detail = (record[1].totals && record[1].totals[allCategories[i]]) ? record[1].totals[allCategories[i]][1] : 0
                            return total ? <NumberFormat placeholder="0" value={detail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' %'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                        },
                    }
                );
                _expandColumns.push(
                    {
                        title: allCategories[i],
                        dataIndex: allCategories[i],
                        key: allCategories[i],
                        width: 100,
                        align: 'center',
                        render: (value, record) => {
                            let total = (record[1] && record[1][allCategories[i]]) ? record[1][allCategories[i]][0] : 0
                            let detail = (record[1] && record[1][allCategories[i]]) ? record[1][allCategories[i]][1] : 0
                            return total ? <NumberFormat placeholder="0" value={detail * 100 / total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' %'} decimalScale={2} renderText={(value) => <Text style={{ fontSize: 10 }}>{value}</Text>} /> : <Text style={{ fontSize: 10 }} strong>-</Text>
                        },
                    }
                );
            }
        }
        setColumns(_columns);
        setExpandColumns(_expandColumns);

    }, [allCategories])



    return <Table
        dataSource={datas}
        rowKey={(record) => record[0]}
        pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
        columns={columns}
        scroll={{ x: (allCategories) ? allCategories.length * 150 + 150 : 150 }}
        className='tablelevel1'
        expandable={{
            expandedRowRender: (record) => {
                let key = record[0];
                let shopDatas = { ...record[1] };
                delete shopDatas.totals;
                delete shopDatas.totalCustomer;
                const _shopDatas = Object.entries(shopDatas);
                return <Table
                    dataSource={_shopDatas}
                    rowKey={(record, index1) => {
                        return key + '__' + (index1) + record[0]
                    }}
                    className='tablelevel2'
                    showHeader={true}
                    pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                    columns={expandColumns}
                    scroll={{ x: (allCategories) ? allCategories.length * 150 + 150 : 150 }}

                />
            }
        }}
    />
}
export default DetailKpiTable;