import React from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Image,
  Space,
  Form,
  Button,
  DatePicker,
  Tabs,
  Switch,
  List as AntList,
  Divider,
  Input,
  Collapse,
  Dropdown,
  Menu,
  Modal,
  Select

} from 'antd';

import { connect } from 'react-redux';
// import { homeActions } from '../redux/actions';
// import authActions from '../../auth/redux/actions';
// import history from '../../../common/history';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const StoreKpi = ({ dispatch, home, user }) => {
  const { Text, Title } = Typography;
  const rootData = {
    NPD: {
      customer: [
        {
          name: '7-ELEVENT',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        }, {
          name: 'BSMART',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        },
      ],
      supervisor: [
        {
          name: 'ĐÀO XUÂN TRƯỜNG',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        }, {
          name: 'HỒNG VĨNH THANH',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        },
      ]
    },
    OSA: {
      customer: [
        {
          name: '7-ELEVENT',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        }, {
          name: 'BSMART',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        },
      ],
      supervisor: [
        {
          name: 'ĐÀO XUÂN TRƯỜNG',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        }, {
          name: 'HỒNG VĨNH THANH',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        },
      ]
    },
    PROMOTION: {
      customer: [
        {
          name: '7-ELEVENT',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        }, {
          name: 'BSMART',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        },
      ],
      supervisor: [
        {
          name: 'ĐÀO XUÂN TRƯỜNG',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        }, {
          name: 'HỒNG VĨNH THANH',
          data: [
            {
              name: 'Clear dầu gội 630g',
              store: 15,
              nostock: 0,
              failstock: 0,
              failprice: 0,
            },
            {
              name: 'Clear dầu gội 7gx10/66 dây',
              store: 11,
              nostock: 83.33,
              failstock: 0,
              failprice: 83.33,
            },
            {
              name: 'Clear dầu gội 880g',
              store: 15,
              nostock: 6.67,
              failstock: 0,
              failprice: 6.67,
            }
          ]
        },
      ]
    }

  };
  const fulldatas = {
    filterDatas: {
      period: ['2022-01', '2022-02', '2022-03', '2022-04'],
      auditdate: ['2022-04-02', '2022-04-03', '2022-04-04', '2022-04-05'],
      format: ['BIG FORMAT', 'SMALL FORMAT'],
      position: ['COC', 'GE', 'MS', 'OL', 'SS'],
      type: 'region',
      lowerOnly: false,
    }
  }
  const [filterDatas, setFilterDatas] = React.useState(fulldatas.filterDatas);


  React.useEffect(() => {

  }, [filterDatas])

  return (
    <Row>
      <Col span={24}>
        <Card
          title={"STORE KPI"}
          bordered={false}
          style={{ width: '100%' }}
          extra={
            <Form name="filter" layout="inline">
              <Form.Item
                name="period"
                label="Month"
              >
                <Select placeholder="Please choose" mode="multiple" style={{ minWidth: 150 }}
                  defaultValue={filterDatas.period} allowClear
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.period = value;
                    setFilterDatas(_filterDatas)
                  }}
                >
                  <Select.Option value="2022-04">04/2022</Select.Option>
                  <Select.Option value="2022-03">03/2022</Select.Option>
                  <Select.Option value="2022-02">02/2022</Select.Option>
                  <Select.Option value="2022-01">01/2022</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="format"
                label="Format"
              >
                <Select placeholder="Please choose" mode="multiple" style={{ minWidth: 150 }}
                  defaultValue={filterDatas.format} allowClear
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.format = value;
                    setFilterDatas(_filterDatas)
                  }}
                >
                  <Select.Option value="BIG FORMAT">BIG FORMAT</Select.Option>
                  <Select.Option value="SMALL FORMAT">SMALL FORMAT</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="auditdate"
                label="Audit Date"
              >
                <Select placeholder="Please choose" mode="multiple" style={{ minWidth: 150 }}
                  defaultValue={filterDatas.auditdate} allowClear
                  onChange={(value) => {
                    let _filterDatas = { ...filterDatas }
                    _filterDatas.auditdate = value;
                    setFilterDatas(_filterDatas)
                  }}
                >
                  <Select.Option value="2022-04-02">02/04/2022</Select.Option>
                  <Select.Option value="2022-04-03">03/04/2022</Select.Option>
                  <Select.Option value="2022-04-04">04/04/2022</Select.Option>
                  <Select.Option value="2022-04-05">05/04/2022</Select.Option>
                  <Select.Option value="2022-04-06">06/04/2022</Select.Option>
                  <Select.Option value="2022-04-07">07/04/2022</Select.Option>
                </Select>
              </Form.Item>
              <Switch
                checkedChildren="Lower only"
                unCheckedChildren="Show all"
                checked={filterDatas.lowerOnly}
                onChange={(checked) => {
                  let _filterDatas = { ...filterDatas }
                  _filterDatas.lowerOnly = checked;
                  setFilterDatas(_filterDatas)
                }}
              />
            </Form>
          }
        >
          <Row>
            <Col span={24}>
              <Typography.Title style={{ fontSize: 16, marginTop: 15 }}>ALL KPIs PASS PERCENT</Typography.Title>
              <Table
                dataSource={rootData.NPD[filterDatas.type] || []}
                rowKey={(record) => record.id}
                pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                columns={[
                  {
                    title: 'Customer',
                    dataIndex: 'customer',
                    key: 'customer',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>{record.name}</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.name}</Text></div>
                        })}
                      </div>
                    },
                  },

                  {
                    title: 'C2A MIX',
                    dataIndex: 'C2A_MIX',
                    key: 'C2A_MIX',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.nostock || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'NPD',
                    dataIndex: 'npd',
                    key: 'npd',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failstock || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'OSA',
                    dataIndex: 'osa',
                    key: 'osa',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failprice || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'PROMOTION',
                    dataIndex: 'promotion',
                    key: 'promotion',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failprice || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'PROMS',
                    dataIndex: 'proms',
                    key: 'proms',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failprice || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'PROOL DUP',
                    dataIndex: 'prool-dup',
                    key: 'prool-dup',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failprice || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'Retal',
                    dataIndex: 'retal',
                    key: 'retal',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failprice || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                ]}
              />
            </Col>
            <Col span={24}>
              <Typography.Title style={{ fontSize: 16, marginTop: 15 }}>SOS BY STORE AND CATEGORY</Typography.Title>
              <Table
                dataSource={rootData.NPD[filterDatas.type] || []}
                rowKey={(record) => record.id}
                pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
                columns={[
                  {
                    title: 'Labels',
                    dataIndex: 'labels',
                    key: 'labels',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>{record.name}</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.name}</Text></div>
                        })}
                      </div>
                    },
                  },

                  {
                    title: 'Total SOS Unilever',
                    dataIndex: 'total-sos-unilever',
                    key: 'total-sos-unilever',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.nostock || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'Total SOS Store',
                    dataIndex: 'total-sos-store',
                    key: 'total-sos-store',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failstock || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'SOS DOB',
                    dataIndex: 'sos-dob',
                    key: 'sos-dob',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failprice || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'SOS import',
                    dataIndex: 'sos-import',
                    key: 'sos-import',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failprice || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'SOS %',
                    dataIndex: 'sos-pc',
                    key: 'sos-pc',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failprice || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'SOS TARGET',
                    dataIndex: 'sos-target',
                    key: 'sos-target',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failprice || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                  {
                    title: 'SOS % ON TARGET',
                    dataIndex: 'sos-on-target',
                    key: 'sos-on-target',
                    render: (value, record) => {
                      return <div>
                        <Text style={{ textTransform: 'uppercase' }}>&nbsp;</Text>
                        {!!record.data.length && record.data.map((type, indextype) => {
                          return <div style={{ borderTop: (indextype) ? '1px solid #ddd' : 'none' }}><Text style={{ fontSize: 12 }} key={'' + indextype}>{type.failprice || '0.00%'}</Text></div>
                        })}
                      </div>
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        </Card>
      </Col >
    </Row >
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(StoreKpi);
