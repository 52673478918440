import React from 'react';

import { Row, Col, Card, Form, Select, Button, DatePicker, Input } from 'antd';

import { connect } from 'react-redux';
import { downloadActions } from './redux/actions';
import history from '../../common/history';
const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};
const customers = ["MATSUKIYO", "HASAKI", "7 - ELEVEN", "GENSHAI MINIMART", "VISSAN", "HAPRO FOOD", "AN NAM", "NHAN VAN", "BEE MART", "KING FOOD", "AEON M", "FINELINE M", "IND NORTH M", "PHARMACITY", "BHX ONLINE", "CIRCLE K", "MINISTOP", "FAMILY MART", "WINPLUS", "COOPFOOD", "MEDICARE", "BSMART", "IND NORTH", "COOPMART", "WATSONS", "BEAUTY BOX", "CITI", "FARMERS MARKET", "ABBW", "THIEN HOA", "TGSF", "SOCIOLLA", "JMART", "NUTY", "SAMMI", "WINMART", "MEGA M", "LAN CHI", "MINT COSMETIC", "COCO", "THANH DO", "GS25", "AN PHU", "KOHNAN", "LAN CHI M", "GENSHAI", "HOANG DUC", "SATRA"];
const DownloadLayout = ({ dispatch, user }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const formData = {
      type: values.option || 'full',
      user_id: user.user_id,
      date_from: values.date ? values.date[0] : '',
      date_to: values.date ? values.date[1] : '',
      format_type: values.format_type || 'BIG FORMAT',
      customer: values.customer || 'BIG FORMAT',
      // yearweek: values.yearweek ? values.yearweek : '',
    };
    // console.log('formData', formData);
    dispatch(downloadActions.exportData(formData));
  };
  const _directDownload = (file_name) => {
    let params = {
      file_name
    }
    // console.log('formData', formData);
    dispatch(downloadActions.directDownload(params));
  };

  const onReset = () => {
    form.resetFields();
  };
  React.useEffect(() => {
    if (!user || !['tester', 'admin'].includes(user.role)) {
      // history.push('/');
    }
  }, [user]);
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Card title="Tải xuống" bordered={false} style={{ width: '100%' }}>
          <Form {...layout} form={form} name="control-ref" onFinish={onFinish}>
            <Form.Item
              name="option"
              label="Option"
            >
              <Select defaultValue={'full'} placeholder="Select an option you want to download" allowClear>
                {/* <Option value="osa">osa</Option>
                <Option value="sos">sos</Option>
                <Option value="weekend">weekend</Option>
                <Option value="prool">prool</Option>
                <Option value="rental">rental</Option>
                <Option value="npd">npd</Option>
                <Option value="tracking">tracking</Option>
                <Option value="procms">procms</Option>
                <Option value="c2a">c2a</Option> */}
                <Option value="full">Full</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="format_type"
              label="Format"
            >
              <Select defaultValue={'BIG FORMAT'} placeholder="Select an option you want to download">
                <Option value="BIG FORMAT">BIG FORMAT</Option>
                <Option value="SMALL FORMAT">SMALL FORMAT</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="customer"
              label="Customer"
            >
              <Select placeholder="Select a customer you want to download">
                {customers.map((customer) => {
                  return <Option key={customer} value={customer}>{customer}</Option>
                })}
              </Select>
            </Form.Item>
            <Form.Item name="date" label="Date" rules={[{ type: 'array', required: false }]}>
              <RangePicker style={{ width: '100%' }} format="DD-MM-YYYY" />
            </Form.Item>
            {/* <Form.Item
              name="yearweek"
              label="Yearweek"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input placeholder="yearweek" />
            </Form.Item> */}
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" style={{ marginRight: '10px' }}>
                Submit
              </Button>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      {((user?.user_id == 53) || (user?.user_id == 42)) ? <Col span={12}>
        <Card title="Tải báo cáo có sẵn" bordered={false} style={{ width: '100%' }}>
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <Button type="primary" htmlType="button" onClick={() => {
              _directDownload('full_detail_7_day_bf.xls')
            }}>
              7 days Big FORMAT
            </Button>
            <Button type='default' danger htmlType="button" onClick={() => {
              _directDownload('full_detail_7_day_sf.xls')
            }}>
              7 days SMALL FORMAT
            </Button>
          </div>
        </Card>
      </Col> : null}
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth && state.auth.user ? state.auth.user : null,
  };
};
export default connect(mapStateToProps)(DownloadLayout);
