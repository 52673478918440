import React from 'react';
import _ from 'lodash';
import {
    Row,
    Col,
    Card,
    Table,
    Typography,
    Image,
    Space,
    Form,
    Button,
    DatePicker,
    Tabs,
    Switch,
    List as AntList,
    Divider,
    Input,
    Collapse,
    Dropdown,
    Menu,
    Modal,
    Select

} from 'antd';

import { connect } from 'react-redux';

import { green, gold, red, grey } from '@ant-design/colors';
import { homeActions } from '../redux/actions';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import AllCustomersTable from './AllCustomersTable';
// import Procms from './OsaNpdProProol/Procms';
// import Prool from './OsaNpdProProol/Prool';
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const AllCustomers = ({ dispatch, home, user }) => {
    const { Text, Title } = Typography;
    const [form] = Form.useForm();
    // const kpiCheckList = home.kpiCheckListInMonth || null;
    // const allKpiDatas = home.allKpiDatas || null;
    const filterHomeData = home.filterDatas || null;
    const checklistTypes = [
        'c2a', 'npd', 'olbig', 'osa', 'procms', 'prool', 'rental', 'sos'
    ];
    const [lastfilter, setLastfilter] = React.useState(null);
    const [customers, setCustomers] = React.useState([]);
    const [regions, setRegions] = React.useState([]);
    const [supervisors, setSupervisors] = React.useState([]);
    const [phases, setPhases] = React.useState([]);
    const [auditors, setAuditor] = React.useState([]);


    const [isFirstLoad, setIsFirstLoad] = React.useState(true);
    const [kpiCheckList, setKpiCheckList] = React.useState([]);
    const [kpiCheckListChart, setKpiCheckListChart] = React.useState([]);
    const [datas, setDatas] = React.useState([]);
    const [months, setMonths] = React.useState([]);
    const [dataBar, setDataBar] = React.useState(null);
    const generetorColor = (length) => {
        if (length == 3) {
            let graphColors = ['rgba(235, 69, 95,0.2)', 'rgba(43, 52, 103,0.2)', 'rgba(60, 207, 78,0.2)'];
            let graphOutlines = ['rgba(235, 69, 95,1)', 'rgba(43, 52, 103,1)', 'rgba(60, 207, 78,1)'];
            let hoverColor = ['rgba(235, 69, 95,0.6)', 'rgba(43, 52, 103,0.6)', 'rgba(60, 207, 78,0.6)'];
            return { graphColors, graphOutlines, hoverColor };
        }
        let graphColors = [];
        let graphOutlines = [];
        let hoverColor = [];
        let i = 0;
        while (i <= length) {
            let randomR = Math.floor((i / length * 130) + 100);
            let randomG = Math.floor(((length - i) / length * 65) + 100);
            let randomB = Math.floor(((length - i) / length * 130) + 100);

            let graphBackground = "rgba("
                + randomR + ", "
                + randomG + ", "
                + randomB + ",0.2)";
            graphColors.push(graphBackground);

            let graphOutline = "rgb("
                + randomR + ", "
                + randomG + ", "
                + randomB + ")";
            graphOutlines.push(graphOutline);

            let hoverColors = "rgba("
                + (randomR + 25) + ", "
                + (randomG + 25) + ", "
                + (randomB + 25) + ",0.6)";
            hoverColor.push(hoverColors);

            i++;
        };
        return { graphColors, graphOutlines, hoverColor };
    }
    const optionsBar = {
        plugins: {
            title: {
                display: true,
                text: (months) ? moment(months[0], 'YYYY-MM').format('MM/YYYY') + ' - ' + moment(months[2], 'YYYY-MM').format('MM/YYYY') : '-',
            },
            tooltip: {
                enabled: true
            },
            legend: {
                display: false,
                position: 'bottom',
                align: 'start',
                labels: {
                    // boxWidth: 12,
                    usePointStyle: true
                }

            },
            datalabels: {
                backgroundColor: function (context) {
                    return 'transparent';// context.dataset.backgroundColor;
                },
                color: '#000',
                borderColor: 'white',
                borderRadius: 25,
                // borderWidth: 2,
                // color: 'white',
                display: function (context) {
                    var dataset = context.dataset;
                    var count = dataset.data.length;
                    var value = dataset.data[context.dataIndex];
                    return value > count * 1.5;
                },
                font: {
                    weight: 'bold',
                    size: 0
                },
                padding: 6,
                formatter: (value, ctx) => {
                    let percentage = value.toFixed(2);
                    return percentage + ' %';
                },
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: false,
                font: {
                    size: 10
                },
            },
            y: {
                stacked: false,
            },
        },
    };

    const fulldatas = {
        defaultFilter: {
            region: "",
            customer: "",
            supervisor: "",
            phase: "",
            auditor: "",
            month: filterHomeData && filterHomeData.month ? moment(filterHomeData.month, 'MM-YYYY') : moment(),
            format: filterHomeData?.format || 'BIG FORMAT',
            lowerOnly: 0,
        }
    }
    const [filterDatas, setFilterDatas] = React.useState(fulldatas.defaultFilter);
    const onOk = (value) => {
        // setLastfilter(null);
        let _filterDatas = { ...fulldatas.defaultFilter };
        form.resetFields();
        let _toDate = '';
        let _fromDate = '';
        let m = moment.utc(value).daysInMonth();
        if (value) {
            _toDate = moment.utc(value).tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
            _fromDate = moment.utc(value).tz(moment.tz.guess(true)).format('01-MM-YYYY');
            _filterDatas.month = moment.utc(value).tz(moment.tz.guess(true)).format('YYYY-MM');
        } else {
            _toDate = moment.utc().tz(moment.tz.guess(true)).format(m + '-MM-YYYY');
            _fromDate = moment.utc().tz(moment.tz.guess(true)).format('01-MM-YYYY');
            _filterDatas.month = moment(moment(), 'YYYY-MM');
        }
        // console.log(_filterDatas);
        setFilterDatas(_filterDatas);
        // getDatas(_fromDate);
        // _getKpiReportCheckListItemByCategory(_fromDate, _toDate)
    };
    const analyticData = (_data, _customerDatas) => {
        let data = _data.data;
        let customer = _data.customer;
        let keydata = 'qc_statistics';
        // console.log('keydata', keydata);
        //_kpiCheckList[i].data.npd_by_category
        if (typeof (_customerDatas[customer]) === 'undefined') {
            _customerDatas[customer] = {
                totals: { 'store_audit': [], 'store_qc': [], 'qc_total': [0, 0] }
            }; // customer
            for (let j = 0; j < checklistTypes.length; j++) {
                _customerDatas[customer].totals[checklistTypes[j]] = [0, 0];
            }
        }
        if (data[keydata]) {
            let shopname = data[keydata].shop_name;
            if (shopname) {
                if (typeof (_customerDatas[customer][shopname]) === 'undefined') {
                    _customerDatas[customer][shopname] = {
                        totals: { 'store_audit': [], 'store_qc': [], 'qc_total': [0, 0] }
                    }; // customer
                    for (let j = 0; j < checklistTypes.length; j++) {
                        _customerDatas[customer][shopname].totals[checklistTypes[j]] = [0, 0];
                    }
                }



                if (data[keydata].qc_statistics && Object.entries(data[keydata].qc_statistics).length) {
                    if (!_customerDatas[customer].totals.store_audit.includes(shopname)) {
                        _customerDatas[customer].totals.store_audit.push(shopname)
                    }
                    if (data[keydata].is_qced && !_customerDatas[customer].totals.store_qc.includes(shopname)) {
                        _customerDatas[customer].totals.store_qc.push(shopname)
                    }
                    // _customerDatas[customer].totals.qc_total[0] += (data[keydata].qc_statistics.totals) ? data[keydata].qc_statistics.totals[0] : 0;
                    // _customerDatas[customer].totals.qc_total[1] += (data[keydata].qc_statistics.totals) ? data[keydata].qc_statistics.totals[1] : 0;
                    for (let j = 0; j < checklistTypes.length; j++) {
                        _customerDatas[customer].totals[checklistTypes[j]][0] += (data[keydata].qc_statistics[checklistTypes[j]] && data[keydata].qc_statistics[checklistTypes[j]].totals) ? data[keydata].qc_statistics[checklistTypes[j]].totals[0] : 0;
                        _customerDatas[customer].totals[checklistTypes[j]][1] += (data[keydata].qc_statistics[checklistTypes[j]] && data[keydata].qc_statistics[checklistTypes[j]].totals) ? data[keydata].qc_statistics[checklistTypes[j]].totals[1] : 0;
                    }
                    Object.entries(data[keydata].qc_statistics).map(([key, value]) => {// key la check list type
                        if (!_customerDatas[customer][shopname].totals.store_audit.includes(shopname)) {
                            _customerDatas[customer][shopname].totals.store_audit.push(shopname)
                        }
                        if (data[keydata].is_qced && !_customerDatas[customer][shopname].totals.store_qc.includes(shopname)) {
                            _customerDatas[customer][shopname].totals.store_qc.push(shopname)
                        }

                        if (key !== 'totals') {
                            _customerDatas[customer][shopname].totals[key][0] += (value && value.totals) ? value.totals[0] : 0;
                            _customerDatas[customer][shopname].totals[key][1] += (value && value.totals) ? value.totals[1] : 0;

                            _customerDatas[customer].totals.qc_total[0] += (value && value.totals) ? value.totals[0] : 0;
                            _customerDatas[customer].totals.qc_total[1] += (value && value.totals) ? value.totals[1] : 0;

                            if (value && Object.entries(value).length) {
                                _customerDatas[customer][shopname].totals.qc_total[0] += (value.totals) ? value.totals[0] : 0;
                                _customerDatas[customer][shopname].totals.qc_total[1] += (value.totals) ? value.totals[1] : 0;
                                Object.entries(value).map(([key1, value1]) => {// key 1 la category

                                    if (key1 && key1 !== 'totals') {

                                        if (typeof (_customerDatas[customer][shopname][key1]) === 'undefined') {
                                            _customerDatas[customer][shopname][key1] = {
                                                totals: { 'store_audit': [], 'store_qc': [], 'qc_total': [0, 0] }
                                            }; // customer
                                            for (let j = 0; j < checklistTypes.length; j++) {
                                                _customerDatas[customer][shopname][key1][checklistTypes[j]] = [0, 0];
                                            }
                                        }
                                        if (!_customerDatas[customer][shopname][key1].totals.store_audit.includes(shopname)) {
                                            _customerDatas[customer][shopname][key1].totals.store_audit.push(shopname)
                                        }
                                        if (data[keydata].is_qced && !_customerDatas[customer][shopname][key1].totals.store_qc.includes(shopname)) {
                                            _customerDatas[customer][shopname][key1].totals.store_qc.push(shopname)
                                        }
                                        _customerDatas[customer][shopname][key1][key][0] += (value1) ? value1[0] : 0;
                                        _customerDatas[customer][shopname][key1][key][1] += (value1) ? value1[1] : 0;
                                        _customerDatas[customer][shopname][key1].totals.qc_total[0] += (value1) ? value1[0] : 0;
                                        _customerDatas[customer][shopname][key1].totals.qc_total[1] += (value1) ? value1[1] : 0;
                                    }
                                    //    _customerDatas[customer][shopname].totals.store_audit += (data[keydata].is_qced) ? 1 : 0;
                                });
                            }

                        }

                        // data[keydata].is_qced
                    })
                }
            }
            return _customerDatas;
        }
        return _customerDatas;
    }
    const analyticDataChart = (_data, _customerDatas) => {
        let yearMonth = moment(_data.date).format('YYYY-MM');

        let data = _data.data;
        let customer = _data.customer;
        let keydata = 'qc_statistics';
        if (data[keydata]) {
            let shopname = data[keydata].shop_name;
            if (shopname) {
                //init data
                if (typeof (_customerDatas[customer]) === 'undefined') {
                    _customerDatas[customer] = {}; // auditor
                }
                if (typeof (_customerDatas[customer][yearMonth]) === 'undefined') {
                    _customerDatas[customer][yearMonth] = [0, 0];

                }

                if (data[keydata].qc_statistics && Object.entries(data[keydata].qc_statistics).length) {
                    Object.entries(data[keydata].qc_statistics).map(([key, value]) => {// key la check list type
                        if (key !== 'totals') {
                            _customerDatas[customer][yearMonth][0] += (value && value.totals) ? value.totals[0] : 0;
                            _customerDatas[customer][yearMonth][1] += (value && value.totals) ? value.totals[1] : 0;
                        }

                        // data[keydata].is_qced
                    })
                }

                return _customerDatas;
            }
            return _customerDatas;
        }
        return _customerDatas;
    }

    React.useEffect(() => {
        let _datas = [];
        let _customers = [];
        let _regions = [];
        let _supervisors = [];
        let _phases = [];
        let _auditors = [];
        let _customerDatas = {
        };

        if (kpiCheckList && kpiCheckList.length) {
            let _kpiCheckList = kpiCheckList.filter((_itemdata) => {
                if (
                    (filterDatas.region != '' && filterDatas.region != _itemdata.region) ||
                    (filterDatas.customer != '' && filterDatas.customer != _itemdata.customer) ||
                    (filterDatas.supervisor != '' && filterDatas.supervisor != _itemdata.supervisor) ||
                    (filterDatas.phase != '' && filterDatas.phase != _itemdata.phase) ||
                    (filterDatas.auditor != '' && filterDatas.auditor != _itemdata.data.qc_statistics.auditor_type) ||
                    (filterDatas.format != '' && filterDatas.format != _itemdata.format_type)
                ) {
                    return false;
                }
                return true;
            });
            for (let i = 0; i < _kpiCheckList.length; i++) {
                let _customer = _kpiCheckList[i].customer || null;
                if (_customer && !_customers.includes(_customer)) {
                    _customers.push(_customer);
                }
                if (_kpiCheckList[i].supervisor && !_supervisors.includes(_kpiCheckList[i].supervisor)) {
                    _supervisors.push(_kpiCheckList[i].supervisor)
                }
                if (_kpiCheckList[i].region && !_regions.includes(_kpiCheckList[i].region)) {
                    _regions.push(_kpiCheckList[i].region)
                }
                if (_kpiCheckList[i].phase && !_phases.includes(_kpiCheckList[i].phase)) {
                    _phases.push(_kpiCheckList[i].phase)
                }
                if (_kpiCheckList[i].data.qc_statistics.auditor_type && !_auditors.includes(_kpiCheckList[i].data.qc_statistics.auditor_type)) {
                    // if()
                    _auditors.push(_kpiCheckList[i].data.qc_statistics.auditor_type)
                }
                _customerDatas = analyticData(_kpiCheckList[i], _customerDatas);
                // _customerDatas = { ...__customerDatas }
                // _customerDatas.npd[_customer].



            }
            _datas = Object.entries(_customerDatas);
        }
        setDatas(_datas);
        // setProDatas(_proDatas);
        // setProolDatas(_proolDatas);
        // setColumns(_columns);
        // setExpandColumns(_expandcolumns);
        // setColumnsProol(_columnsProol);
        // setExpandCategoryColumns(_expandCategorycolumns);
        // console.log('_auditors_auditors', _auditors);
        if (isFirstLoad || !lastfilter) {
            setCustomers(_customers);
            setSupervisors(_supervisors);
            setPhases(_phases);
            setAuditor(_auditors);
            setRegions(_regions);
        } else {
            if (lastfilter == 'customer') {
                if (filterDatas.customer == '') {
                    setCustomers(_customers);
                }
                setSupervisors(_supervisors);
                setPhases(_phases);
                setAuditor(_auditors);
                setRegions(_regions);
            } else if (lastfilter == 'region') {
                if (filterDatas.region == '') {
                    setRegions(_regions);
                }
                setCustomers(_customers);
                setSupervisors(_supervisors);
                setPhases(_phases);
                setAuditor(_auditors);
            } else if (lastfilter == 'supervisor') {
                if (filterDatas.supervisor == '') {
                    setSupervisors(_supervisors);
                }
                setCustomers(_customers);
                setPhases(_phases);
                setAuditor(_auditors);
                setRegions(_regions);
            } else if (lastfilter == 'phase') {
                if (filterDatas.phase == '') {
                    setPhases(_phases);
                }
                setCustomers(_customers);
                setSupervisors(_supervisors);
                setAuditor(_auditors);
                setRegions(_regions);
            } else if (lastfilter == 'auditor') {
                if (filterDatas.auditor == '') {
                    setAuditor(_auditors);
                }
                setCustomers(_customers);
                setPhases(_phases);
                setSupervisors(_supervisors);
                setRegions(_regions);
            }
        }
        setIsFirstLoad(false);
    }, [kpiCheckList, isFirstLoad, filterDatas]);
    React.useEffect(() => {
        let _customerDatasChart = {}
        if (kpiCheckListChart && kpiCheckListChart.length) {
            let _kpiCheckListChart = kpiCheckListChart.filter((_itemdata) => {
                if (
                    (filterDatas.region != '' && filterDatas.region != _itemdata.region) ||
                    (filterDatas.customer != '' && filterDatas.customer != _itemdata.customer) ||
                    (filterDatas.supervisor != '' && filterDatas.supervisor != _itemdata.supervisor) ||
                    (filterDatas.phase != '' && filterDatas.phase != _itemdata.phase) ||
                    (filterDatas.auditor != '' && filterDatas.auditor != _itemdata.data.qc_statistics.auditor_type) ||
                    (filterDatas.format != '' && filterDatas.format != _itemdata.format_type)
                ) {
                    return false;
                }
                return true;
            });
            for (let i = 0; i < _kpiCheckListChart.length; i++) {
                // let yearMonth = moment(_kpiCheckListChart[i].date).format('YYYY-MM');
                // if (yearMonth != '2022-09') {
                //   console.log(_kpiCheckListChart[i].data);
                // }
                _customerDatasChart = analyticDataChart(_kpiCheckListChart[i], _customerDatasChart);


            }
            let labels = Object.keys(_customerDatasChart);
            // console.log('_customerDatasChart', _customerDatasChart);
            // _months
            let colors = generetorColor(3);
            let _datasets = [];

            for (let j = 0; j < months.length; j++) {
                let data = {
                    label: months[j],
                    data: [],
                    backgroundColor: colors.hoverColor[j],

                };
                for (let i = 0; i < labels.length; i++) {
                    let _data = _customerDatasChart[labels[i]];

                    let total = _data && _data[months[j]] ? _data[months[j]][0] : 0;
                    let detail = _data && _data[months[j]] ? _data[months[j]][1] : 0;

                    let percent = (total > 0) ? Math.round(detail * 10000 / total) / 100 : 0;
                    data.data.push(percent)
                }
                _datasets.push(data);
            }

            let _datasChart = {
                labels: labels,
                datasets: _datasets,
            }
            // console.log('_datasChart', _datasChart);
            setDataBar(_datasChart)
        }

    }, [kpiCheckListChart, filterDatas]);
    const getDatas = async (_fromDate, _toDate) => {
        if (_fromDate && _toDate) {
            let res = await dispatch(homeActions.getAuditProgressMixDims({ date_from: _fromDate, date_to: _toDate }));
            if (res && res.length) {
                // console.log('resres', res[0]);
            }

            setKpiCheckList(res);
        }
    };
    const getDatasChart = async (_fromDate, _toDate) => {
        if (_fromDate && _toDate) {
            let res = await dispatch(homeActions.getAuditProgressMixDims({ date_from: _fromDate, date_to: _toDate }));

            setKpiCheckListChart(res);
        }
    }
    const _getDatas = async () => {
        let _fromDate, _toDate, _fromDateChart, _months = [];
        if (filterDatas.month) {
            let _d = moment.utc(filterDatas.month),
                _dc = moment.utc(filterDatas.month),
                _lm = moment.utc(filterDatas.month);
            let m = _d.daysInMonth();
            _fromDate = _d.format('01-MM-YYYY');
            _fromDateChart = _dc.subtract(2, 'months');
            let lastMonth = _lm.subtract(1, 'months');
            _toDate = _d.format(m + '-MM-YYYY');
            _months = [_fromDateChart.format('YYYY-MM'), lastMonth.format('YYYY-MM'), _d.format('YYYY-MM')];
            setMonths(_months);
            await getDatas(_fromDate, _toDate);
            await getDatasChart(_fromDateChart.format('01-MM-YYYY'), _toDate);
        }
    }
    React.useEffect(() => {
        _getDatas();
    }, [filterDatas.month]);
    const _saveFilter = async () => {
        let _filterDatas = { ...filterDatas }
        _filterDatas.month = moment(_filterDatas.month).format('MM-YYYY');
        console.log(' _filterDatas.month1', _filterDatas.month);
        if (_filterDatas.month != 'Invalid date') {
            dispatch(homeActions.saveFilter(_filterDatas));
        }
    }
    React.useEffect(() => {
        _saveFilter();
    }, [filterDatas]);
    return (
        <Row>
            <Col span={24} style={{ backgroundColor: '#f0f2f5' }}>
                <Collapse accordion defaultActiveKey={['filter']} style={{ position: 'sticky', top: 0, zIndex: 4 }}>
                    <Collapse.Panel header="Filter" key="filter">
                        <Card
                            size={'small'}
                            bordered={false}
                            bodyStyle={{ padding: 0 }}
                            style={{ width: '100%' }}>
                            <Form name="filter" form={form} layout='vertical'>
                                <Row gutter={16}>
                                    <Col>
                                        <Form.Item title="Month" label='Month' >
                                            <DatePicker format="MM-YYYY" picker="month" onChange={onOk} style={{ width: '100%' }}
                                                value={filterDatas.month ? moment(filterDatas.month) : moment()}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="region"
                                            label="Region"
                                        >
                                            <Select placeholder="Chọn All" value={filterDatas.region} style={{ minWidth: 100 }} onChange={(value) => {
                                                setLastfilter('region');
                                                let _filterDatas = { ...filterDatas }
                                                _filterDatas.region = value;
                                                setFilterDatas(_filterDatas);
                                            }}>
                                                <Select.Option value={''} key={'region-1'}>{'Chọn All'}</Select.Option>
                                                {regions && regions.length ? regions.filter((__region) => {
                                                    if (lastfilter == 'region') {
                                                        return true;
                                                    } else {
                                                        return (filterDatas.region) ? __region == filterDatas.region : true;
                                                    }
                                                }).map((_region, index) => {
                                                    return <Select.Option value={_region} key={'region' + index}>{_region}</Select.Option>
                                                }) : null}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="customer"
                                            label="Customer"
                                        >
                                            <Select placeholder="Chọn All" value={filterDatas.customer} style={{ minWidth: 150 }} onChange={(value) => {
                                                setLastfilter('customer');
                                                let _filterDatas = { ...filterDatas }
                                                _filterDatas.customer = value;
                                                setFilterDatas(_filterDatas);
                                            }}>
                                                <Select.Option value={''} key={'customer-1'}>{'Chọn All'}</Select.Option>
                                                {customers && customers.length ? customers.filter((__customer) => {
                                                    if (lastfilter == 'customer') {
                                                        return true;
                                                    } else {
                                                        return (filterDatas.customer) ? __customer == filterDatas.customer : true;
                                                    }
                                                }).map((_customer, index) => {
                                                    return <Select.Option value={_customer} key={'customer' + index}>{_customer}</Select.Option>
                                                }) : null}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="supervisor"
                                            label="Supervisor"
                                        >
                                            <Select placeholder="Chọn All" value={filterDatas.supervisor} style={{ minWidth: 200 }} onChange={(value) => {
                                                setLastfilter('supervisor');
                                                let _filterDatas = { ...filterDatas }
                                                _filterDatas.supervisor = value;
                                                setFilterDatas(_filterDatas);
                                            }}>
                                                <Select.Option value={''} key={'supervisor-1'}>{'Chọn All'}</Select.Option>
                                                {supervisors && supervisors.length ? supervisors.filter((__supervisor) => {
                                                    if (lastfilter == 'supervisor') {
                                                        return true;
                                                    } else {
                                                        return (filterDatas.supervisor) ? __supervisor == filterDatas.supervisor : true;
                                                    }
                                                }).map((_supervisor, index) => {
                                                    return <Select.Option value={_supervisor} key={'supervisor' + index}>{_supervisor}</Select.Option>
                                                }) : null}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="phase"
                                            label="Phases"
                                        >
                                            <Select placeholder="Chọn All" value={filterDatas.phase} style={{ minWidth: 100 }} onChange={(value) => {
                                                setLastfilter('phase');
                                                let _filterDatas = { ...filterDatas }
                                                _filterDatas.phase = value;
                                                setFilterDatas(_filterDatas);
                                            }}>
                                                <Select.Option value={''} key={'phase-1'}>{'Chọn All'}</Select.Option>
                                                {phases && phases.length ? phases.map((_phase, index) => {
                                                    return <Select.Option value={_phase} key={'phase' + index}>{_phase}</Select.Option>
                                                }) : null}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="auditor"
                                            label="Auditor"
                                        >
                                            <Select placeholder="Chọn All" value={filterDatas.auditor} style={{ minWidth: 200 }} onChange={(value) => {
                                                setLastfilter('auditor');
                                                let _filterDatas = { ...filterDatas }
                                                _filterDatas.auditor = value;
                                                setFilterDatas(_filterDatas);
                                            }}>
                                                <Select.Option value={''} key={'auditor-1'}>{'Chọn All'}</Select.Option>
                                                {auditors && auditors.length ? auditors.filter((_auditor) => {
                                                    return _auditor != 't'
                                                }).map((_auditor, index) => {
                                                    return <Select.Option value={_auditor} key={'auditor' + index}>{_auditor}</Select.Option>
                                                }) : null}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="format"
                                            label="Format"
                                        >
                                            <Text style={{ fontSize: 0 }}>{filterDatas.format}</Text>
                                            <Select placeholder="BIG FORMAT" value={filterDatas.format || "BIG FORMAT"} style={{ minWidth: 150 }} onChange={(value) => {
                                                setLastfilter('format');
                                                let _filterDatas = { ...filterDatas }
                                                _filterDatas.format = value;
                                                setFilterDatas(_filterDatas);
                                            }}>
                                                <Select.Option value="BIG FORMAT" key={'format-2'}>BIG FORMAT</Select.Option>
                                                <Select.Option value="SMALL FORMAT" key={'format-3'}>SMALL FORMAT</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Collapse.Panel>
                </Collapse>
                <Card
                    title={"Table"}
                    bordered={false}
                    style={{ width: '100%', marginTop: 15 }}
                >
                    <AllCustomersTable datas={datas} />

                </Card>
                <Card
                    title={"Chart"}
                    bordered={false}
                    style={{ width: '100%', marginTop: 15 }}
                >
                    {!!dataBar && <Bar options={optionsBar} data={dataBar} />}
                </Card>

            </Col >
        </Row >
    );
};

const mapStateToProps = (state) => {
    return {
        home: state.home,
        user: state.auth && state.auth.user ? state.auth.user : null,
    };
};
export default connect(mapStateToProps)(AllCustomers);
